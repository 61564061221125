import { Fragment, useState, useEffect } from 'react'
import { connect } from 'react-redux'
import {
  useGetMLModelQuery,
} from '../../api/config'
import LiveInferenceListener from '../../telemetry/LiveInferenceListener'
import InferenceTarget from './InferenceTarget'
import {
  Checkbox,
  Grid,
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'

const styles = theme => ({
  gridHeader: {
    borderBottom: `1px solid ${theme.palette.text.primary}`,
    padding: theme.spacing(1),
  },
  gridLabel: {
    borderRight: `1px solid ${theme.palette.text.primary}`,
    padding: theme.spacing(1, 1, 1, 0),
    display: 'flex',
    alignItems: 'center',
  },
  gridLabelText: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  object: {
    padding: theme.spacing(1),
    textAlign: 'center',
  }
})

function InferenceTable({ classes, modelId, inference_table }) {
  const [ mlLabels,   setMlLabels   ] = useState(['person', 'car', 'truck', 'boat'])
  const [ checkboxes, setCheckboxes ] = useState(Array(4).fill(true)) // same index as mlLabels

  const { data: labelData } = useGetMLModelQuery(modelId, { skip: !modelId })

  useEffect(() => {
    const labels = labelData?.data?.ml_model?.labels
    if (labels) {
      const newLabels = [ ...labels ]
      // newLabels.push('Person')
      setMlLabels(newLabels)
      setCheckboxes(Array(newLabels.length).fill(true))
    }
  }, [labelData])

  return (<LiveInferenceListener>
    <Grid container>
      <Grid item xs={1} className={classes.gridHeader}>Object</Grid>
      <Grid item xs={11} className={classes.gridHeader}>Identified</Grid>
      { mlLabels.map((label, i) =>
        <Fragment key={label}>
          <Grid item xs={1} className={classes.gridLabel}>
            <Checkbox
              color='primary'
              checked={checkboxes[i]}
              onChange={(e) => setCheckboxes(prev => {
                const next = [...prev]
                next[i] = e.target.checked
                setCheckboxes(next)
              })}
            />
            <div className={classes.gridLabelText}>{label}</div>
          </Grid>
          <Grid item xs={11} container>
            { checkboxes[i] && inference_table[label] &&
              Object.keys(inference_table[label]).map(id => {
                const name = `${label} ${id}`
                return <Grid item xs={1} key={id} className={classes.object}>
                  <InferenceTarget name={name} />
                </Grid>
              })
            }
          </Grid>
        </Fragment>)
      }
    </Grid>
  </LiveInferenceListener>)
}

const mapStateToProps = state => ({
  inference_table: state.inference.inference_table,
})

export default connect(mapStateToProps, null)(withStyles(styles)(InferenceTable))
