import {
  List,
  ListItem,
  ListItemText,
  Grid,
  Typography,
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'

const styles = theme => ({
  stats: {
    padding: theme.spacing(1),
    overflowWrap: 'anywhere'
  }
})

function GridPanel({ classes, label, value, list, children }) {
  if (list?.length > 0) 
    return (
      <Grid item xs={4} className={classes.stats}>
        <Typography variant='overline'>{label}</Typography>
        <List dense>
          {list.map((l, i) => (
            <ListItem key={i}>
              <ListItemText primary={l?.toString()} />
            </ListItem>
          ))}
        </List>
        <Typography variant='h5'>{value?.toString()}</Typography>
        { children }
      </Grid>
    )
  else
    return (
      <Grid item xs={4} className={classes.stats}>
        <Typography variant='overline'>{label}</Typography>
        <Typography variant='h5'>{value?.toString()}</Typography>
        { children }
      </Grid>
    )
}

export default withStyles(styles)(GridPanel)
