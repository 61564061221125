import React from "react"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { switchNetwork } from "../../modules/actions/commandActions"
import { FormLabel, FormControlLabel, RadioGroup, Radio, FormControl } from "@material-ui/core"

const NetworkSwitch = props => {
    const { onSelect, networks, setSelectedNetwork } = props
    const networksList = Boolean(networks) || []

    const [value, setValue] = React.useState(
        networksList.length > 0
            ? networksList.filter(network => network.status === "selected")[0].id
            : ""
    )

    // Update this to command action to send to drone
    const onChangeStub = event => {
        onSelect()
        setValue(event.target.value)
        setSelectedNetwork(event.target.value)
    }

    return (
        <FormControl>
            <FormLabel focused={false} component="legend">
                Network
            </FormLabel>
            {networksList && networksList.length > 0 ? (
                <RadioGroup value={value} onChange={event => onChangeStub(event)}>
                    {networksList.map(network => (
                        <FormControlLabel
                            control={<Radio />}
                            label={network.id}
                            value={network.id}
                            disabled={network.status === "error"}
                        />
                    ))}
                </RadioGroup>
            ) : (
                <div>Not Available</div>
            )}
        </FormControl>
    )
}

const mapStateToProps = state => {
    return {
        networks: state.telemetry.telemetry.networks, // TODO: State removed, to be replaced with call to Flight Status
    }
}

const mapDispatchToProps = dispatch => {
    return bindActionCreators(
        {
            switchNetwork,
        },
        dispatch
    )
}

export default connect(mapStateToProps, mapDispatchToProps)(NetworkSwitch)
