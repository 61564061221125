import { useState }           from 'react'
import { bindActionCreators } from 'redux'
import { connect }            from 'react-redux'
import { useInControl }       from '../../utils/useFlightStates'
import { takeOff }            from '../../modules/actions/commandActions'
import { addWarning }         from '../../modules/actions/warningsActions'
import ConfirmTakeOffDialog   from '../Components/ConfirmTakeOffDialog'
import { Button }             from '@material-ui/core'
import { withStyles }         from '@material-ui/core/styles'
import { ReactComponent as TakeOffIcon } from '../../static/images/icon_droneTakeoff.svg'

const styles = theme => ({
  button: {
    width:  theme.spacing(24),
    height: theme.spacing(7),
  },
  svg: {
    height: theme.spacing(4),
  },
})

function TakeOffButton({ classes, connected, isLocked, isReady, takeOff, addWarning }) {
  const inControl = useInControl()

  const canTakeoff = connected && inControl && isReady
  
  const [ confirmOpen, setConfirmOpen ] = useState(false)

  function handleConfirmTakeoff() {
    setConfirmOpen(false)
    addWarning({
      source: 'GCS',
      severity: 'NO_EFFECT',
      details: 'Taking Off',
      name: 'OTHER',
      time: Date.now()
    })
    takeOff()
  }

  return (
    <>
      <Button
        color='primary'
        variant='contained'
        disabled={!canTakeoff}
        className={classes.button}
        startIcon={<TakeOffIcon className={classes.svg} />}
        onClick={() => setConfirmOpen(true)}>
        Take Off
      </Button>
      { confirmOpen &&
        <ConfirmTakeOffDialog
          actionText={'taking off'}
          buttonText={'Take Off'}
          open={confirmOpen}
          onClose={() => setConfirmOpen(false)}
          onConfirm={handleConfirmTakeoff}
        />
      }
    </>
  )
}

const mapStateToProps = state => ({
  connected: state.telemetry?.linkStatus === null,
  isLocked:  state.command.isLocked,
  isReady:   state.command.isReady
})

const mapDispatchToProps = dispatch => {
  return bindActionCreators({ takeOff, addWarning }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(TakeOffButton))
