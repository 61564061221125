/*
  camera.js v1.1 http://github.com/idevelop/camera.js
  Forked from https://raw.githubusercontent.com/idevelop/ascii-camera/master/script/camera.js
*/
const camera = (function() {
  var options
  var video, canvas, context
  var renderTimer

  function initVideoStream() {
    video = document.createElement('video')
    video.setAttribute('width', options.width)
    video.setAttribute('height', options.height)
    video.setAttribute('muted', true) // doesn't seem to prevent the feedback in the video object
    video.setAttribute('playsinline', 'true')
    video.setAttribute('webkit-playsinline', 'true')

    navigator.getUserMedia = navigator.getUserMedia || navigator.webkitGetUserMedia || navigator.mozGetUserMedia || navigator.msGetUserMedia
    window.URL = window.URL || window.webkitURL || window.mozURL || window.msURL

    if (navigator.getUserMedia) {
      navigator.getUserMedia({
        video: true,
        audio: false, // setting to true currently has feedback. to investigate how to capture without feedback
      }, function(stream) {
        options.onSuccess()

        if (video.mozSrcObject !== undefined) { // hack for Firefox < 19
          video.mozSrcObject = stream
        } else {
          video.srcObject = stream
        }

        initCanvas()
      }, options.onError)
    } else {
      options.onNotSupported()
    }
  }

  function initCanvas() {
    canvas = options.targetCanvas || document.createElement('canvas')
    canvas.setAttribute('width', options.width)
    canvas.setAttribute('height', options.height)

    context = canvas.getContext('2d')

    // mirror video
    if (options.mirror) {
      context.translate(canvas.width, 0)
      context.scale(-1, 1)
    }
  }

  function startCapture() {
    initVideoStream()

    video.play()

    renderTimer = setInterval(function() {
      try {
        context.drawImage(video, 0, 0, video.width, video.height)
        options.onFrame(canvas)
      } catch (e) {
        // TODO
      }
    }, Math.round(1000 / options.fps))
  }

  function stopCapture() {
    pauseCapture()

    context.clearRect(0, 0, canvas.width, canvas.height)

    const stream = video.mozSrcObject || video.srcObject
    const tracks = stream.getTracks()
    tracks.forEach(track => track.stop())

    if (video.mozSrcObject !== undefined) {
      video.mozSrcObject = null
    } else {
      video.srcObject = null
    }
  }

  function pauseCapture() {
    if (renderTimer) clearInterval(renderTimer)
    video.pause()
  }

  return {
    init: function(captureOptions) {
      var doNothing = function(){}

      options = captureOptions || {}

      options.fps = options.fps || 30
      options.width = options.width || 640
      options.height = options.height || 480
      options.mirror = options.mirror || false
      options.targetCanvas = options.targetCanvas || null // TODO: is the element actually a <canvas> ?

      options.onSuccess = options.onSuccess || doNothing
      options.onError = options.onError || doNothing
      options.onNotSupported = options.onNotSupported || doNothing
      options.onFrame = options.onFrame || doNothing
    },

    start: startCapture,

    pause: pauseCapture,

    stop: stopCapture
  }
})()

export default camera
