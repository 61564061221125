import { getAccessToken } from './accounts'

const MEDIA_URL = process.env.REACT_APP_MEDIA_URL
if (!MEDIA_URL)
  throw new Error('Environment Variable REACT_APP_MEDIA_URL is required.')

export function createMediaUrl({ mediaId, size }) {
  if (mediaId && ['original', 'fullscreen', 'preview', 'thumb', 'upnorth'].includes(size))
    return MEDIA_URL + '/m/' + mediaId + '/' + size + '?token=' + getAccessToken()
  else
    return ''
}
