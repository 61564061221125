// While TelemetryListener focuses on the drone that's being flown, a separate
// socket is used to listen to any nearby drones.
//
// What constitutes "nearby" is defined in the backend by company_id for now.
// In the future this will need to go towards Identification Service Areas

import { useRef, useEffect } from 'react'
import Sockette from 'sockette'
import { getDecodedToken } from '../api/accounts'

const TRACKER_WS_API = process.env.REACT_APP_TRACKER_WEBSOCKET_API

if (!TRACKER_WS_API)
  throw Error('Environment Variable REACT_APP_TRACKER_WEBSOCKET_API is missing')

function trackWS({
  onOpen, onMessage, onReconnect, onMaximum, onClose, onError
}) {
  const { company_id, access_token } = getDecodedToken()
  if (!company_id || !access_token)
    return null

  const url  = `${TRACKER_WS_API}/web/?companyId=${company_id}&access_token=${access_token}`
  const ws = new Sockette(url, {
    timeout:     5000, // 5 seconds
    maxAttempts: 3,    // 3 attempts
    onopen:      e => { onOpen      ? onOpen(e)      : console.info('tracker ws connected.', e) },
    onmessage:   e => { onMessage   ? onMessage(e)   : console.info('tracker ws received:', e) },
    onreconnect: e => { onReconnect ? onReconnect(e) : console.info('tracker ws reconnecting..', e) },
    onmaximum:   e => { onMaximum   ? onMaximum(e)   : console.info('tracker ws stop attempting.', e) },
    onclose:     e => { onClose     ? onClose(e)     : console.info('tracker ws closed.', e) },
    onerror:     e => { onError     ? onError(e)     : console.error('tracker ws error:', e) },
  })
  return ws
}

function NearbyTelemetryListener({
  // showCfmsTracks,
  onNearbyTelemetryMessage,
}) {
  // https://stackoverflow.com/questions/60152922/proper-way-of-using-react-hooks-websockets
  const wsRef = useRef(null)

  useEffect(() => {
    if (wsRef.current)
      return // only need to create it once

    // To figure out whether we can split regular tracks or combine
    // if (showCfmsTracks) {
      wsRef.current = trackWS({ onMessage: onNearbyTelemetryMessage })

      // Cannot close here: it will close after every message...
      // const wsCurrent = wsRef.current
      // return (() => {
      //   wsCurrent.close()
      // })
    // }
  }, [onNearbyTelemetryMessage])

  return null
}

export default NearbyTelemetryListener
