import { useState } from 'react'
import { connect }  from 'react-redux'
import { bindActionCreators } from 'redux'
import { switchNetwork } from '../../modules/actions/commandActions'

import DaaControls from '../../daa/DaaControls'
import NetworkSwitch from './NetworkSwitch'
import {
  Button,
  Card,
  CardActions,
  CardContent,
  Divider,
  Typography,
} from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import { withStyles } from '@material-ui/core/styles'

const styles = theme => ({
  button: {
    marginLeft: 'auto',
  },
  divider: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
})

function AdvancedSettings({ classes, switchNetwork, isNetworkSwitchSuccess }) {
  const [ networkUpdated,  setNetworkUpdated  ] = useState(false)
  const [ selectedNetwork, setSelectedNetwork ] = useState('')
  const [ didSwitch,       setDidSwitch       ] = useState(false)
  const [ daaUpdated,      setDaaUpdated      ] = useState(false)

  const onSelection = () => {
    setNetworkUpdated(true)
    setDidSwitch(false)
  }

  const onSave = () => {
    switchNetwork(selectedNetwork, setDidSwitch)
    setNetworkUpdated(false)
    console.log('Switching Network: ', selectedNetwork)
  }

  return (
    <Card variant='outlined'>
      <CardContent>
        <Typography variant='h6' gutterBottom>
          Options
        </Typography>
        <Divider className={classes.divider} />
        <DaaControls setDaaUpdated={setDaaUpdated} />
        <CardActions>
          <Button
            color='primary'
            variant='contained'
            className={classes.button}
            disabled={!daaUpdated}
            onClick={() => console.log('save daa selection')}>
            Save
          </Button>
        </CardActions>
        <Divider className={classes.divider} />
        <NetworkSwitch onSelect={onSelection} setSelectedNetwork={setSelectedNetwork} />
        { didSwitch &&
          (isNetworkSwitchSuccess ? (
            <Alert>Network switch successful</Alert>
          ) : (
            <Alert severity='error'>Network switch unsuccessful</Alert>
          ))}
        <CardActions>
          <Button
            color='primary'
            variant='contained'
            className={classes.button}
            disabled={!networkUpdated}
            onClick={onSave}>
            Save
          </Button>
        </CardActions>
      </CardContent>
    </Card>
  )
}

const mapDispatchToProps = dispatch => bindActionCreators({ switchNetwork }, dispatch)

const mapStateToProps = ({ command: isNetworkSwitchSuccess }) => ({ ...isNetworkSwitchSuccess })

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(AdvancedSettings))
