import {
  PAYLOAD_ZOOM_ACTION,
  PAYLOAD_PITCH_ACTION,
  PAYLOAD_YAW_ACTION,
  UPDATE_PAYLOAD_TELEMETRY,
  PAYLOAD_RESET_PITCH_YAW_ACTION,
  PAYLOAD_RECORD_ACTION,
  // SET_WINCH_ACTION,
  // SET_SERVO_ACTION,
} from '../actions/payloadActions'

const initialState = {
  states: [] // {payload_id: _id_, (remaining values)}
}

function payloadReducer(state = initialState, action) {
  switch (action.type) {
    case PAYLOAD_ZOOM_ACTION:
      return state
    case PAYLOAD_PITCH_ACTION:
      return state
    case PAYLOAD_YAW_ACTION:
      return state
    case UPDATE_PAYLOAD_TELEMETRY:
      // console.log('payload reducer: action', action, 'state', state)
      const { payloadTelemetry } = action
      return {
        ...state,
        payloadTelemetry: {
          ...payloadTelemetry
        }
      }
    case PAYLOAD_RESET_PITCH_YAW_ACTION:
      return state
    case PAYLOAD_RECORD_ACTION:
      return state
    default:
      return state
  }
}

export default payloadReducer
