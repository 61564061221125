import { useState, useEffect } from 'react'
import { useWindowSize } from 'usehooks-ts'

import {
  Typography,
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'

const styles = theme => ({
  info: {
    color: theme.palette.common.white,
  },
  name: {
    lineHeight: 0.8
  },
  title: {
    lineHeight: 0.8
  },
})

const dayWord = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']
function leadZero(n) {
  return n >= 10 || n < 0 ? '' + n : '0' + n
}

function MissionInfo({ classes, name, drone, pilot, commander }) {
  const { width } = useWindowSize()
  const [ browserDate, setBrowserDate ] = useState('')
  const [ browserTime, setBrowserTime ] = useState('')

  function updateTime(time) {
    // TODO: Is there a better way to avoid creating many Date objects?
    const t = new Date()
    setBrowserDate(`${dayWord[t.getDay()]} ${t.getDate()}/${t.getMonth() + 1}/${t.getFullYear()}`)
    setBrowserTime(`${leadZero(t.getHours())}:${leadZero(t.getMinutes())}:${leadZero(t.getSeconds())}`)
  }

  useEffect(() => {
    const timerID = setInterval(() => updateTime(), 999)
    return () => clearInterval(timerID)
  })

  if (width < 620) {
    return null
  }

  if (width < 1024) {
    return <>
      { name && (
        <div className={classes.info}>
          <Typography variant='overline' className={classes.title}>
            Mission
          </Typography>
          <Typography variant='h6' className={classes.name}>
            {name}
          </Typography>
        </div>
      )}
      <div className={classes.info}>
        <Typography variant='overline' className={classes.title}>
          { browserDate }
        </Typography>
        <Typography variant='h6' className={classes.name}>
          { browserTime }
        </Typography>
      </div>
    </>
  }

  return (
    <>
      { name && (
        <div className={classes.info}>
          <Typography variant='overline' className={classes.title}>
            Mission
          </Typography>
          <Typography variant='h6' className={classes.name}>
            {name}
          </Typography>
        </div>
      )}
      <div className={classes.info}>
        <Typography variant='overline' className={classes.title}>
          Drone
        </Typography>
        <Typography variant='h6' className={classes.name}>
          { drone }
        </Typography>
      </div>
      <div className={classes.info}>
        <Typography variant='overline' className={classes.title}>
          Pilot
        </Typography>
        <Typography variant='h6' className={classes.name}>
          { pilot }
        </Typography>
      </div>
      <div className={classes.info}>
        <Typography variant='overline' className={classes.title}>
          Commander
        </Typography>
        <Typography variant='h6' className={classes.name}>
          { commander }
        </Typography>
      </div>
      <div className={classes.info}>
        <Typography variant='overline' className={classes.title}>
          { browserDate }
        </Typography>
        <Typography variant='h6' className={classes.name}>
          { browserTime }
        </Typography>
      </div>
    </>
  )
}

export default withStyles(styles)(MissionInfo)