import {
  Button,
  Grid,
} from '@material-ui/core'
import {
  Gavel,
  TrendingUp,
  Warning,
} from '@material-ui/icons'
import { withStyles } from '@material-ui/core/styles'

const styles = theme => ({
  container: {
    height: theme.spacing(6),
    backgroundColor: theme.palette.bluegrey.dark
  },
})

function CfmsControls({
  classes,
  onToggleCfmsNfzs,       showCfmsNfz,
  onToggleCfmsTracks,     showCfmsTracks,
  onToggleCfmsViolations, showCfmsViolations,
}) {

  const toggleCfmsNfzButton =
    <Button
      color={ showCfmsNfz ? 'secondary' : 'primary' }
      variant='contained'
      startIcon={<Warning />}
      onClick={onToggleCfmsNfzs}>
      { showCfmsNfz ? 'Hide' : 'Show' } CFMS No Fly Zones
    </Button>

  const toggleCfmsTracksButton =
    <Button
      color={ showCfmsTracks ? 'secondary' : 'primary' }
      variant='contained'
      startIcon={<TrendingUp />}
      onClick={onToggleCfmsTracks}>
      { showCfmsTracks ? 'Hide' : 'Show' } CFMS Tracks
    </Button>

  const toggleCfmsViolationsButton =
    <Button
      color={ showCfmsViolations ? 'secondary' : 'primary' }
      variant='contained'
      startIcon={<Gavel />}
      onClick={onToggleCfmsViolations}>
      { showCfmsViolations ? 'Hide' : 'Show' } CFMS Violations
    </Button>

  return (
    <Grid container justifyContent='space-evenly' alignItems='center' className={classes.container}>
      <Grid item>{ toggleCfmsNfzButton        }</Grid>
      <Grid item>{ toggleCfmsTracksButton     }</Grid>
      <Grid item>{ toggleCfmsViolationsButton }</Grid>
    </Grid>
  )
}

export default withStyles(styles)(CfmsControls)
