import L from 'leaflet'

function featureToMarker(iconName, latlng) {

  switch (iconName) {
    // case 'airport':
    //   return L.marker(latlng, {
    //     icon: new L.icon({
    //       iconUrl: require('../../assets/map/utm/airport.png'),
    //       iconRetinaUrl: require('../../assets/map/utm/airport.png'),
    //       iconAnchor: null,
    //       shadowUrl: null,
    //       shadowSize: null,
    //       shadowAnchor: null,
    //       iconSize: [35, 35],
    //     }),
    //   })
    // case 'awas-light':
    //   return L.marker(latlng, {
    //     icon: new L.icon({
    //       iconUrl: require('../../assets/map/utm/awas-light.png'),
    //       iconRetinaUrl: require('../../assets/map/utm/awas-light.png'),
    //       iconAnchor: null,
    //       shadowUrl: null,
    //       shadowSize: null,
    //       shadowAnchor: null,
    //       iconSize: [35, 35],
    //     }),
    //   })
    // case 'fence':
    //   return L.marker(latlng, {
    //     icon: new L.icon({
    //       iconUrl: require('../../assets/map/utm/fence.png'),
    //       iconRetinaUrl: require('../../assets/map/utm/fence.png'),
    //       iconAnchor: null,
    //       shadowUrl: null,
    //       shadowSize: null,
    //       shadowAnchor: null,
    //       iconSize: [35, 35],
    //     }),
    //   })
    // case 'national-park':
    //   return L.marker(latlng, {
    //     icon: new L.icon({
    //       iconUrl: require('../../assets/map/utm/national-park.png'),
    //       iconRetinaUrl: require('../../assets/map/utm/national-park.png'),
    //       iconAnchor: null,
    //       shadowUrl: null,
    //       shadowSize: null,
    //       shadowAnchor: null,
    //       iconSize: [35, 35],
    //     }),
    //   })
    // case 'firestation':
    //   return L.marker(latlng, {
    //     icon: new L.icon({
    //       iconUrl: require('../../assets/fire-station.svg'),
    //       iconRetinaUrl: require('../../assets/fire-station.svg'),
    //       iconAnchor: null,
    //       shadowUrl: null,
    //       shadowSize: null,
    //       shadowAnchor: null,
    //       iconSize: [25, 25],
    //     }),
    //   })
    // case 'hospital':
    //   return L.marker(latlng, {
    //     icon: new L.icon({
    //       iconUrl: require('../../assets/hospital.svg'),
    //       iconRetinaUrl: require('../../assets/hospital.svg'),
    //       iconAnchor: null,
    //       shadowUrl: null,
    //       shadowSize: null,
    //       shadowAnchor: null,
    //       iconSize: [25, 25],
    //     }),
    //   })
    // case 'doc':
    //   return L.marker(latlng, {
    //     icon: new L.icon({
    //       iconUrl: require('../../assets/control-center.png'),
    //       iconRetinaUrl: require('../../assets/control-center.png'),
    //       iconAnchor: null,
    //       shadowUrl: null,
    //       shadowSize: null,
    //       shadowAnchor: null,
    //       iconSize: [25, 25],
    //     }),
    //   })
    default:
      return L.marker(latlng, {
        icon: new L.icon({
          iconUrl: require('../../static/images/doc.svg'),
          iconRetinaUrl: require('../../static/images/doc.svg'),
          iconAnchor: null,
          shadowUrl: null,
          shadowSize: null,
          shadowAnchor: null,
          iconSize: [25, 25],
        }),
      })
  }
}

export default featureToMarker
