// This even exists because we can't get GeoJSON to display Leaflet Icons correctly
// (pointToLayer shows [object Module] instead of /static/media/... in img src) - FIXME
import { useState } from 'react'
import L from 'leaflet'
import { useMapEvents, Marker, Popup, CircleMarker } from 'react-leaflet'
import dronefriendlySvg from '../../static/images/dronefriendly.svg'
import theme from '../../utils/site-theme'

function NfzPoint({ nfzGeoJson, nfzValidity }) {
  const coords = nfzGeoJson.geometry.coordinates // lng, lat
  const center = [coords[1], coords[0]]

  const [ show, setShow ] = useState(false)

  const map = useMapEvents({
    moveend() {
      setShow(map.getZoom() >= 13 && map.getBounds().contains(center))
    },
  })

  if (!show)
    return null

  const { start, end } = nfzValidity
  const pointIcon = new L.icon({
    // TODO: iconUrl:  nfzGeoJson.properties.icon === 'dronefriendly' ?
    iconUrl:  dronefriendlySvg,
    iconSize: [35, 35],
  })

  return (<>
    <CircleMarker
      radius={35}
      center={center}
      pathOptions={{
        color: theme.palette.map.lightGreen, // TODO different Point Feature different colour
        opacity: 1,
        weight: 2,
        fill: true,
        fillOpacity: 0.5,
      }}
    />
    <Marker
      position={center}
      icon={pointIcon}>
      <Popup>
        <div style={{ textAlign: 'center' }}>
          <b>{nfzGeoJson.name}</b><br />
          {new Date(start * 1000).toLocaleDateString()} - {new Date(end * 1000).toLocaleDateString()}
        </div>
      </Popup>
    </Marker>
  </>)
}

export default NfzPoint
