import { bindActionCreators } from 'redux'
import { connect }            from 'react-redux'
import { useInControl }       from '../../utils/useFlightStates'
import { Button }             from '@material-ui/core'
import { Pause }              from '@material-ui/icons'
import { withStyles }         from '@material-ui/core/styles'
import { holdDrone }          from '../../modules/actions/commandActions'

const styles = theme => ({
  button: {
    width:  '100%',
    height: theme.spacing(7),
  },
  svg: {
    transform: 'scale(1.5, 1.5)',
  },
})

function PauseButton({ classes, connected, isLocked, isMoving, holdDrone }) {
  const inControl = useInControl()
  
  const canPause = connected && inControl && isMoving
  return (
    <Button
      size='small'
      disabled={!canPause}
      variant='contained'
      color='primary'
      className={classes.button}
      onClick={holdDrone}>
      <Pause className={classes.svg} />
    </Button>
  )
}

const mapStateToProps = state => ({
  connected: state.telemetry?.linkStatus === null,
  isLocked:  state.command.isLocked,
  isMoving:  state.command.isMoving,
})

const mapDispatchToProps = dispatch => {
  return bindActionCreators({ holdDrone }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(PauseButton))
