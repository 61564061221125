import {
  RETRIEVE_DEPLOYMENT_SUCCESS,
  RETRIEVE_DEPLOYMENT_FAILURE,
  RETRIEVE_DEPLOYMENT_LOADING,
  GET_ALL_DEPLOYMENTS_SUCCESS,
  GET_ALL_DEPLOYMENTS_FAILURE,
} from '../actions/deployment'

const DEPLOYMENT_STORAGE = 'DEPLOYMENT'
const initialState = {
  deploymentInfoLoading: false,
  deploymentInfoLoaded: false,
  deploymentInfoError: null,
  deploymentData: window.localStorage.getItem(DEPLOYMENT_STORAGE) !== null ? 
    JSON.parse(window.localStorage.getItem(DEPLOYMENT_STORAGE)) : null,
}

function deploymentReducer(state = initialState, action) {
  switch (action.type) {
    case RETRIEVE_DEPLOYMENT_SUCCESS:
      return {
        ...state,
        deploymentData: action.data.data,
        deploymentInfoLoading: false,
        deploymentInfoLoaded: true,
        deploymentInfoError: null,
      }
    case RETRIEVE_DEPLOYMENT_FAILURE:
      return {
        ...state,
        deploymentData: null,
        deploymentInfoLoading: false,
        deploymentInfoLoaded: true,
        deploymentInfoError: action.err,
      }
    case RETRIEVE_DEPLOYMENT_LOADING:
      return {
        ...state,
        deploymentData: null,
        deploymentInfoLoading: true,
        deploymentInfoLoaded: false,
        deploymentInfoError: null,
      }
    case GET_ALL_DEPLOYMENTS_SUCCESS:
      console.log('GET_ALL_DEPLOYMENTS_SUCCESS!', action)
      return {
        ...state,
        deployments: action.deployments,
      }
    case GET_ALL_DEPLOYMENTS_FAILURE:
      console.log('GET_ALL_DEPLOYMENTS_FAILURE!', action)
      return {
        ...state,
        deployments: [],
      }
    default:
      return state
  }
}

export default deploymentReducer
