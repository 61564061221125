import { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { useInterval } from 'usehooks-ts'

import WebRTCStream from './Components/WebRTCStream'
import { getDecodedToken } from '../api/accounts'
import camera from '../utils/camera'

import {
  Avatar,
  CssBaseline,
  IconButton,
  Typography,
} from '@material-ui/core'
import {
  Call,
  CallEnd,
} from '@material-ui/icons'
import { green, red, grey } from '@material-ui/core/colors'
import { withStyles } from '@material-ui/core/styles'
import whiteLogo from '../static/images/horizon.logo.png'
import blackLogo from '../static/images/horizon.logo-black.png'

const styles = theme => ({
  appHeader: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    display: 'flex',
    justifyContent: 'space-around',
    width: '100%',
  },
  overline: {
    lineHeight: 1.5,
  },
  mainContainer: {
    zIndex:   10,
    position: 'fixed',
    top:      theme.spacing(10),
    bottom:   theme.spacing(2),
    left:     theme.spacing(8),
    right:    theme.spacing(8),
    border:   '1px solid ' + theme.palette.bluegrey.main,
  },
  pipContainer: {
    zIndex:   20,
    position: 'fixed',
    bottom:   theme.spacing(4),
    left:     theme.spacing(2),
    width:    theme.spacing(40),
    height:   theme.spacing(30),
    border:   '1px solid ' + theme.palette.bluegrey.main,
  },
  controller: {
    zIndex:   20,
    position: 'fixed',
    bottom:   theme.spacing(4),
    right:    theme.spacing(2),
    width:    theme.spacing(20),
    display:  'flex',
    justifyContent: 'space-evenly',
    border:   '1px solid ' + theme.palette.bluegrey.main,
    borderRadius: theme.spacing(2),
    padding:  theme.spacing(2),
  },
  green: {
    color: theme.palette.getContrastText(green[500]),
    backgroundColor: green[500],
  },
  red: {
    color: theme.palette.getContrastText(red[500]),
    backgroundColor: red[500],
  },
  grey: {
    color: theme.palette.getContrastText(grey[500]),
    backgroundColor: grey[500],
  },
})

function MobileApp({ classes, useLightTheme }) {
  const { company_id } = getDecodedToken()
  // const videoContainer = document.getElementById('webcam')
  const [ counter, setCounter ] = useState(0)
  const [ calling, setCalling ] = useState(false)

  const [ title,  setTitle  ] = useState('Search and Rescue')
  const [ target, setTarget ] = useState({
    label: 'Target',
    text: '10 / M / CHI',
    subtext: 'Red Shirt, Glasses',
  })

  useInterval(() => {
    setCounter(counter + 1)
  }, 999)

  useEffect(() => {
    camera.init({
      targetCanvas: document.getElementById('webcam'),
      width:  320,
      height: 240,
      fps:    30,
      mirror: true,
      onError: function(error) {
        console.log('Camera error', error)
      },
      onNotSupported: function() {
        console.log('Browser does not support getUserMedia')
      }
    })
    const counterData = localStorage.getItem('COUNTER-DATA')
    if (!counterData)
      return
    try {
      const counter = JSON.parse(counterData)
      if (counter.title)
        setTitle(counter.title)
      if (counter.gridData)
        setTarget(counter.gridData.find(g => g.label === 'Target'))
    } catch (e) { return }
  }, [])

  function handleCall() {
    setCalling(true)
    camera.start()
  }

  function handleEndCall() {
    setCalling(false)
    camera.stop()
  }

  return (
    <>
      <CssBaseline />
      <div className={classes.appHeader}>
        <img src={useLightTheme ? blackLogo : whiteLogo} alt='Plex Horizon Logo' />
        <div>
          <Typography variant='overline' className={classes.overline}>Mission</Typography>
          <Typography variant='h5'>{title}</Typography>
        </div>
        <div>
          <Typography variant='overline' className={classes.overline}>Target</Typography>
          <Typography variant='h5'>{target.text} {target.subtext}</Typography>
        </div>
        <div>
          <Typography variant='overline' className={classes.overline}>Time Elapsed</Typography>
          <Typography variant='h5'>00 : {Math.floor(counter / 60).toString().padStart(2, '0')} : {Math.floor(counter % 60).toString().padStart(2, '0')}</Typography>
        </div>
      </div>
      <div className={classes.mainContainer}>
        <WebRTCStream
          applicationName='SkyStream/docCam' // The web camera at the pilot console (1-1 call over WebRTC (WebRTC ingest and output) https://www.wowza.com/docs/webrtc-workflows-in-wowza-streaming-engine)
          streamName='rgb'
          companyId={company_id}
        />
      </div>
      <div className={classes.pipContainer}>
        <canvas id='webcam' width='320' height='240' />
      </div>
      <div className={classes.controller}>
        <Avatar className={calling ? classes.grey : classes.green}>
          <IconButton
            disabled={calling}
            onClick={handleCall}>
            <Call />
          </IconButton>
        </Avatar>
        <Avatar className={calling ? classes.red : classes.grey}>
          <IconButton
            disabled={!calling}
            onClick={handleEndCall}>
            <CallEnd />
          </IconButton>
        </Avatar>
      </div>
    </>
  )
}

const mapStateToProps = state => ({ useLightTheme: state.settings.settings.useLightTheme })

export default connect(mapStateToProps, null)(withStyles(styles)(MobileApp))
