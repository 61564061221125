import { useState, useEffect } from 'react'
import { parseMissionCommands } from '../../utils/mission'
import CommandTable from '../../live/maps/CommandTable'
import TopXCloseButton from '../../components/TopXCloseButton'
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'

const styles = theme => ({
  root: {
    margin:          theme.spacing(1),
    padding:         theme.spacing(1),
    backgroundColor: theme.palette.bluegrey.dark
  },
  center: {
    padding: theme.spacing(2),
    textAlign: 'center',
  }
})

function MissionProfile({ classes, mission, deployment }) {
  const [ open, setOpen ] = useState(false)
  const [ commands, setCommands ] = useState(undefined)

  useEffect(() => {
    // Check if a mission plan exists. Since a non-existent mission is
    // passed as an empty object, we cannot use a simple null comparison.
    if (Object.keys(mission).length !== 0) {
      setCommands(parseMissionCommands(mission.plan.commands))
    }
  }, [mission])

  return (
    <div className={classes.root}>
      <Typography variant='h6'>Mission Profile</Typography>
      <Typography variant='overline'>Deployment Name</Typography>
      <Typography variant='body1'>{deployment.name}</Typography>
      <Typography variant='overline'>Purpose</Typography>
      { deployment.purpose?.length > 0 ?
          deployment.purpose.map((p, i) =>
            <Typography key={i} variant='body1'>{p}</Typography>
        ) :
        <Typography variant='body1'>No Purpose</Typography>
      }
      <Typography variant='overline'>Personnel</Typography>
      { deployment.personnel?.length > 0 ?
          deployment.personnel.map((person, i) =>
            <Grid container key={i}>
              <Grid item xs={6}>
                <Typography variant='body1'>
                  {person.name} { deployment.lead.id === person.id &&
                    <Typography variant='overline' color='primary'>LEAD</Typography> }
                </Typography>
              </Grid>
              <Grid>
                <Typography variant='body1'>{person.email}</Typography>
              </Grid>
            </Grid>
        ) :
          <Typography variant='body1'>No Personnel</Typography>
      }
      <Typography variant='overline'>Drones</Typography>
      { deployment.drones?.length > 0 ?
          deployment.drones.map((drone, i) =>
          <Grid container key={i}>
            <Grid item xs={6}>
              <Typography variant='body1'>{drone.name}</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant='body1'>
                {drone.provider ? drone.provider.name : '(No Brand)'} {drone.model ? drone.model.name : '(No Model)'}
              </Typography>
            </Grid>
          </Grid>
        ) :
          <Typography variant='body1'>No Drones</Typography>
      }
      <Typography variant='overline'>Batteries</Typography>
      { deployment.batteries?.length > 0 ?
        deployment.batteries.map((battery, i) =>
          <Grid container key={i}>
            <Grid item xs={6}>
              <Typography variant='body1'>{battery.name}</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant='body1'>
                {battery.provider ? battery.provider.name : '(No Brand)'} {battery.model ? battery.model.name : '(No Model)'}
              </Typography>
            </Grid>
          </Grid>
        ) :
          <Typography variant='body1'>No Batteries</Typography>
      }
      <Typography variant='overline'>Cameras / Payloads</Typography>
      { deployment.cameras?.length > 0 &&
        deployment.cameras.map((camera, i) =>
          <Grid container key={i}>
            <Grid item xs={6}>
              <Typography variant='body1'>{camera.name}</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant='body1'>
                {camera.provider ? camera.provider.name : '(No Brand)'} {camera.model ? camera.model.name : '(No Model)'}
              </Typography>
            </Grid>
          </Grid>
        )
      }
      { deployment.payloads?.length > 0 ?
        deployment.payloads.map((payload, i) =>
          <Grid container key={i}>
            <Grid item xs={6}>
              <Typography variant='body1'>{payload.name}</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant='body1'>
                {payload.provider ? payload.provider.name : '(No Brand)'} {payload.model ? payload.model.name : '(No Model)'}
              </Typography>
            </Grid>
          </Grid>
        ) :
          <Typography variant='body1'>No Other Payloads</Typography>
      }
      { deployment.cameras?.length === 0 && deployment.payloads?.length === 0 && 
        <Typography variant='body1'>No Cameras / Payloads</Typography>
      }
      { commands &&
        <div className={classes.center}>
          <Button variant='outlined' onClick={() => setOpen(true)}>
            Command Details
          </Button>
        </div>
      }
      { open && commands &&
        <Dialog open={open} onClose={() => setOpen(false)} fullWidth maxWidth='md'>
          <DialogTitle>
            Mission Commands
          </DialogTitle>
          <TopXCloseButton onClick={() => setOpen(false)} />
          <DialogContent>
            <CommandTable commands={commands} />
          </DialogContent>
        </Dialog>
      }
    </div>
  )
}

export default withStyles(styles)(MissionProfile)
