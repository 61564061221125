import { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect }            from 'react-redux'

import TelemetryListener from './TelemetryListener'

import { handleTelemetry } from '../modules/actions/telemetryActions'
import {
  NETWORK_SPEED_CHECK_STORAGE,
} from '../modules/actions/commandActions'
import { getDecodedToken } from '../api/accounts'

const TELEM_WSS_URL = process.env.REACT_APP_TELEMETRY_WSS_URL

class LiveTelemetryListener extends Component {

  pingTimeout = null

  setTimeoutInitializer = () => {
    const { handleTelemetry } = this.props
    clearTimeout(this.pingTimeout)

    this.pingTimeout = setTimeout(() => {
      console.log('Live Telemetry Listener: Time Out')
      handleTelemetry({ error: 'Time Out, Lost Connection' })
    }, 5000) // 5 sec
  }

  onTelemetryOpen = () => {
    console.log('Telem Connected')
    this.setTimeoutInitializer()
  }

  onReceivedMessage = e => {
    try {
      const { drone_id: flyingDroneId } = getDecodedToken()
      const { handleTelemetry } = this.props
      const data = JSON.parse(e.data)

      const speedTestObject = {
        download: data.download,
        upload:   data.upload,
        ping:     data.ping,
      }
      if (data.download !== undefined && data.upload !== undefined) {
        window.localStorage.setItem(
          NETWORK_SPEED_CHECK_STORAGE,
          JSON.stringify(speedTestObject)
        )
      }

      const { drone_id } = data
      // Check whether the drone id from telemetry matches
      if (drone_id === flyingDroneId) {
        handleTelemetry(data)
        this.setTimeoutInitializer()
      }
    } catch (exception) {
      console.error('Unhandled exception caught at Live Telemetry Listener', exception)
    }
  }

  render() {
    return (
      <TelemetryListener
        endpoint={TELEM_WSS_URL}
        onMessage={this.onReceivedMessage}
        onOpen={this.onTelemetryOpen}>
        { this.props.children }
      </TelemetryListener>
    )
  }
}

const mapStateToProps = (state) => ({
  companyId: state.oidc.user.profile.company_id,
  droneId:   state.mission.mission?.droneId,
  telemetry: state.telemetry.telemetry,
})

const mapDispatchToProps = dispatch => {
  return bindActionCreators({ handleTelemetry }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(LiveTelemetryListener)
