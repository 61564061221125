// Adapted from https://medium.com/@pdx.lucasm/canvas-with-react-js-32e133c05258
import { useEffect, useRef } from 'react'

export default function useCanvas({ draw, options={} }) {
  const canvasRef = useRef(null)

  useEffect(() => {
    const canvas  = canvasRef.current
    const context = canvas.getContext(options.context || '2d')

    // TODO: study the need for window.requestAnimationFrame / cancelAnimationFrame

    if (draw)
      draw(context)
  }, [draw, options])

  return canvasRef
}