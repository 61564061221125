import { useState, useEffect } from 'react'
import { getAppStats } from '../api/skystream'
import {
 Typography,
 CircularProgress,
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'

const styles = theme => ({
  container: {
    display: 'flex',
    justifyContent: 'space-between',
  },
})

function SkyStreamHealthFooter({ classes }) {
  const [ current, setCurrent ] = useState(null)

  useEffect(() => {
    const interval = setInterval(() => {
      getAppStats('SkyStream')
      .then(res => {
        if (res.status === 'success')
          setCurrent(res.data)
        else
          setCurrent(null)
      })
    }, 5000)
    return () => clearInterval(interval)
  }, [])

  if (!current)
    return (<div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
      <Typography variant='overline'> Trying to reach Garuda SkyStream... </Typography>
      <CircularProgress size={20} />
    </div>)
 
  return (
    <div className={classes.container}>
      <Typography variant='overline'>Total Connections <b>{current.totalConnections}</b></Typography>
      <Typography variant='overline'>Total Bytes In <b>{current.bytesIn.toLocaleString()}</b></Typography>
      <Typography variant='overline'>Bytes In Rate <b>{current.bytesInRate.toLocaleString()}</b></Typography>
      <Typography variant='overline'>Total Bytes Out <b>{current.bytesOut.toLocaleString()}</b></Typography>
      <Typography variant='overline'>Bytes Out Rate <b>{current.bytesOutRate.toLocaleString()}</b></Typography>
    </div>
  )
}

export default withStyles(styles)(SkyStreamHealthFooter)
