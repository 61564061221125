import {
  Button,
  List,
  ListItem,
  Typography
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'

const styles = theme => ({
  listItem: {
    width: '100%',
    justifyContent: 'flex-end',
  },
  listButton: {
    background: theme.palette.background.paper,
    zIndex: 2,
    textTransform: 'none'
  }
})

function EmergencyLandingZones({ classes, zones, selectLandingZone, highlightLandingZone }) { 
  return (
    <List>
      { zones.features.map((zone, i) => (
        <ListItem key={i} className={classes.listItem}>
          <Button
            size='large'
            className={classes.listButton}
            onMouseEnter={() => highlightLandingZone(zone, true)}
            onMouseLeave={() => highlightLandingZone(zone, false)}
            onClick={() => selectLandingZone(zone)}>
            <Typography>
              {zone.properties.title || `Untitled Emergency Landing Zone ${i}`}
            </Typography>
          </Button>
        </ListItem>
      ))}
    </List>
  )
}

export default withStyles(styles)(EmergencyLandingZones)
