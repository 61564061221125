import { useState, useEffect } from 'react'
import { bindActionCreators }  from 'redux'
import { connect } from 'react-redux'
import { useInterval } from 'usehooks-ts'
import { echo } from '../../modules/actions/commandActions'
import TopXCloseButton from '../../components/TopXCloseButton'
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  InputAdornment,
  TextField,
  Typography,
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'

const styles = theme => ({
  button: {
    width:  '100%',
    height: theme.spacing(3),
    marginBottom: theme.spacing(1),
  },
})

function RTTDialog({ open, onClose, mission, echo, connected, echoDuration }) {
  const [ rttArray, setRttArray ] = useState([])
  const [ count,    setCount    ] = useState('4')
  const [ delay,    setDelay    ] = useState('1')
  const [ echoLeft, setEchoLeft ] = useState(-1)

  useEffect(() => {
    if (echoDuration)
      setRttArray(prev => [ ...prev, {
        time: Date.now(),
        dur:  echoDuration,
        msg:  `${echoDuration / 1000} seconds`
      } ])
  }, [echoDuration])

  function calcAverage() {
    const result = rttArray.reduce(
      ({ count, sum }, rtt) => (rtt.dur >= 0 ? { count: count + 1, sum: sum + rtt.dur } : { count, sum }),
      { count: 0, sum: 0 }
    )
    setRttArray(prev => [ ...prev, {
      time: Date.now(),
      dur:  -1,
      msg:  `Received ${result.count} echoes, average RTT: ${result.count > 0 ? result.sum / result.count / 1000 : '-'} seconds`
    } ])
  }

  useInterval(() => {
    setRttArray(prev => [ ...prev, {
      time: Date.now(),
      dur:  -1,
      msg: `Pinging ${mission.droneName || mission.droneModel}`
    } ])
    if (echoLeft > 0)
      echo()
    setEchoLeft(left => {
      if (left === 0)
        calcAverage()
      return left - 1
    })
  }, echoLeft >= 0 ? parseInt(delay) * 1000 : null)

  function getRTT() {
    if (!Number.isInteger(parseInt(count)))
      setCount('4')
    if (!Number.isInteger(parseInt(delay)))
      setDelay('1')
    setRttArray([])
    setEchoLeft(parseInt(count))
  }

  return (
    <Dialog open={open} onClose={onClose} maxWidth='xs' fullWidth>
      <TopXCloseButton onClick={onClose} />
        { !connected &&
          <DialogTitle>
            Drone not connected.
          </DialogTitle>
        }
      <DialogContent>
        <TextField
          label='Echo count'
          variant='filled' size='small' fullWidth
          value={count}
          InputProps={{
            endAdornment: <InputAdornment position="end">times</InputAdornment>,
          }}
          onChange={evt => {
            setCount(evt.target.value)
            setEchoLeft(0) // stop echoing when user change value
          }}
        />
        <TextField
          label='Echo delay'
          variant='filled' size='small' fullWidth
          value={delay}
          InputProps={{
            endAdornment: <InputAdornment position="end">seconds</InputAdornment>,
          }}
          onChange={evt => {
            setDelay(evt.target.value)
            setEchoLeft(0) // stop echoing when user change value
          }}
        />
        <Button
          disabled={!connected || echoLeft > 0}
          variant='contained' color='primary'
          onClick={getRTT}>Echo</Button>
        { rttArray.map(rtt => ( 
          <Typography>{new Date(rtt.time).toLocaleTimeString()}: {rtt.msg}</Typography>
        )) }
      </DialogContent>
    </Dialog>
  )
}

const mapStateToProps = state => ({
  mission:      state.mission.mission,
  connected:    state.telemetry.linkStatus === null,
  echoDuration: state.command.echoDuration
})

const mapDispatchToProps = dispatch => {
  return bindActionCreators({ echo }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(RTTDialog))
