import { connect } from 'react-redux'
import WarningsStoreConnector from '../../warnings/Components/WarningsStoreConnector'
import WarningListItem from '../../warnings/Components/WarningListItem'
import {
  Typography,
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'

const styles = theme => ({
  noWarnings: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(1, 2),
    height: theme.spacing(7),
    backgroundColor: theme.palette.bluegrey.dark,
  },
  noWarningsText: {
    color: theme.palette.bluegrey.main,
  },
  warnings: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(1, 2),
    height: theme.spacing(7),
    backgroundColor: theme.palette.secondary.main,
  },
  warningsText: {
    color: theme.palette.common.black,
    lineHeight: 1
  }
})

function WarningDisplay({ classes, latestWarning, linkStatus }) {
  return (
    <WarningsStoreConnector>
      { latestWarning ?
        <WarningListItem
          severity={latestWarning.severity}
          warningMessage={latestWarning.details}
          startingTime={latestWarning.startingTime}
          latestTime={latestWarning.latestTime}
          count={latestWarning.count}
        />
      : linkStatus ?
        <WarningListItem
          warningMessage={`Link Status: ${linkStatus}`}
          severity={2}
          startingTime={Date.now() / 1000}
          latestTime={Date.now() / 1000}
        />
      :
        <div className={classes.noWarnings}>
          <Typography variant='h5' className={classes.noWarningsText}>
            All systems operational. Safety First.
          </Typography>
        </div>
      }
    </WarningsStoreConnector>
  )
}

const mapStateToProps = state => {
  const { activeWarnings: activeByKey } = state.warnings
  const activeWarnings = Array.from(Object.keys(activeByKey)).map(key => activeByKey[key])
  // Sort by severity from 1 to 5, then by startingTimestamp from latest to earliest
  // to retrieve the most recently started warning with the highest severity.
  activeWarnings.sort((a, b) => {
    if (a.severity !== b.severity)
      return a.severity - b.severity
    return b.latestTime - a.latestTime
  })
  return {
    linkStatus: state.telemetry.linkStatus,
    latestWarning: activeWarnings.length > 0
                 ? activeWarnings[0]
                 : null,
  }
}

export default connect(mapStateToProps)(withStyles(styles)(WarningDisplay))
