import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import axios from 'axios'

const AIRCRAFT_API = process.env.REACT_APP_AIRCRAFT_API
const PAYLOAD_API  = process.env.REACT_APP_PAYLOAD_API

export function pingAircraftApi() {
  return axios.get(`${AIRCRAFT_API}/sanity`, { timeout: 2000 })
  .then(res => res.status === 200 ? res.data : (() => { console.log(res); return false }))
  .catch(err => false)
}

export function pingPayloadApi() {
  return axios.get(`${PAYLOAD_API}/sanity`, { timeout: 2000 })
  .then(res => res.status === 200 ? res.data : (() => { console.log(res); return false }))
  .catch(err => false)
}

export const aircraftApi = createApi({
  reducerPath: 'aircraftApi',
  baseQuery: fetchBaseQuery({
    baseUrl: AIRCRAFT_API,
    prepareHeaders: (headers, { getState }) => {
      const user = getState().oidc?.user
      if (user)
        headers.set('Authorization', `Bearer ${user.access_token}`)
      headers.set('Content-Type', 'application/json')
      headers.set('Accept', 'application/json')
      return headers
    },
  }),
  tagTypes: ['Drones'],
  endpoints: (builder) => ({
    getDroneByDroneId: builder.query({
      query: (droneId) => `drones/${droneId}`,
      providesTags: ['Drones'],
    }),
  })
})

export const {
  useGetDroneByDroneIdQuery,
} = aircraftApi
