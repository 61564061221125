import { connect } from 'react-redux'
import { Route, Redirect, Switch } from 'react-router-dom'

// Pilot Console
import PilotForwardScreen  from './live/PilotForwardScreen'
import PilotDownwardScreen from './live/PilotDownwardScreen'
import PayloadScreen       from './live/PayloadScreen'
import RegionalMap         from './regional-map/RegionalMap'
import RegionalMapOld      from './live/RegionalMap'
import CeranaHealth        from './health/CeranaHealth'
import CeranaWarnings      from './warnings/CeranaWarnings'
import PictureInPicture    from './video/PictureInPicture'
import MobileApp           from './video/MobileApp'

// Joint Console
import SysHealth         from './health/SysHealth' // deprecate
import CounterScreen     from './commander/CounterScreen'
import SelectObject      from './track/SelectObject'
import TrackObject       from './track/TrackObject'
import VideoAnalytics    from './video/VideoAnalytics'
import FourByFourGrid    from './video/FourByFourGrid'
import PlexHealth        from './health/PlexHealth'
import NetworkHealth     from './health/NetworkHealth'
import VideoHealth       from './health/VideoHealth'

// Helios Commander Console
import MissionControl      from './commander/HeliosMissionControlContainer'
import CommanderScreen     from './live/CommanderScreenContainer'
import OvenMediaEngineDemo from './video/OvenMediaEngineDemo'

// Mission
import Mission            from './mission/Mission'
import MissionPlanner     from './mission/Planner'
import PreFlightChecks    from './mission/PreFlightChecks'
import PostFlightReview   from './mission/PostFlightReview'
import UploadFlightPlans  from './admin/UploadFlightPlans'

// General / Admin / Developer
import AuthenticatedRoute from './components/AuthenticatedRoute'
import Login              from './components/Login'
import Callback           from './components/Callback'
import PageNotFound       from './components/PageNotFound'
import Settings           from './admin/Settings'
import Developer          from './health/Developer'

import { ThemeProvider, withStyles } from '@material-ui/core/styles'
import theme             from './utils/site-theme'
import lightTheme        from './utils/site-theme-light'

console.log('%c' + String.fromCodePoint(0x1F680) +
  ' Are you interested to help build BVLOS Flight Control Systems? Join us - visit https://garuda.io/careers to learn more!',
  'font-size: 16px; text-shadow: #ddd 1px 1px;')

const styles = theme => ({
  app: {
    '& ::-webkit-scrollbar': {
      width: 0,  /* Remove scrollbar space (doesn't work) */
      background: 'transparent', /* (doesn't work) */
    }
  }
})

function App({ classes, useLightTheme }) {
  return (
    <ThemeProvider theme={useLightTheme ? lightTheme : theme}>
      <div className={classes.app}>
        <Switch>
          <Redirect exact from='/' to='/live' />
          <Route exact path='/login' component={Login} />
          <Route exact path='/callback' component={Callback} />

          {/* Pilot Console */}
          <AuthenticatedRoute exact path='/live' component={PilotForwardScreen} />
          <AuthenticatedRoute exact path='/downward' component={PilotDownwardScreen} />
          <AuthenticatedRoute exact path='/payload' component={PayloadScreen} />
          <AuthenticatedRoute exact path='/regional-map' component={RegionalMap} />
          <AuthenticatedRoute exact path='/regional-map-old' component={RegionalMapOld} />
          <AuthenticatedRoute exact path='/cerana-health' component={CeranaHealth} />
          <AuthenticatedRoute exact path='/cerana-warnings' component={CeranaWarnings} />
          {/* Pilot Console Extensions for Singtel CNY video shoot */}
          <AuthenticatedRoute exact path='/picture-in-picture' component={PictureInPicture} />
          <AuthenticatedRoute exact path='/mobile-app' component={MobileApp} />

          <AuthenticatedRoute exact path='/syshealth' component={SysHealth} />{/* to deprecate for cerana health and plex health */}

          {/* Joint Console (was Commander Console, a.k.a. the TV Wall) */}
          <AuthenticatedRoute exact path='/counter' component={CounterScreen} />
          <AuthenticatedRoute exact path='/select-object' component={SelectObject} />
          <AuthenticatedRoute exact path='/track-object' component={TrackObject} />
          <AuthenticatedRoute exact path='/video-analytics' component={VideoAnalytics} />
          <AuthenticatedRoute exact path='/four-by-four-grid' component={FourByFourGrid} />
          <AuthenticatedRoute exact path='/plex-health' component={PlexHealth} />
          <AuthenticatedRoute exact path='/network-health' component={NetworkHealth} />
          <AuthenticatedRoute exact path='/video-health' component={VideoHealth} />
          {/* Commander Console for Helios (to be cleaned up and incorporated into product) */}
          <AuthenticatedRoute exact path='/mission-control' component={MissionControl}/>
          <AuthenticatedRoute exact path='/commander' component={CommanderScreen} />
          <AuthenticatedRoute exact path='/ome_demo' component={OvenMediaEngineDemo}/>

          {/* Mission */}
          <AuthenticatedRoute exact path='/planner' component={MissionPlanner} />
          <AuthenticatedRoute exact path='/mission' component={Mission} />
          <AuthenticatedRoute exact path='/preflight' component={PreFlightChecks} />
          <AuthenticatedRoute exact path='/postflight' component={PostFlightReview} />
          <AuthenticatedRoute exact path='/upload-flight' component={UploadFlightPlans} />

          {/* Administration */}
          <AuthenticatedRoute exact path='/settings' component={Settings} />
          <AuthenticatedRoute exact path='/developer' component={Developer} />
          <Route component={PageNotFound} />
        </Switch>
      </div>
    </ThemeProvider>
  )
}

const mapStateToProps = state => ({ useLightTheme: state.settings.settings.useLightTheme })

export default connect(mapStateToProps, null)(withStyles(styles)(App))
