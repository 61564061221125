
import { connect }    from 'react-redux'

import FlightState    from '../../utils/FlightState'
import ControlDisplay from './ControlDisplay'

// Primary Pilot actions
import TakeOffButton  from '../buttons/TakeOffButton'
import LandButton     from '../buttons/LandButton'

// Secondary Pilot actions
import TakeOverButton from '../buttons/TakeOverButton'

import {
  Divider,
  Typography,
} from '@material-ui/core'
import { withStyles }       from '@material-ui/core/styles'

const styles = theme => ({
  container: {
    height: theme.spacing(7),
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
  },
  topcontrol: {
    // flexGrow: 1,
    maxHeight: theme.spacing(6),
  },
  indicatorColumns: {
    textAlign: 'center',
    width: theme.spacing(16),
    height: theme.spacing(7),
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
  },
  warningsColumn: {
    flexGrow: 5,
  },
  overline: {
    lineHeight: 1,
  },
})

function DroneStatus({
  classes,
  isFlying,
  inControl,
  telemetry,
  linkStatus,
  obstacleDetection
}) {
  const dockOpen    = Object.keys(telemetry).length === 0 || linkStatus === null
  const droneOnline = telemetry && Object.keys(telemetry).length > 0

  // DAA is on only in the condition that the drone is online with no link loss and
  const daaActivated = obstacleDetection
  const daaStatus    = daaActivated ? 'DETECT' : 'OFF' // TODO: AVOID
  const droneFlightStatus = droneOnline
      ? FlightState(telemetry.flight_state)
      : 'No mode'

  const dockIndicator = <div className={classes.indicatorColumns}>
    <Typography variant='overline' color={dockOpen ? 'secondary' : 'primary'} className={classes.overline}>
      DOCK
    </Typography>
    <Typography variant='h6' color={dockOpen ? 'secondary' : 'primary'}>
      { dockOpen ? 'OPEN' : 'CLOSED' }
    </Typography>
  </div>

  const droneIndicator = <div className={classes.indicatorColumns}>
    <Typography variant='overline' color={droneOnline ? 'secondary' : 'primary'} className={classes.overline}>
      DRONE
    </Typography>
    <Typography variant='h6' color={droneOnline ? 'secondary' : 'primary'}>
      { droneOnline ? 'ON' : 'OFF' }
    </Typography>
  </div>

  const daaIndicator = <div className={classes.indicatorColumns}>
    <Typography variant='overline' color={daaActivated ? 'primary' : 'secondary'} className={classes.overline}>
      DAA
    </Typography>
    <Typography variant='h6' color={daaActivated ? 'primary' : 'secondary'}>
      { daaStatus }
    </Typography>
  </div>

  const flightStatusIndicator = <div className={classes.indicatorColumns}>
    <Typography variant='overline' color={droneOnline ? 'secondary' : 'primary'} className={classes.overline}>
      FLIGHT
    </Typography>
    <Typography variant='h6' color={droneOnline ? 'secondary' : 'primary'}>
      { droneFlightStatus }
    </Typography>
  </div>

  return (
    <div className={classes.container}>
      { inControl ?
        ( isFlying ? <LandButton /> : <TakeOffButton /> ) :
        ( isFlying ? <TakeOverButton /> : null )
      }
      <Divider orientation='vertical' flexItem />
      <div className={classes.warningsColumn}>
        <ControlDisplay />
      </div>
      <Divider orientation='vertical' flexItem />
      {flightStatusIndicator}
      {daaIndicator}
      {droneIndicator}
      {dockIndicator}
    </div>
  )
}

const mapStateToProps = state => {
  return ({
    isFlying:          state.command.isFlying,
    inControl:         state.command.inControl,
    mission:           state.mission.mission,
    telemetry:         state.telemetry.telemetry,
    linkStatus:        state.telemetry.linkStatus,
    obstacleDetection: state.daa.obstacleDetection,
  })
}

export default connect(mapStateToProps, null)(withStyles(styles)(DroneStatus))
