import { useEffect, useRef } from 'react'
import { useInterval } from 'usehooks-ts'
import { withStyles } from '@material-ui/core/styles'

const styles = theme => ({
  canvas: ({ width }) => ({
    display: 'block',
    width:   width,
    height:  `calc(100vh - ${theme.spacing(6)}px)`,
  }),
})

function HighlightCanvas({ classes, width, videoStarted, draw }) {
  const canvasRef = useRef()

  useInterval(() => {
    const context = canvasRef.current.getContext('2d')
    draw(context)
  }, videoStarted ? 40 : null) // 25fps

  useEffect(() => {
    function resizeCanvas() {
      canvasRef.current.height = window.innerHeight - 48
    }
    window.addEventListener('resize', resizeCanvas, false)
  }, [])

  return (
    <canvas
      id='rightCanvas'
      ref={canvasRef}
      width={width}
      height={window.innerHeight - 48}
      className={classes.canvas}>
     Your browser does not support HTML5 Canvas.
    </canvas>
  )
}

export default withStyles(styles)(HighlightCanvas)

// function InferenceHighlights() {
//   const classes = makeStyles(styles)()
//   return (
//     <div className={classes.highlights}>
//       <InferenceTarget
//         name={Math.floor(Math.random() * 500)}
//       />
//       <InferenceTarget
//         name={Math.floor(Math.random() * 500)}
//       />
//       <InferenceTarget
//         name={Math.floor(Math.random() * 500)}
//       />
//     </div>
//   )
// }