import { bindActionCreators } from 'redux'
import { connect }  from 'react-redux'
import { updateSettings } from '../modules/actions/settings'
import Header       from '../components/Header'
import ExternalLink from '../components/ExternalLink'
import {
  Avatar,
  Button,
  CssBaseline,
  Grid,
  Typography
} from '@material-ui/core'
import {
  Warning,
} from '@material-ui/icons'
import { withStyles } from '@material-ui/core/styles'

const styles = theme => ({
  header: {
    height: theme.spacing(6),
  },
  body: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(3),
  },
  card: {
    display:  'inline-block',
    margin:   '8px auto',
    maxWidth: theme.breakpoints.values.md,
    padding:  theme.spacing(2),
    backgroundColor: theme.palette.bluegrey.dark,
    borderRadius:    theme.spacing(2),
  },
  subtitle: {
    backgroundColor: theme.palette.bluegrey.main,
  },
})

function Settings({ classes, currSettings, updateSettings }) {

  const { useLightTheme, useAirmap, useCfms, useOnesky } = currSettings

  // For now, use explicit switch for theme (for future, can also matchMedia prefers-color-scheme light/dark
  // https://stackoverflow.com/questions/56393880/how-do-i-detect-dark-mode-using-javascript
  const settingsControls = <div>
    <Grid container spacing={3}>
      <Grid item xs={4}>Use Light Theme<br />(default is Dark Theme)</Grid>
      <Grid item xs={8}>
        <ToggleButton
          enabled={useLightTheme}
          onToggle={() => updateSettings({ ...currSettings, useLightTheme: !useLightTheme })} />
      </Grid>
      <Grid item xs={4}>OneSky UTM</Grid>
      <Grid item xs={8}>
        <ToggleButton
          enabled={useOnesky}
          onToggle={() => updateSettings({ ...currSettings, useOnesky: !useOnesky })} />
      </Grid>
      <Grid item xs={4}>CFMS (CAAS) UTM</Grid>
      <Grid item xs={8}>
        <ToggleButton
          enabled={useCfms}
          onToggle={() => updateSettings({ ...currSettings, useCfms: !useCfms })} />
      </Grid>
      <Grid item xs={4}>AirMap UTM</Grid>
      <Grid item xs={8}>
        <ToggleButton
          enabled={useAirmap}
          onToggle={() => updateSettings({ ...currSettings, useAirmap: !useAirmap })} />
      </Grid>
    </Grid>
  </div>

  const configGrid =
    <Grid container spacing={2}>
      <Grid item xs={12} className={classes.subtitle}>
        <b>Garuda Plex Platform</b>
      </Grid>
      <Grid item xs={4}>Account Management</Grid>
      <Grid item xs={8}>{ process.env.REACT_APP_ACCOUNTS_URL }</Grid>
      <Grid item xs={4}>API Gateway</Grid>
      <Grid item xs={8}>{ process.env.REACT_APP_API_URL }</Grid>
      <Grid item xs={4}>API Server Name</Grid>
      <Grid item xs={8}>{ process.env.REACT_APP_API_SERVER_NAME }</Grid>
      <Grid item xs={4}>API Server Location</Grid>
      <Grid item xs={8}>{ process.env.REACT_APP_API_SERVER_LOCATION }</Grid>

      <Grid item xs={12} className={classes.subtitle}>
        <b>Garuda Plex Fleet Management</b>
      </Grid>
      <Grid item xs={4}>Aircraft API</Grid>
      <Grid item xs={8}>{ process.env.REACT_APP_AIRCRAFT_API }</Grid>
      <Grid item xs={4}>Payload API</Grid>
      <Grid item xs={8}>{ process.env.REACT_APP_PAYLOAD_API }</Grid>
      <Grid item xs={4}>Projects API</Grid>
      <Grid item xs={8}>{ process.env.REACT_APP_PROJECTS_API }</Grid>
      <Grid item xs={4}>Deployments API</Grid>
      <Grid item xs={8}>{ process.env.REACT_APP_DEPLOYMENTS_API }</Grid>
      <Grid item xs={4}>Media API</Grid>
      <Grid item xs={8}>{ process.env.REACT_APP_MEDIA_API }</Grid>

      <Grid item xs={12} className={classes.subtitle}>
        <b>Garuda SkyStream Live Streaming</b>
      </Grid>
      <Grid item xs={4}>Web RTC Ping</Grid>
      <Grid item xs={8}>{ process.env.REACT_APP_SKYSTREAM_PING_URL }</Grid>
      <Grid item xs={4}>Web RTC Session URL</Grid>
      <Grid item xs={8}>{ process.env.REACT_APP_SKYSTREAM_WEBRTC_URL }</Grid>
      <Grid item xs={4}>REST API URL</Grid>
      <Grid item xs={8}>{ process.env.REACT_APP_SKYSTREAM_API_URL }</Grid>
      <Grid item xs={4}>Manager URL</Grid>
      <Grid item xs={8}>{ process.env.REACT_APP_SKYSTREAM_MANAGER_URL }</Grid>

      <Grid item xs={12} className={classes.subtitle}>
        <b>Plex Horizon (Administration)</b>
      </Grid>
      <Grid item xs={4}>Unmanned Aircraft Onboard System Configuration Management</Grid>
      <Grid item xs={8}>{ process.env.REACT_APP_ONBOARD_CONFIG_API }</Grid>
      <Grid item xs={4}>Geospatial / Artificial Intelligence Configuration Management</Grid>
      <Grid item xs={8}>{ process.env.REACT_APP_GEO_AI_CONFIG_API }</Grid>

      <Grid item xs={12} className={classes.subtitle}>
        <b>Plex Horizon (BVLOS Flight Control)</b>
      </Grid>
      <Grid item xs={4}>Flight Plans API</Grid>
      <Grid item xs={8}>{ process.env.REACT_APP_FLIGHT_PLANS_API }</Grid>
      <Grid item xs={4}>Flight Status Web API & WSS URL</Grid>
      <Grid item xs={8}>
        { process.env.REACT_APP_FLIGHT_STATUS_WEB_API }<br />
        { process.env.REACT_APP_FLIGHT_STATUS_WSS_URL }
      </Grid>
      <Grid item xs={4}>Flight Telemetry Web API & WSS URL</Grid>
      <Grid item xs={8}>
        { process.env.REACT_APP_TELEMETRY_WEB_API }<br />
        { process.env.REACT_APP_TELEMETRY_WSS_URL }
      </Grid>
      <Grid item xs={4}>Flight Telemetry Drone API</Grid>
      <Grid item xs={8}>{ process.env.REACT_APP_TELEMETRY_DRONE_API }</Grid>
      <Grid item xs={4}>Flight Command Web API & WSS URL</Grid>
      <Grid item xs={8}>
        { process.env.REACT_APP_COMMAND_WEB_API }<br />
        { process.env.REACT_APP_COMMAND_WSS_URL }
      </Grid>
      <Grid item xs={4}>Flight Command Drone API</Grid>
      <Grid item xs={8}>{ process.env.REACT_APP_COMMAND_DRONE_API }</Grid>
      <Grid item xs={4}>Payload Telemetry Web API & WSS URL</Grid>
      <Grid item xs={8}>
        { process.env.REACT_APP_TELEMETRY_PAYLOAD_WEB_API }<br />
        { process.env.REACT_APP_TELEMETRY_PAYLOAD_WSS_URL }
      </Grid>
      <Grid item xs={4}>Payload Telemetry Drone API</Grid>
      <Grid item xs={8}>{ process.env.REACT_APP_TELEMETRY_PAYLOAD_DRONE_API }</Grid>

      <Grid item xs={4}>Payload Command Web API</Grid>
      <Grid item xs={8}> { process.env.REACT_APP_COMMAND_PAYLOAD_WEB_API }</Grid>
      <Grid item xs={4}>Payload Telemetry Drone API</Grid>
      <Grid item xs={8}>{ process.env.REACT_APP_COMMAND_PAYLOAD_DRONE_API }</Grid>

      <Grid item xs={4}>Flight Warning Web API & WSS URL</Grid>
      <Grid item xs={8}>
        { process.env.REACT_APP_WARNINGS_WEB_API }<br />
        { process.env.REACT_APP_WARNINGS_WSS_URL }
      </Grid>
      <Grid item xs={4}>Flight Warning Drone API</Grid>
      <Grid item xs={8}>{ process.env.REACT_APP_WARNINGS_DRONE_API }</Grid>
      <Grid item xs={4}>Detect and Avoid (DAA) Bounding Box Web API & WSS URL</Grid>
      <Grid item xs={8}>
        { process.env.REACT_APP_DAA_WEB_API }<br />
        { process.env.REACT_APP_DAA_WSS_URL }
      </Grid>
      <Grid item xs={4}>Detect and Avoid (DAA) Bounding Box Drone API</Grid>
      <Grid item xs={8}>{ process.env.REACT_APP_DAA_DRONE_API }</Grid>

      <Grid item xs={12} className={classes.subtitle}>
        <b>Plex Horizon (Video Analytics)</b>
      </Grid>
      <Grid item xs={4}>ML Model Telemetry Web API & WSS URL</Grid>
      <Grid item xs={8}>
        { process.env.REACT_APP_TELEMETRY_ML_MODEL_WEB_API }<br />
        { process.env.REACT_APP_TELEMETRY_ML_MODEL_WSS_URL }
      </Grid>
      <Grid item xs={4}>ML Model Command Web API</Grid>
      <Grid item xs={8}>{ process.env.REACT_APP_COMMAND_ML_MODEL_WEB_API }</Grid>

      <Grid item xs={12} className={classes.subtitle}>
        <b>Airspace / Maps</b>
      </Grid>
      <Grid item xs={4}>Airspace Web API & WSS URL</Grid>
      <Grid item xs={8}>
        { process.env.REACT_APP_AIRSPACE_WEB_API }<br />
        { process.env.REACT_APP_AIRSPACE_WSS_URL }
      </Grid>
      <Grid item xs={4}>Meteorology (Weather) API</Grid>
      <Grid item xs={8}>{ process.env.REACT_APP_METEOROLOGY_API }</Grid>
      <Grid item xs={4}>Geospatial (Ground Places) API</Grid>
      <Grid item xs={8}>{ process.env.REACT_APP_GEOSPATIAL_API }</Grid>
      <Grid item xs={4}>Base Maps<br />(Tile Servers)</Grid>
      <Grid item xs={8}>
        { process.env.REACT_APP_OSM_TILE_SERVER }<br />
        { process.env.REACT_APP_ONEMAP_TILE_SERVER }
      </Grid>
      <Grid item xs={4}>Default Map Center</Grid>
      <Grid item xs={8}>{ process.env.REACT_APP_DEFAULT_MAP_CENTER }</Grid>

      <Grid item xs={12} className={classes.subtitle}>
        <b>Integrations</b>
      </Grid>
      <Grid item xs={4}>MyDroneFleets UAV Hardware Tracker</Grid>
      <Grid item xs={8}>{ process.env.REACT_APP_TRACKER_WEBSOCKET_API }</Grid>
      <Grid item xs={4}>MyDroneFleets UAV Conformance Monitoting</Grid>
      <Grid item xs={8}>{ process.env.REACT_APP_CONFORMANCE_MONITORING_WEBSOCKET_API }</Grid>
      <Grid item xs={4}>Ground Camera (Bosch)</Grid>
      <Grid item xs={8}>{ process.env.REACT_APP_TELEMETRY_BOSCH_URL }</Grid>

    </Grid>

  return (
    <>
      <CssBaseline />
      <Header className={classes.header} />
      <div className={classes.body}>
        <div className={classes.card}>
          <Typography align='center' variant='h4' gutterBottom>
            Settings
          </Typography>
          { settingsControls }
        </div>

        <div className={classes.card}>
          <Typography variant='h5' gutterBottom>
            System Configuration
          </Typography>
          <Typography variant='body1' gutterBottom>
            The values are provided for reference only. You will need these to
            configure your network if you run Plex Horizon on a Private Network.
            <br /><br />
            These end points are protected by an internationally distributed cloud
            firewall and cannot be configured in app. If you have any questions,
            please contact support@garuda.io for technical support.
            <br /><br />
          </Typography>
          <Typography component={'span'} variant='body1'>
            { configGrid }
          </Typography>
        </div>

        <div className={classes.card}>
          <Typography variant='h5' gutterBottom>
            Terms of Use
          </Typography>
          <Typography variant='body1' gutterBottom>
            By using Plex Horizon, you agree to Garuda Robotics' Terms of Use found
            on <ExternalLink href='https://garuda.io/terms'>garuda.io/terms</ExternalLink>,
            and the Privacy Policy found
            on <ExternalLink href='https://garuda.io/privacy'>garuda.io/privacy</ExternalLink>.
          </Typography>
          <div style={{ border: '1px solid black', padding: '32px' }}>
            <div style={{ display: 'flex' }}>
              <Avatar style={{ marginRight: '32px', width: '80px', height: '80px' }}>
                <Warning color='secondary' style={{ width: '2em', height: '2em' }} />
              </Avatar>
              <Typography variant='body1'>
                You also acknowledge that you understand the danger such a system poses when
                operated by an untrained personnel. Please refer to the Plex Horizon User's
                Manual for more information about how to get the operator suitably trained to
                operate Plex Horizon.
              </Typography>
            </div>
          </div>
        </div>
      </div>
   </>
  )
}

function ToggleButton({ enabled, onToggle }) {
  return (<>
    <Button
      color='primary'
      variant={enabled ? 'contained' : 'outlined' }
      onClick={enabled ? () => {} : onToggle}>
      Enabled
    </Button>
    <Button
      color='primary'
      variant={enabled ? 'outlined' : 'contained' }
      onClick={enabled ? onToggle : () => {}}>
      Disabled
    </Button>
  </>)
}

const mapDispatchToProps = dispatch => {
  return bindActionCreators({ updateSettings }, dispatch)
}

const mapStateToProps = state => ({ currSettings: state.settings.settings })

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Settings))
