import { useEffect } from 'react'

import { getMissionBounds } from '../../utils/mission'

import { useMap } from 'react-leaflet'

function AreaOfOperations({ commands }) {
  // const [ mapBounds, setMapBounds ] = useState(undefined)
  const map = useMap()

  useEffect(() => {
    // console.log('AreaOfOperations commands', commands)
    if (commands) {
      const newBounds = getMissionBounds(commands)
      if (newBounds.isValid()) {
        map.fitBounds(newBounds, { padding: [50, 50] })
        // setMapBounds(newBounds)
        // console.log('AreaOfOperations newBounds', newBounds)
      }
    }
  }, [commands, map])

  return null
}

export default AreaOfOperations