import axios from 'axios'
import { getDecodedToken } from '../../api/accounts'

// TODO: Save the console logs from this file, need to upload to inference logs API

export const INFERENCE_START_ACTION = 'INFERENCE_START_ACTION'
export const INFERENCE_STOP_ACTION  = 'INFERENCE_STOP_ACTION'
export const UPDATE_INFERENCE       = 'UPDATE_INFERENCE'

const ML_MODEL_COMMAND_WEB_API = process.env.REACT_APP_COMMAND_ML_MODEL_WEB_API
const SKYSTREAM_RTMP_URL       = process.env.REACT_APP_SKYSTREAM_RTMP_URL

function dispatchCommand({ cmd, version_id, desc, opts, type, errorDispatch, finalActions, ...rest }) {
  console.log('[MLM]', desc)

  return dispatch => {
    axios
    .post(`${ML_MODEL_COMMAND_WEB_API}/${version_id}/${cmd}`, opts || {})
    .then(response => {
      const result = response.data || {}
      console.log('[MLM]', cmd, 'response:', response)
      dispatch({
        ...rest,
        ...opts,
        ...result,
        version_id,
        type,
      })
    })
    .catch(err => {
      console.log('[MLM] Error', cmd)
      console.dir(err)
      if (errorDispatch)
        dispatch(errorDispatch)
    })
    .finally(() => {
      if (finalActions)
        finalActions()
    })
  }
}

export const startInference = ({ camera, ml_model_version_id }) => {
  if (!camera)
    return console.log('Cannot start inference, invalid camera')
  if (!ml_model_version_id)
    return console.log('Cannot start inference, invalid ml_model_version_id')

  const { drone_id } = getDecodedToken()
  const raw_video = `${SKYSTREAM_RTMP_URL}/SkyStream/${drone_id}/${camera}`
  // const raw_video = 'rtmp://skystream.garuda.io:1935/SkyStream/vod/sample-1080p-1'
  console.log('startInference raw video', raw_video)

  return dispatchCommand({
    cmd:  'start',
    version_id: ml_model_version_id,
    desc: `Starting ml model version ${ml_model_version_id}`,
    opts: {
      raw_video,
      drone_id,
      action: 'start'
    },
    type: INFERENCE_START_ACTION,
  })
}

export const stopInference = ({ video_id, ml_model_version_id }) => {
  if (!video_id)
    return console.log('Cannot stop inference, invalid video_id')
  if (!ml_model_version_id)
    return console.log('Cannot stop inference, invalid ml_model_version_id')

  return dispatchCommand({
    cmd:  'stop',
    version_id: ml_model_version_id,
    desc: `Stopping ml model version ${ml_model_version_id} on video ID ${video_id}`,
    opts: {
      video_id,
      action: 'stop'
    },
    type: INFERENCE_STOP_ACTION,
  })
}

export const updateInference = (inference) => {
  return dispatch => {
    dispatch({
      type: UPDATE_INFERENCE,
      inference
    })
  }
}
