import {
  INFERENCE_START_ACTION,
  INFERENCE_STOP_ACTION,
  UPDATE_INFERENCE,
} from '../actions/inferenceActions'

// Current assumption: each front end can only trigger ONE object class on ONE video
const initialState = {
  video_table: {},     // { video_id: { ...attributes } }
  inference_table: {}, // { objClass: { id: { ...latest telem } }
}

function inferenceReducer(state = initialState, action) {
  let newVideoTable
  switch (action.type) {
    case UPDATE_INFERENCE:
      const { inference } = action
      const { o, id } = inference
      const objLabel = o || 'Unknown Object'
      const objClass = { ...state.inference_table[objLabel] }
      objClass[id || 'Unknown ID'] = { ...inference }
      return {
        ...state,
        inference_table: {
          ...state.inference_table,
          [objLabel]: objClass
        }
      }

    case INFERENCE_START_ACTION:
      const {
        raw_video,
        version_id: start_version_id,
        status:     start_status,
        video_id:   start_video_id,
        annotated_video,
      } = action
      newVideoTable = { ...state.video_table }

      if (start_status === 'success') {
        newVideoTable[start_video_id] = {
          raw_video,
          ml_model_version_id: start_version_id,
          status: 'started',
          annotated_video
        }
      } else {
        newVideoTable[start_video_id] = {
          status: 'error',
          message: 'Start failed' // TBD: Reason from backend
        }
      }
      return {
        ...state,
        video_table: newVideoTable
      }

    case INFERENCE_STOP_ACTION:
      const {
        version_id: stop_version_id,
        video_id:   stop_video_id,
        status:     stop_status,
      } = action
      newVideoTable = { ...state.video_table }
      if (!newVideoTable[stop_video_id])
        newVideoTable[stop_video_id] = {}

      newVideoTable[stop_video_id].ml_model_version_id = stop_version_id
      if (stop_status === 'success') {
        newVideoTable[stop_video_id].status = 'stopped'
      } else {
        newVideoTable[stop_video_id].status = 'error'
        newVideoTable[stop_video_id].message = 'Stop failed' // TBD: Reason from backend
      }
      return {
        ...state,
        video_table: newVideoTable
      }

    default:
      return state
  }
}

export default inferenceReducer
