import auth from './auth'
import command from './command'
import daa from './daa'
import deployment from './deployment'
import inference from './inference'
import map from './map'
import mission from './mission'
import payload from './payload'
import settings from './settings'
import telemetry from './telemetry'
import warnings from './warnings'

function sliceReducers() {
  return {
    auth,
    command,
    daa,
    deployment,
    inference,
    map,
    mission,
    payload,
    settings,
    telemetry,
    warnings,
  }
}

export default sliceReducers
