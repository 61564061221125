import { Component } from 'react'
import videojs from 'video.js'
// import '../static/css/videojs.css'
import 'video.js/dist/video-js.css'
import {
  CircularProgress,
} from '@material-ui/core'

import Peer from 'simple-peer'
window.RTCPeerConnection =
    window.RTCPeerConnection || window.mozRTCPeerConnection || window.webkitRTCPeerConnection
window.RTCIceCandidate =
    window.RTCIceCandidate || window.mozRTCIceCandidate || window.webkitRTCIceCandidate
window.RTCSessionDescription =
    window.RTCSessionDescription ||
    window.mozRTCSessionDescription ||
    window.webkitRTCSessionDescription

const wowzaHostname = process.env.REACT_APP_SKYSTREAM_WEBRTC_URL
const sleep = ms => {
    return new Promise(resolve => setTimeout(resolve, ms))
}
class NewVideoStream extends Component {
    streamInfo = {
        applicationName: this.props.applicationName,
        streamName: this.props.streamName,
        sessionId: '[empty]',
    }
    peer2 = null
    userData = {
        // NOTE: currently stored in .env
        apiServerToken: process.env.REACT_APP_VIDEO_API_SERVER_TOKEN,
        companyId: this.props.companyId,
    }
    state = {
        streamError: null,
        videoLoading: false,
    }
    playVideo = async () => {
        if (this.tryingToPlay) {
            const { videoLoading } = this.state
            if (!videoLoading) {
                this.setState({
                    videoLoading: true,
                })
            }
            return
        }
        this.tryingToPlay = true
        // Create a waiting while loop as long as ready state is less than 2
        // 2: HAVE_CURRENT_DATA
        // 3: HAVE_FUTURE_DATA
        // 4: HAVE_ENOUGH_DATA
        let videoReadyStatus = 0
        do {
            console.log('Trying to load!', this.videoNode.readyState)
            console.log(this.peer2)
            await sleep(500)
            if (this.videoNode !== null) {
                videoReadyStatus = this.videoNode.readyState
            }
        } while (videoReadyStatus < 2)
        await this.videoNode.play()
        this.setState({
            videoLoading: false,
        })
        this.tryingToPlay = false
    }
    waitForReadyStatus = async () => {
        // if (readyState < 1) {
        //     await sleep(1000)
        //     this.waitForReadyStatus()
        // }
    }
    sendPlayOffer = async () => {
        const { readyState } = this.wsConnection
        console.log('WS Status: ', this.wsConnection)
        do {
            await sleep(1000)
        } while (readyState < 1)
        this.wsConnection.send(
            '{"direction":"play", "command":"getOffer", "streamInfo":' +
                JSON.stringify(this.streamInfo) +
                ', "userData":' +
                JSON.stringify(this.userData) +
                '}'
        )
    }
    async startWebsocketConnection() {
        try {
            this.wsConnection = new WebSocket(wowzaHostname)
            this.wsConnection.binaryType = 'arraybuffer'
            this.wsConnection.addEventListener('open', () => {
                console.log(`Connected to signalling server with peer id `, this.wsConnection)
                const peerConfig = {
                    config: { iceServers: [] },
                    initiator: false,
                }
                this.peer2 = new Peer(peerConfig)
                this.peer2.on('signal', data => {
                    console.log('Received signal!', data)
                    // this.peer2.signal(data)
                    if (data.type === 'answer') {
                        this.wsConnection.send(
                            '{"direction":"play", "command":"sendResponse", "streamInfo":' +
                                JSON.stringify(this.streamInfo) +
                                ', "sdp":' +
                                JSON.stringify(data) +
                                ', "userData":' +
                                JSON.stringify(this.userData) +
                                '}'
                        )
                    }
                })
                this.peer2.on('stream', stream => {
                    console.log('Stream: ', stream)
                    let remoteVideo = document.getElementById('screen_html5_api')
                    console.log('Remote video: ', remoteVideo)
                    try {
                        remoteVideo.srcObject = stream
                    } catch (error) {
                        console.log('Error: ', error)
                        remoteVideo.src = window.URL.createObjectURL(stream)
                    }
                    // this.playVideo()
                })
                this.peer2.on('track', (track, stream) => {
                    console.log('Received track!')
                    console.log('Track: ', track)
                    console.log('Stream: ', stream)
                    let remoteVideo = document.getElementById('screen_html5_api')
                    console.log('Remote video: ', remoteVideo)
                    try {
                        remoteVideo.srcObject = stream
                    } catch (error) {
                        console.log('Error: ', error)
                        remoteVideo.src = window.URL.createObjectURL(stream)
                    }
                    // remoteVideo.play()
                    // this.playVideo()
                })
                this.peer2.on('connect', () => console.log('Successfully connected!'))
                this.peer2.on('data', data => console.log('Received data: ', data))
                this.peer2.on('error', err => console.log('Bad signalling data received: ', err))
                this.peer2.on('close', () => console.log('WebRTC Closed!'))
                this.sendPlayOffer()
            })
            this.wsConnection.onmessage = evt => {
                console.log('Message evt: ', evt)
                const dataObj = JSON.parse(evt.data)
                console.log(dataObj)
                var streamInfoResponse = dataObj['streamInfo']
                if (streamInfoResponse !== undefined) {
                    this.streamInfo.sessionId = streamInfoResponse.sessionId
                }
                if (dataObj.command === 'getOffer') {
                    this.peer2.signal(dataObj.sdp)
                }
                if (dataObj.command === 'sendResponse') {
                    console.log('Received response!')
                    this.playVideo()
                }
            }
            this.wsConnection.onclose = async () => {
                console.log('Socket connection closed')
            }
            this.wsConnection.onerror = evt => {
                console.log('Error in wsConnection: ', evt)
            }
        } catch (err) {
            console.log('Error in startWebsocketConnection(): ', err)
            await sleep(500)
            this.startWebsocketConnection(wowzaHostname)
            return
        }
    }
    start() {
        this.streamInfo.applicationName = this.props.applicationName
        this.streamInfo.streamName = this.props.streamName
        this.startWebsocketConnection()
    }
    componentDidMount() {
        this.start()
        this.player = videojs(
            this.videoNode,
            {
                bigPlayButton: false,
                controls: false,
                autoPlay: true,
                preload: 'none',
                inactivityTimeout: 0,
                controlBar: {
                    progressControl: false,
                    currentTimeDisplay: true,
                    remainingTimeDisplay: false,
                    liveDisplay: true,
                    playToggle: true,
                    telemetryToggle: true,
                },
            },
            function onPlayerReady() {
                console.log('onPlayerReady')
            }
        )
        this.start()
    }
    render() {
        const { videoLoading, streamError } = this.state
        return (
            <div
                className='video-container'
                style={{
                    position: 'relative',
                    width: this.props.width,
                    height: this.props.height,
                    backgroundColor: '#000',
                }}
                ref='screenContainer'
            >
                {streamError == null && videoLoading && (
                    <div style={{ position: 'absolute', zIndex: 10000 }}>
                        <CircularProgress />
                    </div>
                )}
                {streamError != null && (
                    <div style={{ position: 'absolute', zIndex: 9999, backgroundColor: '#e74c3c' }}>
                        <p
                            style={{
                                position: 'relative',
                                width: 200,
                                color: '#fff',
                                textAlign: 'center',
                            }}
                        >
                            {streamError}
                        </p>
                    </div>
                )}
                <div data-vjs-player style={{ position: 'relative', zIndex: 9000 }}>
                    <video
                        ref={node => (this.videoNode = node)}
                        className='video-js vjs-default-skin vjs-big-play-centered'
                        autoPlay={true}
                        muted='muted'
                        style={{ width: '100%', height: '100%' }}
                        id='screen'
                    >
                        <p className='vjs-no-js'>
                            To view this video please enable JavaScript, and consider upgrading to a
                            web browser that supports HTML5 video
                        </p>
                    </video>
                </div>
            </div>
        )
    }
}

export default NewVideoStream
