import L from 'leaflet'

export const NAV_TO_WAYPOINT = 'NAV_TO_WAYPOINT'
export const RETURN_TO_LAUNCH_LOC = 'RETURN_TO_LAUNCH_LOC'
export const LAND = 'LAND'
export const TAKE_OFF = 'TAKE_OFF'
export const FACE_HEADING = 'FACE_HEADING'
export const CHANGE_SPEED = 'CHANGE_SPEED'
export const WAIT_FOR_NEXT_ACTION = 'WAIT_FOR_NEXT_ACTION'
export const CHANGE_CAMERA_PITCH = 'CHANGE_CAMERA_PITCH' // old (deprecated in mavlink.io)
export const SET_GIMBAL_PITCHYAW = 'SET_GIMBAL_PITCHYAW' // new (need Arducopter 4.3)

// Support unrecognized commands that include geospatial data.
export const DEFAULT_WITH_GEO = 'DEFAULT_WITH_GEO'

export const commandTypes = ({
  16:   NAV_TO_WAYPOINT,
  20:   RETURN_TO_LAUNCH_LOC,
  21:   LAND,
  22:   TAKE_OFF,
  92:   WAIT_FOR_NEXT_ACTION,
  115:  FACE_HEADING,
  178:  CHANGE_SPEED,
  189:  LAND,
  205:  CHANGE_CAMERA_PITCH,
  1000: SET_GIMBAL_PITCHYAW,
})

/**
 * Parse an array of commands into a more digestable format. Commands represent
 * actions to be taken by the drone.
 * 
 * Some examples include takeoff, landing, and navigation commands, which
 * contain coordinate data in param4, param5, and param6. Other commands such as
 * change speed commands will contain the target speed in param2.
 * @param {{
 *     id: string,
 *     param1: string,
 *     param2: string,
 *     param3: string,
 *     param4: string,
 *     param5: string,
 *     param6: string,
 * }[]} commands The mission planner flight plan commands
 * @returns {({ id: number, type: string, description: string } & ({
 *     lat: number,
 *     lng: number,
 *     alt: number,
 * } | {
 *     speed: number,
 * } | {
 *     heading: number,
 * }))[]} The parsed commands
 */
export const parseMissionCommands = commands => {
  let mapIndex = 1
  const parsedCommands = commands.map(command => {
    const {
      id: stringId,
      description,
      param1, param2, param3, param4, param5, param6, param7,
    } = command
    const id = parseInt(stringId)

    let type = commandTypes[id]
    if (!type) {
      console.error('Unrecognized command, definition required in commandTypes:', command)
      if (param5 && param6 && param7) {
        type = DEFAULT_WITH_GEO
      }
    }
    let cmd = {
      id,
      type,
      description,
    }

    switch (type) {
      case CHANGE_SPEED:
        cmd.speed = parseFloat(param2)
        break
      case FACE_HEADING:
        cmd.heading = parseFloat(param1)
        break
      case WAIT_FOR_NEXT_ACTION:
        break
      case CHANGE_CAMERA_PITCH:
        cmd = { ...cmd,
          pitch: parseFloat(param1), // pitch depending on mount mode (degrees or degrees/second depending on pitch input).
          roll:  parseFloat(param2), // roll depending on mount mode (degrees or degrees/second depending on roll input).
          yaw:   parseFloat(param3), // yaw depending on mount mode (degrees or degrees/second depending on yaw input).
          alt:   parseFloat(param4), // altitude depending on mount mode.
          lat:   parseFloat(param5), // latitude, set if appropriate mount mode.
          lng:   parseFloat(param6), // longitude, set if appropriate mount mode.
          mode:  parseInt(param7),   // Current valid value we handle is 2: MAV_MOUNT_MODE_MAVLINK_TARGETING
                                     // - Load neutral position and start MAVLink Roll,Pitch,Yaw control with stabilization
        }
        break
      case SET_GIMBAL_PITCHYAW:
        cmd = { ...cmd,
          pitch_angle:  parseFloat(param1), // deg, positive to pitch up, relative to vehicle for FOLLOW mode, relative to world horizon for LOCK mode
          yaw_angle:    parseFloat(param2), // deg, positive to yaw to the right, relative to vehicle for FOLLOW mode, absolute to North for LOCK mode
          pitch_rate:   parseFloat(param3), // deg/s positive to pitch up
          yaw_rate:     parseFloat(param4), // deg/s positive to yaw to the right
          device_flags: parseInt(param5),   // 1, 2, 4, 8, 16 for Retract, Neutral, Roll Lock, Pitch Lock, Yaw Lock respectively
          device_id:    parseInt(param7),   // Component ID of gimbal device to address (or 1-6 for non-MAVLink gimbal), 0 for all gimbal device components. Send command multiple times for more than one gimbal (but not all gimbals). 
        }
        break
      case NAV_TO_WAYPOINT:
      case RETURN_TO_LAUNCH_LOC:
      case LAND:
      case TAKE_OFF:
      case DEFAULT_WITH_GEO:
        cmd = { ...cmd,
          lat: parseFloat(param5),
          lng: parseFloat(param6),
          alt: parseFloat(param7),
        }
        break
      default:
        cmd = {}
    }
    if (type !== 'TAKE_OFF' && isValidLatitude(cmd.lat) && isValidLongitude(cmd.lng)) {
      cmd.map_index = mapIndex++
    }
    return cmd
  })

  //
  // @Weicong what is this supposed to do? This was added on 20 May 2021
  // https://bitbucket.org/garudarobotics/garuda-horizon/commits/5223368a2fdb7cb6bae5af9efbdce2f17816ef19
  // According to the commit comments, it's supposed to handle errors - what kind of errors?
  //
  return parsedCommands.map((command, index) => {
    if (command.type === TAKE_OFF && !(command.lat && command.lng && command.alt)) {
      console.log(commands)
      for (let i = 0; i < commands.length; i++) {
        if (i > index) {
          return {
            id:          commands[i].id,
            type:        commands[i].type,
            description: commands[i].description,
            lat: parseFloat(commands[i].param5),
            lng: parseFloat(commands[i].param6),
            alt: parseFloat(commands[i].param7),
          }
        }
      }
    }
    return command
  })
}

export function isValidLatitude(lat) {
  return typeof lat === 'number' && lat >= -90 && lat <= 90
}

export function isValidLongitude(lng) {
  return typeof lng === 'number' && lng >= -180 && lng <= 180
}

export function filterMapCommands(commands) {
  if (Array.isArray(commands))
    return commands
      .filter(command => isValidLatitude(command.lat) && isValidLongitude(command.lng))
  return []  
}

export function commandsToWaypoints(commands) {
  if (Array.isArray(commands))
    return commands
      .filter(command => isValidLatitude(command.lat) && isValidLongitude(command.lng))
      .map(command => [ command.lat, command.lng ])
  return []
}

export function getMissionBounds(commands) {
  return L.latLngBounds(commandsToWaypoints(commands))
}

// An edge is a pair of starting / ending waypoint
export function getMissionEdges(commands) {

  const waypoints = commandsToWaypoints(commands)
  if (waypoints.length <= 1)
    return []
  const edges = []

  for (let i = 1; i < waypoints.length; i++) {
    edges.push([ waypoints[i - 1], waypoints[i] ])
  }
  return edges
}
