/*
This page displays Singapore weather:
rain area movements in last 2 hours (webscrapped from NEA) and 2 hour forecast (NEA API)
	 rain area movements:
			 click top left most button to open rain area panel, default displaying the latest rain area, with NEA rain intensity scale appearing on bottom right
			 click this button again and the panel, intensity scale and rain area overlay will be closed
			 inside the rain area panel:
				 hover mouse on any timestamp will immediately set the rain area of that time onto the map
				 double click on one timestamp to close the panel and intensity scale but keeping the rain area overlay on map
			   in the rain area panel, click the animation to view animated rain area movement when overlay is set on map according to timestamp sequence
		 NOTE: map will not show any change for raincloud if there is no rain observed from NEA, and location of overlay is not very precise
			 https://www.weather.gov.sg/weather-rain-area-50km if the web changes in the future the webscrapper may not work
			 the webpage scrapped is stored in public/NEA_rain_area_source.html
			 NOTE: the coordinate used to set rain area overlay on the map may not be very precise, obtained by trial and error (unable to get exact location of overlay image from website)

		 2 hour forecast:
			 once clicked, weather icons will appear all over singapore; click any of the icons will display specific location information
	   TODO: implement the missing icons (currently some finetuned different weathers are not designed so will be using the same icon as other weather conditions)
			 the current design of icons is very cartoonish, may find a better way to represent different weathers
			 TODO: not visually appealing: currently uses the default google info window, when clicked, the info window pops out and display region and weather information in text;
			 style the infowindow 
*/

import { Component } from 'react'
import axios from 'axios'
// import '../static/css/regionalMap.css'
import Header from '../components/Header'

import Button from '@material-ui/core/Button'
import Popover from '@material-ui/core/Popover'
import SvgIcon from '@material-ui/core/SvgIcon'
import Tooltip from '@material-ui/core/Tooltip'
import {CenterFocusWeak} from '@material-ui/icons'

// weather icons for 2 hour weather forecast
import BR from '../static/images/weather/mist.png'
import CL from '../static/images/weather/cloudy.png'
import DR from '../static/images/weather/light-rain.png'
import FA from '../static/images/weather/fair-day.png'
import FG from '../static/images/weather/mist.png'
import FN from '../static/images/weather/fair-night.png'
import FW from '../static/images/weather/fair-day.png'
import HG from '../static/images/weather/heavy-thundery-showers-with-gusty-winds.png'
import HR from '../static/images/weather/heavy-rain.png'
import HS from '../static/images/weather/heavy-showers.png'
import HT from '../static/images/weather/heavy-thundery-showers.png'
import HZ from '../static/images/weather/mist.png'
import LH from '../static/images/weather/mist.png'
import LR from '../static/images/weather/light-rain.png'
import LS from '../static/images/weather/light-showers.png'
import OC from '../static/images/weather/cloudy.png'
import PC from '../static/images/weather/partly-cloudy-day.png'
import PN from '../static/images/weather/partly-cloudy-night.png'
import PS from '../static/images/weather/passing-showers.png'
import RA from '../static/images/weather/moderate-rain.png'
import SH from '../static/images/weather/showers.png'
import SK from '../static/images/weather/windy.png'
import SN from '../static/images/weather/heavy-showers.png'
import SR from '../static/images/weather/windy.png'
import SS from '../static/images/weather/heavy-showers.png'
import SU from '../static/images/weather/fair-day.png'
import SW from '../static/images/weather/windy.png'
import TL from '../static/images/weather/thundery-showers.png'
import WC from '../static/images/weather/windy.png'
import WD from '../static/images/weather/windy.png'
import WF from '../static/images/weather/windy.png'
import WR from '../static/images/weather/windy.png'
import WS from '../static/images/weather/windy.png'

const NEAWeatherMarkerIcons = {
	BR: {name: 'Mist', icon: BR},
	CL: {name: 'Cloudy', icon: CL},
	DR: {name: 'Drizzle', icon: DR}, // TODO new drizzle icon
	FA: {name: 'Fair (Day)', icon: FA},
	FG: {name: 'Fog', icon: FG}, // TODO new fog icon
	FN: {name: 'Fair (Night)', icon: FN},
	FW: {name: 'Fair & Warm', icon: FW}, // TODO new fair & warm icon
	HG: {name: 'Heavy Thundery Showers with Gusty Winds', icon: HG},
	HR: {name: 'Heavy Rain', icon: HR},
	HS: {name: 'Heavy Showers', icon: HS},
	HT: {name: 'Heavy Thundery Showers', icon: HT},
	HZ: {name: 'Hazy', icon: HZ}, // TODO new hazy icon
	LH: {name: 'Slightly Hazy', icon: LH}, // TODO new slightly hazy icon
	LR: {name: 'Light Rain', icon: LR},
	LS: {name: 'Light Showers', icon: LS},
	OC: {name: 'Overcast', icon: OC}, // TODO new overcast icon
	PC: {name: 'Partly Cloudy (Day)', icon: PC},
	PN: {name: 'Partly Cloudy (Night)', icon: PN},
	PS: {name: 'Passing Showers', icon: PS},
	RA: {name: 'Moderate Rain', icon: RA},
	SH: {name: 'Showers', icon: SH},
	SK: {name: 'Strong Winds, Showers', icon: SK}, // TODO strong winds showers icon
	SN: {name: 'Snow', icon: SN}, // TODO snow icon
	SR: {name: 'Strong Winds, Rain', icon: SR}, // TODO strong winds rain icon
	SS: {name: 'Snow Showers', icon: SS}, // TODO snow showers icon
	SU: {name: 'Sunny', icon: SU}, // TODO sunny icon
	SW: {name: 'Strong Winds', icon: SW}, // TODO strong winds icon
	TL: {name: 'Thundery Showers', icon: TL},
	WC: {name: 'Windy, Cloudy', icon: WC}, // TODO new windy cloudy icon
	WD: {name: 'Windy', icon: WD},
	WF: {name: 'Windy, Fair', icon: WF}, // TODO new windy fair icon
	WR: {name: 'Windy, Rain', icon: WR}, // TODO new windy rain icon
	WS: {name: 'Windy, Showers', icon: WS} // TODO new windy showers icon
}

const weatherApi = process.env.REACT_APP_METEOROLOGY_API

class MapRegional extends Component {
	// the following is the coordinate used to overlay NEA raincloud picture onto SG Map
	// NOTE: the following coordinate may not be very precise, obtained by trial and error (unable to get exact location of overlay image from website)
	rainAreaImageBounds = {
		north: 1.470513,
		south: 1.157910,
		east: 104.145540,
		west: 103.560458
	}

	constructor(props) {
		super(props)
		this.state = {
			rainAreaObj: {}, // store the rain area png as value and timestamp as the key
			rainAreaTimeStamps: [],  // an array of timestamps of all rain area data, used for rain area animation
			rainAreaDisplayIndex: 0,  // for rain area animation, keep track of the current rain area timestamp
			anchorWeatherTimeStamp: null,
			displayScale: 'none', // display the rain intensity scale graph from NEA when rain area is viewed
			weatherForecast2h: [],
			ifShowWeatherForecast2h: false,
			ifRainAnimation: false,
			scrollTopValue: 0, // auto scroll for the list of time stamp in rain area panel
			showLastTimeStamp: true, // default to display latest rain area once rain area panel is opened
		}
	}

	componentDidMount() {
		if (window.google) {
			this.loadMap()
			this.updateRainArea()
		}
	}

  // WARN: The backend for this call is being migrated to meteorology service and might break
	updateRainArea = () => {
		let rainAreaUrl = weatherApi + '/rainAreas'
		console.log('Updated')
		console.log('updating rain area from: ' + rainAreaUrl)

		axios.get(rainAreaUrl).then((response) => {
			const timeStampArr = Object.keys(response.data)
			if (timeStampArr.length) {
				this.setState({
					rainAreaObj: response.data,
					rainAreaTimeStamps: timeStampArr,
				}, () => {
					console.log('successfully updated rain information', response.data)
				})
			}
		}).catch(err => {
			alert('unable to get rain area data!')
			console.log('error when retrieving data from https://www.weather.gov.sg/warning-heavy-rain/:', err)
		})
	}

	handleScroll = () => {
		// prevent auto scroll if user is scrolling
		if (document.getElementById('timestamp')) { // the timestamp component will only be loaded if data retrieved successfully from NEA
			const numScroll = document.getElementById('timestamp').scrollTop
			document.getElementById('timestamp').scrollTop = numScroll
		} else {
			console.log('timestamp component not loaded')
		}
	}

	componentDidUpdate(prevProps, prevState) {
		// if (prevProps.google !== this.props.google) {
		//   this.loadMap();
		// }
		if (document.getElementById('timestamp')) {
			if (this.state.ifRainAnimation) {
				// highlight the corresponding time of the rain area in the timestamp list
				const prevTimestampIndex = this.state.rainAreaDisplayIndex - 1 >= 0 ? this.state.rainAreaDisplayIndex - 1 : this.state.rainAreaTimeStamps.length - 1
				const prevTimestamp = this.state.rainAreaTimeStamps[prevTimestampIndex]
				const timestamp = this.state.rainAreaTimeStamps[this.state.rainAreaDisplayIndex]
				document.getElementById('timestamp').scrollTop = this.state.scrollTopValue
				if (document.getElementById(prevTimestamp)) {
					document.getElementById(prevTimestamp).style.color = '#000'
					document.getElementById(prevTimestamp).style.backgroundColor = '#3AA5E6'
				}
				if (document.getElementById(timestamp)) {
					document.getElementById(timestamp).style.color = '#fff'
					document.getElementById(timestamp).style.backgroundColor = '#4DDBFF'
				}
			}
		}
	}

	toggleForecastDisplay = () => {
		if (this.state.ifShowWeatherForecast2h) {
			this.hideWeatherForecast2h()
		} else {
			this.getWeatherForecast2h()
		}
		this.setState(prevState => {
			return ({
				ifShowWeatherForecast2h: !prevState.ifShowWeatherForecast2h
			})
		})
	}

  // WARN: The backend for this call is being migrated to meteorology service and might break
	getWeatherForecast2h = () => {
		axios.get(weatherApi + '/weatherForecast2h')
			.then(resp => {
				console.log(resp.data)
				this.setState({
					weatherForecast2h: resp.data
				}, () => {
					console.log('get weather forecast 2h:', resp.data)
					this.showWeatherForecast2h(resp.data)
				})
			})
			.catch(err => {
				console.log(err)
			})
	}

	showWeatherForecast2h = (weatherForecast2h) => {
		this.weatherMarkers = [] // clear for good measure...
		this.weatherInfoWindows = []

		const map = this.regionalMap
		weatherForecast2h.forEach(elem => {
			elem.area.forEach((area) => {
				if (!area.forecast || !NEAWeatherMarkerIcons[area.forecast]) {
					console.error('NEA Weather - unknown area:', area.name, area.forecast)
				}

				var iw = new window.google.maps.InfoWindow({
					position: new window.google.maps.LatLng(area.lat, area.lng),
					content: area.name + '<br/><b>' + NEAWeatherMarkerIcons[area.forecast].name + '</b>'
				})
				this.weatherInfoWindows.push(iw)

				if (NEAWeatherMarkerIcons[area.forecast].icon === '')
					iw.setMap(map) // so that we remember we have missing weather icons. remove when done

				var m = new window.google.maps.Marker({
					position: {lat: Number(area.lat), lng: Number(area.lng)},
					map: map,
					icon: {
						url: NEAWeatherMarkerIcons[area.forecast].icon,
						scaledSize: new window.google.maps.Size(50, 50)
					}
				})
				this.weatherMarkers.push(m)
				m.addListener('click', () => {
					iw.open(map, m)
				})
			})
		})

		this.setState({
			ifShowWeatherForecast2h: true
		})
	}

	hideWeatherForecast2h = () => {
		if (this.weatherInfoWindow) {
			for (let i = 0; i < this.weatherInfoWindows.length; i++) {
				this.weatherInfoWindows[i].close()
			}
			this.weatherInfoWindows = []
		}
		if (this.weatherMarkers) {
			for (let i = 0; i < this.weatherMarkers.length; i++) {
				this.weatherMarkers[i].setMap(null)
			}
			this.weatherMarkers = []
		}

	}

	loadMap = () => {
		if (window.google) {
			const maps = window.google.maps
			const styledMapType = new maps.StyledMapType([
				{
					'elementType': 'geometry',
					'stylers': [
						{
							'color': '#f5f5f5'
						}
					]
				},
				{
					'elementType': 'labels.icon',
					'stylers': [
						{
							'visibility': 'off'
						}
					]
				},
				{
					'elementType': 'labels.text.fill',
					'stylers': [
						{
							'color': '#616161'
						}
					]
				},
				{
					'elementType': 'labels.text.stroke',
					'stylers': [
						{
							'color': '#f5f5f5'
						}
					]
				},
				{
					'featureType': 'administrative.land_parcel',
					'elementType': 'labels.text.fill',
					'stylers': [
						{
							'color': '#bdbdbd'
						}
					]
				},
				{
					'featureType': 'poi',
					'elementType': 'geometry',
					'stylers': [
						{
							'color': '#eeeeee'
						}
					]
				},
				{
					'featureType': 'poi',
					'elementType': 'labels.text.fill',
					'stylers': [
						{
							'color': '#757575'
						}
					]
				},
				{
					'featureType': 'poi.park',
					'elementType': 'geometry',
					'stylers': [
						{
							'color': '#e5e5e5'
						}
					]
				},
				{
					'featureType': 'poi.park',
					'elementType': 'labels.text.fill',
					'stylers': [
						{
							'color': '#9e9e9e'
						}
					]
				},
				{
					'featureType': 'road',
					'elementType': 'geometry',
					'stylers': [
						{
							'color': '#ffffff'
						}
					]
				},
				{
					'featureType': 'road.arterial',
					'elementType': 'labels.text.fill',
					'stylers': [
						{
							'color': '#757575'
						}
					]
				},
				{
					'featureType': 'road.highway',
					'elementType': 'geometry',
					'stylers': [
						{
							'color': '#dadada'
						}
					]
				},
				{
					'featureType': 'road.highway',
					'elementType': 'labels.text.fill',
					'stylers': [
						{
							'color': '#616161'
						}
					]
				},
				{
					'featureType': 'road.local',
					'elementType': 'labels.text.fill',
					'stylers': [
						{
							'color': '#9e9e9e'
						}
					]
				},
				{
					'featureType': 'transit.line',
					'elementType': 'geometry',
					'stylers': [
						{
							'color': '#e5e5e5'
						}
					]
				},
				{
					'featureType': 'transit.station',
					'elementType': 'geometry',
					'stylers': [
						{
							'color': '#eeeeee'
						}
					]
				},
				{
					'featureType': 'water',
					'elementType': 'geometry',
					'stylers': [
						{
							'color': '#c9c9c9'
						}
					]
				},
				{
					'featureType': 'water',
					'elementType': 'labels.text.fill',
					'stylers': [
						{
							'color': '#9e9e9e'
						}
					]
				}
			], {name: 'Silver Map', alt: 'show silver map'})

			this.regionalMap = new maps.Map(document.getElementById('regionalMap'), {
				center: {lat: 1.361315, lng: 103.803944},
				zoom: 12,
				fullscreenControl: false,
				zoomControlOptions: {
					position: window.google.maps.ControlPosition.LEFT_CENTER
				},
				mapTypeControl: false,
				zoomControl: true,
				streetViewControl: false
			})
			this.regionalMap.mapTypes.set('styled_map', styledMapType)
			this.regionalMap.setMapTypeId('styled_map')
			// must initiate overlay first so later every call to setWeatherOverlay will have a  previous to clear
			this.weatherOverlay = new window.google.maps.GroundOverlay('', this.rainAreaImageBounds)
		}
	}

	openWeatherTimeStamp = (event) => {
		this.updateRainArea()
		this.setState({
			anchorWeatherTimeStamp: event.currentTarget,
			displayScale: '',
			showLastTimeStamp: true
		}, () => {
			const index = this.state.rainAreaTimeStamps.length - 1
			this.setWeatherOverlay(this.state.rainAreaTimeStamps[index])
		})
	}

	closeWeatherTimeStamp = (clearOverlay) => {
		this.setState({
			anchorWeatherTimeStamp: null,
			displayScale: 'none'
		})
		if (clearOverlay && this.weatherOverlay) {
			this.weatherOverlay.setMap(null)
		}
	}

	setWeatherOverlay = (timeStamp) => {
		if (this.weatherOverlay) {
			this.weatherOverlay.setMap(null)
			this.weatherOverlay = new window.google.maps.GroundOverlay(this.state.rainAreaObj[timeStamp], this.rainAreaImageBounds)
			this.weatherOverlay.setOpacity(0.5)
			this.weatherOverlay.setMap(this.regionalMap)
		}
		if (document.getElementById(timeStamp)) {
			document.getElementById(timeStamp).style.color = '#fff'
			document.getElementById(timeStamp).style.backgroundColor = '#226086'
		}
	}

	handleMouseLeave = (timeStamp) => {
		if (document.getElementById(timeStamp)) {
			document.getElementById(timeStamp).style.color = '#000'
			document.getElementById(timeStamp).style.backgroundColor = '#3AA5E6'
		}
	}

	animateRainMap = () => {
		const currTimeStamp = this.state.rainAreaTimeStamps[this.state.rainAreaDisplayIndex]
		const maxIndex = this.state.rainAreaTimeStamps.length
		this.setWeatherOverlay(currTimeStamp)
		this.setState((prevState) => {
			return ({
				scrollTopValue: (prevState.rainAreaDisplayIndex + 1) % maxIndex * 25,
				rainAreaDisplayIndex: (prevState.rainAreaDisplayIndex + 1) % maxIndex
			})
		})
	}

	toggleRainAnimation = () => {
		if (!this.state.ifRainAnimation) {
			this.rainAnimationID = setInterval(this.animateRainMap, 1000)
			document.getElementById('animation-button').style.cssText = 'background-color: #226086; color:#fff;margin:8px'
		} else {
			clearInterval(this.rainAnimationID)
			if (this.weatherOverlay) {
				this.weatherOverlay.setMap(null)
			}
			document.getElementById('animation-button').style.cssText = 'background-color: #4DDBFF; color:#000;margin:8px'
		}
		this.setState(prevState => {
			return ({
				ifRainAnimation: !prevState.ifRainAnimation,
			})
		})
	}

	centerSG = () => {
		if (this.regionalMap) {
			this.regionalMap.setCenter({lat: 1.361315, lng: 103.803944})
			this.regionalMap.setZoom(12)
		}
	}

	render() {
		let forecaseButtonBackground = this.state.ifShowWeatherForecast2h ? '#3AA5E6' : '#000'
		let rainAreaButtonBackground = this.state.anchorWeatherTimeStamp ? '#3AA5E6' : '#000'
		return (
			<div style={{'positon': 'relative'}}>
				<Header/>
				<div style={{'position': 'relative'}}>
					<div style={{ right: 0, top: 0, marginTop: '10px', marginRight: '10px' }}>
						<Tooltip title="Center Singapore" placement="bottom">
							<Button
								style={{width: 40, minWidth: 40, height: 40, backgroundColor: '#fff'}}
								onClick={this.centerSG}
								size='small'
								children={
									<CenterFocusWeak style={{
										fontSize: 35,
										backgroundColor: '#fff',
										minWidth: 35,
										width: 35,
										marginTop: -5
									}}/>
								}
							/>
						</Tooltip>
					</div>

					<div style={{ left: 0, top: 0, margin: '10px' }}>
						<Tooltip title="Rain Area" placement="bottom">
							<Button
								style={{
									backgroundColor: rainAreaButtonBackground,
									color: '#fff',
									width: 40,
									height: 40,
									minWidth: 40
								}}
								aria-haspopup="true"
								onClick={this.openWeatherTimeStamp}
								children={
									<SvgIcon style={{'fontSize': '35px', 'marginTop': '-5px'}}>
										<path
											d="M20,8.2c0-0.1,0-0.1,0-0.2c0-1.6-1.2-2.9-2.8-3c-0.8-1.2-2.2-2-3.8-2c-0.9,0-1.8,0.3-2.5,0.7c-0.7-1-2-1.7-3.4-1.7   C5,2,3,4,3,6.5c0,0.7,0.2,1.4,0.4,1.9C2.6,9,2,9.9,2,11c0,1.7,1.3,3,3,3h14c1.7,0,3-1.3,3-3C22,9.7,21.2,8.6,20,8.2z"/>
										<path d="M4,20c0,1.1,0.9,2,2,2s2-0.9,2-2s-2-4-2-4S4,18.9,4,20z"/>
										<path d="M10,20c0,1.1,0.9,2,2,2s2-0.9,2-2s-2-4-2-4S10,18.9,10,20z"/>
										<path d="M16,20c0,1.1,0.9,2,2,2s2-0.9,2-2s-2-4-2-4S16,18.9,16,20z"/>
									</SvgIcon>
								}
							/>
						</Tooltip>
						<Tooltip title="2 Hour Forcast" placement="bottom">
							<Button
								style={{
									backgroundColor: forecaseButtonBackground,
									color: '#fff',
									width: 40,
									height: 40,
									minWidth: 40,
									marginLeft: 5
								}}
								aria-haspopup="true"
								onClick={this.toggleForecastDisplay}
								children={
									<SvgIcon style={{'fontSize': '35px', 'marginTop': '-5px'}}>
										<path stroke="white" strokeWidth="1"
											  d="M10.996,14.315c-0.193,0-0.35,0.156-0.35,0.35v1.52c0,0.193,0.156,0.35,0.35,0.35s0.35-0.156,0.35-0.35v-1.52   C11.346,14.471,11.189,14.315,10.996,14.315z"/>
										<path stroke="white" strokeWidth="1"
											  d="M10.996,17.251c-0.193,0-0.35,0.156-0.35,0.35v1.936c0,0.193,0.156,0.35,0.35,0.35s0.35-0.156,0.35-0.35v-1.936   C11.346,17.408,11.189,17.251,10.996,17.251z"/>
										<path stroke="white" strokeWidth="1"
											  d="M10.996,20.357c-0.193,0-0.35,0.156-0.35,0.35v1.52c0,0.193,0.156,0.35,0.35,0.35s0.35-0.156,0.35-0.35v-1.52   C11.346,20.513,11.189,20.357,10.996,20.357z"/>
										<path stroke="white" strokeWidth="1"
											  d="M13.416,16.42c-0.193,0-0.35,0.156-0.35,0.35v1.078c0,0.193,0.156,0.35,0.35,0.35s0.35-0.156,0.35-0.35V16.77   C13.766,16.577,13.609,16.42,13.416,16.42z"/>
										<path stroke="white" strokeWidth="1"
											  d="M13.416,18.771c-0.193,0-0.35,0.156-0.35,0.35v1.421c0,0.193,0.156,0.35,0.35,0.35s0.35-0.156,0.35-0.35v-1.421   C13.766,18.927,13.609,18.771,13.416,18.771z"/>
										<path stroke="white" strokeWidth="1"
											  d="M13.416,21.538c-0.193,0-0.35,0.156-0.35,0.35v1.52c0,0.193,0.156,0.35,0.35,0.35s0.35-0.156,0.35-0.35v-1.52   C13.766,21.694,13.609,21.538,13.416,21.538z"/>
										<path stroke="white" strokeWidth="1"
											  d="M15.837,21.336c-0.193,0-0.35,0.156-0.35,0.35v1.52c0,0.193,0.156,0.35,0.35,0.35c0.193,0,0.35-0.156,0.35-0.35v-1.52   C16.187,21.493,16.03,21.336,15.837,21.336z"/>
										<path stroke="white" strokeWidth="1"
											  d="M15.837,18.219c-0.193,0-0.35,0.156-0.35,0.35v1.904c0,0.193,0.156,0.35,0.35,0.35c0.193,0,0.35-0.156,0.35-0.35v-1.904   C16.187,18.375,16.03,18.219,15.837,18.219z"/>
										<path stroke="white" strokeWidth="1"
											  d="M15.837,15.485c-0.193,0-0.35,0.156-0.35,0.35v1.52c0,0.193,0.156,0.35,0.35,0.35c0.193,0,0.35-0.156,0.35-0.35v-1.52   C16.187,15.641,16.03,15.485,15.837,15.485z"/>
										<path stroke="white" strokeWidth="1"
											  d="M12.807,2.152c-0.143,0.169-0.121,0.421,0.048,0.563l1.275,1.075c0.075,0.063,0.167,0.095,0.258,0.095   c0.113,0,0.227-0.049,0.306-0.143c0.143-0.169,0.121-0.421-0.048-0.563L13.37,2.104C13.201,1.961,12.949,1.983,12.807,2.152z"/>
										<path stroke="white" strokeWidth="1"
											  d="M16.934,0.245c-0.22,0.021-0.381,0.217-0.359,0.438l0.161,1.66c0.021,0.206,0.194,0.361,0.398,0.361   c0.013,0,0.025-0.001,0.039-0.002c0.22-0.021,0.381-0.217,0.359-0.438l-0.161-1.66C17.35,0.385,17.174,0.23,16.934,0.245z"/>
										<path stroke="white" strokeWidth="1"
											  d="M21.077,1.725l-1.045,1.301c-0.139,0.173-0.111,0.425,0.061,0.563c0.074,0.06,0.163,0.089,0.251,0.089   c0.117,0,0.232-0.052,0.312-0.149L21.7,2.227c0.139-0.173,0.111-0.425-0.061-0.562C21.467,1.526,21.216,1.554,21.077,1.725z"/>
										<path stroke="white" strokeWidth="1"
											  d="M23.557,5.694l-1.632,0.158c-0.221,0.021-0.382,0.217-0.36,0.438c0.021,0.206,0.194,0.361,0.398,0.361   c0.013,0,0.025-0.001,0.039-0.002l1.632-0.158c0.221-0.021,0.382-0.217,0.36-0.438C23.973,5.834,23.795,5.683,23.557,5.694z"/>
										<path stroke="white" strokeWidth="1"
											  d="M21.449,8.751c-0.168-0.142-0.421-0.123-0.564,0.045c-0.143,0.168-0.123,0.421,0.045,0.564l1.27,1.083   c0.075,0.063,0.168,0.096,0.26,0.096c0.113,0,0.226-0.048,0.305-0.141c0.143-0.168,0.123-0.421-0.045-0.564L21.449,8.751z"/>
										<path stroke="white" strokeWidth="1"
											  d="M11.542,9.854c-0.769,0-1.395,0.626-1.395,1.395c0,0.212,0.048,0.414,0.134,0.594H0.404c-0.221,0-0.4,0.18-0.4,0.4   s0.18,0.4,0.4,0.4h11.208c0.027,0,0.055-0.003,0.08-0.009c0.699-0.075,1.245-0.668,1.245-1.386   C12.938,10.48,12.312,9.854,11.542,9.854z M11.542,11.842c-0.327,0-0.594-0.267-0.594-0.594s0.267-0.594,0.594-0.594   c0.328,0,0.595,0.267,0.595,0.594S11.87,11.842,11.542,11.842z"/>
										<path stroke="white" strokeWidth="1"
											  d="M0.004,10.058c0,0.221,0.18,0.4,0.4,0.4H7.21c0.027,0,0.055-0.003,0.08-0.009c0.698-0.075,1.244-0.667,1.244-1.385   c0-0.77-0.626-1.396-1.395-1.396S5.745,8.295,5.745,9.065c0,0.212,0.048,0.413,0.133,0.593H0.404   C0.184,9.658,0.004,9.837,0.004,10.058z M7.14,8.47c0.327,0,0.594,0.267,0.594,0.595c0,0.327-0.267,0.593-0.594,0.593   S6.546,9.392,6.546,9.065C6.546,8.737,6.813,8.47,7.14,8.47z"/>
										<path stroke="white" strokeWidth="1"
											  d="M7.29,13.829c-0.025-0.006-0.053-0.009-0.08-0.009H0.404c-0.221,0-0.4,0.18-0.4,0.4s0.18,0.4,0.4,0.4h5.475   c-0.086,0.18-0.134,0.382-0.134,0.594c0,0.77,0.626,1.396,1.395,1.396s1.395-0.626,1.395-1.396   C8.534,14.497,7.988,13.904,7.29,13.829z M7.14,15.809c-0.327,0-0.594-0.267-0.594-0.595c0-0.327,0.267-0.594,0.594-0.594   s0.594,0.267,0.594,0.594C7.733,15.542,7.467,15.809,7.14,15.809z"/>
										<path stroke="white" strokeWidth="1"
											  d="M20.286,9.418c0.819-0.816,1.239-1.954,1.127-3.115c-0.101-1.029-0.596-1.958-1.394-2.614   c-0.799-0.657-1.797-0.958-2.835-0.864c-1.55,0.151-2.821,1.197-3.294,2.647c-0.746-2.446-2.99-4.186-5.64-4.186   c-3.225,0-5.821,2.525-5.911,5.749C2.332,7.256,2.506,7.44,2.728,7.446C2.73,7.447,2.734,7.447,2.738,7.447   c0.216,0,0.394-0.172,0.399-0.39c0.078-2.787,2.323-4.97,5.112-4.97c2.631,0,4.819,1.971,5.092,4.584 c0.012,0.119,0.077,0.227,0.177,0.293c0.102,0.066,0.226,0.085,0.339,0.048c0.238-0.073,0.481-0.11,0.722-0.11   c1.376,0,2.495,1.119,2.495,2.495l-0.006,0.152c-0.007,0.131,0.051,0.257,0.155,0.337c0.102,0.079,0.236,0.102,0.365,0.065   c2.059-0.651,4.098,0.927,4.098,2.972c0,1.726-1.402,3.129-3.126,3.129c-0.221,0-0.4,0.18-0.4,0.4s0.18,0.4,0.4,0.4   c2.165,0,3.927-1.763,3.927-3.93C22.487,11.388,21.573,10.063,20.286,9.418z M17.857,9.061c-0.171-1.676-1.638-2.978-3.336-2.943   c0.01-0.024,0.025-0.046,0.031-0.073c0.276-1.319,1.365-2.294,2.71-2.424c1.694-0.165,3.189,1.074,3.354,2.76   c0.099,1.009-0.308,2-1.085,2.652c-0.029,0.024-0.048,0.055-0.068,0.085C18.953,8.994,18.412,8.959,17.857,9.061z"/>
									</SvgIcon>
								}
							/>
						</Tooltip>
					</div>

					<div style={{ position: 'absolute', zIndex: 3, marginTop: '20vh' }}>
						<Popover
							id="rain-time-menu"
							anchorEl={this.state.anchorWeatherTimeStamp}
							open={Boolean(this.state.anchorWeatherTimeStamp)}
							onClose={() => this.closeWeatherTimeStamp(true)}
							anchorOrigin={{
								vertical: 'bottom',
								horizontal: 'left',
							}}
							transformOrigin={{
								vertical: 'top',
								horizontal: 'left',
							}}
							children={
								<div style={{'backgroundColor': '#3AA5E6'}}>
									<Button
										id="animation-button"
										style={{'backgroundColor': '#4DDBFF', 'color': '#000', 'margin': '8px'}}
										onClick={this.toggleRainAnimation}
										children={
											<span>Animate</span>
										}
									/>
									<div style={{ overflowY: 'scroll', height: '50vh' }}
										 id="timestamp"
										 onScroll={this.handleScroll}
									>
										{Object.keys(this.state.rainAreaObj).map(option => (
											<div
												key={option}
												id={option}
												style={{ padding: '8px', paddingLeft: '20px' }}
												onMouseOver={() => this.setWeatherOverlay(option)}
												onMouseLeave={() => this.handleMouseLeave(option)}
												onDoubleClick={() => this.closeWeatherTimeStamp(false)}
											>
												{option}
											</div>
										))}
									</div>
								</div>
							}
						>
						</Popover>
					</div>


					<div style={{ display: this.state.displayScale, position: 'absolute', zIndex: 3, bottom: 0, right: 0, margin: '0 8px 24px 0', backgroundColor: '#f5f5f5' }}>
						<img alt=""
							 src="https://www.weather.gov.sg/wp-content/themes/wiptheme/images/rain-intensity.jpg"/>
					</div>

					<div style={{ width: '100vw', height: 'calc(100vh - 48px)', margin: '0 auto', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
						Loading map... You may be offline
					</div>

				</div>
			</div>
		)
	}
}


export default MapRegional
