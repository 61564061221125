import { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import axios from 'axios'

import LiveTelemetryListener   from '../telemetry/LiveTelemetryListener'
import NearbyTelemetryListener from '../telemetry/NearbyTelemetryListener'
import NearbyViolationListener from '../telemetry/NearbyViolationListener'
import WarningStoreConnector   from '../warnings/Components/WarningsStoreConnector'

import Header         from '../components/Header'
import LiveMap        from './LiveMap'
import WeatherMap     from './WeatherMap'
import DroneDetailMap from './DroneDetailMap'

import {
  Grid,
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'

// todo move to actions
const PROJECTS_API     = process.env.REACT_APP_PROJECTS_API

const styles = theme => ({
  container: {
    position: 'fixed',
    width:    '100vw',
    height:   'calc(100vh - 48px)',
  },
  droneDetailMap: {
    border:   '1px solid ' + theme.palette.bluegrey.main,
    height:   '45%',
    position: 'relative',
  },
  weatherMap: {
    border:   '1px solid ' + theme.palette.bluegrey.main,
    height:   '55%',
    position: 'relative',
  }
})

function RegionalMap({
  classes,
  telemetry,
  mission,
  deployments,
  targetGeoJson,
  latestWarning,
  useCfms,
  emergencyLandingAreas
}) {
  const [ project, setProject ] = useState()
  const [ docks,   setDocks   ] = useState([])

  const [ cfmsTracks,     setCfmsTracks     ] = useState({})    // uasid -> obj
  const [ cfmsViolations, setCfmsViolations ] = useState([])
  const [ selectedUasId,  setSelectedUasId  ] = useState('')
  const [ selectedDroneId, setSelectedDroneId] = useState('')

  useEffect(() => {
    axios.get(`${PROJECTS_API}/`).then((response) => {
      const htxProject = response.data.data.find((project) => project.project_id === 'htx-helios-project')
      setProject(htxProject)
    })
  }, [])

  useEffect(() => {
    if (project) {
      const docks = project.geoJson.features.filter((f) => f.properties.type === 'docking-station')
      setDocks(docks)
    }
  }, [project])

  function handleNearbyTelemetryMessage(event) {
    const track = JSON.parse(event.data)

    if (track.type === 'cfms_drone') {
      handleCfmsTelemetryMessage(track)
    } else { // normal trackers and demo trackers
      handleTrackerTelemetryMessage(track)
    }
  }

  function handleCfmsTelemetryMessage(track) {
    const uasid = track.data_provider?.reference_data?.reference_id // 6 char CFMS flight identifier

    setCfmsTracks(prevCfmsTracks => ({
      ...prevCfmsTracks,
      [uasid]: track
    }))
  }

  function handleTrackerTelemetryMessage(track) {
    // Coming Soon (copy over from mdf?)
  }
  
  function handleNearbyViolationMessage(event) {
    const violation = JSON.parse(event.data)
    console.log('handleNearbyViolationMessage violation', violation)
    const imei  = violation.violation.vehicle_info.id

    setCfmsViolations(prevCfmsViolations => {
      const newCfmsViolations = prevCfmsViolations.filter(v => (v.vehicle_info.id !== imei))
      if (violation.event_type === 'CREATED' ||
          violation.event_type === 'UPDATED')
        newCfmsViolations.push(violation.violation)
      return newCfmsViolations
    })
  }

  function handleCfmsUasSelected(uasid) {
    console.log('Selected', uasid)
    setSelectedUasId(uasid)
  }

  function handleDroneSelected(droneId){
    setSelectedUasId('')
    setSelectedDroneId(droneId)
  }
  return (
    <LiveTelemetryListener>
      <NearbyTelemetryListener
        // showCfmsTracks={showCfmsTracks}
        onNearbyTelemetryMessage={handleNearbyTelemetryMessage} />
      <NearbyViolationListener
        // showCfmsViolations={showCfmsViolations}
        onNearbyViolationMessage={handleNearbyViolationMessage} />
      <WarningStoreConnector>
        <Header />
        <Grid container spacing={0} className={classes.container}>
          <Grid item xs={8}>
            <LiveMap
              showControls
              showCfmsControls={useCfms}
              docks={docks}
              telemetry={telemetry}
              mission={mission}
              deployments={deployments}
              targetGeoJson={targetGeoJson}
              emergencyLandingAreas={emergencyLandingAreas}
              latestWarning={latestWarning}
              cfmsTracks={cfmsTracks}
              cfmsViolations={cfmsViolations}
              onCfmsUasSelected={handleCfmsUasSelected}
              onDroneSelected={handleDroneSelected}
              hideDomeCamera
            />
          </Grid>
          <Grid item xs={4}>
            <div className={classes.droneDetailMap}> {/* TODO: generalise after helios into UTM Map */}
              <DroneDetailMap zoom={14}
                droneName={mission?.droneName}
                telemetry={telemetry}
                selectedUasId={selectedUasId}
                cfmsTracks={cfmsTracks}
                cfmsViolations={cfmsViolations}
                onUnselectUasId={() => setSelectedUasId('')}
                selectedDroneId={selectedDroneId}
              />
            </div>
            <div className={classes.weatherMap}>
              <WeatherMap zoom={10} /> {/* TODO: center this weather map to the AO */}
            </div>
          </Grid>
        </Grid>
      </WarningStoreConnector>
    </LiveTelemetryListener>
  )
}

const mapStateToProps = state => {
  const activeByKey = state.warnings.activeWarnings
  const activeWarnings = Array.from(Object.keys(activeByKey)).map(key => activeByKey[key])
  // Sort by severity from 1 to 5, then by startingTimestamp from latest to
  // earliest to retrieve the most recently started warning with the highest
  // severity.
  activeWarnings.sort((a, b) => {
    if (a.severity !== b.severity) {
      return a.severity - b.severity
    }
    return b.startingTimestamp - a.startingTimestamp
  })
  const geoJson = state.deployment.deploymentData && state.deployment.deploymentData.geoJson
  const targetGeoJson = geoJson && {
    ...geoJson,
    features: geoJson.features.filter((feature) => feature.properties.layerType === 'Target')
  }

  let emergencyLandingAreas = null
  if (state.deployment.deploymentData && state.deployment.deploymentData.emergency_landing_areas) {
    emergencyLandingAreas = state.deployment.deploymentData.emergency_landing_areas
  }

  return {
    companyId: state.oidc.user.profile.company_id,
    droneId: state.mission.droneId,
    telemetry: state.telemetry.telemetry,
    mission: state.mission.mission,
    deployments: state.deployment.deployments,
    targetGeoJson,
    latestWarning: activeWarnings.length > 0 ? activeWarnings[0] : null,
    useCfms: state.settings?.settings?.useCfms || false,
    emergencyLandingAreas: emergencyLandingAreas
  }
}

export default connect(mapStateToProps, null)(withStyles(styles)(RegionalMap))
