import { NETWORK_SPEED_CHECK_STORAGE } from './commandActions'

// Get Network spped of co-pilot by doing command for speed test
const getNetworkSpeedCheck = () => {
  const networkSpeedCheckStorage = JSON.parse(window.localStorage.getItem(NETWORK_SPEED_CHECK_STORAGE))
  let downloadSpeed; let uploadSpeed = '-'
  if (networkSpeedCheckStorage) {
    downloadSpeed = (networkSpeedCheckStorage.download / 1000000).toFixed(2)
    uploadSpeed = (networkSpeedCheckStorage.upload / 1000000).toFixed(2)
  }
  return { downloadSpeed, uploadSpeed }
}

// Reset the network to default state after 1) Drone looses connection
const resetNetworkSpeed = () => {
  let downloadSpeed; const uploadSpeed = '-'
  return { downloadSpeed, uploadSpeed }
}

export { getNetworkSpeedCheck, resetNetworkSpeed }
