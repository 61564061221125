import { useState, useEffect } from 'react'
import ReactDOMServer from 'react-dom/server'
import { useMap } from 'react-leaflet'
import { useInterval } from 'usehooks-ts'
import L from 'leaflet'
import { droneCategoryToSVG, arrowCategorytoSVG } from '../../static/constants/droneSVG'
import { withStyles } from '@material-ui/core/styles'

const fresh_ms = 60 * 1000 // e.g. only telemetry in the last 1 minute is fresh

function CfmsTrackMarkers({ cfmsTracks, showCfmsTracks, onClick }) {

  const map = useMap()
  const [ markers,    setMarkers    ] = useState([])
  const [ lastUpdate, setLastUpdate ] = useState(null)

  useEffect(() => {
    markers.forEach(marker => marker.remove(map))
    if (!showCfmsTracks) {
      setMarkers([])
      return null
    }
    if (!cfmsTracks)
      return null

    console.log('cfmsTracks', cfmsTracks)
    const newMarkers = []

    Object.keys(cfmsTracks).forEach(uasid => {
      const t = cfmsTracks[uasid]
      const marker = L.marker([t.lat, t.lng], {
        zIndexOffset: 100,
        icon: L.divIcon({
          className: 'dummy',
          html: ReactDOMServer.renderToString(
            <StyledDroneIcon yaw={t.data_provider.extras.trackDirection} />
          ),
          iconSize: [120, 120],
        })
      })
      .on('click', (e) => { // e.latlng (if need)
        onClick(uasid)
      })
      .addTo(map)
      newMarkers.push(marker)
    })
    setMarkers(newMarkers)
    setLastUpdate(new Date())
  // eslint-disable-next-line
  }, [cfmsTracks, showCfmsTracks, map])

  useInterval(() => {
    if (!lastUpdate)
      return

    const now = new Date()
    if (now.getTime() - lastUpdate.getTime() > fresh_ms) {
      console.log('Expiring track markers', markers)
      markers.forEach(marker => {
        marker.setIcon(L.divIcon({
          className: 'dummy',
          html: ReactDOMServer.renderToString(<StyledDroneIcon old={true} />),
          iconSize: [120, 120],
        }))
      })
      setLastUpdate(null)
    }
  }, fresh_ms)

  return null
}

const droneStyles = theme => ({
  iconContainer: ({ yaw }) => ({
    position: 'absolute',
    width: '120px',
    height: '120px',
    transform: `rotate(${yaw}deg)`,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  }),
  drone: ({ old }) => ({
    position: 'absolute',
    fill:     theme.palette.common.white,
    stroke:   old ? '#E0E0E0' : theme.palette.common.black,
  }),
  arrow: ({ old }) => ({
    position: 'absolute',
    fill:     old ? '#E0E0E0' : theme.palette.primary.light,
    stroke:   old ? '#E0E0E0' : theme.palette.primary.main,
  })
})

function DroneIcon({ classes, yaw, old }) {
  return (
    <div className={classes.iconContainer}>
      <svg className={classes.drone} width='100%' height='100%' viewBox='0 0 600 600'>
        <path d={droneCategoryToSVG('Quadcopter')}
          strokeWidth='10px'
        />
      </svg>
      <svg className={classes.arrow} width='75%' height='75%' viewBox='0 0 600 600'>
          <path d={arrowCategorytoSVG('Quadcopter')}
            strokeWidth='12px'
            strokeLinejoin='round'
          />
      </svg>
    </div>
  )
}
const StyledDroneIcon = withStyles(droneStyles)(DroneIcon)

export default CfmsTrackMarkers
