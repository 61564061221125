import { signalToQuality } from '../../utils'
import TopXCloseButton from '../../components/TopXCloseButton'
import SignalBar from './SignalBar'
import {
  Button,
  Dialog,
  DialogContent,
  Grid,
  Typography,
} from '@material-ui/core'
import {
  WifiOff,
} from '@material-ui/icons'
import { withStyles } from '@material-ui/core/styles'

import wcdmaLogo from '../../static/images/network-3g-logo.jpg'
import lteLogo   from '../../static/images/network-4g-lte-logo.png'
import nrnsaLogo from '../../static/images/network-5g-nsa-logo.png'
import nrsaLogo  from '../../static/images/network-5g-sa-logo.png' 
const imageSrc = {
  'WCDMA':    wcdmaLogo,
  'LTE':      lteLogo,
  'NR5G-NSA': nrnsaLogo,
  'NR5G-SA':  nrsaLogo,
}
const styles = theme => ({
  img: {
    width:   theme.spacing(16),
    height:  theme.spacing(16),
    border:  '1px solid white',
  }
})

function CeranaNetworkDialog({ classes, open, onClose, network }) {
  const quality = signalToQuality(network)
  return (
    <Dialog open={open} onClose={onClose} maxWidth='sm' fullWidth>
      <DialogContent>
        <TopXCloseButton onClick={onClose} />
        <Grid container spacing={2}>
          <Grid item xs={3}>
            { network.type ?
              <img src={imageSrc[network.type]} alt={network.type} className={classes.img} />
            :
              <WifiOff className={classes.img} />
            }
          </Grid>
          <Grid item xs={6}>
            <Typography variant='h4'>{network.name || '(No Network)'}</Typography>
            <Typography variant='body1'>IP Address: {network.ip_address || '(No IP Address)'}</Typography>
            { network.type ?
              <Button size='small' variant='contained' color='primary'>{network.type}</Button>
            :
              <Button size='small' variant='outlined'>Unknown Type</Button>
            } { network.status === 'connected' ? <>
              <Button size='small' variant='contained' color='primary'>Active</Button>
              <Button size='small' variant='contained' color='primary'>Connected</Button>
            </> : network.status === 'active' ?
              <Button size='small' variant='contained' color='primary'>Active</Button>
            : network.status === 'inactive' ?
              <Button size='small' variant='outlined' color='secondary'>Inactive</Button>
            :
              <Button size='small' variant='outlined'>Invalid Status: "{network.status}"</Button>
            }
            {/* TODO: The rest of the modem commands goes here, if any */}
          </Grid>
          <Grid item xs={3}>
            <SignalBar
              signalStrength={quality}
              tooltipText={quality || 'No Signal'}
            />
          </Grid>
          <Grid item xs={4}>
            <Typography variant='h6'><u>Modem</u></Typography>
            <Typography variant='body2'><b>IMEI</b><br />{network.imei || 'No IMEI'}</Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography variant='h6'>Network</Typography>
            <Typography variant='body2'><b>IMSI</b><br />{
              network.imsi === 'SIM_ABSENT' ? 'No SIM Card detected' :
              network.imsi || 'No IMSI'}</Typography>
            <br />
            <Typography variant='body2'><b>Cell ID</b><br />{network.cell_id || 'No Cell ID'}</Typography>
            <br />
            { network.signal_rssi &&
              <Typography variant='body2'>RSSI : {network.signal_rssi} dB</Typography>
            }
            { network.signal_sinr &&
              <Typography variant='body2'>SINR : {network.signal_sinr} dB</Typography>
            }
            { network.signal_rsrq &&
              <Typography variant='body2'>RSRQ : {network.signal_rsrq} dB</Typography>
            }
            { network.signal_rscp &&
              <Typography variant='body2'>RSCP : {network.signal_rscp} dB</Typography>
            }
          </Grid>
          <Grid item xs={4}>
            <Typography variant='h6'>Performance</Typography>
            <CeranaNetworkPerformance performance={network.performance} />
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  )
}

// TODO: Move this to query Flight Status API instead
function CeranaNetworkPerformance({ performance }) {
  const perf = performance || {}
  return (
    <Grid container>
      <Grid item xs={6}>Server Name</Grid>
      <Grid item xs={6}>{perf.name || '-'}</Grid>
      <Grid item xs={6}>Server Addr</Grid>
      <Grid item xs={6}>{perf.address || '-'}</Grid>
      <Grid item xs={6}>Uplink</Grid>
      <Grid item xs={6}>{perf.ul || '-'}</Grid>
      <Grid item xs={6}>Downlink</Grid>
      <Grid item xs={6}>{perf.dl || '-'}</Grid>
      <Grid item xs={6}>Latency</Grid>
      <Grid item xs={6}>{perf.latency || '-'}</Grid>
      <Grid item xs={6}>Update Time</Grid>
      <Grid item xs={6}>{perf.update_time || '-'}</Grid>
    </Grid>
  )
}

export default withStyles(styles)(CeranaNetworkDialog)
