import { useState } from 'react'
import { connect } from 'react-redux'
import { useGetAllFlightStatusQuery } from '../api/liveflights'
import { useGetDroneByDroneIdQuery } from '../api/aircraft'

import Header from '../components/Header'
import AircraftDialog from './Components/AircraftDialog'
import CeranaHealthTab from './Components/CeranaHealthTab'
import CeranaNetworkDialog from './Components/CeranaNetworkDialog'
import CeranaVideoDialog from './Components/CeranaVideoDialog'
import {
  AppBar,
  CssBaseline,
  IconButton,
  Tab,
  Tabs,
  Typography,
} from '@material-ui/core'
import {
  Info,
} from '@material-ui/icons'
import { withStyles } from '@material-ui/core/styles'

const styles = theme => ({
  root: {
    pading: theme.spacing(2),
    maxWidth: theme.breakpoints.values.lg,
    margin: '0 auto',
  },
  titleContainer: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    display: 'flex',
    justifyContent: 'space-between'
  },
  title: {
    lineHeight: 1,
    padding: theme.spacing(1, 3),
  },
  appbar: {
    width: 'calc(100% - 260px)',
    flexGrow: 5,
  },
  tab: {
    fontSize: '1.5rem',
  },
  info: {
    width: theme.spacing(10),
  },
})

function CeranaHealth({
  classes,
  mission,
  linkStatus,
  telemetry,
  autonomyLevel,

  // TODO - eliminate or keep
  // networkSpeed,
}) {
  // const droneId = telemetry?.drone_id || ''

  const droneId = mission?.droneId || ''
  const [ tab,         setTab         ] = useState(0)
  const [ showDrone,   setShowDrone   ] = useState(false)
  const [ showNetwork, setShowNetwork ] = useState(null)
  const [ showVideo,   setShowVideo   ] = useState(null)

  const { data: allFlightStatusData } = useGetAllFlightStatusQuery(droneId, true)

  const allFlights = allFlightStatusData?.data?.flights

  const { data: aircraftData } = useGetDroneByDroneIdQuery(droneId, {
    skip: !droneId
  })

  const aircraft = aircraftData?.data?.aircraft
  console.log('droneId, allFlights, aircraft', droneId, allFlights, aircraft)

  // How to find out which is the flight? if only a single drone id and not ENDED,
  // expect and deal with only one. TODO: Just make multiple Tabs for multiple status
  const activeFlight = allFlights?.length === 1 ? allFlights[0] : {}

  return (
    <>
      <CssBaseline />
      <Header />
      { showDrone &&
        <AircraftDialog
          open={showDrone}
          onClose={() => setShowDrone(false)}
          mission={mission}
        />
      }
      { showNetwork &&
        <CeranaNetworkDialog
          open={!!showNetwork}
          onClose={() => setShowNetwork(null)}
          network={showNetwork}
        />
      }
      { showVideo &&
        <CeranaVideoDialog
          open={!!showVideo}
          onClose={() => setShowVideo(null)}
          video={showVideo}
        />
      }
      <div className={classes.root}>
        <div className={classes.titleContainer}>
          <div className={classes.title}>
            <Typography variant='h6'>Cerana Health</Typography>
          </div>
          <AppBar position='relative' color='primary' className={classes.appbar}>
            <Tabs variant='scrollable' scrollButtons='auto'
              value={tab}
              indicatorColor='secondary'
              onChange={(evt, newTab) => setTab(newTab)}>
              <Tab wrapped
                label={mission.droneName || 'No Drone Selected'}
                className={classes.tab}
              />
            </Tabs>
          </AppBar>
          <IconButton color='inherit' className={classes.info} onClick={() => setShowDrone(true)}>
            <Info />
          </IconButton>
        </div>

        <CeranaHealthTab
          droneId={droneId}
          flightStatus={activeFlight}
          linkStatus={linkStatus}
          telemetry={telemetry}
          setShowNetwork={setShowNetwork}
          setShowVideo={setShowVideo}
          autonomyLevel={autonomyLevel}
        />
      </div>
    </>
  )
}


const mapStateToProps = state => ({
  autonomyLevel: state.daa.autonomyLevel,
  linkStatus:    state.telemetry.linkStatus,
  mission:       state.mission.mission,
  // networkSpeed:  state.telemetry.telemetry.networkSpeed || state.telemetry.networkSpeed,
  telemetry:     state.telemetry.telemetry,
})

export default connect(mapStateToProps, null)(withStyles(styles)(CeranaHealth))
