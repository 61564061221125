import { bindActionCreators } from 'redux'
import { connect }            from 'react-redux'
import { useInControl }       from '../../utils/useFlightStates'
import { landDrone }          from '../../modules/actions/commandActions'
import { Button }             from '@material-ui/core'
import { withStyles }         from '@material-ui/core/styles'
import { ReactComponent as LandIcon } from '../../static/images/icon_droneLand.svg'

const styles = theme => ({
  button: {
    width:  theme.spacing(24),
    height: theme.spacing(7),
  },
  svg: {
    height: theme.spacing(4),
  },
})

function LandButton({ classes, connected, isLocked, isFlying, isLanding, landDrone }) {
  const inControl = useInControl()
  const canLand = connected && inControl && isFlying && !isLanding

  return (
    <Button
      color='primary'
      disabled={!canLand}
      variant='contained'
      className={classes.button}
      startIcon={<LandIcon className={classes.svg} />}
      onClick={landDrone}>
      Land Here
    </Button>
  )
}

const mapStateToProps = state => ({
  connected: state.telemetry?.linkStatus === null,
  isLocked:  state.command.isLocked,
  isFlying:  state.command.isFlying,
  isLanding: state.command.isLanding,
})

const mapDispatchToProps = dispatch => {
  return bindActionCreators({ landDrone }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(LandButton))
