import { connect } from 'react-redux'

import Header                from '../components/Header'
import LiveTelemetryListener from '../telemetry/LiveTelemetryListener'
import TelemetryHealth       from './Components/TelemetryHealth'

import {
  CssBaseline,
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'

const styles = theme => ({
  header: {
    height: theme.spacing(6),
  },
})

function Developer({ classes, mission, user, deployment }) {
  return (
    <LiveTelemetryListener>
      <CssBaseline />
      <Header className={classes.header} />
      <TelemetryHealth />
    </LiveTelemetryListener>
  )
}

function mapStateToProps(state) {
  return {
    mission: state.mission.mission,
    user: state.oidc.user,
    deployment: state.deployment
  }
}
export default connect(mapStateToProps)(withStyles(styles)(Developer))
