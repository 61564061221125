import React               from 'react'
import { Route, Redirect } from 'react-router-dom'
import { connect }         from 'react-redux'

const AuthenticatedRoute = ({ component: Component, pathname, isLoading, user, ...rest }) => {
  if (isLoading)
    return null

  const authComponent = props => (
    user !== null ?
      <Component {...props} />
    :
      <Redirect to={`/login?orig_url=${pathname.substring(1)}`} />
  )
  return <Route {...rest} render={ authComponent } />
}

const mapStateToProps = state => {
  return {
    user:      state.oidc.user,
    isLoading: state.oidc.isLoadingUser,
    pathname:  state.router.location.pathname,
  }
}

export default connect(mapStateToProps, null)(AuthenticatedRoute)
