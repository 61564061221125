import { bindActionCreators } from 'redux'
import { connect }            from 'react-redux'
import { landDrone }          from '../../modules/actions/commandActions'
import { Button }             from '@material-ui/core'
import { withStyles }         from '@material-ui/core/styles'
import { ReactComponent as LandIcon } from '../../static/images/icon_droneLand.svg'

const styles = theme => ({
  button: {
    width:  theme.spacing(36),
    height: theme.spacing(7),
  },
  svg: {
    marginRight: theme.spacing(2),
    height:      theme.spacing(5),
  },
})

function BigLandButton({ classes, connected, inControl, isLocked, isFlying, isLanding, landDrone }) {
  const canLand = connected && inControl && !isLocked && isFlying && !isLanding

  return (
    <Button
      color='primary'
      disabled={!canLand}
      variant='contained'
      className={classes.button}
      startIcon={<LandIcon className={classes.svg} />}
      onClick={landDrone}>
      Land Right Here
    </Button>
  )
}

const mapStateToProps = state => ({
  connected: state.telemetry?.linkStatus === null,
  isLocked:  state.command.isLocked,
  isFlying:  state.command.isFlying,
  isLanding: state.command.isLanding,
  inControl: state.command.inControl,
})

const mapDispatchToProps = dispatch => {
  return bindActionCreators({ landDrone }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(BigLandButton))
