
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@material-ui/core'
import {
  Check,
  CheckBoxOutlined,
} from '@material-ui/icons'
import { withStyles } from '@material-ui/core/styles'

import oneSkyLogo from '../../static/images/onesky.png'
const styles = theme => ({
  onesky: {
    width: '100px',
    float: 'left',
    borderRadius: theme.spacing(1),
    marginTop: theme.spacing(0.5),
    marginRight: theme.spacing(2),
  },
})

function PreFlightCheck({ classes, open, onClose, onProceed }) {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth='md'>
      <DialogTitle>Pre-Flight Checklist</DialogTitle>
      <DialogContent dividers>
        <Grid container>
          <Grid item xs={6}>
            <Typography variant='h6'>Default</Typography>
            <List>
              <ListItem>
                <ListItemIcon><Check /></ListItemIcon>
                <ListItemText primary='Drone is Online' />
              </ListItem>
              <ListItem>
                <ListItemIcon><Check /></ListItemIcon>
                <ListItemText primary='Battery Full (>25V)' />
              </ListItem>
              <ListItem>
                <ListItemIcon><CheckBoxOutlined /></ListItemIcon>
                <ListItemText primary='[SG] Obtain RSAF Approval'
                  secondary='You will need to call RSAF for manual approval if (1) you are not carrying the CFMS tracker, or (2) your instance of Plex Horizon is not connected to CFMS.' />
              </ListItem>
            </List>
          </Grid>
          <Grid item xs={6}>
            <Typography variant='h6'>User Defined</Typography>
            <List>
              <ListItem>
                <ListItemIcon><CheckBoxOutlined /></ListItemIcon>
                <ListItemText primary='Airframe Check'
                  secondary='Perform a visual check of the UA for visible damages.' />
              </ListItem>
            </List>
            <Typography variant='body2'>
              Edit your user defined checklist under Settings.
            </Typography>
          </Grid>
        </Grid>
        <Typography variant='body2'>
          <img src={oneSkyLogo} alt='onesky' className={classes.onesky} />
          By clicking PROCEED, Plex Horizon will submit your flight plan to the connected UTM.
          Unless authorised by your airspace authority, authorisation by the UTM is
          insufficient. You are solely liable of the flight that is about to happen.
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button
          variant='outlined'
          onClick={onClose}>
          Cancel
        </Button>
        <Button
          color='primary'
          variant='contained'
          onClick={onProceed}>
          Proceed
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default withStyles(styles)(PreFlightCheck)