import {
  Snackbar
} from '@material-ui/core'
import { Alert } from '@material-ui/lab'

function PayloadSnackbar({
  message,
  severity,
  onClose,
}) {
  return (
    <Snackbar
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      open={message !== ''}
      autoHideDuration={1500}
      onClose={onClose}>
      <Alert severity={severity || 'info'} variant='filled'>{message}</Alert>
    </Snackbar>
  )
}

export default PayloadSnackbar
