import { useEffect } from 'react'
import { useMap, Polyline } from 'react-leaflet'
import L from 'leaflet'
import * as turf from '@turf/turf'

import JsxMarker from '../../components/JsxMarker'
import { droneCategoryToSVG, arrowCategorytoSVG } from '../../static/constants/droneSVG'
import { withStyles } from '@material-ui/core/styles'

const guidelineOptions = {
  color: 'black',
  weight: 1,
  opacity: 0.8,
}
const styles = theme => ({
  icon: {
    display:        'flex',
    alignItems:     'center',
    justifyContent: 'center',
  },
  iconContainer: {
    display:        'flex',
    justifyContent: 'center',
    alignItems:     'center',
    position:       'absolute',
    width:          '120px',
    height:         '120px',
    transform: ({ yaw }) => {
      return `rotate(${yaw}deg)`
    },
  },
  drone: {
    position: 'absolute',
    fill:   ({ severity }) => theme.palette.severity[severity] || theme.palette.severity[0],
    stroke: ({ severity }) => theme.palette.common.black,
  },
  arrow: {
    position: 'absolute',
    fill:   ({ severity }) => theme.palette.common.black,
    stroke: ({ severity }) => theme.palette.common.black,
  }
})

function DroneMarker({ classes, lat, lng, yaw, fixateOnDrone, showGuideLines, ...rest }) {
  const map = useMap()
  // const [ guideline, setGuideline ] = useState(null)

  useEffect(() => {
    if (fixateOnDrone)
      map.flyTo(new L.LatLng(lat, lng))
  }, [map, lat, lng, fixateOnDrone])

  // useEffect(() => {
  //   if (guideline) {
  //     guideline.remove(map)
  //     setGuideline(null)
  //   }
  //   if (showGuideLines && lat && lng) {
  //     const endCoord = turf.destination(
  //       turf.point([ lng, lat ]), 0.5, yaw, { units: 'kilometers' }
  //     ).geometry.coordinates
  //     const latlngs = [[ lat, lng ], [ endCoord[1], endCoord[0] ]]
  //     const polyline = L.polyline(latlngs, guidelineOptions).addTo(map)
  //     console.log('latlngs', latlngs)
  //     setGuideline(polyline)
  //   }
  // }, [map, lat, lng, yaw, guideline, showGuideLines])

  if (!lat || !lng)
    return null

  let endCoord
  try {
    endCoord = turf.destination(turf.point([ lng, lat ]), 0.5, yaw, {
      units: 'kilometers'
    }).geometry.coordinates
  } catch {
    return null
  }

  return (<>
    <JsxMarker iconClassName={classes.icon} position={{ lat, lng }} zIndexOffset={1000} {...rest}>
      <div className={classes.iconContainer}>
        <svg className={classes.drone} width='100%' height='100%' viewBox='0 0 600 600'>
          <path d={droneCategoryToSVG('Quadcopter')}
            strokeWidth='10px'
          />
        </svg>
        <svg className={classes.arrow} width='75%' height='75%' viewBox='0 0 600 600'>
            <path d={arrowCategorytoSVG('Quadcopter')}
              strokeWidth='12px'
              strokeLinejoin='round'
            />
        </svg>
      </div>
    </JsxMarker>
    <Polyline
      positions={[[ lat, lng ], [ endCoord[1], endCoord[0] ]]}
      pathOptions={guidelineOptions}
    />
  </>)
}

export default withStyles(styles)(DroneMarker)
