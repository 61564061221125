import { useState } from 'react'
// import { connect } from 'react-redux'

// import BoundingBoxDisplay from '../../daa/BoundingBoxDisplay'
import { getDecodedToken } from '../../api/accounts'
import VideoControls from './VideoControls'
import LocalVideo    from './LocalVideo'
import WebRTCStream  from './WebRTCStream'
import { withStyles } from '@material-ui/core/styles'

const styles = theme => ({
  videoOverlay: {
    position: 'relative',
    width: '100%',
    backgroundColor: 'blue',
  },
  videoControls: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
    alignItems: 'center',
    position: 'absolute',
    color: theme.palette.common.white,
    zIndex: 2,
  },
  videoContainer: {
    backgroundColor: '#000',
    height:          '75vh',
    width:           'calc(75vh * 16 / 9)',
    display:         'flex',
    alignItems:      'center',
    justifyContent:  'center',
  },
  downwardVideoContainer: {
    backgroundColor: '#000',
    height:          `calc(100vh - ${theme.spacing(6)}px)`,
    width:           '100%',
    display:         'flex',
    alignItems:      'center',
    justifyContent:  'center',
    overflow:        'hidden',
  },
  payloadVideoContainer: {
    backgroundColor: '#000',
    height:          `calc(100vh - ${theme.spacing(6)}px)`,
    width:           '100%',
    display:         'flex',
    alignItems:      'center',
    justifyContent:  'center',
    overflow:        'hidden',
  },
  fullscreen: {
    backgroundColor: '#000',
    height:          '100vh',
    width:           '100%',
    display:         'flex',
    alignItems:      'center',
    justifyContent:  'center',
    overflow:        'hidden',
  },
  '4x4': {
    backgroundColor: '#000',
    height:          '45vh',
    width:           '50vw',
    display:         'flex',
    alignItems:      'center',
    justifyContent:  'center',
    overflow:        'hidden',
  },
  'fullscreen4x4': {
    backgroundColor: '#000',
    height:          '50vh',
    width:           '50vw',
    display:         'flex',
    alignItems:      'center',
    justifyContent:  'center',
    overflow:        'hidden',
  },
})

function SingleVideo({
  classes,
  videoId,
  streamName,
  stylingClass,
  onFocus,
  // obstacleDetection,
}) {
  const [ _streamName, setStreamName  ] = useState('')
  const [ anchorEl,    setAnchorEl    ] = useState(null)
  const [ videoWidth,  setVideoWidth  ] = useState(-1)
  const [ videoHeight, setVideoHeight ] = useState(-1)
  const [ localVideo,  setLocalVideo  ] = useState('')

  const [ videoNodeWidth,  setVideoNodeWidth  ] = useState(0)
  const [ videoNodeHeight, setVideoNodeHeight ] = useState(0)

  useState(() => {
    if (streamName)
      setStreamName(streamName)
  }, [streamName])

  // These are read out values from the browser video element (ie actual pixels)
  function handleUpdate(videoNode) {
    setVideoWidth(videoNode.videoWidth)
    setVideoHeight(videoNode.videoHeight)
    setVideoNodeWidth(videoNode.clientWidth)
    setVideoNodeHeight(videoNode.clientHeight)
  }
  function handleLoadedLocalVideo(e) {
    handleUpdate(e.target)
  }

  function handleOverlay(e) {
    // TODO to get CTRL to bubble up to this level (probably treat as right click below)
    if (onFocus && videoWidth && videoHeight) {
      e.preventDefault()
      onFocus && onFocus({
        x:     e.clientX,
        y:     e.clientY,
        shift: e.shiftKey,
        ctrl:  e.ctrlKey,
        alt:   e.altKey,
        meta:  e.metaKey,
        videoWidth,
        videoHeight,
        videoNodeWidth,
        videoNodeHeight,
      })
    }
  }

  const { drone_id, company_id } = getDecodedToken()
  const appName = _streamName.substr(0, 6) === 'sample' ? 'SkyStream/vod' : `SkyStream/${drone_id || 'invalid-drone-id'}`

  return (
    <>
      <VideoControls
        streamName={_streamName}
        setStreamName={setStreamName}
        setLocalVideo={setLocalVideo}
        anchorEl={anchorEl}
        setAnchorEl={setAnchorEl}
        videoWidth={videoWidth}
        videoHeight={videoHeight}
      />
      <div className={classes.videoOverlay} onClick={handleOverlay}>
        <div className={classes[stylingClass]}>
          {/* obstacleDetection &&
            <BoundingBoxDisplay />
          */}
          { localVideo ?
            <LocalVideo
              videoId={videoId}
              localVideo={localVideo}
              onLoadedData={handleLoadedLocalVideo}
            />
          : <WebRTCStream
              videoId={videoId}
              applicationName={appName}
              streamName={_streamName}
              companyId={company_id}
              onUpdate={handleUpdate}
            />
          }
        </div>
      </div>
    </>
  )
}

// const mapStateToProps = state => ({
//   obstacleDetection: state.daa.obstacleDetection
// })
// export default connect(mapStateToProps, null)(withStyles(styles)(SingleVideo))
export default withStyles(styles)(SingleVideo)
