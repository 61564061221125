import { useEffect, useState } from 'react'
import moment from 'moment' // TODO: DEPRECATE and migrate to date-fns
import CommonLayers  from '../components/Map/CommonLayers'
import { getPrecipitationImageUrl } from './components/getWeatherUrl'

import {
  ImageOverlay,
  MapContainer,
  ZoomControl,
  ScaleControl,
} from 'react-leaflet'
import { Typography } from '@material-ui/core'
import { withStyles } from '@material-ui/styles'

import {
  DEFAULT_MAP_CENTER
} from '../utils/site-maps'

const styles = theme => ({
  titleContainer: {
    zIndex:   '1000',
    width:    '100%',
    position: 'absolute',
  },
  title: {
    margin:       theme.spacing(1) + 'px auto',
    textAlign:    'center',
    lineHeight:   1.5,
    width:        theme.spacing(14),
    height:       theme.spacing(2),
    padding:      theme.spacing(1),
    borderRadius: theme.spacing(1),
    border:       '1px solid ' + theme.palette.bluegrey.main,
    color:        theme.palette.bluegrey.main,
    backgroundColor: theme.palette.map.offWhite,
  },
  timeLabel: {
    zIndex:   '1000',
    position: 'absolute',
    bottom:   theme.spacing(1),
    left:     theme.spacing(1),
    padding:  theme.spacing(1),
    margin:   theme.spacing(1),
    backgroundColor: theme.palette.map.offWhite,
  },
  rainScale: {
    zIndex:   '1000',
    position: 'absolute',
    width:    '50%',
    bottom:   theme.spacing(1),
    right:    theme.spacing(1),
    padding:  theme.spacing(1),
    backgroundColor: theme.palette.map.offWhite,
  }
})

function WeatherMap({ classes, zoom = 11, center, ...props }) {

  // TODO: Refine the gov weather boundaries
  const weatherGovBounds = [[1.480450, 103.546844], [1.147017, 104.142032]]

  const [ shownIndex, setShownIndex ] = useState(0)
  const [ timings,    setTimings    ] = useState([])

  function makeTimings() {
    const now = moment()
    const endTime = now.minute(5 * Math.floor(now.minute() / 5)).seconds(0).milliseconds(0)
    const startTime = endTime.add(-2, 'hours')
    const currentTime = startTime
    const timings = []
    for (let i = 0; i < 120 / 5; i++) {
      timings.push(currentTime.unix() * 1000)
      currentTime.add(5, 'minutes')
    }
    return timings
  }

  useEffect(() => {
    setTimings(makeTimings())
  }, [])

  useEffect(() => {
    if (timings.length > 0) {
      let loopInterval = setTimeout(function () {
        const newIndex = shownIndex + 1
        if (newIndex === timings.length) {
          setTimings(makeTimings())
          setShownIndex(0)
        } else {
          setShownIndex(newIndex)
        }
      }, 1000)
      return () => clearTimeout(loopInterval)
    }
  }, [timings, shownIndex])

  return (
    <>
      <div className={classes.titleContainer}>
        <Typography variant='overline' component='div' className={classes.title}>
          Meteorology
        </Typography>
      </div>
      <Typography variant='body2' className={classes.timeLabel}>
        Time: {moment(timings[shownIndex]).format('hh:mm:ss A')}
      </Typography>
      <img alt='rain'
        className={classes.rainScale}
        src='https://www.weather.gov.sg/wp-content/themes/wiptheme/images/rain-intensity.jpg'
      />

      <MapContainer
        center={center || DEFAULT_MAP_CENTER}
        zoom={zoom}
        zoomControl={false}
        maxZoom={22}
        {...props}>
        <CommonLayers hideControls />
        <ZoomControl position='topright' />
        <ScaleControl position='topleft' imperial={false} />
        <ImageOverlay
          url={getPrecipitationImageUrl(timings[shownIndex])}
          bounds={weatherGovBounds}
          opacity={0.8}
        />
      </MapContainer>
    </>
  )
}

export default withStyles(styles)(WeatherMap)
