import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import winchUpImg from '../../static/images/winch-up.png'
import { ChangeWinchState } from '../../modules/actions/payloadActions'
import {
  Avatar,
  Button,
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'

const styles = theme => ({
  button: {
    width: theme.spacing(16),
    margin: theme.spacing(1)
  }
})

function WinchUpButton(props) {

  const { ChangeWinchState, isLocked, isFlying, isMoving, classes } = props

  // Disable button if either not flying or moving
  const canPress = !isMoving && isFlying && !isLocked

  function onClick() {
    if (canPress)
      ChangeWinchState({ winch: 'UP'})
    else
      alert('Set the drone to hover in place before winching')
  }

  return (
    <Button
      color='primary'
      variant='contained'
      className={classes.button}
      endIcon={<Avatar variant='square' src={winchUpImg} />}
      onClick={onClick}>
      Winch Up
    </Button>
  )
}

const mapStateToProps = state => {
  return {
    isMoving: state.command.isMoving,
    isFlying: state.command.isFlying,
    isLocked: state.command.isLocked,
  }
}

const mapDispatchToProps = dispatch => {
  return bindActionCreators({
    ChangeWinchState,
  }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(WinchUpButton))
