import store     from '../modules/store'
import jwtDecode from 'jwt-decode'
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

const ACCOUNTS_API = process.env.REACT_APP_ACCOUNTS_API
if (!ACCOUNTS_API) throw new Error('Environment Variable REACT_APP_ACCOUNTS_API is required.')

export function getAccessToken() {
  return store.getState().oidc.user.access_token
}

export function getDecodedToken() {
  const state = store.getState()
  const access_token = state.oidc.user.access_token
  const decoded = jwtDecode(access_token)
  const {
    plex: { company_id, company_status }
  } = decoded
  return {
    ...decoded,
    access_token,
    company_id,
    company_status,
    user_id:  decoded.sub,
    // TODO add user role here
    drone_id: state.mission.mission ? state.mission.mission?.droneId : null,
  }
}

export const accountsApi = createApi({
  reducerPath: 'accountsApi',
  baseQuery: fetchBaseQuery({
    baseUrl: ACCOUNTS_API,
    prepareHeaders: (headers, { getState }) => {
      const user = getState().oidc?.user
      if (user)
        headers.set('Authorization', `Bearer ${user.access_token}`)
      headers.set('Content-Type', 'application/json')
      headers.set('Accept', 'application/json')
      return headers
    },
  }),
  tagTypes: ['Users', 'Subscriptions'],
  endpoints: (builder) => ({
    // App Roles
    // Users
    getUserById: builder.query({
      query: (userId) => `users/${userId}`,
    }),
    getUsersByCompanyId: builder.query({
      query: (companyId) => `users?company_id=${companyId}`,
      providesTags: ['Users'],
    }),
    // Companies
    getCompanyByCompanyId: builder.query({
      query: (companyId) => `companies/${companyId}`,
    }),
    // Subscriptions
    getSubscriptionsByCompanyId: builder.query({
      query: (companyId) => `subscriptions?company_id=${companyId}`,
      providesTags: ['Subscriptions']
    })
  }),
})

export const {
  useGetUserByIdQuery,
  useGetUsersByCompanyIdQuery,
  useGetCompanyByCompanyIdQuery,
  useGetSubscriptionsByCompanyIdQuery,
} = accountsApi
