import { useState } from 'react'
import Header from '../components/Header'
import MissionSetupDialog from './Components/MissionSetupDialog'
import {
  Button,
  CssBaseline,
  Typography,
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import RequestControl from './Components/RequestControl'

const styles = theme => ({
  root: {
    height: `calc(100vh - ${theme.spacing(6)}px)`,
    padding: theme.spacing(2),
    backgroundColor: theme.palette.bluegrey.dark,
  }
})

function Planner({ classes }) {

  const [ isSetup, setIsSetup ] = useState(false)

  return <>
    <CssBaseline />
    <Header />
    <div className={classes.root}>
      <Typography variant='h5'>Mission Planner</Typography>
      <Button variant='contained' color='primary' onClick={() => { setIsSetup(true) }}>
        Setup Mission
      </Button>
      <RequestControl />
    </div>
    { isSetup &&
      <MissionSetupDialog
        open={isSetup}
        onClose={() => { setIsSetup(false) }}
      />
    }
    
  </>
}

export default withStyles(styles)(Planner)