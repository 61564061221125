/**
 * This file contains constants and helper functions to calculate the category level of detected obstacles.
 * Category level consist of strings "RED", "YELLOW", "GREEN" to be used as an enum-type
 * Functions that call determineDanger() should pass distance in mm and airSpeed in m/s, and create a mapping of string to required value.
 * (see levelToStroke and levelToFillStyle in BoundingBoxDisplay for examples)
 */
const GREEN_LEVEL = 2
const YELLOW_LEVEL = 1
const RED_LEVEL = 0

const THRESHOLD_L = 10
const THRESHOLD_M = 3

const MIN_SPEED = 0.5
export const GREEN = "GREEN"
export const YELLOW = "YELLOW"
export const RED = "RED"
export const LEVELS = [RED, YELLOW, GREEN]

// const getAverageSpeed = (lastAverage,lastSpeed) => {
//     return avg = (lastAverage + lastSpeed)/2
// }

/**
 * Determines the "danger" category of an obstacle given the obstacle's distance and speed of drone
 * @param {number} distance - Distance of obstacle in millimeters
 * @param {number} airSpeed - Speed of drone in meters/second (can be ground speed, use whichever seems more accurate)
 */
export const determineDanger = (distance, airSpeed) => {
    if (airSpeed < MIN_SPEED) {
        const level = distance > 2500 ? GREEN_LEVEL : distance > 1500 ? YELLOW_LEVEL : RED_LEVEL
        return LEVELS[level]
    } else {
        const time = distance / 1000 / airSpeed
        const level = time > THRESHOLD_L ? GREEN_LEVEL : time > THRESHOLD_M ? YELLOW_LEVEL : RED_LEVEL
        return LEVELS[level]
    }
}
