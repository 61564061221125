import { connect } from 'react-redux'
import WarningsStoreConnector from './Components/WarningsStoreConnector'
import WarningListItem        from './Components/WarningListItem'

import Header       from '../components/Header'
import {
  CssBaseline,
  List,
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'

const styles = theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    height: `calc(100% - ${theme.spacing(6)})`,
    overflowY: 'scroll',
  },
  list: {
    padding: theme.spacing(2)
  }
})

function CeranaWarnings({ classes, activeWarnings, previousWarnings }) {
  return (
    <WarningsStoreConnector>
      <CssBaseline />
      <Header />
      <div className={classes.root}>
        <List className={classes.list}>
          { activeWarnings.length === 0 && previousWarnings.length === 0 &&
            <WarningListItem
              warningMessage='No Warning Messages'
              severity={0}
              startingTime={Date.now()}
              latestTime={Date.now()}
            />
          }
          { activeWarnings.length > 0 && <div>Active Warnings</div> }
          { activeWarnings.map(({ details, severity, startingTime, latestTime, count }, i) =>
            <WarningListItem
              key={'active' + i}
              warningMessage={details}
              severity={severity}
              startingTime={startingTime}
              latestTime={latestTime}
              count={count}
            />
          )}
          { previousWarnings.length > 0 && <div>Previous Warnings</div> }
          { previousWarnings.map(({ details, severity, startingTime, latestTime }, i) =>
            <WarningListItem
              key={i}
              warningMessage={details}
              severity={severity}
              startingTime={startingTime}
              latestTime={latestTime}
            />
          )}
        </List>
      </div>
    </WarningsStoreConnector>
  )
}


const mapStateToProps = state => {
  const { activeWarnings: activeByKey, previousWarnings } = state.warnings
  const activeWarnings = Array.from(Object.keys(activeByKey)).map(key => activeByKey[key])
  activeWarnings.sort((a, b) => {
    return b.startingTimestamp - a.startingTimestamp
  })
  return { activeWarnings, previousWarnings }
}

export default connect(mapStateToProps)(withStyles(styles)(CeranaWarnings))
