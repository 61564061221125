import NewCommanderScreen from './NewCommanderScreen'
import MissionContext from '../mission/MissionContext'

const CommanderScreenContainer = () => {
  return <MissionContext.Consumer>
    {({ updateMission,
      // triggerAlert
    }) => {
      return <NewCommanderScreen
        updateMission={updateMission}
      />
    }}
  </MissionContext.Consumer>
}
export default CommanderScreenContainer