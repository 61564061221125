import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import winchRetractImg from '../../static/images/winch-retract.png'
import { ChangeReleaseState } from '../../modules/actions/payloadActions'
import { withStyles } from '@material-ui/core/styles'
import {
  Avatar,
  Button,
} from '@material-ui/core'

const styles = theme => ({
  button: {
    width: theme.spacing(16),
    margin: theme.spacing(1)
  }
})

function ReleaseServoButton(props) {

  const { ChangeReleaseState, isLocked, isFlying, isMoving, classes } = props

  // Disable button if either not flying or moving
  const canPress = !isMoving && isFlying && !isLocked

  function onClick() {
    if (canPress)
      ChangeReleaseState({ winch: 'OPEN'})
    else
      alert('Set the drone to hover in place before releasing')
  }

  return (
    <Button
      color='primary'
      variant='contained'
      className={classes.button}
      endIcon={<Avatar variant='square' src={winchRetractImg} />}
      onClick={onClick}>
      Retract Winch
    </Button>
  )
}

const mapStateToProps = state => {
  return {
    isMoving: state.command.isMoving,
    isFlying: state.command.isFlying,
    isLocked: state.command.isLocked,
  }
}

const mapDispatchToProps = dispatch => {
  return bindActionCreators({
    ChangeReleaseState,
  }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ReleaseServoButton))
