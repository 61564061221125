import React, { Component } from "react"
import PropTypes from "prop-types"
import withStyles from "@material-ui/core/styles/withStyles"
import { connect } from "react-redux"
import { ReactComponent as DroneSvg } from "../static/images/drone.svg"
import { determineDanger } from "./util"

const MAX_RADIUS = 100
const R_OFFSET = Math.PI / 4
const D_SEG = Math.PI / 6
const INIT_RADIUS = 50
const RING_WIDTH = 25
const RING_GAP = 4
// const SCREEN_PIXEL_WIDTH = 1440

const ringToFillMap = ["red", "yellow", "green"]

// Returns string representation of path to draw a 1/12 segment,
/**
 * Gets a string representation of a svg path to draw a segment, using polar coordinations,
 * with each segment being D_SEG radians apart.
 *
 * Polar-coordinates : https://en.wikipedia.org/wiki/Polar_coordinate_system
 *
 * @param {number} radius - radius in pixels
 * @param {number} segNo - segment number from 0 - 11
 */
const getArcCoord = (radius, segNo) => {
    const coord1x = Math.sin(R_OFFSET + segNo * D_SEG) * radius
    const coord1y = Math.cos(R_OFFSET + segNo * D_SEG) * radius
    const coord1 = coord1x + " " + coord1y
    const coord2x = Math.sin(R_OFFSET + (segNo + 1) * D_SEG) * radius
    const coord2y = Math.cos(R_OFFSET + (segNo + 1) * D_SEG) * radius
    const coord2 = coord2x + " " + coord2y
    return `M0 0 ${coord1} A ${radius} ${radius} 0 0 0 ${coord2} Z`
}

/**
 * Gets the colour of the ring based on index
 * @param {number} ringNo - Ring number, with 0 being the innermost ring
 */
const getFill = ringNo => {
    return ringToFillMap[ringNo]
}

const styles = theme => ({
    green: {
        fill: "green",
    },
    yellow: {
        fill: "yellow",
    },
    red: {
        fill: "red",
    },
    clear: {
        opacity: "25%",
    },
})

const levelToRing = {
    GREEN: 2,
    YELLOW: 1,
    RED: 0,
}

// TODO: change into functional component
class DroneRadar extends Component {
    getOpacity = (segIndex, ringNo, preprocess) => {
        return this.props.obstacleDetection && preprocess[segIndex][ringNo] ? "100%" : "25%"
    }
    determineSegment = x => {
        // determine segment from x-coordinate
        // This makes use of hardcoded values to determine threshold
        // TODO: come up with better way hash range
        return x > 684 ? 3 : x > 342 ? 4 : 5
    }
    determineRing = (d, s) => levelToRing[determineDanger(d, s)]
    render() {
        // Creates a 2d boolean array of segments to be lit up
        // Segments are identified by their ringNumber and segmentIndex
        // Preprocess must be calculated everytime objects from the store are updated.
        const preprocess = this.props.obstacles.reduce(
            (acc, curr) => {
                const { x1, w } = curr
                const ring = this.determineRing(curr.distance, this.props.airSpeed)
                const left_bound = this.determineSegment(x1)
                const right_bound = this.determineSegment(x1 + w)
                for (let i = left_bound; i >= right_bound; i--) {
                    acc[i][ring] = true
                }
                return acc
            },
            Array(12)
                .fill(0)
                .map(_ => [false, false, false])
        )

        return (
            <svg
              style={{ padding: '10px', width: '30vh', height: '30vh' }}
                xmlns="http://www.w3.org/2000/svg"
                width="100%"
                height="100%"
                viewBox={`0 0 ${2 * MAX_RADIUS} ${2 * MAX_RADIUS}`}
            >
                {/* Shift origin to center of svg */}
                <g transform={`translate(${MAX_RADIUS}, ${MAX_RADIUS})`}>
                    <defs>
                        {/* Define X-mask to separate radar circle into 4 quadrants */}
                        <mask id="cross" stroke="black" strokeWidth="8">
                            <path d="M0 0-70 70A99 99 0 0 1-70-70Z" fill="#fff" />
                            <path d="M0 0-70-70A99 99 0 0 1 70-70Z" fill="#fff" />
                            <path d="M0 0 70-70A99 99 0 0 1 70 70Z" fill="#fff" />
                            <path d="M0 0 70 70A99 99 0 0 1-70 70Z" fill="#fff" />
                        </mask>
                    </defs>
                    <g mask="url(#cross)">
                        {[...Array(3).keys()].map(ringNo => {
                            // build rings from smallest to largest
                            const targetRadius = INIT_RADIUS + ringNo * RING_WIDTH
                            const maskRadius = targetRadius - RING_WIDTH + RING_GAP
                            const maskId = `mask-${ringNo}`
                            return (
                                <React.Fragment key={maskId}>
                                    <mask id={maskId}>
                                        {/* Define ring mask */}
                                        <circle
                                            cx="0"
                                            cy="0"
                                            r={targetRadius}
                                            fill="white"
                                        ></circle>
                                        <circle cx="0" cy="0" r={maskRadius} fill="black"></circle>
                                    </mask>
                                    <g mask={`url(#${maskId})`}>
                                        {/* Generate individual segments */}
                                        {[...Array(12).keys()].map(i => (
                                            <path
                                                key={`${i}.${ringNo}.${preprocess}`}
                                                d={getArcCoord(targetRadius, i)}
                                                fill={getFill(ringNo)}
                                                opacity={this.getOpacity(i, ringNo, preprocess)}
                                            ></path>
                                        ))}
                                    </g>
                                </React.Fragment>
                            )
                        })}
                    </g>
                    {/* Drone Icon in center of radar */}
                    <g transform="scale(0.13) translate(-100, -100)" fill="white">
                        <DroneSvg />
                    </g>
                </g>
            </svg>
        )
    }
}

DroneRadar.propTypes = {
    obstacles: PropTypes.array.isRequired,
    airSpeed: PropTypes.number.isRequired,
}

// default props does not seem to work (?)
DroneRadar.defaultProps = {
    obstacles: [],
    airSpeed: 1,
}

const mapStateToProps = state => {
    const { daa, telemetry } = state
    const { obstacles, obstacleDetection } = daa
    const { airSpeed } = telemetry.telemetry
    return {
        telemetry: state.telemetry.telemetry,
        linkStatus: state.telemetry.linkStatus,
        airSpeed,
        obstacles,
        obstacleDetection,
    }
}

export default connect(mapStateToProps, null)(withStyles(styles)(DroneRadar))
