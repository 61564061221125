import { useState, useEffect } from 'react'
import { useInterval } from 'usehooks-ts'
import Header          from '../components/Header'
import {
  CssBaseline,
  Grid,
  IconButton,
  TextField,
  Typography,
} from '@material-ui/core'
import {
  Edit,
  KeyboardArrowDown,
  KeyboardArrowUp,
  Restore,
  Save,
} from '@material-ui/icons'
import { withStyles } from '@material-ui/core/styles'

const styles = theme => ({
  root: {
    width: '100%',
    height: `calc(100vh - ${theme.spacing(6)}px)`,
  },
  container: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    flexWrap: 'nowrap',
  },
  timer: {
    fontSize:        theme.spacing(28),
    fontWeight:      500,
    backgroundColor: theme.palette.bluegrey.dark,
    padding:         `${theme.spacing(2)}px ${theme.spacing(8)}px`,
  },
  timerLabels: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
    backgroundColor: theme.palette.bluegrey.dark,
  },
  gridContainer: {
    width: '90%',
  },
  grid: {
    padding: `${theme.spacing(4)}px ${theme.spacing(4)}px`,
  },
  buttons: {
    position: 'absolute',
    bottom: theme.spacing(1),
    right: theme.spacing(1),
  },
})

function CounterScreen({ classes }) {
  const [ title,    setTitle    ] = useState('Search and Rescue Operation')
  const [ subtitle, setSubtitle ] = useState('Time Elapsed')
  const [ gridData, setGridData ] = useState([
    {
      col: 3,
      label: 'Response Team',
      text: 'Aero Ranger 1',
      subtext: '',
    },
    {
      col: 2,
      label: 'Location',
      text: 'Palawan',
      subtext: 'Southern Sector',
    },
    {
      col: 2,
      label: 'Report Time',
      text: '3:05pm',
      subtext: '21 Dec 2021',
    },
    {
      col: 2,
      label: 'Caller',
      text: 'Mr. Ang',
      subtext: 'Singtel 5G SAR',
    },
    {
      col: 3,
      label: 'Target',
      text: '10 / M / CHI',
      subtext: 'Red Shirt, Glasses',
    },
  ])
  const [ editing, setEditing ] = useState(false)
  const [ counter, setCounter ] = useState(0)

  useEffect(() => {
    const counterData = localStorage.getItem('COUNTER-DATA')
    if (!counterData)
      return
    try {
      const counter = JSON.parse(counterData)
      if (counter.title)
        setTitle(counter.title)
      if (counter.subtitle)
        setSubtitle(counter.subtitle)
      if (counter.gridData)
        setGridData(counter.gridData)
    } catch (e) { return }
  }, [])

  useInterval(() => {
    setCounter(counter + 1)
  }, 999)

  const
    h = Math.floor(counter / 3600),
    m = Math.floor(counter / 60 % 60),
    s = Math.floor(counter % 60),
    timeStr =
      h.toString().padStart(2, '0') + ' : ' +
      m.toString().padStart(2, '0') + ' : ' +
      s.toString().padStart(2, '0')

  function updateGridData(col, field, value) {
    setGridData(prevGridData => {
      const newGridData = [ ...prevGridData ]
      newGridData[col][field] = value
      return newGridData
    })
  }

  return (
    <div className={classes.root}>
      <CssBaseline />
      <Header />
      <div className={classes.container}>
        {editing ? <>
          <TextField
            label='Title'
            value={title}
            onChange={evt => setTitle(evt.target.value)}
          />
          <TextField
            label='Subtitle'
            value={subtitle}
            onChange={evt => setSubtitle(evt.target.value)}
          />
        </> : <>
          <Typography variant='h2'>{title}</Typography>
          <Typography variant='h5'>{subtitle}</Typography>
        </>}
        <div>
          <Typography variant='h1' className={classes.timer}>{timeStr}</Typography>
          <div className={classes.timerLabels}>
            <Typography variant='h6'>HOURS</Typography>
            <Typography variant='h6'>MINUTES</Typography>
            <Typography variant='h6'>SECONDS</Typography>
          </div>
        </div>
        <Grid container className={classes.gridContainer}>
          { editing ?
            gridData.map((gd, i) =>
            <Grid item key={i} xs={gd.col} className={classes.grid}>
              <TextField fullWidth
                label='Columns (must sum up to 12)'
                value={gd.col}
                onChange={evt => updateGridData(i, 'col', evt.target.value)}
              />
              <TextField fullWidth
                label='Label'
                value={gd.label}
                onChange={evt => updateGridData(i, 'label', evt.target.value)}
              />
              <TextField fullWidth
                label='Text'
                value={gd.text}
                onChange={evt => updateGridData(i, 'text', evt.target.value)}
              />
              <TextField fullWidth
                label='Subtext'
                value={gd.subtext}
                onChange={evt => updateGridData(i, 'subtext', evt.target.value)}
              />
            </Grid>
          ) : gridData.map((gd, i) => 
            <Grid item xs={gd.col} className={classes.grid}>
              <Typography variant='overline'>{gd.label}</Typography>
              <Typography variant='h4'>{gd.text}</Typography>
              <Typography variant='h6'>{gd.subtext}</Typography>
            </Grid>
          ) }
        </Grid>
      </div>
      <div className={classes.buttons}>
        { editing ? <>
          <IconButton
            onClick={() => setGridData(prevGridData => {
              const newGridData = [...prevGridData]
              newGridData.push({ col: 3, label: '', text: '', subtext: ''})
              return newGridData
            })}>
            <KeyboardArrowUp />
          </IconButton>
          <IconButton
            onClick={() => setGridData(prevGridData => {
              const newGridData = [...prevGridData]
              newGridData.pop()
              return newGridData
            })}>
            <KeyboardArrowDown />
          </IconButton>
        </> :
          <IconButton onClick={() => setCounter(0)}>
            <Restore />
          </IconButton>
        }
        <IconButton
          variant={editing ? 'contained' : 'default'}
          color={editing ? 'primary' : 'default'}
          onClick={() => {
            if (editing)
              localStorage.setItem('COUNTER-DATA', JSON.stringify({
                title: title,
                subtitle: subtitle,
                gridData: gridData,
              }))
            setEditing(!editing)
          } }>
          {editing ? <Save /> : <Edit />}
        </IconButton>
      </div>
    </div>
  )
}


export default withStyles(styles)(CounterScreen)