import {
  UPDATE_TELEMETRY,
  UPDATE_LINK_STATUS,
  FIRST_ESTABLISH_TELEMETRY,
  LAST_KNOWN_TELEM_STORAGE
} from '../actions/telemetryActions'
import { RESET_MISSION } from '../actions/mission'

const initialState = {
  linkStatus: 'Not connected',
  telemetryEstablished: false,
  telemetry:
    window.localStorage.getItem(LAST_KNOWN_TELEM_STORAGE) !== null
      ? JSON.parse(window.localStorage.getItem(LAST_KNOWN_TELEM_STORAGE))
      : {},

  // networks: [] // TODO: Migrating this to consult Flight Status
  networkSpeed: { downloadSpeed: '-', uploadSpeed: '-' },
}

function telemetryReducer(state = initialState, action) {
  switch (action.type) {
    case RESET_MISSION:
      return {
        ...state,
        telemetry: {}
      }
    case UPDATE_LINK_STATUS:
      return {
        ...state,
        linkStatus: action.error,
        networkSpeed: initialState.networkSpeed
      }
    case FIRST_ESTABLISH_TELEMETRY:
      return {
        ...state,
        telemetryEstablished: true
      }
    case UPDATE_TELEMETRY:
      const { data: telemetry, networkSpeed } = action
      return {
        ...state,
        linkStatus: null,
        telemetry: {
          ...telemetry
        },
        networkSpeed: {
          ...networkSpeed
        }
      }
    default:
      return state
  }
}

export default telemetryReducer

