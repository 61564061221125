import { useEffect } from 'react'

import WinchDownButton    from '../payload-buttons/WinchDownButton'
import WinchIdleButton    from '../payload-buttons/WinchIdleButton'
import WinchUpButton      from '../payload-buttons/WinchUpButton'
import ReleaseServoButton from '../payload-buttons/ReleaseServoButton'
import RetractServoButton from '../payload-buttons/RetractServoButton'

import {
  Grid,
  IconButton,
  Typography,
} from '@material-ui/core'
import {
  ChevronLeft,
  ChevronRight,
  KeyboardArrowDown,
  KeyboardArrowUp,
  Stop,
} from '@material-ui/icons'
import { withStyles } from '@material-ui/core/styles'

// Nudge the drone in increments of 3 meters (TODO: to make it a config in the future)
const nudgeDistance = 3

const styles = theme => ({
  container: {
    width: theme.spacing(20),
  },
  center: {
    textAlign: 'center',
  }
})

function DownwardControls({ classes }) {
  const title = (
    <div className={classes.center}>
      <Typography noWrap variant='overline'>Payload</Typography>
      <Typography noWrap variant='h6'>AED Winch 1</Typography>
    </div>
  )

  useEffect(() => {
    function handleKeyDown(event) {
      event.preventDefault()

      switch (event.key) {
        case "Down":
        case "ArrowDown":
          handleMove(-nudgeDistance, 0)
          break
        case "Up":
        case "ArrowUp":
          handleMove(nudgeDistance, 0)
          break
        case "Left":
        case "ArrowLeft":
          handleMove(0, -nudgeDistance)
          break
        case "Right":
        case "ArrowRight":
          handleMove(0, nudgeDistance)
          break
        case "Esc":
        case "Escape":
          handleMove(0, 0)
          break
        default:
      }
    }
    window.addEventListener('keydown', handleKeyDown, true)
    return () => {
      window.removeEventListener('keydown', handleKeyDown, true)
    }
  }, [])

  function handleMove(y, x) {
    console.log('Move drone y', y, 'x', x)
    // TODO - Nudging API to nudge drones by 5m horizontally
  }

  function ControlButton(props) {
    const { onClick, icon, text } = props
    return (
      <div className={classes.center}>
        <IconButton
          color='primary'
          variant='contained'
          onClick={onClick}>
          { icon }
        </IconButton>
        <Typography variant='subtitle2'>{ text }</Typography>
      </div>
    )
  }

  const moveForward =
    <ControlButton
      icon={<KeyboardArrowUp className={classes.icon} />}
      text='Forward'
      onClick={() => handleMove(nudgeDistance, 0)} />

  const moveBackward =
    <ControlButton
      icon={<KeyboardArrowDown className={classes.icon} />}
      text='Backward'
      onClick={() => handleMove(-nudgeDistance, 0)} />

  const moveLeft =
    <ControlButton
      icon={<ChevronLeft className={classes.icon} />}
      text='Left'
      onClick={() => handleMove(0, -nudgeDistance)} />

  const moveRight =
    <ControlButton
      icon={<ChevronRight className={classes.icon} />}
      text='Right'
      onClick={() => handleMove(0, nudgeDistance)} />

  const stop =
    <ControlButton
      icon={<Stop className={classes.icon} />}
      text='Stop'
      onClick={() => handleMove(0, 0)} />

  return (
    <Grid container direction='column' spacing={0} justifyContent='flex-start' className={classes.container}>
      <Grid item>{ title }</Grid>
      <Grid item><WinchUpButton /></Grid>
      <Grid item><WinchIdleButton /></Grid>
      <Grid item><WinchDownButton /></Grid>
      <Grid item><ReleaseServoButton /></Grid>
      <Grid item><RetractServoButton /></Grid>
      <Grid container direction='row' >
        <Grid item xs={4}></Grid>
        <Grid item xs={4}>{ moveForward }</Grid>
        <Grid item xs={4}></Grid>
        <Grid item xs={4}>{ moveLeft }</Grid>
        <Grid item xs={4}>{ stop }</Grid>
        <Grid item xs={4}>{ moveRight }</Grid>
        <Grid item xs={4}></Grid>
        <Grid item xs={4}>{ moveBackward }</Grid>
        <Grid item xs={4}></Grid>
      </Grid>
    </Grid>
  )
}

export default withStyles(styles)(DownwardControls)
