import {
  CHOOSE_MISSION,
  RETRIEVE_MISSIONS_FAILURE,
  RETRIEVE_MISSIONS_SUCCESS,
  RETRIEVE_MISSIONS_LOADING,
  SUBMIT_TO_AIRMAP_SUCCESS,
  SUBMIT_TO_AIRMAP_IN_PROGRESS,
  SUBMIT_TO_AIRMAP_ERROR,
  CLOSE_AIRMAP_SUBMIT_DIALOG,
  FLIGHT_PLAN_SENT,
  FLIGHT_PLAN_SENT_STORAGE,
  RESET_MISSION,
} from '../actions/mission'

const debug = true
const MISSION_STORAGE = 'MISSION'
const initialState = {
  checklistIsOpen: false,
  missionList: [],
  missionListLoading: false,
  missionListLoaded: false,
  missionListError: null,
  airmapSubmitLoading: false,
  airmapSubmitSuccess: false,
  airmapSubmitError: null,
  airmapSubmittedDialog: false,
  // Grab mission information if in local storage
  mission:
    window.localStorage.getItem(MISSION_STORAGE) !== null
      ? JSON.parse(window.localStorage.getItem(MISSION_STORAGE))
      : debug
      ? {
          name: '-',
          route: [],
          flightPlanId: null,
          droneId: null,
          droneModel: '-',
          droneName: '-',
          airmapFlightId: '',
          pilot: '-',
          commander: '-',
        }
      : null,
  flightPlanSent: window.localStorage.getItem(FLIGHT_PLAN_SENT_STORAGE) !== null ? true : false,
}

function missionReducer(state = initialState, action) {
    switch (action.type) {
        case RESET_MISSION:
            return {
                ...state,
                missionListLoaded: false,
                missionListError: null,
                airmapSubmitSuccess: false,
                airmapSubmitError: null,
                flightPlanSent: false,
                airmapSubmittedDialog: false,
                mission: null,
            }
        case FLIGHT_PLAN_SENT:
            window.localStorage.setItem(FLIGHT_PLAN_SENT_STORAGE, true)
            return {
                ...state,
                flightPlanSent: true,
            }
        case CLOSE_AIRMAP_SUBMIT_DIALOG:
            return {
                ...state,
                airmapSubmitLoading: false,
                airmapSubmitSuccess: false,
                airmapSubmitError: null,
                airmapSubmittedDialog: false,
            }
        case SUBMIT_TO_AIRMAP_IN_PROGRESS:
            return {
                ...state,
                airmapSubmitLoading: true,
                airmapSubmitSuccess: false,
                airmapSubmitError: null,
            }
        case SUBMIT_TO_AIRMAP_SUCCESS:
            return {
                ...state,
                airmapSubmitLoading: false,
                airmapSubmitSuccess: true,
                airmapSubmitError: null,
                airmapFlightId: action.airmapFlightId,
                airmapSubmittedDialog: true,
            }
        case SUBMIT_TO_AIRMAP_ERROR:
            return {
                ...state,
                airmapSubmitLoading: false,
                airmapSubmitSuccess: false,
                airmapSubmitError: action.err,
                airmapSubmittedDialog: true,
            }
        case RETRIEVE_MISSIONS_FAILURE:
            return {
                ...state,
                missionListError: action.err,
                missionListLoading: false,
                missionListLoaded: true,
                missionList: [],
            }

        case RETRIEVE_MISSIONS_SUCCESS:
            return {
                ...state,
                missionListError: null,
                missionListLoading: false,
                missionListLoaded: true,
                missionList: action.missionList,
            }

        case RETRIEVE_MISSIONS_LOADING:
            return {
                ...state,
                missionListError: action.err,
                missionListLoading: true,
                missionListLoaded: false,
                missionList: [],
            }
        // Choose the mission to update
        case CHOOSE_MISSION:
            return {
                ...state,
                mission: action.mission,
            }
        default:
            return state
    }
}

export default missionReducer
