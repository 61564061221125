import { useState, useEffect, useRef } from 'react'
import { bindActionCreators } from 'redux'
import { connect }            from 'react-redux'

import {
  payloadZoomAction,
  payloadPitchAction,
  payloadYawAction,
  payloadResetPitchYawAction,
  payloadRecordAction,
} from '../modules/actions/payloadActions'

import LivePayloadTelemetryListener from '../telemetry/LivePayloadTelemetryListener'
import Header          from '../components/Header'
import SingleVideo     from '../video/Components/SingleVideo'
import PayloadControls from './Components/PayloadControls'
import PayloadSnackbar from './Components/PayloadSnackbar'

import {
  CssBaseline,
  IconButton,
} from '@material-ui/core'
import {
  Fullscreen,
  FullscreenExit,
} from '@material-ui/icons'
import { makeStyles, withStyles }  from '@material-ui/core/styles'

const styles = theme => ({
  header: {
    height: theme.spacing(6),
  },
  body: {
    display: 'flex',
  },
  fsButton: {
    position: 'absolute',
    zIndex:   1000,
    right:    theme.spacing(0),
    bottom:   theme.spacing(0),
  },
})

// ViewPro ICD
// https://docs.google.com/document/d/1R5HHZPCuRDmKp13d_7I6kX129zQnan5JccrFjWS98rQ/edit#
const MAX_PITCH     = 90
const MIN_PITCH     = -90
const DEFAULT_PITCH = 0

const MAX_YAW       = 180
const MIN_YAW       = -180
const DEFAULT_YAW   = 0

const MAX_ZOOM_LEVEL     = 10
const MIN_ZOOM_LEVEL     = 1
const DEFAULT_ZOOM_LEVEL = 1

// default focus on center of 4K frame. TODO: make it dynamic based on video received.
const DEFAULT_ZOOM_FOCUS = [1888, 1016, 64, 128]

const synth = window.speechSynthesis

async function say(words) {
  const speech = new SpeechSynthesisUtterance(words)
  // TODO: Find a better sound to represent the entire product, and abstract into Utils
  // speech.voice = await synth.getVoices().find(v => v.name === 'Daniel')
  synth.speak(speech)
}

function PayloadScreen({
  classes,
  payloadZoomAction,
  payloadPitchAction,
  payloadYawAction,
  payloadResetPitchYawAction,
  selectedPayload,
  payloadTelemetry,
}) {
  const [ fullscreen,       setFullscreen       ] = useState(false)
  const [ recordingOnBoard, setRecordingOnBoard ] = useState(false)
  const [ recordingOnCloud, setRecordingOnCloud ] = useState(true)
  const [ snackbarMessage,  setSnackbarMessage  ] = useState('')
  const [ pitch,   _setPitch  ] = useState(DEFAULT_PITCH)
  const [ yaw,     _setYaw    ] = useState(DEFAULT_YAW)
  const [ zLevel,  _setZLevel ] = useState(DEFAULT_ZOOM_LEVEL)
  const [ zFocus,  setZFocus  ] = useState(DEFAULT_ZOOM_FOCUS) // video frame space
  const [ zoomBox, setZoomBox ] = useState(null) // screen pixel space

  const pitchRef = useRef(pitch)
  const yawRef   = useRef(yaw)
  const zoomRef  = useRef(zLevel)
  const setPitch = pitch => {
    pitchRef.current = pitch
    _setPitch(pitch)
  }
  const setYaw = yaw => {
    yawRef.current = yaw
    _setYaw(yaw)
  }
  const setZLevel = zoom => {
    zoomRef.current = zoom
    _setZLevel(zoom)
  }

  useEffect(() => {
    // TODO: check for well formed telemetry
    if (!payloadTelemetry)
      return

    setPitch(Math.round(payloadTelemetry.msg.dPitch))
    setYaw(Math.round(payloadTelemetry.msg.dYaw))
    setZLevel(Math.round(payloadTelemetry.msg.sZoomMagTimes))
    setRecordingOnBoard(!!payloadTelemetry.msg.recording) // TODO
    setRecordingOnCloud(!!payloadTelemetry.msg.recording) // TODO
  }, [payloadTelemetry])

  function handlePitch(pitchChange) {
    let newPitch = pitchRef.current + pitchChange
    if (newPitch > MAX_PITCH) {
      say('maximum pitch reached')
      newPitch = MAX_PITCH
    }
    else if (newPitch < MIN_PITCH) {
      say('minimum pitch reached')
      newPitch = MIN_PITCH
    }
    setPitch(newPitch)
    if (selectedPayload)
      payloadPitchAction(selectedPayload.payload_id, 'pitchTo', newPitch)
  }

  function handleYaw(yawChange) {
    let newYaw = yawRef.current + yawChange
    if (newYaw > MAX_YAW) {
      say('maximum yaw reached')
      newYaw = MAX_YAW
    }
    else if (newYaw < MIN_YAW) {
      say('minimum yaw reached')
      newYaw = MIN_YAW
    }
    setYaw(newYaw)
    if (selectedPayload)
      payloadYawAction(selectedPayload.payload_id, 'yawTo', newYaw)
  }

  function handleZoom(zoomChange) {
    let newZoomLevel = zoomRef.current + zoomChange
    if (newZoomLevel > MAX_ZOOM_LEVEL) {
      say('maximum zoom reached')
      newZoomLevel = MAX_ZOOM_LEVEL
    }
    else if (newZoomLevel < MIN_ZOOM_LEVEL) {
      say('minimum zoom reached')
      newZoomLevel = MIN_ZOOM_LEVEL
    }
    setZLevel(newZoomLevel)

    if (selectedPayload)
      payloadZoomAction({
        payloadId: selectedPayload.payload_id,
        zoomType:  'zoomTo',
        zoomLevel: newZoomLevel,
        zoomFocus: zFocus,
      })
  }

  function handleFocus(e) {
    // Current default values, not sure what will happen in the future
    const focusWidth = 64
    const focusHeight = 128

    // Determine whether the screen (container div) is fatter or taller than the video's aspect ratio
    const videoAR  = e.videoWidth / e.videoHeight
    const screenAR = e.videoNodeWidth / e.videoNodeHeight
    let videoScreenWidth, videoScreenHeight, xOffset, yOffset

    if (videoAR > screenAR) { // has black bars top bottom
      videoScreenWidth  = e.videoNodeWidth
      videoScreenHeight = e.videoNodeWidth / videoAR
      xOffset = 0
      yOffset = (e.videoNodeHeight - videoScreenHeight) / 2
    }
    else if (videoAR < screenAR) { // has black bars left right
      videoScreenWidth  = e.videoNodeHeight * videoAR
      videoScreenHeight = e.videoNodeHeight
      xOffset = (e.videoNodeWidth - videoScreenWidth) / 2
      yOffset = 0
    }
    else { // same aspect ratio
      videoScreenWidth  = e.videoNodeWidth
      videoScreenHeight = e.videoNodeHeight
      xOffset = 0
      yOffset = 0
    }

    const ratio = e.videoWidth / videoScreenWidth
    console.log(`video ${e.videoWidth}x${e.videoHeight} (ratio ${videoAR.toFixed(3)})\n`,
      `screen ${e.videoNodeWidth}x${e.videoNodeHeight} (ratio ${screenAR.toFixed(3)})\n`,
      `video in screen ${videoScreenWidth.toFixed(0)}x${videoScreenHeight.toFixed(0)}\n`,
      `offset ${xOffset.toFixed(0)}x${yOffset.toFixed(0)}\n`,
      'video to screen ratio', ratio.toFixed(3))

    const focusX = Math.round((e.x - xOffset) * ratio - 0.5 * focusWidth)
    const focusY = Math.round((e.y - yOffset) * ratio - 0.5 * focusHeight)
    const newZFocus = [ focusX, focusY, focusWidth, focusHeight ]
    setZFocus(newZFocus)

    const w = Math.round(focusWidth  / ratio)
    const h = Math.round(focusHeight / ratio)
    setZoomBox({ x: e.x - w/2, y: e.y - h/2, w, h })

    console.log(
      'pixel space', e.x, e.y, w, h, `(${e.videoNodeWidth}x${e.videoNodeHeight})\n`,
      'video space', newZFocus, `(${e.videoWidth}x${e.videoHeight})`
    )

    if (selectedPayload)
      payloadZoomAction({
        payloadId: selectedPayload.payload_id,
        zoomType:  'zoomTo',
        zoomLevel: zLevel,
        zoomFocus: newZFocus,
      })
  }

  function resetPose() {
    setZLevel(DEFAULT_ZOOM_LEVEL)
    setPitch(DEFAULT_PITCH)
    setYaw(DEFAULT_YAW)

    if (selectedPayload) {
      payloadResetPitchYawAction(selectedPayload.payload_id)
      payloadZoomAction({
        payloadId: selectedPayload.payload_id,
        zoomType:  'zoomTo',
        zoomLevel: DEFAULT_ZOOM_LEVEL,
        zoomFocus: DEFAULT_ZOOM_FOCUS,
      })
    }
  }

  // function handleScreenshot() {
  //   // TODO: Eventual feature should be a popup with somewhere to type in words
  //   say('Coming soon. For now, use print screen.')
  // }

  function handleRecordOnBoard() {
    // To discuss: whether to have this feature at all.
    setSnackbarMessage('WARNING: Recording On Board cannot be started.')
    return

    // console.log((recordingOnBoard ? 'Stopped' : 'Started'),
    //   'recording onboard UAV: ', new Date().toLocaleString())
    // payloadRecordAction(selectedPayload.payload_id, recordingOnBoard ? 'stopRecord' : 'record')
    // setSnackbarMessage(recordingOnBoard ? 'Recording On-board UAV Stopped' : 'Recording On-board UAV Started')
    // setRecordingOnBoard(!recordingOnBoard)
  }

  function handleRecordOnCloud() {
    // For now, to set Wowza to always record every stream that comes in, and clean up through
    // uploading them to media service post flight.
    setSnackbarMessage('WARNING: Recording On Cloud cannot be stopped.')
    return

    // console.log((recordingOnCloud ? 'Stopped' : 'Started'),
    //   'recording on cloud server: ', new Date().toLocaleString())
    // setSnackbarMessage(recordingOnCloud ? 'Recording On Cloud Server Stopped' : 'Recording On Cloud Server Started')
    // setRecordingOnCloud(!recordingOnCloud)

    // TODO: Action to start / stop skystream recording
    //       Latest decision is not to call wowza APIs directly, but to wrap it
    //       in a backend service that can receive commands and update status
  }

  function handleSnackbarClose() {
    setSnackbarMessage('')
  }

  const payloadValues = {
    name: selectedPayload?.name ?? 'No Payload',
    pitch,
    yaw,
    zoom: zLevel,
    focus: zFocus,
    recordingOnBoard,
    recordingOnCloud,
  }

  return (
    <LivePayloadTelemetryListener>
      <CssBaseline />
      { !fullscreen &&
        <Header className={classes.header} />
      }
      <IconButton className={classes.fsButton}
        onClick={() => setFullscreen(!fullscreen)}>
        { fullscreen ? <FullscreenExit /> : <Fullscreen />}
      </IconButton>
      <div className={classes.body}>
        <SingleVideo
          streamName='payload'
          stylingClass={fullscreen ? 'fullscreen' : 'payloadVideoContainer'}
          onFocus={handleFocus}
        />
        { zoomBox &&
          <ZoomBox coords={zoomBox} />
        }
        { !fullscreen &&
          <PayloadControls
            values={payloadValues}
            handlePitch={handlePitch}
            handleYaw={handleYaw}
            handleZoom={handleZoom}
            resetPose={resetPose}
            handleRecordOnBoard={handleRecordOnBoard}
            handleRecordOnCloud={handleRecordOnCloud}
            // handleScreenshot={handleScreenshot}
          />
        }
      </div>
      <PayloadSnackbar
        message={snackbarMessage}
        onClose={handleSnackbarClose} />
    </LivePayloadTelemetryListener>
  )
}

function ZoomBox({ coords }) {
  if (!coords)
    return null
  const classes = makeStyles(theme => ({
    zoomBox: {
      opacity:  0.5,
      left:     coords.x || 0,
      top:      coords.y || 0,
      width:    coords.w || theme.spacing(8),  // 64px
      height:   coords.h || theme.spacing(16), // 128px
      border:   '5px solid white',
      position: 'absolute',
    },
  }))()
  return <div className={classes.zoomBox} />
}

function mapStateToProps(state) {
  const selectedPayload = state.deployment?.deploymentData?.payloads?.length > 0 ?
                          state.deployment?.deploymentData?.payloads[0] : null
  return {
    payloadTelemetry: state.payload.payloadTelemetry,
    selectedPayload:  selectedPayload,
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    payloadZoomAction,
    payloadPitchAction,
    payloadYawAction,
    payloadResetPitchYawAction,
    payloadRecordAction,
  }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(PayloadScreen))
