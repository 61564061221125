export const UPDATE_SETTINGS = 'UPDATE_SETTINGS'
export const RESET_SETTINGS  = 'RESET_SETTINGS'

// Keep these for setting that persists only on Local Storage
// TODO: Migrate UTM settings to Config Management
export const USE_LIGHT_THEME = 'USE_LIGHT_THEME'
export const USE_AIRMAP_UTM  = 'USE_AIRMAP_UTM'
export const USE_CFMS_UTM    = 'USE_CFMS_UTM'
export const USE_ONESKY_UTM  = 'USE_ONESKY_UTM'

export const updateSettings = settings => {
  const { useLightTheme, useAirmap, useCfms, useOnesky } = settings
  console.info('Updating settings', settings)

  if (useLightTheme) {
    window.localStorage.setItem(USE_LIGHT_THEME, 'true')
  } else {
    window.localStorage.setItem(USE_LIGHT_THEME, 'false')
  }

  if (useAirmap) {
    window.localStorage.setItem(USE_AIRMAP_UTM, 'true')
  } else {
    window.localStorage.setItem(USE_AIRMAP_UTM, 'false')
  }

  if (useCfms) {
    window.localStorage.setItem(USE_CFMS_UTM, 'true')
  } else {
    window.localStorage.setItem(USE_CFMS_UTM, 'false')
  }

  if (useOnesky) {
    window.localStorage.setItem(USE_ONESKY_UTM, 'true')
  } else {
    window.localStorage.setItem(USE_ONESKY_UTM, 'false')
  }

  return dispatch => {
    dispatch({
      type: UPDATE_SETTINGS,
      settings
    })
  }
}

export const resetSettings = settings => {
  return dispatch => {
    dispatch({
      type: RESET_SETTINGS,
    })
  }
}
