
import {
  LayersControl,
  TileLayer,
} from 'react-leaflet'

import {
  OSM_SERVER,
  OSM_ATTRIBUTION,
  ONEMAP_SERVER,
  ONEMAP_ATTRIBUTION,
} from '../../utils/site-maps'

import GoogleMapsBaseLayers from './GoogleMapsBaseLayers'

const { BaseLayer } = LayersControl

function CommonLayers({ position, hideControls }) {
  return (hideControls ?
    <TileLayer
      url={OSM_SERVER}
      attribution={OSM_ATTRIBUTION}
      maxZoom={22}
      maxNativeZoom={18}
    />
  : <LayersControl position={ position || 'topright' }>
      <GoogleMapsBaseLayers />
      <BaseLayer checked name='OpenStreetMap'>
        <TileLayer
          url={OSM_SERVER}
          attribution={OSM_ATTRIBUTION}
          maxZoom={22}
          maxNativeZoom={18}
        />
      </BaseLayer>
      { ONEMAP_SERVER && ONEMAP_ATTRIBUTION &&
        <BaseLayer name='SLA OneMap'>
          <TileLayer
            url={ONEMAP_SERVER}
            attribution={ONEMAP_ATTRIBUTION}
            maxZoom={22}
            maxNativeZoom={18}
          />
        </BaseLayer>
      }
    </LayersControl>
  )
}

export default CommonLayers
