import formatDuration from 'date-fns/formatDuration'
import { formatBw } from '../../utils'
import TopXCloseButton from '../../components/TopXCloseButton'
import {
  Button,
  Dialog,
  DialogContent,
  Grid,
  Typography,
} from '@material-ui/core'
import {
  Videocam,
  VideocamOff,
} from '@material-ui/icons'
import { withStyles } from '@material-ui/core/styles'

const styles = theme => ({
  img: {
    width:   theme.spacing(16),
    height:  theme.spacing(16),
    border:  '1px solid white',
  }
})

function CeranaVideoDialog({ classes, open, onClose, video }) {
  console.log('video', video)
  return (
    <Dialog open={open} onClose={onClose} maxWidth='sm' fullWidth>
      <DialogContent>
        <TopXCloseButton onClick={onClose} />
        <Grid container spacing={2}>
          <Grid item xs={3}>
            { video ?
              <Videocam className={classes.img} />
            :
              <VideocamOff className={classes.img} />
            }
          </Grid>
          <Grid item xs={9}>
            <Typography variant='h4'>
              {video.name || 'Unknown Incoming Video'}
            </Typography>
            <Typography variant='body2'>
              {video.serverName || '-' } | {video.applicationInstance || '-' }
            </Typography>
            <Button size='small' variant='contained' color='primary'>
              {video.totalConnections} Connections
            </Button>
            <Typography variant='body2'>
              Uptime : {formatDuration({ seconds: video.uptime }) || '-'}
            </Typography>
            {/* TODO: The rest of the video commands goes here, if any */}
          </Grid>
          <Grid item xs={6}>
            <Typography variant='h6'>Connection Counts</Typography>
            <Typography variant='body2'>RTMP : {video.connectionCount.RTMP}</Typography>
            <Typography variant='body2'>RTP : {video.connectionCount.RTP}</Typography>
            <Typography variant='body2'>MPEGDASH : {video.connectionCount.MPEGDASH}</Typography>
            <Typography variant='body2'>CUPERTINO : {video.connectionCount.CUPERTINO}</Typography>
            <Typography variant='body2'>SANJOSE : {video.connectionCount.SANJOSE}</Typography>
            <Typography variant='body2'>SMOOTH : {video.connectionCount.SMOOTH}</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant='h6'>Bandwidth</Typography>
            <Typography variant='body2'>Bytes In : {formatBw(video.bytesIn)}</Typography>
            <Typography variant='body2'>Bytes In Rate : {formatBw(video.bytesInRate)}</Typography>
            <Typography variant='body2'>Bytes Out : {formatBw(video.bytesOut)}</Typography>
            <Typography variant='body2'>Bytes Out Rate : {formatBw(video.bytesOutRate)}</Typography>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  )
}

export default withStyles(styles)(CeranaVideoDialog)
