import { bindActionCreators } from 'redux'
import { connect }            from 'react-redux'
import { useInControl }       from '../../utils/useFlightStates'
import { Button }             from '@material-ui/core'
import { withStyles }         from '@material-ui/core/styles'
import { yawDrone }           from '../../modules/actions/commandActions'
import { ReactComponent as YawIcon } from '../../static/images/icon_droneYawRight.svg'

const styles = theme => ({
  button: {
    width:  '100%',
    height: theme.spacing(7),
  },
  svg: {
    transform: 'scale(0.4, 0.4)',
  },
})

function YawRightButton({ classes, connected, isLocked, isFlying, isMoving, yawDrone }) {
  const inControl = useInControl()
  
  const canYaw = connected && inControl && isFlying
  return (
    <Button
      size='small'
      disabled={!canYaw}
      variant='contained'
      color='primary'
      className={classes.button}
      onClick={() => yawDrone({ degree: 9, direction: 'clockwise' })}>
      <YawIcon className={classes.svg} />
    </Button>
  )
}

const mapStateToProps = state => ({
  connected: state.telemetry?.linkStatus === null,
  isLocked:  state.command.isLocked,
  isFlying:  state.command.isFlying,
})

const mapDispatchToProps = dispatch => {
  return bindActionCreators({ yawDrone }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(YawRightButton))
