import React from 'react'
import MissionFormContainer from './MissionFormContainer'

import {
  Drawer,
  List,
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'

const drawerWidth = 400
const styles = theme => ({
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
    height: `calc(100% - ${theme.spacing(6)}px)`,
    top: theme.spacing(6),
  },
  list: {
    paddingTop: 0,
  },
})

function MissionDrawer({ classes }) {
  return (
    <Drawer
      className={classes.drawer}
      variant='permanent'
      anchor='left'
      classes={{
          paper: classes.drawerPaper,
      }}>
      <List className={classes.list}>
        <MissionFormContainer />
      </List>
    </Drawer>
  )
}

export default withStyles(styles)(MissionDrawer)
