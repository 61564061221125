import { Link } from '@material-ui/core'

function ExternalLink({ href, target, rel, children, ...rest }) {
  return (
    <Link href={href} target='_blank' rel='noopener noreferrer' underline='always' {...rest}>
      {children}
    </Link>
  )
}

export default ExternalLink
