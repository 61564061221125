import { useState } from 'react'
import { connect }  from 'react-redux'
import {
  Button,
  Popover,
} from '@material-ui/core'
import { Settings } from '@material-ui/icons'
import { withStyles } from '@material-ui/core/styles'
import AdvancedSettings from '../Components/AdvancedSettings'

const styles = theme => ({
  button: {
    width:  '100%',
    height: theme.spacing(3),
    marginBottom: theme.spacing(1),
  },
})

function AdvancedOptionButton({ classes, telemetry, linkStatus }) {
  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)
  const id = open ? 'simple-popover' : undefined

  const isDroneOnline = !(Object.keys(telemetry).length === 0 || linkStatus !== null)

  const openPopover = event => {
    setAnchorEl(event.currentTarget)
  }
  const closePopover = () => {
    setAnchorEl(null)
  }

  return (
    <>
      <Button
        disabled={!isDroneOnline}
        size='small'
        color='primary'
        variant='contained'
        className={classes.button}
        startIcon={<Settings />}
        onClick={openPopover}>
        Advanced
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        transformOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        onClose={closePopover}>
        <AdvancedSettings closePopover={closePopover} />
      </Popover>
    </>
  )
}

const mapStateToProps = state => ({
  telemetry:  state.telemetry.telemetry,
  linkStatus: state.telemetry.linkStatus,
})

export default connect(mapStateToProps, null)(withStyles(styles)(AdvancedOptionButton))
