import { useState, useEffect }   from 'react'
import ReactDOMServer from 'react-dom/server'
import L from 'leaflet'
import { useMap } from 'react-leaflet'
import { useInterval } from 'usehooks-ts'
import theme from '../../utils/site-theme'

const fresh_ms = 300 * 1000 // e.g. only violations in the last 5 minutes is fresh

const alertColour = {
  'NOTIFY': theme.palette.map.offWhite,
  'WARN':   theme.palette.warning.main,
  'BREACH': theme.palette.error.main,
}
const alertTextColour = {
  'NOTIFY': theme.palette.common.black,
  'WARN':   theme.palette.common.black,
  'BREACH': theme.palette.common.white,
}

function CfmsViolationMarkers({ cfmsViolations, showCfmsViolations, onClick }) {

  const map = useMap()
  const [ violationLabelMarkers,  setViolationLabelMarkers  ] = useState([])
  const [ violationCircleMarkers, setViolationCircleMarkers ] = useState([])
  const [ lastUpdate,             setLastUpdate             ] = useState(null)

  useEffect(() => {
    violationLabelMarkers.forEach(marker => marker.remove(map))
    violationCircleMarkers.forEach(marker => marker.remove(map))

    if (!showCfmsViolations) {
      setViolationLabelMarkers([])
      setViolationCircleMarkers([])
      return null
    }
    if (!cfmsViolations)
      return null

    console.log('cfmsViolations', cfmsViolations)
    const newViolationLabelMarkers = [], newViolationCircleMarkers = []

    cfmsViolations.forEach(violation => {
      const violationColor = alertColour[violation.alert_type] || theme.palette.common.white
      const popupStyles = {
        width:           '120px',
        textAlign:       'center',
        border:          '1px solid ' + theme.palette.bluegrey.main,
        borderRadius:    '0 16px 16px 16px',
        padding:         theme.spacing(1),
        color:           alertTextColour[violation.alert_type] || theme.palette.common.black,
        backgroundColor: violationColor,
      }
      const circleOptions = {
        radius:    15,
        color:     violationColor,
        weight:    5,
        fill:      true,
        fillColor: violationColor,
      }
      const popup =
        <div style={popupStyles}>
          <b>{violation.alert_type}</b><br />
          {violation.vehicle_info.id}<br />
          {violation.violation_type}<br />
          {violation.violation_area_type}
        </div>
      const pos = [violation.vehicle_info.lat, violation.vehicle_info.lon]
      const labelMarker = L.marker(pos, {
        interactive: false,
        icon: L.divIcon({ html: ReactDOMServer.renderToString(popup), className: 'dummy' })
      }).addTo(map)
      newViolationLabelMarkers.push(labelMarker)
      const circleMarker = L.circleMarker(pos, circleOptions).addTo(map)
      newViolationCircleMarkers.push(circleMarker)
    })
    setViolationLabelMarkers(newViolationLabelMarkers)
    setViolationCircleMarkers(newViolationCircleMarkers)
    setLastUpdate(new Date())
  // eslint-disable-next-line
  }, [cfmsViolations, showCfmsViolations, map])
  
  useInterval(() => {
    if (!lastUpdate)
      return

    const now = new Date()
    if (now.getTime() - lastUpdate.getTime() > fresh_ms) {
      console.log('Expiring violation markers', violationLabelMarkers)
      violationLabelMarkers.forEach(marker => {
        const popupStyles = {
          width:           '60px',
          textAlign:       'center',
          border:          '1px solid #E0E0E0',
          borderRadius:    '0 16px 16px 16px',
          padding:         theme.spacing(1),
          color:           '#E0E0E0',
          backgroundColor: '#FFFFFF99',
        }
        const popup = <div style={popupStyles}>Earlier Violation</div>
        marker.setIcon(L.divIcon({
          className: 'dummy',
          html: ReactDOMServer.renderToString(popup),
        }))
      })
      // violationCircleMarkers.forEach(marker => {
      // })
      setLastUpdate(null)
    }
  }, fresh_ms)

  return null
}

export default CfmsViolationMarkers
