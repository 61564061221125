import { useState }    from 'react'
import { connect }     from 'react-redux'
import { getDecodedToken } from '../api/accounts'
import Header          from '../components/Header'
import WebRTCStream    from './Components/WebRTCStream'
import {
  Button,
  CssBaseline,
  Dialog,
  DialogContent,
  Divider,
  IconButton,
  Menu,
  MenuItem,
} from '@material-ui/core'
import {
  Group,
  Info,
  Person,
} from '@material-ui/icons'
import { withStyles } from '@material-ui/core/styles'

const styles = theme => ({
  videoContainer: {
    zIndex:   10,
    position: 'fixed',
    top:      theme.spacing(8),
    bottom:   theme.spacing(2),
    left:     theme.spacing(2),
    right:    theme.spacing(2),
    border:   '1px solid ' + theme.palette.bluegrey.main,
  },
  overlay: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
    alignItems: 'center',
    position: 'absolute',
    color: theme.palette.common.white,
    zIndex: 12,
    paddingLeft: theme.spacing(1),
  },
})

function VideoAnalytics({ classes, mission }) {

  const [ mlModelId, setMlModelId ] = useState('2')
  const { company_id, drone_id } = getDecodedToken()

  return (<>
    <CssBaseline />
    <Header />
    <div className={classes.videoContainer}>
      <div className={classes.overlay}>
        <MLModelControls
          mlModelId={mlModelId}
          setMlModelId={setMlModelId}
        />
        { /* TODO: review how to have different UI elements for each ML Model */
          mlModelId === '2' &&
          <SafeDistanceControls />
        }
      </div>
      <WebRTCStream
        width='100%'
        height='100%'
        applicationName={`SkyStream/${drone_id}`}
        streamName='video-analytics'
        companyId={company_id}
      />
    </div>
  </>)
}

function MLModelControls({ mlModelId, setMlModelId }) {
  // TODO: Keeping this view for hard coded feeds and demos
  const mlModels = [
    {
      _id: '2',
      name: 'safe-distance',
      description: 'People and Crowd detection based on Safe Management Measures',
    },
  ]
  const sampleMlModels = [
    {
      _id: '1',
      name: 'default-yolo-v4',
      description: 'Default YOLO v4 object classification with 80 classes',
    },
  ]

  const [ anchorEl,  setAnchorEl  ] = useState(null)
  const [ openDesc,  setOpenDesc  ] = useState(false)

  function handleCloseMenu() {
    setAnchorEl(null)
  }

  function updateMLModel(mlm) {
    setMlModelId(mlm._id)
    setAnchorEl(null)
  }

  const selectedModel =
    mlModels.find(mlm => mlm._id === mlModelId) ||
    sampleMlModels.find(mlm => mlm._id === mlModelId)

  return (<>
    <Button variant='outlined' onClick={(event) => { setAnchorEl(event.currentTarget) }}>
      { selectedModel?.name || 'Select VA algorithm' }
    </Button>
    <IconButton variant='outlined' onClick={() => setOpenDesc(true)}>
      <Info />
    </IconButton>
    <Menu
      anchorEl={anchorEl}
      keepMounted
      open={Boolean(anchorEl)}
      onClose={handleCloseMenu}>
      { mlModels.map(mlm => (
        <MenuItem key={mlm._id} onClick={() => { updateMLModel(mlm) }}>{ mlm.name }</MenuItem>
      )) }
      <Divider />
      { sampleMlModels.map(mlm => (
        <MenuItem key={mlm._id} onClick={() => { updateMLModel(mlm) }}>{ mlm.name }</MenuItem>
      )) }
    </Menu>
    { openDesc &&
      <MLModelDescriptionDialog
        open={openDesc}
        onClose={() => setOpenDesc(false)}
        description={ selectedModel?.description }
      />
    }
  </>)
}

function MLModelDescriptionDialog({ open, onClose, description }) {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogContent style={{ padding: '48px' }}>
        {description}
      </DialogContent>
    </Dialog>
  )
}

function SafeDistanceControls() {
  // TODO: Connect these harcoded values to the service where the AI publishes the people/crowd count live
  const peopleCount = 42
  const crowdCount = 6
  return (<>
    <Button variant='outlined' startIcon={<Person />}>
      { peopleCount }
    </Button>
    <Button variant='outlined' startIcon={<Group />}>
      { crowdCount }
    </Button>
  </>)
}

const mapStateToProps = (state) => ({ mission: state.mission.mission })

export default connect(mapStateToProps, null)(withStyles(styles)(VideoAnalytics))
