//
// The following needs to be keep in sync with the Flight State Design
//
// https://app.diagrams.net/#G1BzEfkvX49xYSCS-s8T8pxfwPfRPgWjAt
//
function FlightState(state) {
  if (!state)
    return 'No State'

  return {
    INIT:      'Initalizing',  // Waiting on pilot inputs, configurations, and health checks
    RTF:       'Ready To Fly', // Config locked / Pre-flight check passed, Takeoff enabled
    OG:        'On Ground',    // Landed / All Controls disabled, waiting for pilot inputs
    ENDED:     'Ended',        // Pilot confirm mission had ended and Post-flight check done
    LAUNCH:    'Launch',       // Taking Off
    HOVER:     'Hover',        // Position Hold
    MISSION:   'Mission',      // Waypoint Flight
    MOVING:    'Moving',       // Manual Flight
    BACKTRACK: 'Backtrack',    // Manual Recovery
    LANDING:   'Landing',      // Landing at location
    EMERGENCY: 'EMERGENCY',    // Autonomous Recovery
    // The following are old states, they are invalid, fix them
    HOLD:      'Hold (Invalid)',
    TAKEOFF:   'Take Off (Invalid)'
  }[state] || ('Invalid Flight State: ' + state)
}

export default FlightState
