import { combineReducers } from 'redux'
import { connectRouter, routerMiddleware } from 'connected-react-router'
import { createStateSyncMiddleware, withReduxStateSync } from 'redux-state-sync'
import { loadUser, reducer as oidcReducer, USER_FOUND } from 'redux-oidc'
import { createBrowserHistory } from 'history'
import { configureStore } from '@reduxjs/toolkit'
import axios from 'axios'

import userManager from '../utils/userManager'
import sliceReducers from './reducers/index'
import { accountsApi, getDecodedToken } from '../api/accounts'
import { aircraftApi } from '../api/aircraft'
import { flightStatusApi } from '../api/liveflights'
import { geoAiConfigApi } from '../api/config'

import { CHOOSE_MISSION, RESET_MISSION } from './actions/mission'
import { TOGGLE_AVOIDANCE, TOGGLE_DETECTION } from './actions/commandActions'
import { UPDATE_TELEMETRY, UPDATE_LINK_STATUS } from './actions/telemetryActions'
import { UPDATE_INFERENCE, INFERENCE_START_ACTION, INFERENCE_STOP_ACTION } from './actions/inferenceActions'

export const history = createBrowserHistory()

// For redux-state-sync to update the states of the other browser tabs using Horizon
// We need to explicitly whitelist the list of Redux actions that should trigger the state change update
const config = {
  whitelist: [
    CHOOSE_MISSION, RESET_MISSION,
    TOGGLE_AVOIDANCE, TOGGLE_DETECTION,
    UPDATE_TELEMETRY, UPDATE_LINK_STATUS,
    USER_FOUND,
    UPDATE_INFERENCE, INFERENCE_START_ACTION, INFERENCE_STOP_ACTION
  ],
}
// Combine traditional Redux reducer slices with RTK reducer slices
const rootReducer = withReduxStateSync(combineReducers({
  router: connectRouter(history),
  oidc: oidcReducer,
  ...(sliceReducers()),
  [accountsApi.reducerPath]:     accountsApi.reducer,
  [aircraftApi.reducerPath]:     aircraftApi.reducer,
  [flightStatusApi.reducerPath]: flightStatusApi.reducer,
  [geoAiConfigApi.reducerPath]:  geoAiConfigApi.reducer,
}))

const store = configureStore({
  reducer: rootReducer,
  // default middleware already included thunk, immutableStateInvariant, serializableStateInvariant
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({
    serializableCheck: {
      // oidc.user is a class, issue still open, muting
      // https://github.com/maxmantz/redux-oidc/issues/169
      ignoredActions: ['redux-oidc/USER_FOUND'],
      ignoredPaths: ['oidc.user']
    },
  })
  .concat(createStateSyncMiddleware(config))
  .concat(routerMiddleware(history))
  .concat(accountsApi.middleware)
  .concat(aircraftApi.middleware)
  .concat(flightStatusApi.middleware)
  .concat(geoAiConfigApi.middleware)
})

// Load user info into redux store if it exists
loadUser(store, userManager)

// Make use of axios interceptors to attach the Authorization header before API request
// - Declare exceptions using REACT_APP_IGNORE_AUTHORIZATION_HEADERS
//   Example: SkyStream WebRTC does not have require any authorization header
axios.interceptors.request.use(function(config) {
  const { access_token } = getDecodedToken()
  const urls = process.env.REACT_APP_IGNORE_AUTHORIZATION_HEADERS

  const ignore_authorization_headers = urls ? urls.split(',') : []
  if (!ignore_authorization_headers.includes(config.url)) {
    config.headers.Authorization = `Bearer ${access_token}`
  }
  return config
})

export default store
