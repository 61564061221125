import SelectBTLButton from '../../live/buttons/SelectBTLButton'
import BigLandButton   from '../../live/buttons/BigLandButton'
import {
  Grid,
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'

const styles = theme => ({
  root: {
    width: '100%',
    padding: theme.spacing(1),
    backgroundColor: theme.palette.common.black
  }
})

function CommanderControls({
  classes,
  elzs,
  setELZs,
  selectedDrone,
}) {
  if (!selectedDrone)
    return null

  return (
    <Grid container justifyContent='space-evenly' className={classes.root}>
      <Grid item>
        <SelectBTLButton
          highlightLandingZone={(zone, highlightState) => {
            // mark the landing zone as highlighted
            const elzIndex = elzs.features.findIndex((elz) => elz === zone)
            const copyElz = { ...elzs }
            copyElz.features[elzIndex].properties.highlighted = highlightState
            setELZs(copyElz)
          }}
          allocated
        />
      </Grid>
      <Grid item>
        <BigLandButton />
      </Grid>
    </Grid>
  )
}

export default withStyles(styles)(CommanderControls)
