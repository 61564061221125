import { useState } from 'react'
// import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import {
  useGetAllFlightStatusQuery,
  useGetFlightStatusByIdQuery,
  useUpdateFlightStatusMutation,
} from '../api/liveflights'
// import { endFlight } from '../modules/actions/commandActions'
import ExternalLink from '../components/ExternalLink'
import Header from '../components/Header'
import {
  Avatar,
  Button,
  Checkbox,
  CssBaseline,
  Table,
  TableCell,
  TableBody,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core'
import {
  Block,
  Check,
} from '@material-ui/icons'
import { withStyles } from '@material-ui/core/styles'

const styles = theme => ({
  root: {
    padding: theme.spacing(2),
  },
  panel: {
    border: '1px solid ' + theme.palette.bluegrey.main,
    borderRadius: theme.spacing(2),
    marginRight: theme.spacing(1),
    padding: theme.spacing(2),
    maxWidth: '50%',
    backgroundColor: theme.palette.bluegrey.dark,
  },
  bold: {
    fontWeight: 'bold',
    color: theme.palette.primary.main,
  },
  endFlightMsg: {
    color: theme.palette.success.main,
  },
})

function PostFlightReview({
  classes,
  mission,
  deployment,
  // endFlight,
}) {
  const { data: allFlightStatusData } = useGetAllFlightStatusQuery({drone_id: mission.droneId, only_ongoing_flights: true})

  const allFlights = [ ...(allFlightStatusData?.data?.flights || []) ]
    .sort((a, b) => (b.time_updated - a.time_updated))

  console.log('All Flights for droneId', mission.droneId, allFlights)

  const flightStatusId = allFlights?.length > 0 ? allFlights[0].flight_status_id : ''

  const { data: flightStatusData } = useGetFlightStatusByIdQuery(flightStatusId, {
    skip: !flightStatusId
  })

  const flightStatus = flightStatusData?.data?.flight || {}

  console.log('Flight Status for flight status id', flightStatusId, flightStatus)

  return (<>
    <CssBaseline />
    <Header />
    <div className={classes.root}>
      <Typography variant='h5' gutterBottom>Post-flight Review</Typography>
      <div style={{ display: 'flex' }}>
        <div className={classes.panel}>
          <FlightStatusChecklist flightStatus={flightStatus} />
        </div>
        { deployment && mission &&
          <div className={classes.panel}>
            <ManagementChecklist deployment={deployment} mission={mission} />
          </div>
        }
      </div>
    </div>
  </>)
}

const ManagementChecklist = withStyles(styles)(({ classes, deployment, mission }) => {
  const [ checks, setChecks ] = useState({})

  function handleChange(event) {
    setChecks({ ...checks, [event.target.name]: event.target.checked })
  }

  const { missionOK, deploymentOK, serviceable, incident } = checks
  return (<>
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>
            <Avatar style={{ color: 'white' }}><Check /></Avatar>
          </TableCell>
          <TableCell>
            <Typography variant='h6'>Management Checklist</Typography>
            <Typography variant='body2'>{deployment.name}</Typography>
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        <CheckTableRow checked={missionOK}
          msg={<>Mission <B>{mission.name}</B> has completed successfully.</>}
          onChange={handleChange} />
        <CheckTableRow checked={deploymentOK}
          msg={<>Deployment <B>{deployment.name}</B> has completed successfully.</>}
          onChange={handleChange} />
        <CheckTableRow checked={serviceable}
          msg={<>The drone <B>{mission.droneName}</B> is serviceable and ready for next mission.</>}
          onChange={handleChange} />
        <CheckTableRow checked={incident}
          msg={<>There was no incidents. If there were incidents, do not check this box and
            file an incident report at <ExternalLink href='https://incidents.mydronefleets.com'>
            <u>incidents.mydronefleets.com</u></ExternalLink></>}
          onChange={handleChange} />
      </TableBody>
    </Table>
    <div style={{ padding: '16px' }}>
      <Typography variant='body2'>
        The post flight management check must be done by the end of the deployment,
        to ensure the security of the assets, and no safety incidents.
      </Typography>
    </div>
  </>)
})

const FlightStatusChecklist = withStyles(styles)(({ classes, flightStatus }) => {

  const [ updateStatus, updateResult ] = useUpdateFlightStatusMutation()
  const [ endFlightMsg, setEndFlightMsg ] = useState('')
  const [ checks, setChecks ] = useState({})

  function endFlight() {
    console.log('Ending Flight', flightStatus.flight_status_id)
    updateStatus({ flight_status_id: flightStatus.flight_status_id, last_received_state: 'ENDED'})
    .unwrap()
    .then(fulfilled => {
      console.log('update fulfilled', fulfilled)
      console.log('update result', updateResult)
      if (fulfilled.status === 'success') {
        setEndFlightMsg('Updated Flight State to ENDED')
      } else {
        setEndFlightMsg('Updating Flight State failed')
      }
    })
    .catch(rejected => {
      console.log('update rejected', rejected)
        setEndFlightMsg('Updating Flight State error.')
    })
  }

  function handleChange(event) {
    setChecks({ ...checks, [event.target.name]: event.target.checked })
  }

  console.log('flightStatus', flightStatus)
  const { flightlog, media } = checks
  let tableBody
  if (!flightStatus || !flightStatus.flight_status_id) {
    tableBody = <TableBody>
      <NoCheckTableRow msg='All your flights have ended.' />
    </TableBody>
  } else {
    tableBody = <TableBody>
      <NoCheckTableRow>
        <Typography variant='body1'>
          The Flight State of the drone is <B>{flightStatus.last_received_state}</B>.
          <br /><br />
          { flightStatus.last_received_state === 'OG' ? '' :
            <>We recommend that you only end your flight when the state is <b>OG</b> (On Ground).</>
          }
          <br /><br />
          If you're sure the landing has completed, end the flight by clicking the button below.
          This will allow the drone to shut down, start battery swapping, and prepare for its next
          mission.
          <br /><br />
        </Typography>
        <Button disabled={flightStatus.last_received_state === 'ENDED'}
          variant='contained' color='secondary' onClick={endFlight}>
          End the Flight Now
        </Button>
        <Typography variant='body1' className={classes.endFlightMsg}>
          { endFlightMsg }
        </Typography>
      </NoCheckTableRow>
      <CheckTableRow checked={flightlog}
        msg={<>The drone's <B>flight log</B> has been saved / uploaded.</>}
        onChange={handleChange} />
      <CheckTableRow checked={media}
        msg={<>The <B>images and videos</B> stored on the drone has been saved / uploaded.</>}
        onChange={handleChange} />
    </TableBody>
  }
  return (<>
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>
            <Avatar style={{ color: 'white' }}><Check /></Avatar>
          </TableCell>
          <TableCell>
            <Typography variant='h6'>Flight Status Checklist</Typography>
            <Typography variant='body2'>
              {flightStatus?.time_updated ? new Date(flightStatus.time_updated).toLocaleString() : '-'}
            </Typography>
          </TableCell>
        </TableRow>
      </TableHead>
      { tableBody }
    </Table>
    <div style={{ padding: '16px' }}>
      <Typography variant='body2'>
        The post-flight flight status check must be done once the drone lands,
        to ensure that all valuable data collected during the flight has been
        transported to a safe place.
      </Typography>
    </div>
  </>)
})

const B = withStyles(styles)(({ classes, children }) => {
  return <span className={classes.bold}>{children}</span>
})

function CheckTableRow({ checked, onChange, msg, children }) {
  return (
    <TableRow>
      <TableCell>
        <Checkbox checked={checked} onChange={onChange} />
      </TableCell>
      <TableCell>
        { msg && <Typography variant='body1'>{msg}</Typography> }
        { children }
      </TableCell>
    </TableRow>
  )
}

function NoCheckTableRow({ msg, children }) {
  return (
    <TableRow>
      <TableCell>
        <Avatar style={{ color: 'white' }}><Block /></Avatar>
      </TableCell>
      <TableCell>
        { msg && <Typography variant='body1'>{msg}</Typography> }
        { children }
      </TableCell>
    </TableRow>
  )
}

const mapStateToProps = state => ({
  deployment: state.deployment.deploymentData,
  mission:    state.mission.mission,
})

// const mapDispatchToProps = dispatch => {
//   return bindActionCreators({ endFlight }, dispatch)
// }

export default connect(mapStateToProps, null)(withStyles(styles)(PostFlightReview))
