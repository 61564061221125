
export function sleep(ms) {
  return new Promise(resolve => setTimeout(resolve, ms))
}

// Both isFinte and Number.isFinite doesn't give what we want
// we want n to be a number, including 0, no strings
export function isNumber(n) {
  return (n !== null && isFinite(n))
}

function numberWithCommas(x) {
  const parts = x.toString().split('.')
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  return parts.join('.');
}

export function formatBw(bytes) {
  if (bytes < 1e3)
    return bytes + ' bytes'
  if (bytes < 1e6)
    return numberWithCommas(bytes / 1e3) + ' KB'
  if (bytes < 1e9)
    return numberWithCommas(bytes / 1e6) + ' MB'
  if (isFinite(bytes))
    return numberWithCommas(bytes / 1e9) + ' GB'
  return '- bytes'
}

// In the previous protocol, CoPilot decides whether the signal quality is 'good', 'caution', 'error', or falsy
// In the latest protocol, this value is missing. Here is a surrogate until the mapping is recovered at source
export function signalToQuality(n) {

  // Assume 3G (not sure how to differentiate with 2G and other WCDMA performance)
  if (n.type === 'WCDMA') {
    // RSCP is only found for 3G, let's prefer that
    if (n.signal_rscp) {
      if (n.signal_rscp > -80)  return 'good'
      if (n.signal_rscp > -100) return 'caution'
      return 'error'
    }
    // Teltonika says 3G also gives RSSI
    if (n.signal_rssi) {
      if (n.signal_rssi > -86)  return 'good'
      if (n.signal_rssi > -110) return 'caution'
      return 'error'
    }
    return ''
  }

  // 4G LTE signal - based on website instead of actual modem
  if (n.type === 'LTE') {
    // Since we are more familiar with RSSI, we use this first
    if (n.signal_rssi) {
      if (n.signal_rssi > -75) return 'good'
      if (n.signal_rssi > -95) return 'caution'
      return 'error'
    }
    // 4G also gives SINR
    if (n.signal_sinr) {
      if (n.signal_sinr > 13) return 'good'
      if (n.signal_sinr > 0)  return 'caution'
      return 'error'
    }
    // Lastly, 4G also gives RSRQ
    if (n.signal_rsrq) {
      if (n.signal_rsrq > -15) return 'good'
      if (n.signal_rsrq > -20) return 'caution'
      return 'error'
    }
    return ''
  }

  // 5G signal -- values are wrong currently, using 4G values
  if (n.type === 'NR5G-NSA' || n.type === 'NR5G-SA') {
    if (n.signal_sinr) {
      if (n.signal_sinr > 13) return 'good'
      if (n.signal_sinr > 0) return 'caution'
      return 'error'
    }
    if (n.signal_rsrq) {
      if (n.signal_rsrq > -15) return 'good'
      if (n.signal_rsrq > -20) return 'caution'
      return 'error'
    }
    return ''
  }

  return false
}
