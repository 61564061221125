import { useState, useEffect } from 'react'
import { withRouter } from 'react-router'
import { connect } from 'react-redux'
import axios from 'axios'
import moment from 'moment' // TODO: DEPRECATE and migrate to date-fns
import queryString from 'query-string'

import { getDecodedToken } from '../api/accounts'
import { submitToAirmap } from '../modules/actions/mission'
import { clearDeployment, retrieveDeployment } from '../modules/actions/deployment'

import LiveTelemetryListener from '../telemetry/LiveTelemetryListener'
import Header                from '../components/Header'
import WebRTCStream          from '../video/Components/WebRTCStream'
import OvenWebRTCStream      from '../video/Components/OvenWebRTCStream'
import LiveMap               from '../regional-map/LiveMap'
import 'leaflet/dist/leaflet.css'
import SelectBTLButton       from './buttons/SelectBTLButton'
import LandButton            from './buttons/LandButton'

import {
  CssBaseline,
  Grid,
  MenuItem,
  Select,
  Typography,
} from '@material-ui/core'
import {
  ToggleButton,
  ToggleButtonGroup,
} from '@material-ui/lab'
import { withStyles } from '@material-ui/core/styles'

const styles = theme => ({
  header: { height: '70px' }
})

// TODO: Move these to RTK Query
const IDENTITY_API      = process.env.REACT_APP_OIDC_AUTHORITY // Move to Accounts API
const DEPLOYMENTS_API   = process.env.REACT_APP_DEPLOYMENTS_API
const FLIGHT_PLANS_API  = process.env.REACT_APP_FLIGHT_PLANS_API
const FLIGHT_STATUS_API = process.env.REACT_APP_FLIGHT_STATUS_WEB_API

const CommanderScreen = ({
  classes,
  deployment,
  location,
  mission,
  telemetry,
  clearDeployment,
  retrieveDeployment,
  updateMission,
}) => {
  const [ selectedDrone, setSelectedDrone ] = useState()
  const [ drones,        setDrones        ] = useState('')
  const [ commanderInfo, setCommanderInfo ] = useState()
  const [ elzs,          setELZs          ] = useState()

  const [ videoStreams,  setVideoStreams  ] = useState()
  const [ selectedVideo, setSelectedVideo ] = useState()

  const renderVideoList = (streams) => {
    return (
      <Select
        style={{ minWidth: '120px' }}
        onChange={(event) => {
          const selected = videoStreams.find((s) => s.name === event.target.value)
          setSelectedVideo(selected)
        }}
        value={selectedVideo ? selectedVideo.name : ''}
        autoWidth>
        { streams.map((stream) =>
          <MenuItem key={stream.name} value={stream.name}>{stream.name}</MenuItem>
        ) }
      </Select>
    )
  }

  const renderDroneElements = (drones) => {
    return (
      <div style={{ overflowX: 'hidden', overflowY: 'auto', height: 'calc(100vh - 200px)' }}>
        <Grid container direction='column' alignItems='center' justifyContent='center'>
          { drones.map((drone) =>
            <Grid item xs={12} key={drone.id}>
              <ToggleButtonGroup
                key={selectedDrone ? selectedDrone.id : ''}
                value={selectedDrone ? selectedDrone.id : ''}
                onChange={(e, value) => {
                  const selected = drones.find((r) => r.id === value)
                  setSelectedDrone(selected)
                }}
                exclusive>
                <ToggleButton size='large' style={{ width: '10vw' }} key={drone.id} value={drone.id}>
                  {drone.name}
                </ToggleButton>
              </ToggleButtonGroup> <br /><br />
            </Grid>
          ) }
        </Grid>
      </div>
    )
  }

  useEffect(() => {
    // TODO: This is broken, to decide whether this screen is needed before moving from 
    //       FLEET_API (which has been retired) to the formal Aircraft API (using RTK Query)
    const FLEET_API = ''
    axios.get(`${FLEET_API}/drone/company_drones/`).then((response) => {
      const companyDrones = response.data
      setDrones(companyDrones.data)
    })

    // TODO: Move to Accounts API
    axios.get(`${IDENTITY_API}api/users/${getDecodedToken().user_id}`).then((r) => {
      setCommanderInfo(r.data.user)
    })
  }, [])

  useEffect(() => {
    if (selectedDrone) {
      axios.get(`${FLIGHT_STATUS_API}/${selectedDrone.id}`).then(async (r) => {
        const flight = r.data.data.flight
        if (flight && flight.flightplan_id && flight.deployment_id) {
          const deploymentAndFpResponses = await Promise.allSettled([
            axios.get(`${FLIGHT_PLANS_API}/${flight.flightplan_id}`),
            axios.get(`${DEPLOYMENTS_API}/${flight.deployment_id}`),
            axios.get(`${FLIGHT_STATUS_API}/video/${selectedDrone.id}?video_source=ome`)
          ]).then((responses) => responses.map((r) => r.data.data))

          retrieveDeployment(flight.deployment_id)

          const [flightplan, deployment, video] = deploymentAndFpResponses

          const copy = { ...flight }
          delete copy.flightplan_id
          delete copy.deployment_id
          
          if (flightplan) {
            updateMission({
              name: flightplan.description,
              route: flightplan.plan.commands,
              flightPlanId: flight.flightplan_id,
              droneId: selectedDrone.id,
              droneModel: '',
              droneName: selectedDrone.name,
              leadPilot: deployment.personnel[0].name,
              coPilot: deployment.personnel[1] ? deployment.personnel[1].name : null
            })

            setVideoStreams(video.streams)
          } else {
            updateMission({
              name: 'No mission',
              route: [],
              flightPlanId: '',
              droneId: selectedDrone.id,
              droneModel: '',
              droneName: selectedDrone.name,
              leadPilot: '',
              coPilot: ''
            })
            setVideoStreams([])
          }
        } else {
          clearDeployment()
          updateMission({
            name: 'No mission',
            route: [],
            flightPlanId: '',
            droneId: selectedDrone.id,
            droneModel: '',
            droneName: selectedDrone.name,
            leadPilot: '',
            coPilot: ''
          })
          setVideoStreams([])
        }
      })
    } else {
      clearDeployment()
      updateMission({
        name: 'No mission',
        route: [],
        flightPlanId: '',
        droneId: selectedDrone,
        droneModel: '',
        droneName: '',
        leadPilot: '',
        coPilot: ''
      })
    }
  // eslint-disable-next-line
  }, [selectedDrone])

  useEffect(() => {
    if (deployment)
      setELZs(deployment.emergency_landing_areas)
  }, [deployment])

  if (telemetry.flight_state === 'RTF') {
    telemetry.flight_state = 'Ready to Fly'
  }
  const selectedDroneOnline = selectedDrone && Object.keys(telemetry).length > 0 && telemetry.drone_id === selectedDrone.id

  let status = 'Offline'
  if (selectedDroneOnline) {
    if (telemetry.flight_state === 'OG') {
      status = 'Mission Ended'
    } else if (telemetry.lat === '' || telemetry.lon === '') {
      status = 'Starting Mission'
    } else {
      status = 'Online'
    }
  }
  const qs = queryString.parse(location.search)
  console.log(mission)
  return (
    <>
      <CssBaseline />
      <Header
        className={classes.header}
        hideMissionInfo
      />
      <Grid container direction='column' justifyContent='center' alignItems='center'>
        <Grid item>
          <Typography variant='h4'>You are now interacting as Fleet Commander</Typography>
        </Grid>
        <Grid item>
          <Typography variant='h4'>{commanderInfo ? commanderInfo.name : '-'}</Typography>
        </Grid>
      </Grid>
      <Grid container direction='row'>
        <Grid item xs={2} container direction='column' justifyContent='center'>
          <Grid item>
            <Typography variant='h5' align='center'>
              Select Drone
            </Typography>
          </Grid>
          <Grid item>
            {drones && renderDroneElements(drones)}
          </Grid>
        </Grid>
        <Grid
          item xs={8} style={{ padding: '0vw 1vw' }}
          container={!selectedDrone}
          alignItems='center'
          justifyContent='center'
        >
          <Grid item style={{ position: 'relative', height: '100%' }}>
            {
              !qs.new_video && selectedDrone && <WebRTCStream
                width='100%'
                streamName={selectedVideo ? selectedVideo.name : 'forward'}
                applicationName={`live/${selectedDrone.id}`}
              />
            }
            {
              qs.new_video && selectedDrone && <OvenWebRTCStream
                width='100%'
                streamName={selectedVideo ? selectedVideo.name : 'forward'}
                droneId={`${selectedDrone.id}`}
              />
            }
            {
              !selectedDrone && <Typography variant='h4' align='center'>Select a drone to obtain video feed</Typography>
            }
            <div style={{ position: 'absolute', zIndex: '9999', bottom: '5vh', left: '18vw' }}>
              {
                selectedDrone &&
                <div style={{ bottom: '10px', width: '10vw', border: '1px solid white' }}>
                  <SelectBTLButton
                    highlightLandingZone={(zone, highlightState) => {
                      // mark the landing zone as highlighted
                      const elzIndex = elzs.features.findIndex((elz) => elz === zone)
                      const copyElz = { ...elzs }

                      copyElz.features[elzIndex].properties.highlighted = highlightState
                      setELZs(copyElz)
                    }}
                  />
                </div>
              }
            </div>
            <div style={{ position: 'absolute', zIndex: '9999', bottom: '5vh', right: '18vw' }}>
              {
                selectedDrone &&
                <div style={{ bottom: '10px', width: '10vw', border: '1px solid white' }}>
                  <LandButton text='Return to Home' />
                </div>
              }
            </div>
          </Grid>
        </Grid>
        <Grid item xs={2} container direction='column'>
          <Grid item>
            <LiveMap
              telemetry={(selectedDrone && telemetry && selectedDroneOnline) ? telemetry : {}}
              mission={(selectedDrone && mission) ? mission : null}
              elzs={(selectedDrone && deployment) ? { ...elzs } : null}
              latestWarning={null}
              targetGeoJson={(selectedDrone && deployment) ? deployment.geoJson : null}
              style={{ height: '15vw', width: '15vw', position: 'relative' }}
            />
          </Grid>
          {
            selectedDrone && mission.droneId &&
            <LiveTelemetryListener>
              <Grid item>
                <Typography variant='h4'>About {(mission && mission.droneName) ? mission.droneName : ''}</Typography>
                <Typography variant='h6'>Model: {(selectedDrone && selectedDrone.model) ? selectedDrone.model.name : ''}</Typography>
                <br /><br />
                <Typography variant='h4'>Video Stream: </Typography>
                {videoStreams && renderVideoList(videoStreams)}
                <br /> <br />
                <Typography variant='h4' fontWeight='bold'>Status: {status}</Typography>
                {selectedDroneOnline &&
                  <>
                    {status === 'Online' &&
                      <>
                        <Typography variant='h6' fontWeight='normal'>Battery: {selectedDrone && telemetry ? telemetry.batt_capacity : '-'}%</Typography>
                        <Typography variant='h6' fontSize='12'>Altitude Above Ground: {selectedDrone && telemetry ? Math.round(telemetry.agl / 100) : '-'}m</Typography>
                        <Typography variant='h6' fontSize='12'>Speed: {selectedDrone && telemetry ? (telemetry.air_speed / 100) : '-'}m/s</Typography>
                        <Typography variant='h6'>Flight State: {selectedDrone && telemetry ? telemetry.flight_state : '-'}</Typography>
                      </>
                    }
                    <Typography variant='h6'>Mission: {selectedDrone && mission ? mission.name : 'No mission'}</Typography>
                    <Typography variant='h6'>Mission Time: {(selectedDrone && telemetry) ? `${moment.utc(moment.duration(telemetry.flight_time, 'seconds').asMilliseconds()).format('mm:ss')}` : '-'}</Typography>
                  </>}

              </Grid>
            </LiveTelemetryListener>
          }
        </Grid>
      </Grid>
    </>
  )
}

function mapStateToProps(state) {
  return {
    mission: state.mission.mission,
    user: state.oidc.user,
    deployment: state.deployment && state.deployment.deploymentData ? state.deployment.deploymentData : null,
    telemetry: state.telemetry.telemetry
  }
}

export default connect(mapStateToProps, {
  updateMission: submitToAirmap,
  retrieveDeployment: retrieveDeployment,
  clearDeployment: clearDeployment
})(withStyles(styles)(withRouter(CommanderScreen)))
