import { useState, useEffect } from 'react'
import { useGetFlightStatusByIdQuery } from '../../api/liveflights'
import { getIncomingStreamStats } from '../../api/skystream'

import { signalToQuality } from '../../utils'
import SignalBar   from './SignalBar'
import StatusGroup from './StatusGroup'
import Status      from './Status'
import {
  Grid,
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'

const AutonomyLevelText = [
  { label: 'OFF', text: 'Off' },
  { label: 'D',   text: 'Detect Only' },
  { label: 'A',   text: 'Detect and Avoid' }
]

const styles = theme => ({
  grid: {
    cursor: 'pointer',
  },
  signalBars: {
    width: '100%',
    padding: '16px',
    fontWeight: 'bold',
    textAlign: 'center',
    color: theme.palette.common.white,
    backgroundColor: theme.palette.common.black,
  },
})

function CeranaHealthTab({ classes, droneId, flightStatus, linkStatus, telemetry, setShowNetwork, setShowVideo, autonomyLevel }) {
  // const droneId = telemetry?.drone_id || '' // later

  const { data } = useGetFlightStatusByIdQuery(flightStatus?.flight_status_id, {
    skip: !flightStatus?.flight_status_id
  })
  const flight = data?.data?.flight
  console.log('flight for', droneId, flight)

  const [ videoStates, setVideoStates ] = useState({})

  const telemetryIsGood   = Object.keys(telemetry).length > 0 && !telemetry.error
  const telemetryIsRecent = telemetry.time  
  // TODO: To decide what is the future of linkStatus across the entire code base
  //       Or deprecate it and switch the entire code base to looking only at telemetry
  const droneIsOnline   = telemetryIsGood && linkStatus === null
  const linkStatusGood  = ['Not connected'].indexOf(linkStatus) < 0

  const autonomyIsOn = 0 // autonomyLevel > 0

  const networks = Array.isArray(telemetry.networks) ? telemetry.networks : []

  useEffect(() => {
    Promise.allSettled([
      getIncomingStreamStats('forward',  droneId),
      getIncomingStreamStats('downward', droneId),
      getIncomingStreamStats('stereo',   droneId),
      getIncomingStreamStats('payload',  droneId),
    ]).then(results => {
      console.log('skystreams', results)
      setVideoStates({
        forward:  results[0].value.status === 'success' ? results[0].value.data : {},
        downward: results[1].value.status === 'success' ? results[1].value.data : {},
        stereo:   results[2].value.status === 'success' ? results[2].value.data : {},
        payload:  results[3].value.status === 'success' ? results[3].value.data : {},
      })
    })
  }, [droneId])

  return (
    <Grid container>
      <Grid item sm={3} className={classes.grid}>
        <StatusGroup title='Link Status' tooltip='What is Link Status?'>
          <Status good enable={droneIsOnline}>ONLINE</Status>
          <Status bad enable={!droneIsOnline}>OFFLINE</Status>
        </StatusGroup>
      </Grid>
      <Grid item sm={3} className={classes.grid}>
        <StatusGroup title='Link Status Message' tooltip='What is Link Status?'>
          <Status good enable={linkStatusGood}>{linkStatus}</Status>
        </StatusGroup>
      </Grid>
      <Grid item sm={3} className={classes.grid}>
        <StatusGroup title='Telemetry Status' tooltip='Telemetry updates from UAV'>
          <Status good enable={telemetryIsGood}>ON</Status>
          <Status bad enable={!telemetryIsGood}>OFF</Status>
        </StatusGroup>
      </Grid>
      <Grid item sm={3} className={classes.grid}>
        <StatusGroup title='Last Telemetry' tooltip='Timestamp of the last received telemetry'>
          <Status good enable={telemetryIsRecent}>
            {telemetry.time ? new Date(telemetry.time).toLocaleString() : '-'}
          </Status>
        </StatusGroup>
      </Grid>
      { networks.map((n, i) => {
        const quality = signalToQuality(n)
        return (
          <Grid item sm={3} className={classes.grid} key={n.imei} onClick={() => setShowNetwork(n)}>
            <StatusGroup title={`Modem ${n.ip_address || '(No IP Address)'}`} tooltip='UAV 4G/5G Cellular Connection'>
              <div className={classes.signalBars}>
                <SignalBar
                  signalStrength={quality}
                  tooltipText={quality || 'No Signal'}
                />
                {n.signal_rssi ? 'RSSI ' + n.signal_rssi : null}
                {n.signal_sinr ? 'SINR ' + n.signal_sinr : null}
                {n.signal_rsrq ? 'RSRQ ' + n.signal_rsrq : null}
                {n.signal_rscp ? 'RSCP ' + n.signal_rscp : null}
              </div>
            </StatusGroup>
          </Grid>
        )
      }) }
      { networks.length === 0 &&
        <Grid item sm={3} className={classes.grid}
          onClick={() => setShowNetwork({})}>
          <StatusGroup title='No Cellular Modems Found' tooltip='UAV 4G/5G Cellular Connection'>
            <div className={classes.signalBars}>
              <SignalBar signalStrength='' tooltipText={'No Signal'} />
            </div>
          </StatusGroup>
        </Grid>
      }
      { Object.keys(videoStates).map(key => {
        const video = videoStates[key]
        const count = video?.totalConnections || 0
        return (
          <Grid item sm={3} className={classes.grid} key={key}
            onClick={() => setShowVideo(video)}>
            <StatusGroup title={`${key} Camera Video Stream`} tooltip='Incoming Video Streams from UAV'>
              <Status good enable={count > 0}>{count} connections</Status>
              <Status bad enable={!count}>OFF</Status>
            </StatusGroup>
          </Grid>
        )
      }) }
      <Grid item sm={3} className={classes.grid}>
        <StatusGroup title='DAA' tooltip='Detect And Avoid updates from UAV'>
          <Status good enable={autonomyIsOn}>{AutonomyLevelText[autonomyLevel].text}</Status>
          <Status bad enable={!autonomyIsOn}>OFF</Status>
        </StatusGroup>
      </Grid>
      {/*<Typography variant='body1'>Network Speed</Typography>
      <pre>{JSON.stringify(networkSpeed, null, 2)}</pre>*/}
    </Grid>
  )
}

export default withStyles(styles)(CeranaHealthTab)
