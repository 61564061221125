import { makeStyles } from '@material-ui/core/styles'

const styles = theme => ({
  status: ({ good, bad, enable }) => ({
    width: '100%',
    padding: '16px',
    fontWeight: 'bold',
    textAlign: 'center',
    color: enable ? 'white' : 'grey',
    backgroundColor: enable ? (good ? 'green' : 'red') : 'black'
  })
})

function Status({ children, good, bad, enable }) {
  const classes = makeStyles(styles)({ good, bad, enable })
  return <div className={classes.status}>{children}</div>
}

export default Status
