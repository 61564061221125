import { isNumber } from '../../utils'
import {
  Table,
  TableBody,
  TableRow,
  TableHead,
  TableCell,
} from '@material-ui/core'

// For now. The next thing users will ask is to see this together with the map and highlight
// each action and map icon together, will be a much longer development to be spent on the 
// new deployment planner.
function CommandTable({ commands }) {
  if (!Array.isArray(commands))
    return null

  function prettyParams(cmd) {
    switch (cmd.type) {
      case 'CHANGE_SPEED':
        return <span>Speed: {cmd.speed || '-'} m/s</span>
      case 'FACE_HEADING':
        return <span>Heading: {cmd.heading || '-'}&deg;</span>
      case 'CHANGE_CAMERA_PITCH':
        return <div>
          Pitch:     {isNumber(cmd.pitch) ? cmd.pitch : '-'}&deg;<br />
          Roll:      {isNumber(cmd.roll)  ? cmd.roll  : '-'}&deg;<br />
          Yaw:       {isNumber(cmd.yaw)   ? cmd.yaw   : '-'}&deg;<br />
          Altitude:  {isNumber(cmd.alt)   ? cmd.alt   : '-'}m<br />
          Latitude:  {isNumber(cmd.lat)   ? cmd.lat   : '-'}<br />
          Longitude: {isNumber(cmd.lng)   ? cmd.lng   : '-'}<br />
          Mode:      {isNumber(cmd.mode)  ? cmd.mode  : '-'}<br />
        </div>
      case 'SET_GIMBAL_PITCHYAW':
        return <div>
          Pitch Angle:  {isNumber(cmd.pitch_angle)  ? cmd.pitch_angle  : '-'}&deg;<br />
          Yaw Angle:    {isNumber(cmd.yaw_angle)    ? cmd.yaw_angle    : '-'}&deg;<br />
          Pitch Rate:   {isNumber(cmd.pitch_rate)   ? cmd.pitch_rate   : '-'}&deg;/s<br />
          Yaw Rate:     {isNumber(cmd.yaw_rate)     ? cmd.yaw_rate     : '-'}&deg;/s<br />
          Device Flags: {isNumber(cmd.device_flags) ? cmd.device_flags : '-'}<br />
          Device ID:    {cmd.device_id}<br />
        </div>
      case 'NAV_TO_WAYPOINT':
      case 'RETURN_TO_LAUNCH_LOC':
      case 'LAND':
      case 'TAKE_OFF':
      case 'DEFAULT_WITH_GEO':
        return <div>
          Position: {isNumber(cmd.lat) ? cmd.lat : '-'}, {isNumber(cmd.lng) ? cmd.lng : '-'}<br />
          Altitude: {isNumber(cmd.alt) ? cmd.alt : '-'}m<br />
        </div>
      case 'WAIT_FOR_NEXT_ACTION':
        return <span>-</span>
      default:
        return <span>WARNING: Unknown Command Type</span>
    }
  }

  return (
    <Table size='small'>
      <TableHead>
        <TableRow>
          <TableCell>Map Index</TableCell>
          <TableCell>MAVLink ID</TableCell>
          <TableCell>Type</TableCell>
          <TableCell>Description</TableCell>
          <TableCell>Params</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        { commands.map((cmd, i) => <TableRow key={i}>
          <TableCell>{cmd.map_index || '-'}</TableCell>
          <TableCell>{cmd.id}</TableCell>
          <TableCell>{cmd.type}</TableCell>
          <TableCell>{cmd.description}</TableCell>
          <TableCell>{prettyParams(cmd)}</TableCell>
        </TableRow>) }
      </TableBody>
    </Table>
  )
}

export default CommandTable
