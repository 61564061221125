import moment          from 'moment'
import * as turf       from '@turf/turf'
import { GeoJSON }     from 'react-leaflet'
import featureToMarker from './featureToMarker'

function NfzPolygon({ nfzGeoJson, nfzValidity, geoJsonArray }) {

  function onEachFeature(feature, layer) {
    layer.on('click', (event) => {
      const { lat, lng } = event.latlng
      const selectedPoint = turf.point([lng, lat])

      const selectedNfzs = geoJsonArray
        .filter(nfz => {
          const geoJsonGeometry = nfz.geometry
          const coordinates = geoJsonGeometry.coordinates
          let polygon = null

          if (geoJsonGeometry.type === 'Polygon') {
            polygon = turf.polygon(coordinates)
          } else if (geoJsonGeometry.type === 'MultiPolygon') {
            polygon = turf.multiPolygon(coordinates)
          }

          return polygon && turf.booleanPointInPolygon(selectedPoint, polygon)
        })
        .map(nfz => {
          const { start, end } = nfzValidity
          return nfz.validity
            .filter(validityEntry => {
              return start <= validityEntry.end && validityEntry.start <= end
            })
            .map(v => {
              if (nfz.nfz_type === 'temp') {
                const start = moment.unix(v.start).format('DD MMM hh:mm A')
                const end = moment.unix(v.end).format('DD MMM hh:mm A')
                return `<div class="nfz-popup-content">${nfz.name} (${start} - ${end}) </div>`
              } else {
                return `<div class="nfz-popup-content">${nfz.name}</div>`
              }
            })
            .join('')
        })

      if (selectedNfzs.length > 0) {
        const popupContent = `
        <Popup class="nfz-popup">
          <div class="nfz-popup-title">Selected Airspaces</div>
          ${selectedNfzs.join('')}
        </Popup>`

        layer.bindPopup(popupContent)
      }
    })
  }

  // This is not HTML style, it's SVG path option
  // Read https://leafletjs.com/reference.html#path-option
  function pathOption(properties) {
    return {
      color: properties.color,
      weight: 2,
      fillOpacity: 0.2,
      fillColor: properties.color,
      className: properties.customCssClassName,
    }
  }

  return (
    <GeoJSON
      data={nfzGeoJson}
      style={() => pathOption(nfzGeoJson.properties)}
      onEachFeature={onEachFeature}
      pointToLayer={(feature, latlng) => featureToMarker(feature.properties.icon, latlng)}
    />
  )
}

export default NfzPolygon

