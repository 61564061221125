import { useState } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { useInControl } from '../../utils/useFlightStates'

import { selectBackTrackToLand } from '../../modules/actions/commandActions'
import EmergencyLandingZones from '../Components/EmergencyLandingZones'

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@material-ui/core'
import { FlightLand } from '@material-ui/icons'
import { withStyles } from '@material-ui/core/styles'

const styles = theme => ({
  button: {
    width:  theme.spacing(36),
    height: theme.spacing(7),
  },
  emergencyLandingZones: {
    position: 'absolute',
    top:      theme.spacing(13),
    right:    theme.spacing(2),
    padding:  theme.spacing(2),
    border:   '1px solid white',
    backgroundColor: theme.palette.bluegrey.main,
  },
  imageButton: {
    background: '#de183c',
    width: '100%',
    height: '64px',
    color: 'white',
  },
  image: {
    maxHeight: '2.3rem',
    display: 'flex',
    maxWidth: '64px',
    justifyContent: 'center',
    fill: 'white',
  },
  icon: {
    transform:    'scale(2, 2)',
    marginRight: theme.spacing(2),
    // height: theme.spacing(5)
  }
})

function SelectBTLButton({
  classes,
  connected,
  isLocked,
  isFlying,
  isLanding,
  zones,
  selectBackTrackToLand,
  highlightLandingZone,
}) {
  const inControl = useInControl()
  
  const [ selectedZone,     setSelectedZone     ] = useState(null)
  const [ showLandingZones, setShowLandingZones ] = useState(false)
  const [ showConfirmation, setShowConfirmation ] = useState(false)

  function selectLandingZone(zone) {
    setShowLandingZones(false)
    setSelectedZone(zone)
    setShowConfirmation(true)
  }

  function confirmEmergencyLanding() {
    setShowConfirmation(false)
    selectBackTrackToLand(selectedZone)
  }

  const canSBTL = connected && inControl && isFlying && !isLanding
  return (<>
    <div>
      <Button
        disabled={!canSBTL}
        variant='contained'
        color='primary'
        className={classes.button}
        startIcon={<FlightLand className={classes.icon} />}
        onClick={() => setShowLandingZones(!showLandingZones)}>
        Select Backtrack To Land
      </Button>
      {
        showLandingZones &&
        <div className={classes.emergencyLandingZones}>
          <Typography variant='h6'>
            Choose an Emergency Landing Zone
          </Typography>
          <EmergencyLandingZones
            zones={zones}
            selectLandingZone={selectLandingZone}
            highlightLandingZone={highlightLandingZone || (() => {})}
          />
        </div>
      }
    </div>
    { showConfirmation &&
      <ConfirmDialog
        open={showConfirmation}
        onClose={() => setShowConfirmation(false)}
        selectedZone={selectedZone}
        onConfirm={confirmEmergencyLanding}
      />
    }
  </>)
}

function ConfirmDialog({ open, onClose, selectedZone, onConfirm }) {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth='sm'
      fullWidth>
      <DialogTitle>
        You are about to autonomously navigate and land in {selectedZone.properties.title || 'Untitled Emergency Landing Zone'}?
      </DialogTitle>
      <DialogContent>
        <Typography variant='body1'>
          Select Backtrack To Land: The drone will follow the predetermined path back to the selected landing zone.
          During this time, you can still use the Pause and Play button to guide the drone.
        </Typography>
        <br />
        <Typography variant='body1'>
          <b>Warning:</b> Once you click confirm, you cannot go back to completing the original mission any more.
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>
          Cancel
        </Button>
        <Button color='secondary' variant='contained' onClick={onConfirm}>
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  )
}

const mapStateToProps = state => ({
  connected: state.telemetry?.linkStatus === null,
  isLocked:  state.command.isLocked,
  isFlying:  state.command.isFlying,
  isLanding: state.command.isLanding,
  zones:     state.deployment?.deploymentData?.emergency_landing_areas || {},
})

const mapDispatchToProps = dispatch => {
  return bindActionCreators({ selectBackTrackToLand }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(SelectBTLButton))
