import { Redirect } from 'react-router-dom'
import { connect }  from 'react-redux'
import queryString  from 'query-string'

import {
  Button,
  Typography,
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import logo from '../static/images/Logo_PlexHorizon_White.png'
import bg   from '../static/images/horizon-2.png'

const styles = theme => ({
  root: {
    position: 'fixed',
    width:    '100%',
    height:   '100%',
    left:     0,
    top:      0,
    zIndex:   10,
    margin:   0,
    background: `url(${bg}) no-repeat fixed center`,
    overlay: {
      backgroundColor: theme.palette.common.black,
      opacity: 0.9
    }
  },
  container: {
    position:  'absolute',
    left:      '50%',
    top:       '40%',
    transform: 'translate(-50%, -50%)'
  },
  logo: {
    height:     '80%',
    width:      '80%',
    marginLeft: 40
  },
  title: {
    marginBottom: 30,
    color: theme.palette.common.white,
  },
  login: {
    display: 'block',
    margin: '0 auto',
    color: theme.palette.common.white,
    border: '3px solid'
  },
})

const PageNotFound = ({ classes, user, router }) => {
  if (user !== null) {
    const query = queryString.parse(router.location.search)
    if (query.orig_url) {
      return <Redirect to={query.orig_url} />
    }
  }

  function goBack(event) {
    event.preventDefault()
    window.location = '/mission'
  }

  return (
    <div className={classes.root}>
      <div className={classes.container}>
        <img src={logo} alt='Logo' align='center' className={classes.logo} />
        <Typography variant='h5' align='center' className={classes.title}>
          Page Not Found
        </Typography>
        <Button variant='outlined' size='large' className={classes.login} onClick={goBack}>
          Go Back
        </Button>
      </div>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    user:   state.oidc.user,
    router: state.router,
  }
}

export default connect(mapStateToProps, null)(withStyles(styles)(PageNotFound))
