import { connect } from 'react-redux'

import Header from '../components/Header'
import MissionDrawer from './MissionDrawer'
import MissionPreviewMap from './MissionPreviewMap'
import MissionContext, { MissionProvider } from './MissionContext'
import AirmapSubmitDialog from './AirmapSubmitDialog'

const Mission = ({ targetGeoJson }) => {
  return (
    <>
      <Header />
      <AirmapSubmitDialog />
      <MissionProvider>
        <MissionDrawer />
        <MissionContext.Consumer>
          {({ mission }) => <MissionPreviewMap mission={mission} targetGeoJson={targetGeoJson} />}
        </MissionContext.Consumer>
      </MissionProvider>
    </>
  )
}

const mapStateToProps = state => {
  const geoJson = state.deployment.deploymentData?.geoJson
  const targetGeoJson = geoJson && {
    ...geoJson,
    features: geoJson.features.filter((feature) => feature.properties.layerType === 'Target'),
  }
  return { targetGeoJson }
}

export default connect(mapStateToProps, null)(Mission)
