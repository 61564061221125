import Header from '../components/Header'
import WebRTCStream from '../video/Components/WebRTCStream'
import { getDecodedToken } from '../api/accounts'

import {
  CssBaseline,
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'

const styles = theme => ({
  mainContainer: {
    zIndex:   10,
    position: 'fixed',
    top:      theme.spacing(8),
    bottom:   theme.spacing(2),
    left:     theme.spacing(8),
    right:    theme.spacing(8),
    border:   '1px solid ' + theme.palette.bluegrey.main,
  },
  pipContainer: {
    zIndex:   20,
    position: 'fixed',
    bottom:   theme.spacing(4),
    left:     theme.spacing(2),
    width:    theme.spacing(48),
    height:   theme.spacing(30),
    border:   '1px solid ' + theme.palette.bluegrey.main,
  },
})

function PictureInPicture({ classes }) {
  const { drone_id, company_id } = getDecodedToken()

  return (
    <>
      <CssBaseline />
      <Header />
      <div className={classes.mainContainer}>
        <WebRTCStream
          width='100%'
          height='100%'
          applicationName={`SkyStream/${drone_id}`}
          streamName='payload'
          companyId={company_id}
        />
      </div>
      <div className={classes.pipContainer}>
        <WebRTCStream
          width='100%'
          height='100%'
          applicationName='SkyStream/videoCall'
          streamName='1258'
          companyId={company_id}
        />
      </div>
    </>
  )
}

export default withStyles(styles)(PictureInPicture)
