import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import axios from 'axios'

const ONBOARD_CONFIG_API = process.env.REACT_APP_ONBOARD_CONFIG_API
const GEO_AI_CONFIG_API  = process.env.REACT_APP_GEO_AI_CONFIG_API

function ping(url) {
  return axios.get(url, { timeout: 2000 })
  .then(res => res.status === 200 ? res.data : false)
  .catch(err => false)
}

export function pingOnboardConfigApi() { return ping(`${ONBOARD_CONFIG_API}/sanity`) }
export function pingGeoAiConfigApi()   { return ping(`${GEO_AI_CONFIG_API}/sanity`) }

export const geoAiConfigApi = createApi({
  reducerPath: 'geoAiConfigApi',
  baseQuery: fetchBaseQuery({
    baseUrl: GEO_AI_CONFIG_API,
    prepareHeaders: (headers, { getState }) => {
      const user = getState().oidc?.user
      if (user)
        headers.set('Authorization', `Bearer ${user.access_token}`)
      headers.set('Content-Type', 'application/json')
      headers.set('Accept', 'application/json')
      return headers
    },
  }),
  tagTypes: ['ML Model', 'ML Model Version'],
  endpoints: (builder) => ({
    getMLModels: builder.query({
      query: () => 'ml_model',
      providesTags: ['ML Model'],
    }),
    getMLModel: builder.query({
      query: (ml_model_id) => `ml_model/${ml_model_id}`, // to be implenented (to get labels)
      providesTags: ['ML Model'],
    }),
    getMLModelVersions: builder.query({
      query: (ml_model_id) => `/ml_model_version?ml_model_id=${ml_model_id}`,
      providesTags: ['ML Model Version'],
    }),
  })
})

// TODO: Move from ../cfg/*
// export const {
// } = onboardConfigApi

export const {
  useGetMLModelsQuery,
  useGetMLModelQuery,
  useGetMLModelVersionsQuery,
} = geoAiConfigApi
