import { useState } from 'react'
import { connect } from 'react-redux'

import Header from '../components/Header'
import WebRTCStream from '../video/Components/WebRTCStream'
import { getDecodedToken } from '../api/accounts'

import LiveInferenceListener from '../telemetry/LiveInferenceListener'
// import InferenceTarget from './components/InferenceTarget'
import HighlightCanvas from './components/HighlightCanvas'
import {
  CssBaseline,
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'

const canvasWidth  = 128
const canvasCenter = canvasWidth / 2
const targetWidth  = 100
const targetHeight = 160

const styles = theme => ({
  root: {
    height: `calc(100vh - ${theme.spacing(6)}px)`,
    backgroundColor: theme.palette.bluegrey.dark,
    display: 'flex',
  },
  videoContainer: {
    width:    `calc(100vw - ${canvasWidth}px)`,
    height:   `calc(100vh - ${theme.spacing(6)}px)`,
  },
  canvasContainer: {
    width:    canvasWidth,
    height:   `calc(100vh - ${theme.spacing(6)}px)`,
  },
})

function TrackObject({ classes, video_table, inference_table }) {
  const [ videoElem, setVideoElem ] = useState(undefined)
  const [ videoStarted, setVideoStarted ] = useState(false)

  function draw(context) {
    // https://developer.mozilla.org/en-US/docs/Web/API/CanvasRenderingContext2D/drawImage
    // drawImage(image, sx, sy, sWidth, sHeight, dx, dy, dWidth, dHeight)
    context.fillStyle = 'black'
    context.fillRect(0, 0, canvasWidth, window.innerHeight)

    context.font = '16px Roboto'
    context.textAlign = 'center'
    context.fillStyle = 'white'

    const telemArray = inference_table?.Person ? Object.values(inference_table?.Person) : []

    telemArray.forEach((telem, i) => {
      context.drawImage(videoElem, telem.x, telem.y, telem.w, telem.h,
        (canvasCenter - targetWidth / 2), 10 + (i * 190), targetWidth, targetHeight)
      context.fillText(telem.o + ' ' + telem.id, canvasCenter, 186 + (i * 190))
    })
    context.fillText('Time:' + videoElem.currentTime, canvasCenter, 32 + (telemArray.length * 190))
  }

  function handleUpdate(videoNode) {
    setVideoElem(videoNode)

    // Now we control everything to be mp4. In future we need to handle ogg webm.
    // console.log('video.canPlayType mp4?', videoNode.canPlayType('video/mp4'))
    function videoLoaded(event) {
      console.log('video loaded, start drawing canvas')
      setVideoStarted(true)
    }
    videoNode.addEventListener('canplaythrough', videoLoaded, false)
  }

  const { drone_id, company_id } = getDecodedToken()

  // TODO: Allow user to switch if video_table has more than 1 video.
  const videoId = video_table && Object.keys(video_table).length > 0 ?
    Object.keys(video_table)[0] : 'payload'

  return <LiveInferenceListener>
    <CssBaseline />
    <Header />
    <div className={classes.root}>
      <div className={classes.videoContainer}>
        <WebRTCStream
          videoId='track-object'
          applicationName={`SkyStream/${drone_id}`}
          streamName={videoId}
          companyId={company_id}
          onUpdate={handleUpdate}
        />
      </div>
      <div className={classes.canvasContainer}>
        <HighlightCanvas
          width={canvasWidth}
          videoStarted={videoStarted}
          draw={draw}
        />
      </div>
    </div>
  </LiveInferenceListener>
}

const mapStateToProps = state => ({
  video_table:     state.inference.video_table,
  inference_table: state.inference.inference_table,
})

export default connect(mapStateToProps, null)(withStyles(styles)(TrackObject))
