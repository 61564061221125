import { useState, useEffect }    from 'react'
import { bindActionCreators } from 'redux'
import { connect }            from 'react-redux'
import WSContainer            from '../components/WSContainer'
import { updateInference }    from '../modules/actions/inferenceActions'
import { getInferenceWssUrl } from '../api/liveflights'

function LiveInferenceListener({ user, video_table, children, updateInference }) {

  const [ wsURLs, setWsURLs ] = useState([])
  
  useEffect(() => {
    if (user && video_table) {
      setWsURLs(Object.keys(video_table)
        .filter(video_id => video_table[video_id].status === 'started')
        .map(video_id => getInferenceWssUrl(video_id)))
    }
  }, [user, video_table])

  const onOpen = (e) => {
    console.log('Live Inference Listener (LIL) connected')
  }

  const onMessage = (e) => {
    try {
      const data = JSON.parse(e.data)
      console.log('LIL received data:', data)
      updateInference(data)
    } catch(exception) {
      console.error('Unhandled exception caught at Live Inference Listener', exception)
    }
  }

  return (<>
    { wsURLs.map(url => 
      <WSContainer key={url} url={url} onMessage={onMessage} onOpen={onOpen} />
    ) }
    { children }
  </>)
}

const mapStateToProps = state => ({
  user:        state.oidc.user,
  video_table: state.inference.video_table,
})

const mapDispatchToProps = dispatch => {
  return bindActionCreators({ updateInference }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(LiveInferenceListener)
