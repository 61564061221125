import { useEffect } from 'react'
import CommonLayers  from '../components/Map/CommonLayers'
import DroneMarker   from './components/DroneMarker'
import L from 'leaflet'
import {
  MapContainer,
  useMap,
} from 'react-leaflet'
import {
  Button,
  Divider,
  Grid,
  Typography
} from '@material-ui/core'
import { withStyles } from '@material-ui/styles'

import {
  DEFAULT_MAP_CENTER
} from '../utils/site-maps'

const styles = theme => ({
  titleContainer: {
    zIndex:    '1000',
    width:     '100%',
    position:  'absolute',
    textAlign: 'center',
  },
  title: {
    margin:       theme.spacing(1) + 'px auto',
    textAlign:    'center',
    lineHeight:   1.5,
    width:        theme.spacing(25),
    height:       theme.spacing(3),
    padding:      theme.spacing(1),
    borderRadius: theme.spacing(1),
    border:       '1px solid ' + theme.palette.bluegrey.main,
    color:        theme.palette.bluegrey.dark,
    backgroundColor: theme.palette.map.offWhite,
  },
  trackTitle: {
    margin:       theme.spacing(1) + 'px auto',
    textAlign:    'center',
    lineHeight:   1.5,
    width:        theme.spacing(14),
    height:       theme.spacing(3),
    padding:      theme.spacing(1),
    borderRadius: theme.spacing(1),
    border:       '1px solid ' + theme.palette.bluegrey.main,
    color:        theme.palette.common.black,
    backgroundColor: theme.palette.map.offWhite,
  },
  buttonContainer:{
    zIndex:    '1000',
    width:     '100%',
    position:  'absolute',
    textAlign: 'center',
    bottom:    theme.spacing(2),
  },
  button: {
    width:     theme.spacing(18),
    height:    theme.spacing(4),
  }
})

function DroneDetailMap({
  classes,
  center,
  zoom,
  telemetry,
  droneName,
  cfmsTracks,
  cfmsViolations,
  selectedUasId,
  onUnselectUasId,
  ...props
}) {
  const t = cfmsTracks[selectedUasId]

  const title = <div className={classes.titleContainer}>
    <Typography
      variant='body1'
      component='div'
      className={selectedUasId ? classes.trackTitle : classes.title}>
      { selectedUasId || droneName || 'Offline / No drone selected' }
    </Typography>
  </div>

  const singleControl = <div className={classes.buttonContainer}>
    { selectedUasId ?
      <Button
        variant='contained'
        color='primary'
        className={classes.button}
        onClick={onUnselectUasId}>
        Close
      </Button>
    : droneName && telemetry.lat && telemetry.lon ?
      <Button
        variant='contained'
        color='primary'
        className={classes.button}
        onClick={() => {
          window.open('/mission-control?droneId=' + telemetry.drone_id, 'Mission Control', 'popup=yes')
        }}>
        Take Control
      </Button>
    : droneName ?
      <Typography>Offline</Typography>
    : null
    }
  </div>

  return (<>
    { title }
    { singleControl }
    <MapContainer
      center={center || DEFAULT_MAP_CENTER}
      zoom={zoom}
      maxZoom={22}
      zoomControl={false}
      {...props}>
      <CommonLayers hideControls />
      <DroneDetailPanel
        telemetry={telemetry}
        track={t} />
      { t && <DroneMarker
        lat={t.lat}
        lng={t.lng}
        yaw={t.data_provider.extras.trackDirection}
        severity={0}
      /> }
      { !t && telemetry && <DroneMarker
        lat={telemetry.lat}
        lng={telemetry.lon}
        yaw={telemetry.yaw}
        severity={0}
      /> }
    </MapContainer>
  </>)
}


function secToMinSec(s) {
  if (!s)
    return '-'
  let min = Math.floor(s / 60)
  let sec = Math.floor(s % 60)
  if (min < 10) min = '0' + min
  if (sec < 10) sec = '0' + sec
  return min + ':' + sec
}

const panelStyles = theme => ({
  panel: {
    zIndex:       '500',
    position:     'absolute',
    top:          theme.spacing(1),
    bottom:       theme.spacing(2),
    width:        theme.spacing(19),
    // height:       theme.spacing(36),
    borderRadius: theme.spacing(1),
    border:       '1px solid ' + theme.palette.bluegrey.main,
    color:        theme.palette.bluegrey.main,
    backgroundColor: theme.palette.map.offWhite + '80',
  },
  leftPanel: {
    right: theme.spacing(1),
  },
  rightPanel: {
    left: theme.spacing(1),
  },
  grid: {
    color:     theme.palette.common.black,
    textAlign: 'center',
    fontSize:  '14px',
    padding:   theme.spacing(1)
  },
  divider: {
    backgroundColor: theme.palette.bluegrey.main,
  }
})

const idType = {
  0: 'None',
  1: 'Serial Number',
  2: 'CAA Registration ID',
  3: 'UTM Assigned UUID',
}

const uaType = {
  0: 'None',
  1: 'Aeroplane',
  2: 'Helicopter, Multirotor',
  3: 'Gyroplane',
  4: 'Hybrid Lift',
  5: 'Orniyhopter',
  6: 'Glider',
  7: 'Kite',
  8: 'Free Balloon',
  9: 'Captive Balloon',
  10: 'Airship, Blimp',
  11: 'Free Fall, Parachute',
  12: 'Rocket',
  13: 'Tethered Powered Aircraft',
  14: 'Ground Obstacle',
  15: 'Other',
}

const status = {
  0: 'Undeclared',
  1: 'Ground',
  2: 'Airborne',
  // 3 - 15 Reserved
}

const DroneDetailPanel = withStyles(panelStyles)(({
  classes,
  telemetry, // default is the drone you're flying
  track,     // if you select some other drone, then this comes
}) => {

  const map = useMap()

  useEffect(() => {
    if (track) {
      map.flyTo(new L.LatLng(track.lat, track.lng), 16)
    } else if (telemetry.drone_id && telemetry.lat && telemetry.lon) { // this excludes latlng = 0,0
      map.flyTo(new L.LatLng(telemetry.lat, telemetry.lon), 16)
    }
  }, [map, track, telemetry])

  if (track) {
    return (<>
      <div className={classes.panel + ' ' + classes.leftPanel}>
        <Grid container className={classes.grid}>
          <Grid item xs={12}><Typography variant='overline'>Latitude</Typography></Grid>
          <Grid item xs={12}>{track?.lat || '-'}</Grid>
          <Grid item xs={12}><Divider className={classes.divider} /></Grid>
          <Grid item xs={12}><Typography variant='overline'>Longitude</Typography></Grid>
          <Grid item xs={12}>{track?.lng || '-'}</Grid>
          <Grid item xs={12}><Divider className={classes.divider} /></Grid>
          <Grid item xs={12}><Typography variant='overline'>Altitude</Typography></Grid>
          <Grid item xs={12}>{track?.alt || '-'} m</Grid>
          <Grid item xs={12}><Divider className={classes.divider} /></Grid>
          <Grid item xs={12}><Typography variant='overline'>Heading</Typography></Grid>
          <Grid item xs={12}>{track?.data_provider.extras.trackDirection || '-'} °</Grid>
          <Grid item xs={12}><Divider className={classes.divider} /></Grid>
          <Grid item xs={12}><Typography variant='overline'>Speed</Typography></Grid>
          <Grid item xs={12}>{track?.data_provider.extras.speed || '-'} m/s</Grid>
        </Grid>
      </div>
      <div className={classes.panel + ' ' + classes.rightPanel}>
        <Grid container className={classes.grid}>
          <Grid item xs={12}><Typography variant='overline'>Timestamp</Typography></Grid>
          <Grid item xs={12}>{track ? new Date(parseInt(track.timestamp)).toLocaleString() : '-'}</Grid>
          <Grid item xs={12}><Divider className={classes.divider} /></Grid>
          <Grid item xs={12}><Typography variant='overline'>Track Type</Typography></Grid>
          <Grid item xs={12}>CFMS (Simulation)</Grid>
          <Grid item xs={12}><Divider className={classes.divider} /></Grid>
          <Grid item xs={12}><Typography variant='overline'>ID Type</Typography></Grid>
          <Grid item xs={12}>{idType[track?.data_provider.extras.IDType] || 'Unknown'} </Grid>
          <Grid item xs={12}><Divider className={classes.divider} /></Grid>
          <Grid item xs={12}><Typography variant='overline'>UA Type</Typography></Grid>
          <Grid item xs={12}>{uaType[track?.data_provider.extras.UAType] || 'Unknown'}</Grid>
          <Grid item xs={12}><Divider className={classes.divider} /></Grid>
          <Grid item xs={12}><Typography variant='overline'>Status</Typography></Grid>
          <Grid item xs={12}>{status[track?.data_provider.extras.status] || 'Unknown'}</Grid>
        </Grid>
      </div>
    </>)
  }
  return (<>
    <div className={classes.panel + ' ' + classes.leftPanel}>
      <Grid container className={classes.grid}>
        <Grid item xs={12}><Typography variant='overline'>Flight State</Typography></Grid>
        <Grid item xs={12}>{telemetry?.flight_state || '-'}</Grid>
        <Grid item xs={12}><Divider className={classes.divider} /></Grid>
        <Grid item xs={12}><Typography variant='overline'>Latitude</Typography></Grid>
        <Grid item xs={12}>{telemetry?.lat ? Number(telemetry.lat).toFixed(7) : '-'}</Grid>
        <Grid item xs={12}><Divider className={classes.divider} /></Grid>
        <Grid item xs={12}><Typography variant='overline'>Longitude</Typography></Grid>
        <Grid item xs={12}>{telemetry?.lon ? Number(telemetry.lon).toFixed(7) : '-'}</Grid>
        <Grid item xs={12}><Divider className={classes.divider} /></Grid>
        <Grid item xs={12}><Typography variant='overline'>AMSL/AGL</Typography></Grid>
        <Grid item xs={12}>{telemetry?.alt ? Number(telemetry.alt / 100) : '-'}m {telemetry?.agl ? Number(telemetry.agl / 100) : '-'} m</Grid>
        <Grid item xs={12}><Divider className={classes.divider} /></Grid>
        <Grid item xs={12}><Typography variant='overline'>Speed (Gnd/Air)</Typography></Grid>
        <Grid item xs={12}>{telemetry?.ground_speed ? Number(telemetry?.ground_speed) / 100 : '-'}m/s {telemetry?.air_speed ? Number(telemetry.air_speed) / 100 : '-'}m/s</Grid>
        <Grid item xs={12}><Divider className={classes.divider} /></Grid>
        <Grid item xs={12}><Typography variant='overline'>Yaw/Pitch/Roll</Typography></Grid>
        <Grid item xs={12}>{telemetry?.yaw ? Number(telemetry.yaw).toFixed(1) : '-'}° {telemetry?.pitch ? Number(telemetry.pitch).toFixed(1) : '-'}° {telemetry?.roll ? Number(telemetry.roll).toFixed(1) : '-'}°</Grid>
      </Grid>
    </div>
    <div className={classes.panel + ' ' + classes.rightPanel}>
      <Grid container className={classes.grid}>
        <Grid item xs={12}><Typography variant='overline'>Battery</Typography></Grid>
        <Grid item xs={12}>{telemetry?.batt_capacity || '-'}% ({telemetry?.batt_voltage ? Number(telemetry.batt_voltage / 1000).toFixed(1) : '-'}V)</Grid>
        <Grid item xs={12}><Divider className={classes.divider} /></Grid>
        <Grid item xs={12}><Typography variant='overline'>Flight Mode (FCC)</Typography></Grid>
        <Grid item xs={12}>{telemetry?.fcc_mode || '-'} </Grid>
        <Grid item xs={12}><Divider className={classes.divider} /></Grid>
        <Grid item xs={12}><Typography variant='overline'>Flight Start Time</Typography></Grid>
        <Grid item xs={12}>{telemetry?.start_time ? new Date(telemetry.start_time).toLocaleString() : '-'}</Grid>
        <Grid item xs={12}><Divider className={classes.divider} /></Grid>
        <Grid item xs={12}><Typography variant='overline'>Flight Time</Typography></Grid>
        <Grid item xs={12}>{telemetry?.flight_time ? secToMinSec(telemetry.flight_time) : '-'}</Grid>
        <Grid item xs={12}><Divider className={classes.divider} /></Grid>
        <Grid item xs={12}><Typography variant='overline'>#Sat/Accuracy</Typography></Grid>
        <Grid item xs={12}>{telemetry?.sat_count || '-'} {telemetry?.gps_accuracy || '-'}</Grid>
        <Grid item xs={12}><Divider className={classes.divider} /></Grid>
        <Grid item xs={12}><Typography variant='overline'>Dock (T/O, Land)</Typography></Grid>
        <Grid item xs={12}>{telemetry?.metadata?.takeoff_docking_station_id || '-'}, {telemetry?.metadata?.landing_docking_station_id || '-'}</Grid>
      </Grid>
    </div>
  </>)
})

export default withStyles(styles)(DroneDetailMap)
