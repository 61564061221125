import {
  Grid,
  Typography,
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import theme from '../../utils/site-theme'

// Latest Sample Telemetry
// See https://bitbucket.org/garudarobotics/liveflights-service/src/master/asyncapi.yml

const styles = theme => ({
  grid: {
    padding: theme.spacing(1)
  },
  label: {
    lineHeight: 1
  }
})

function Telemetry({ classes, linkStatus, telemetry }) {

  if (!telemetry) telemetry = {}

  // const droneOnline = !(Object.keys(telemetry).length === 0 || linkStatus !== null)

  const agl = Number.isFinite(telemetry.agl) ? telemetry.agl / 100 : '-'

  let min = '-', sec = '-'
  if (Number.isFinite(telemetry.flight_time)) {
    min = Math.floor(telemetry.flight_time / 60)
    sec = Math.floor(telemetry.flight_time % 60)
    if (min < 10) min = '0' + min
    if (sec < 10) sec = '0' + sec
  }
  const flightTime = min + ':' + sec

  const battCapacity = Number.isFinite(telemetry.batt_capacity) ? telemetry.batt_capacity : '-'
  const battVoltage = Number.isFinite(telemetry.batt_voltage) ? (telemetry.batt_voltage / 1000).toFixed(1) : '-'
  const battCurrent = Number.isFinite(telemetry.batt_current) ? (telemetry.batt_current / 1000).toFixed(1) : '-'

  const pitch = telemetry.pitch ? Number(telemetry.pitch).toFixed(1) : '-'
  const roll  = telemetry.roll  ? Number(telemetry.roll).toFixed(1) : '-'
  const yaw   = telemetry.yaw   ? Number(telemetry.yaw).toFixed(1) : '-'

  const groundSpeed = Number.isFinite(telemetry.ground_speed) ? telemetry.ground_speed / 100 : '-'
  const satCount = Number.isFinite(telemetry.sat_count) ? telemetry.sat_count : '-'
  const hdop = Number.isFinite(telemetry.hdop) ? Math.round(telemetry.hdop * 1.0) / 100 : '-'
  const vdop = Number.isFinite(telemetry.vdop) ? Math.round(telemetry.vdop * 1.0) / 100 : '-'

  function renderTelemetry(label, value, unit, errorValue, warningValue, successValue) {
    let colour
    if (errorValue && value < errorValue)
      colour = theme.palette.common.white
    else if (warningValue && value < warningValue)
      colour = theme.palette.warning.main
    else if (successValue && value < successValue)
      colour = theme.palette.success.main
    else
      colour = theme.palette.common.white

    return (<div style={{ backgroundColor: errorValue && value < errorValue ? theme.palette.error.main : undefined }}>
      <Typography variant='overline' className={classes.label}>{label}</Typography>
      <Typography variant='h6' style={{ color: colour }}>{value} {unit}</Typography>
    </div>)
  }

  return (
    <Grid container>
      <Grid item xs={4}>
        {renderTelemetry('APM State', telemetry.fcc_mode || '-', '')}
      </Grid>
      <Grid item xs={4}>
        {renderTelemetry('AGL', agl, 'm')}
      </Grid>
      <Grid item xs={4}>
        {renderTelemetry('Flight Time', flightTime, '')}
      </Grid>
      <Grid item xs={4}>
        {renderTelemetry('Batt Left', battCapacity, '%', 30, 50, 100)}
      </Grid>
      <Grid item xs={4}>
        {renderTelemetry('Voltage', battVoltage, 'V')}
      </Grid>
      <Grid item xs={4}>
        {renderTelemetry('Current', battCurrent, 'A')}
      </Grid>
      <Grid item xs={4}>
        {renderTelemetry('Pitch', pitch, '˚')}
      </Grid>
      <Grid item xs={4}>
        {renderTelemetry('Roll', roll, '˚')}
      </Grid>
      <Grid item xs={4}>
        {renderTelemetry('Yaw', yaw, '˚')}
      </Grid>
      <Grid item xs={4}>
        {renderTelemetry('Ground speed', groundSpeed, 'm/s')}
      </Grid>
      <Grid item xs={4}>
        {renderTelemetry('Sat Count', satCount, '', 3, 6, 20)}
      </Grid>
      <Grid item xs={4}>
        {renderTelemetry('hdop, vdop', hdop + ', ' + vdop, '')}
      </Grid>
    </Grid>
  )
}

export default withStyles(styles)(Telemetry)
