import { useEffect } from 'react'
import { useInControl } from '../../utils/useFlightStates'
import {
  Grid,
  IconButton,
  Typography,
} from '@material-ui/core'
import {
  ChevronLeft,
  ChevronRight,
  FiberManualRecord,
  KeyboardArrowDown,
  KeyboardArrowUp,
  Stop,
  ZoomIn,
  ZoomOut,
} from '@material-ui/icons'
import { withStyles } from '@material-ui/core/styles'

const styles = theme => ({
  root: {
    flexGrow: 1,
    margin: theme.spacing(1),
    width: theme.spacing(20),
  },
  center: {
    textAlign: 'center'
  },
  text: {
    display: 'flex',
    margin: theme.spacing(2)
  },
  icon: {
    width: theme.spacing(4),
    height: theme.spacing(4),
  }
})

const PITCH_STEP = 5 // pitch discrete controls increments in 5 degrees
const YAW_STEP   = 9 // yaw discrete controls increments in 9 degrees
const ZOOM_STEP  = 1 // zoom discrete controls increments by number of times zoom

// Dynamic Pitch / Yaw values (at high zoom, should pitch and yaw less)
function getPitchStepByZoom(zoom) {
  // ['5.00', '4.00', '3.20', '2.56', '2.05', '1.64', '1.31', '1.05', '0.84', '0.67']
  if (zoom >= 1 && zoom <= 10)
    return PITCH_STEP * Math.pow(0.8, zoom - 1)
  return PITCH_STEP
}
function getYawStepByZoom(zoom) {
  // ['9.00', '7.20', '5.76', '4.61', '3.69', '2.95', '2.36', '1.89', '1.51', '1.21']
  if (zoom >= 1 && zoom <= 10)
    return YAW_STEP * Math.pow(0.8, zoom - 1)
  return YAW_STEP
}

function PayloadControls({
  classes,
  values,
  handlePitch,
  handleYaw,
  handleZoom,
  resetPose,
  handleRecordOnBoard,
  handleRecordOnCloud,
  // handleScreenshot,
}) {
  const inControl = useInControl()
  const zoom = values?.zoom || 0

  useEffect(() => {
    function handleKeyDown(event) {
      switch (event.key) {
        case 'Down':
        case 'ArrowDown':
          event.preventDefault()
          handlePitch(-getPitchStepByZoom(zoom))
          break
        case 'Up':
        case 'ArrowUp':
          event.preventDefault()
          handlePitch(getPitchStepByZoom(zoom))
          break
        case 'Left':
        case 'ArrowLeft':
          event.preventDefault()
          handleYaw(-getYawStepByZoom(zoom))
          break
        case 'Right':
        case 'ArrowRight':
          event.preventDefault()
          handleYaw(getYawStepByZoom(zoom))
          break
        case 'Shift':
          event.preventDefault()
          handleZoom(-ZOOM_STEP)
          break
        case 'Enter':
          event.preventDefault()
          handleZoom(ZOOM_STEP)
          break
        case 'Esc':
        case 'Escape':
          event.preventDefault()
          resetPose()
          break
        default:
      }
    }
    window.addEventListener('keydown', handleKeyDown, true)
    return () => {
      window.removeEventListener('keydown', handleKeyDown, true)
    }
  // eslint-disable-next-line
  }, [])

  const title =
    <div className={classes.center}>
      <Typography noWrap variant='overline'>Payload</Typography>
      <Typography noWrap variant='h6'>{ values.name }</Typography>
    </div>
  
  const pitchValues =
    <div className={classes.center}>
      <Typography noWrap variant='overline'>Pitch (Tilt)</Typography>
      <Typography noWrap variant='h6'>{ values.pitch.toFixed(1) }</Typography>
    </div>

  const yawValues =
    <div className={classes.center}>
      <Typography noWrap variant='overline'>Yaw (Pan)</Typography>
      <Typography noWrap variant='h6'>{ values.yaw.toFixed(1) }</Typography>
    </div>
  
  const zoomValues =
    <div className={classes.center}>
      <Typography noWrap variant='overline'>Zoom</Typography>
      <Typography noWrap variant='h6'>{ values.zoom !== 0 ? values.zoom : '-'}</Typography>
    </div>

  function ControlButton(props) {
    const { onClick, icon, text, color, variant, disabled } = props
    return (
      <div className={classes.center}>
        <IconButton
          color={ color || 'primary' }
          variant={ variant || 'contained'}
          className={classes.button}
          onClick={onClick}
          disabled={disabled}
          >
          { icon }
        </IconButton>
        <Typography variant='subtitle2'>{ text }</Typography>
      </div>
    )
  }
  const pitchUp =
    <ControlButton
      icon={<KeyboardArrowUp className={classes.icon} />}
      text='Up'
      disabled={!inControl}
      onClick={() => handlePitch(getPitchStepByZoom(zoom))} />

  const pitchDown =
    <ControlButton
      icon={<KeyboardArrowDown className={classes.icon} />}
      text='Down'
      disabled={!inControl}
      onClick={() => handlePitch(-getPitchStepByZoom(zoom))} />

  const yawLeft =
    <ControlButton
      icon={<ChevronLeft className={classes.icon} />}
      text='Left'
      disabled={!inControl}
      onClick={() => handleYaw(-getYawStepByZoom(zoom))} />

  const yawRight =
    <ControlButton
      icon={<ChevronRight className={classes.icon} />}
      text='Right'
      disabled={!inControl}
      onClick={() => handleYaw(getYawStepByZoom(zoom))} />
  
  const zoomIn =
    <ControlButton
      icon={<ZoomIn className={classes.icon} />}
      text='In'
      disabled={!inControl || values.zoom === 0}
      onClick={() => handleZoom(ZOOM_STEP)} />

  const zoomOut =
    <ControlButton
      icon={<ZoomOut className={classes.icon} />}
      text='Out'
      disabled={!inControl || values.zoom === 0}
      onClick={() => handleZoom(-ZOOM_STEP)} />

  const recordOnBoard =
    <ControlButton
      color={ values.recordingOnBoard ? 'secondary' : 'primary' }
      icon={ values.recordingOnBoard ?
        <Stop className={classes.icon} /> :
        <FiberManualRecord className={classes.icon} /> }
      text={ values.recordingOnBoard ? 'Stop recording on-board' : 'Record on-board' }
      disabled={!inControl}
      onClick={() => handleRecordOnBoard()} />

  const recordOnCloud =
    <ControlButton
      color={ values.recordingOnCloud ? 'secondary' : 'primary' }
      icon={ values.recordingOnCloud ?
        <Stop className={classes.icon} /> :
        <FiberManualRecord className={classes.icon} /> }
      text={ values.recordingOnCloud ? 'Stop recording on cloud' : 'Record on cloud' }
      disabled={!inControl}
      onClick={() => handleRecordOnCloud()} />

  // const screenshot =
  //   <ControlButton
  //     icon={<CameraAlt className={classes.icon} />}
  //     text='Capture'
  //     onClick={() => handleScreenshot()} />
  
  return (
    <div className={classes.root}>
      <Grid container spacing={1}>
        <Grid item xs={12}>{ title }</Grid>

        <Grid item xs={12}>{ pitchValues }</Grid>
        <Grid item xs={6}>{ pitchUp }</Grid>
        <Grid item xs={6}>{ pitchDown }</Grid>

        <Grid item xs={12}>{ yawValues }</Grid>
        <Grid item xs={6}>{ yawLeft }</Grid>
        <Grid item xs={6}>{ yawRight }</Grid>
    
        <Grid item xs={12}>{ zoomValues  }</Grid>
        <Grid item xs={6}>{ zoomIn }</Grid>
        <Grid item xs={6}>{ zoomOut }</Grid>

        <Grid item xs={6}>{ recordOnBoard }</Grid>
        <Grid item xs={6}>{ recordOnCloud }</Grid>
        {/*<Grid item xs={6}>{ screenshot }</Grid>*/}
      </Grid>
    </div>
  )
}

export default withStyles(styles)(PayloadControls)
