import { useState, useEffect } from 'react'

import { getMissionEdges } from '../../utils/mission'

import L from 'leaflet'
import 'leaflet-polylinedecorator'
import { useMap } from 'react-leaflet'
import theme from '../../utils/site-theme'

const polylineOptions = {
  color: theme.palette.primary.main
}

const decoratorOptions = {
  patterns: [{
    offset: '50%',
    repeat: 0,
    symbol: L.Symbol.arrowHead({
      pixelSize: 15,
      polygon: false,
      pathOptions: {
        color: theme.palette.primary.main,
        stroke: true,
      }
    })
  }]
}

// Current assumption: only one flight plan (with multiple edges)
// TODO: ID each path to keep each path stateful 
//       Prep for multi-drone map views for tactical deconflict

function FlightPlanPath({ commands }) {

  const map = useMap()
  const [ polylines,  setPolylines  ] = useState([])
  const [ decorators, setDecorators ] = useState([])

  useEffect(() => {
    if (!commands)
      return
    console.log('FlightPlanPath commands', commands)

    const edges = getMissionEdges(commands)

    polylines.forEach(line => { line.remove(map) })
    decorators.forEach(deco => { deco.remove(map) })

    const newPolylines = [], newDecorators = []
    edges.forEach(edge => {
      newPolylines.push(L.polyline(edge, polylineOptions).addTo(map))
      newDecorators.push(L.polylineDecorator(edge, decoratorOptions).addTo(map))
    })
    console.log('FlightPlanPath polylines / decorators', newPolylines, newDecorators)

    setPolylines(newPolylines)
    setDecorators(newDecorators)
  // eslint-disable-next-line
  }, [map, commands])

  return null
}

export default FlightPlanPath
