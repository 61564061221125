import { useState } from 'react'
import { getDecodedToken } from '../../api/accounts'
import {
  useGetActiveHandoverRequestsQuery,
  useGetAllFlightStatusQuery,
  useRequestControlMutation,
  useRequestControlOkMutation,
  useTakeControlMutation,
} from '../../api/liveflights'

import WarningDisplay from './WarningDisplay'
import WarningListItem from '../../warnings/Components/WarningListItem'
import ConfirmTakeOffDialog from './ConfirmTakeOffDialog'
import {
  Button,
  CircularProgress,
} from '@material-ui/core'

function SecondaryDisplay({ flight }) {
  const { drone_id, company_id, access_token, user_id } = getDecodedToken()
  const [ requestControl,  requestResult  ] = useRequestControlMutation()
  const [ takeoverControl, takeoverResult ] = useTakeControlMutation()

  function handleClickRequestControl() {
    requestControl({ drone_id })
    .unwrap()
    .then(fulfilled => {
      console.log('requestControl fulfilled', fulfilled)
      console.log('requestControl result', requestResult)
    })
    .catch(rejected => {
      console.log('requestControl rejected', rejected)
      console.log('requestControl result', requestResult)
    })
  }

  function handleClickTakeover(request) {
    takeoverControl(request)
    .unwrap()
    .then(fulfilled => {
      console.log('takeoverControl fulfilled', fulfilled)
      console.log('takeoverControl result', takeoverResult)
    })
    .catch(rejected => {
      console.log('takeoverControl rejected', rejected)
      console.log('takeoverControl result', takeoverResult)
    })
  }

  const { data } = useGetActiveHandoverRequestsQuery({ drone_id, company_id, access_token }, {
    selectFromResult: (res) => {
      if (res.data) {
        res.data = Object.values(res.data).filter((r) => {
          return r.status !== 'completed' 
        })
      }
      return res
    }
  })

  const lastRequest = data ? data[data.length - 1] : null

  let message, severity
  if (lastRequest?.status === 'pending') {
    message = `Waiting for acknowledgement from ${flight?.personnel_roles?.ua_pilot_primary.name}`
    severity = 'MAJOR'
  }
  else if (lastRequest?.status === 'accepted') {
    message = 'Your request has been accepted.'
    severity = 'MINOR'
  }
  else if (lastRequest?.status === 'completed' && lastRequest.requested_by?.user_id === user_id) {
    message = 'You now have control of the UAV'
    severity = 'NO_EFFECT'
  }
  else if (!lastRequest) {
    message = 'You are a secondary pilot in this operation.'
    severity = 'NO_EFFECT'
  }

  return <>
    { message &&
      <WarningListItem
        severity={severity}
        warningMessage={message}
        startingTime={lastRequest?.requested_on / 1000}
        latestTime={(lastRequest?.requested_on || Date.now()) / 1000}>
        { !lastRequest &&
          <Button
            variant='contained'
            color='primary'
            onClick={handleClickRequestControl}>
            Request Control
          </Button>
        }
        { lastRequest && lastRequest?.status === 'pending' && <CircularProgress />}
        { lastRequest && lastRequest?.status === 'accepted' &&
          <Button
            variant='contained'
            color='primary'
            onClick={() => handleClickTakeover(lastRequest)}>
            Take Control
          </Button>
        }
      </WarningListItem>
    }
  </>
}


// Temporary wrapper around WarningsDisplay
function ControlDisplay() {
  const [ showApproveDialog,  setShowApproveDialog  ] = useState(false)
  const [ selectedHandoverId, setSelectedHandoverId ] = useState()

  const { drone_id, company_id, access_token, user_id } = getDecodedToken()

  const { data } = useGetActiveHandoverRequestsQuery({ drone_id, company_id, access_token }, {
    selectFromResult: (res) => {
      if (res.data) {
        // maybe should do in warninglistitem instead
        res.data = Object.values(res.data).filter((r) => r.status !== 'completed')
      }
      return res
    }
  })

  const { data: flightStatusInfo } = useGetAllFlightStatusQuery({
    access_token, company_id, drone_id, only_ongoing_flights: true
  })

  const [ requestControlOk, requestResult ] = useRequestControlOkMutation()

  const requests = data || []

  function handleApprove(requestId) {
    return () => {
      setShowApproveDialog(true)
      setSelectedHandoverId(requestId)
    }
  }

  function handleConfirmApprove(code) {
    setShowApproveDialog(false)

    requestControlOk({
      drone_id,
      handover_id: selectedHandoverId,
      approval_token: code + ''
    })
    .unwrap()
    .then(fulfilled => {
      console.log('requestControlOk fulfilled', fulfilled)
      console.log('requestControlOk result', requestResult)
    })
    .catch(rejected => {
      console.log('requestControlOk rejected', rejected)
      console.log('requestControlOk result', requestResult)
    })
  }

  const lastRequest = requests[requests.length - 1]
  let message, severity

  if (lastRequest?.status === 'pending') {
    message = `${lastRequest?.requested_by.name} requesting for handover of control`
    severity = 'HAZARDOUS'
  }
  else if (lastRequest?.status === 'accepted') {
    if (lastRequest?.approval_expiry < Date.now()) {
      message = 'Handover request timeout'
      severity = 'MAJOR'
    }
    else {
      message = `Waiting for ${lastRequest?.requested_by?.name} to take control`
      severity = 'MINOR'
    }
  }
  else if (lastRequest?.status === 'completed' && lastRequest?.approved_by?.user_id === user_id) {
    message = `${lastRequest?.requested_by?.name} has taken control. You are now Secondary Pilot.`
    severity = 'NO_EFFECT'
  }
  const flight = flightStatusInfo ? flightStatusInfo[drone_id] : null

  const isPrimaryPilot   = flight?.personnel_roles?.ua_pilot_primary.user_id === user_id || false
  const isSecondaryPilot = flight?.personnel_roles?.ua_pilot_secondary.find((pilot) => pilot.user_id === user_id) !== null || false

  if (isSecondaryPilot && !isPrimaryPilot)
    return <SecondaryDisplay flight={flight} />

  if (isPrimaryPilot) {
    if (requests.length > 0 && message) { // refactor this
      return (
        <WarningListItem
          severity={severity}
          warningMessage={message}
          startingTime={lastRequest.updated_on / 1000}
          latestTime={Date.now() / 1000}>
          { lastRequest?.status === 'pending' &&
            <Button
              variant='contained'
              color='primary'
              onClick={handleApprove(lastRequest.handover_id)}>
              Approve
            </Button>
          }
          <ConfirmTakeOffDialog
            actionText='handover control'
            buttonText='Handover Control'
            open={showApproveDialog}
            onConfirm={handleConfirmApprove}
            onClose={() => setShowApproveDialog(false)}
          />
        </WarningListItem>
      )
    } else {
      return <WarningDisplay />
    }
  }

  // Other people (not primary / not secondary)
  // TODO build something read only that people can follow along
  return <WarningDisplay />
}

export default ControlDisplay
