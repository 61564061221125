/*
 * Used to perform map relevant actions
 *
 * Current actions:
 * updateMarkerDirty -> Update the redux store to inform that the map is dirty and pending changes to save
 * discardMissionPlan -> Used to the discard any changes and revert back to the old mission plan
 * createNewMarker -> Used to navigate to a custom waypoint!
 *
 */
import axios from "axios"
import { getDecodedToken } from "../../api/accounts"
export const CREATE_NEW_CUSTOM_MARKER = "CREATE_NEW_CUSTOM_MARKER"
export const UPDATE_MARKER_DIRTY = "UPDATE_MARKER_DIRTY"
export const DISCARD_MISSION_PLAN_CHANGE = "DISCARD_MISSION_PLAN_CHANGE"
export const commandWebApi = process.env.REACT_APP_COMMAND_WEB_API

// Used to create a new marker
export const updateMarkerDirty = isDirty => {
    return dispatch => {
        dispatch({
            type: UPDATE_MARKER_DIRTY,
            isDirty,
        })
    }
}

/*
 *
 * Action that will be called when the user decides to not
 * update an existing flight plan.
 *
 * 1. Update the current map with the original copy.
 * This will involve DOM manipulation of the map after the reducer updates.
 *
 *
 */
export const discardMissionPlan = () => {
    return dispatch => {
        dispatch({
            type: DISCARD_MISSION_PLAN_CHANGE,
            isMapDirty: false,
        })
    }
}

export const createNewMarker = (lat, lng) => {
    const { drone_id } = getDecodedToken()
    return dispatch => {
        const altitude = 30
        axios
            .post(`${commandWebApi}/drone/${drone_id}/navigate`, {
                latitude: lat.toString(),
                // temp bug fix
                latitiude: lat.toString(),
                longitude: lng.toString(),
            })
            .then(resp => {
                console.log("Sent data")
                dispatch({
                    type: CREATE_NEW_CUSTOM_MARKER,
                    geoData: {
                        latitude: lat,
                        // temp bug fix
                        latitiude: lat,
                        longitude: lng,
                        altitude,
                    },
                })
            })
            .catch(err => {
                console.log("error in hold: ", err)
            })
    }
}
