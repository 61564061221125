import store from '../store'
import { getNetworkSpeedCheck } from './utils'
import { sendFlightPlan } from './commandActions'
export const UPDATE_LINK_STATUS = 'UPDATE_LINK_STATUS'
export const UPDATE_TELEMETRY = 'UPDATE_TELEMETRY'
export const UPDATE_DAA_TELEMETRY = 'UPDATE_DAA_TELEMETRY'
export const TELEMETRY_UPDATE_DRONE_STATE = 'TELEMETRY_UPDATE_DRONE_STATE '
export const FIRST_ESTABLISH_TELEMETRY = 'FIRST_ESTABLISH_TELEMETRY'
export const LAST_KNOWN_TELEM_STORAGE = 'last_known_telemetry'

export const updateDaaTelemetry = currTelemetry => {
  const obstacles = currTelemetry.map(el => {
    const { x1, x2, y1, y2, d: distance, t: timestamp } = el
    const w = x2 - x1
    const h = y2 - y1
    return { x1, x2, y1, y2, w, h, distance, timestamp }
  })
  return dispatch => {
    dispatch({
      type: UPDATE_DAA_TELEMETRY,
      obstacles,
    })
  }
}

//
// Called whenever the telemetry data has been updated
//
export const handleTelemetry = currTelemetry => {

  return dispatch => {
    window.localStorage.setItem(LAST_KNOWN_TELEM_STORAGE, JSON.stringify(currTelemetry))
    const { downloadSpeed, uploadSpeed } = getNetworkSpeedCheck()

    // Check if there is any telemetry error
    if (currTelemetry.error && currTelemetry.error === 'Time Out, Lost Connection') {
      dispatch({
        type: TELEMETRY_UPDATE_DRONE_STATE,
        droneState: null, // TODO: Need an "error state" for the UI (which buttons should be enabled?)
      })
      dispatch({
        type: UPDATE_TELEMETRY,
        data: {telemetry: {}},
        networkSpeed: { downloadSpeed, uploadSpeed },
      })
      return dispatch({
        type: UPDATE_LINK_STATUS,
        error: currTelemetry.error,
      })
    }

    // Upon the first telemetry, an API call will have to made to Plex to
    // submit flight plan to Airmap. This will also return a airmap_flight_plan_id.
    const { telemetryEstablished } = store.getState().telemetry
    if (!telemetryEstablished) {
      sendFlightPlan()(dispatch)
      // Update redux store to indicate that a telemetry link has been established
      dispatch({
        type: FIRST_ESTABLISH_TELEMETRY,
      })
    }

    // Update redux state based on telemetry
    //
    // This is how the UI control variables correspond to the Flight State Diagram
    // (EMERGENCY is out of scope for now)
    //
    //      INIT - RTF - LAUNCH - HOVER - MISSION / MOVING / BACKTRACK - LANDING - OG
    // isReady     <->
    // isFlying          <----------------------------------------------------->
    // isTakingOff       <---->
    // isMoving          <---->           <------------------------------------>
    // isLanding                                                         <----->
    // isLanded                                                                    <>
    const { flight_state } = currTelemetry
    let droneState = {}

    if (flight_state === 'INIT') {
      droneState = {
        isReady:     false,
        isFlying:    false,
        isTakingOff: false,
        isMoving:    false,
        isLanding:   false,
        isLanded:    false,
      }
    } else if (flight_state === 'RTF') {
      droneState = {
        isReady:     true,
        isFlying:    false,
        isTakingOff: false,
        isMoving:    false,
        isLanding:   false,
        isLanded:    false,
      }
    } else if (flight_state === 'LAUNCH') {
      // TODO: For takeoff actions not originating from DOC (eg dispatch service)
      droneState = {
        isReady:     false,
        isFlying:    true,
        isTakingOff: true,
        isMoving:    true,
        isLanding:   false,
        isLanded:    false,
      }
    } else if (flight_state === 'HOVER') {
      droneState = {
        isReady:     false,
        isFlying:    true,
        isTakingOff: false,
        isMoving:    false,
        isLanding:   false,
        isLanded:    false,
      }
    } else if (flight_state === 'MISSION' || flight_state === 'MOVING' || flight_state === 'BACKTRACK') {
      droneState = {
        isReady:     false,
        isFlying:    true,
        isTakingOff: false,
        isMoving:    true,
        isLanding:   false,
        isLanded:    false,
      }
    } else if (flight_state === 'LANDING') {
      droneState = {
        isReady:     false,
        isFlying:    true,
        isTakingOff: false,
        isMoving:    true,
        isLanding:   true,
        isLanded:    false,
      }
    } else if (flight_state === 'OG') {
      droneState = {
        isReady:     false,
        isFlying:    false,
        isTakingOff: false,
        isMoving:    false,
        isLanding:   false,
        isLanded:    true,
      }
    }

    // Update the telemetry with the latest drone telemetry
    dispatch({
      type: TELEMETRY_UPDATE_DRONE_STATE,
      droneState,
    })

    window.localStorage.setItem(LAST_KNOWN_TELEM_STORAGE, JSON.stringify(currTelemetry))

    dispatch({
      type: UPDATE_TELEMETRY,
      data: currTelemetry,
      networkSpeed: { downloadSpeed, uploadSpeed },
    })
  }
}
