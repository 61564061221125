/**
 * Critical to ensure that this page is in sync with API
 * https://bitbucket.org/garudarobotics/liveflights-service/src/master/asyncapi.yml
 * (Viewer: https://playground.asyncapi.io)
 */
import { connect } from 'react-redux'
import { isNumber } from '../../utils'
import { getDecodedToken } from '../../api/accounts'

import { withStyles } from '@material-ui/core/styles'
import {
  Button,
  Grid,
  Typography,
} from '@material-ui/core'

const styles = theme => ({
  root: {
    padding: theme.spacing(1),
  },
  outerPanel: {
    padding: theme.spacing(1),
  },
  innerPanel: {
    padding: theme.spacing(1),
    border: '1px solid ' + theme.palette.bluegrey.main,
  },
  title: {
    border: '1px solid ' + theme.palette.bluegrey.main,
    textAlign: 'center',
    marginTop: theme.spacing(2),
  },
  ellipsis: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  link: {
    color: theme.palette.common.white,
  },
})

function msecToDateTime(t) {
  // TODO: Is there a better way to avoid creating many Date objects?
  //       Or should we display the raw data in this screen?
  return new Date(t).toLocaleString()
}

function secToMinSec(s) {
  let min = Math.floor(s / 60)
  let sec = Math.floor(s % 60)
  if (min < 10) min = '0' + min
  if (sec < 10) sec = '0' + sec
  return min + ':' + sec
}

function TelemetryHealth({ classes, telemetry, linkStatus }) {

  const { company_id, user_id, drone_id, access_token } = getDecodedToken()

  const required = '- (req)'
  const optional = '-'

  // Convert the telemetry object for display (developer view)
  // Note: This UI needs to be tolerant to multiple versions of Telemetry
  //       due to lack of care in versioning DroneJSON. When in doubt, refer
  //       to documentation. The following object t is only for visualization
  //       and not a protocol.
  // TODO: Almost all the fields here are wrong - they are written using
  //       https://garudarobotics.github.io/plex-web-api/#telemetry which
  //       has not been updated. Actual documentation for DroneJSON is wanting
  const t = {
    // Flight Status
    isOnline: (
      linkStatus ?
        <Button variant='outlined' size='small' color='secondary'>{linkStatus}</Button> : (
        telemetry && telemetry.error ?
          <Button variant='outlined' size='small' color='secondary'>{telemetry.error}</Button> :
          <Button variant='contained' size='small' color='primary'>Online</Button>
        )
      ),
    dateTime:       telemetry.time ? msecToDateTime(telemetry.time) : required,
    flightTime:     telemetry.flight_time === -1 ? 'no FLIGHT info' :
                    isNumber(telemetry.flight_time) ? secToMinSec(telemetry.flight_time) : required,
    startTime:      telemetry.start_time ? msecToDateTime(telemetry.start_time) : '-',
    apmFlightState: telemetry.fcc_mode || required,
    failsafe:       telemetry.failsafe || '-',

    // Flight Position
    lat:      telemetry.lat === '' ? 'no GPS fix' : (telemetry.lat || required),
    lng:      telemetry.lon === '' ? 'no GPS fix' : (telemetry.lon || required), // TODO: harmonise lon and lng
    alt:      telemetry.alt === -44300 ? 'no ALT info' :
              isNumber(telemetry.alt) ? (telemetry.alt / 100).toFixed(2) : required,
    agl:      telemetry.agl === -44300 ? 'no AGL info' :
              telemetry.agl ? Number(telemetry.agl / 100).toFixed(2) : required,
    satCount: telemetry.sat_count === -1 ? 'no SAT info' :
              isNumber(telemetry.sat_count) ? telemetry.sat_count : required,
    hdop:     telemetry.hdop === -1 ? 'no SAT info' :
              isNumber(telemetry.hdop) ? Math.round(telemetry.hdop * 1.0) / 100 : required,
    vdop:     telemetry.vdop === -1 ? 'no SAT info' :
              isNumber(telemetry.vdop) ? Math.round(telemetry.vdop * 1.0) / 100 : required,

    // Flight Attitude / Velocity
    pitch:       telemetry.pitch === '' ? 'no POSE info' : (telemetry.pitch || required),
    roll:        telemetry.roll  === '' ? 'no POSE info' : (telemetry.roll  || required),
    yaw:         telemetry.yaw   === '' ? 'no POSE info' : (telemetry.yaw   || required),
    airSpeed:    telemetry.air_speed === -1 ? 'no SPEED info' :
                 isNumber(telemetry.air_speed) ? telemetry.air_speed / 100    : required,
    groundSpeed: telemetry.ground_speed === -1 ? 'no SPEED info' :
                 isNumber(telemetry.ground_speed) ? telemetry.ground_speed / 100 : required,
    // velocity_x: telemetry.velocity_x || '-',
    // velocity_y: telemetry.velocity_y || '-',
    // velocity_z: telemetry.velocity_z || '-',

    // Battery Info (from FCC)
    battLife: telemetry.batt_capacity === -1 ? 'no BATT info' :
              isNumber(telemetry.batt_capacity) ? telemetry.batt_capacity : required,
    battVolt: telemetry.batt_voltage === -1 ? 'no BATT info' :
              isNumber(telemetry.batt_voltage) ? (telemetry.batt_voltage / 1000).toFixed(3) : required,
    battCurr: telemetry.batt_current === -1 ? 'no BATT info' :
              isNumber(telemetry.batt_current) ? (telemetry.batt_current / 1000).toFixed(1) : optional,

    // Power Subsystem (from PMS)
    batteries: Array.isArray(telemetry.batteries) ? telemetry.batteries : [],

    // Network Subsystem (from ModemIO)
    networks: Array.isArray(telemetry.networks) ? telemetry.networks : [],

    // CoPilot
    currentConfigId: telemetry.current_config_id || optional,
    flightState:     telemetry.flight_state || required,
    precLandStatus:  '-',
    daaStatus:       '-',
    gccMonitoring:   telemetry?.monitoring ? telemetry?.monitoring['GCC'] : {},
    gcccmMonitoring: telemetry?.monitoring ? telemetry?.monitoring['GCC-CM'] : {},

    // Payload
    cameraPitch:      isNumber(telemetry.camera_pitch) ? telemetry.camera_pitch : optional,
    cameraResolution: telemetry.camera_resolution || optional,

    // Emergency
    homeLat: telemetry.homeLat || '-',
    homeLng: telemetry.homeLng || '-',
    homeAlt: telemetry.homeAlt || '-',

    // Innards
    horizonVersion:   '-',
    plexVersion:      '-',
    copilotVersion:   '-',
    apmVersion:       '-',
    fcuVersion:       '-',
    telemetryDroneId: telemetry.drone_id || '-',
    droneId:          drone_id           || '-',
    companyId:        company_id         || '-',
    pilotUserId:      user_id            || '-',
    commanderUserId:  '-',
    pilotAccessToken: access_token || '-',
  }

  const flightTelemetry = <>
    <Grid item xs={12} className={classes.title}>
      <Typography variant='overline'>Status</Typography>
    </Grid>
    <Grid item xs={7}>Date / Time</Grid>
    <Grid item xs={5}>{t.dateTime}</Grid>
    <Grid item xs={7}>Flight Time</Grid>
    <Grid item xs={5}>{t.flightTime}</Grid>
    <Grid item xs={7}>Start Time</Grid>
    <Grid item xs={5}>{t.startTime}</Grid>
    <Grid item xs={7}>APM Flight State</Grid>
    <Grid item xs={5}>{t.apmFlightState}</Grid>
    <Grid item xs={7}>APM Failsafe</Grid>
    <Grid item xs={5}>{t.failsafe}</Grid>
    <Grid item xs={12} className={classes.title}>
      <Typography variant='overline'>Position</Typography>
    </Grid>
    <Grid item xs={7}>Latitude</Grid>
    <Grid item xs={5}>{t.lat}</Grid>
    <Grid item xs={7}>Longitude</Grid>
    <Grid item xs={5}>{t.lng}</Grid>
    <Grid item xs={7}>Alt AMSL</Grid>
    <Grid item xs={5}>{t.alt} m</Grid>
    <Grid item xs={7}>Alt AGL</Grid>
    <Grid item xs={5}>{t.agl} m</Grid>
    <Grid item xs={7}>Sat Count</Grid>
    <Grid item xs={5}>{t.satCount}</Grid>
    <Grid item xs={7}>HDOP, VDOP</Grid>
    <Grid item xs={5}>{t.hdop}, {t.vdop}</Grid>
    <Grid item xs={12} className={classes.title}>
      <Typography variant='overline'>Attitude / Veloctiy</Typography>
    </Grid>
    <Grid item xs={7}>Pitch</Grid>
    <Grid item xs={5}>{t.pitch}</Grid>
    <Grid item xs={7}>Roll</Grid>
    <Grid item xs={5}>{t.roll}</Grid>
    <Grid item xs={7}>Yaw / Heading</Grid>
    <Grid item xs={5}>{t.yaw}</Grid>
    <Grid item xs={7}>Air Speed</Grid>
    <Grid item xs={5}>{t.airSpeed} m/s</Grid>
    <Grid item xs={7}>Ground Speed</Grid>
    <Grid item xs={5}>{t.groundSpeed} m/s</Grid>
{/*    <Grid item xs={7}>Velocity X</Grid>
    <Grid item xs={5}>{t.velocity_x} m/s</Grid>
    <Grid item xs={7}>Velocity Y</Grid>
    <Grid item xs={5}>{t.velocity_x} m/s</Grid>
    <Grid item xs={7}>Velocity Z</Grid>
    <Grid item xs={5}>{t.velocity_x} m/s</Grid>*/}
  </>

  const powerTelemetry = <>
    <Grid item xs={12} className={classes.title}>
      <Typography variant='overline'>Battery (from FC)</Typography>
    </Grid>
    <Grid item xs={7}>Batt Remaining</Grid>
    <Grid item xs={5}>{t.battLife} %</Grid>
    <Grid item xs={7}>Voltage</Grid>
    <Grid item xs={5}>{t.battVolt} V</Grid>
    <Grid item xs={7}>Current</Grid>
    <Grid item xs={5}>{t.battCurr} A</Grid>
    {/* TODO: Important "metadata" here */}
    {t.batteries.map(b => <>
      <Grid item xs={12} className={classes.title}>
        <Typography variant='overline'>Battery {b.battery_id || required}</Typography>
      </Grid>
      <Grid item xs={7}>Number of Cells</Grid>
      <Grid item xs={5}>{isNumber(b.number_of_cells) ? b.number_of_cells : required}</Grid>
      <Grid item xs={7}>Cell Voltages</Grid>
      <Grid item xs={5}>{Array.isArray(b.voltages) ? b.voltages.join(', ') : required} mV</Grid>
      <Grid item xs={7}>Cell Temperatures</Grid>
      <Grid item xs={5}>{Array.isArray(b.temperatures) ? b.temperatures.join(', ') : required} &deg;C</Grid>
      <Grid item xs={7}>Current</Grid>
      <Grid item xs={5}>{isNumber(b.current) ? b.current : required} mA</Grid>
      <Grid item xs={7}>Charge Consumed</Grid>
      <Grid item xs={5}>{isNumber(b.charge_consumed) ? b.charge_consumed : required} mAh</Grid>
      <Grid item xs={7}>Charge Remaining</Grid>
      <Grid item xs={5}>{isNumber(b.charge_remaining) ? b.charge_remaining : required} mAh</Grid>
      <Grid item xs={7}>Capacity Remaining</Grid>
      <Grid item xs={5}>{isNumber(b.capacity_remaining) ? b.capacity_remaining : required} mAh</Grid>
      <Grid item xs={7}>Time Remaining</Grid>
      <Grid item xs={5}>{isNumber(b.time_remaining) ? secToMinSec(b.time_remaining) : required}</Grid>
      <Grid item xs={7}>Current State (duration)</Grid>
      <Grid item xs={5}>{b.current_state || required} (since {isNumber(b.uptime_in_state) ? secToMinSec(b.uptime_in_state) : required} ago)</Grid>
      <Grid item xs={7}>Health</Grid>
      <Grid item xs={5}>{isNumber(b.battery_health) ? b.battery_health : required} %</Grid>
      <Grid item xs={7}>Health Status</Grid>
      <Grid item xs={5}>{Array.isArray(b.battery_health_status) ? b.battery_health_status.join(', ') : required}</Grid>
      <Grid item xs={7}>Used Cycle</Grid>
      <Grid item xs={5}>{isNumber(b.used_cycles) ? b.used_cycles : required}</Grid>
    </>)}
    {t.batteries.length === 0 && <>
      <Grid item xs={12} className={classes.title}>
        <Typography variant='overline'>No Batteries Found</Typography>
      </Grid>
      <Grid item xs={7}>Number of Cells</Grid>
      <Grid item xs={5}>{required}</Grid>
      <Grid item xs={7}>Cell Voltages</Grid>
      <Grid item xs={5}>{required} mV</Grid>
      <Grid item xs={7}>Cell Temperatures</Grid>
      <Grid item xs={5}>{required} &deg;C</Grid>
      <Grid item xs={7}>Current</Grid>
      <Grid item xs={5}>{required} mA</Grid>
      <Grid item xs={7}>Charge Consumed</Grid>
      <Grid item xs={5}>{required} mAh</Grid>
      <Grid item xs={7}>Charge Remaining</Grid>
      <Grid item xs={5}>{required} mAh</Grid>
      <Grid item xs={7}>Capacity Remaining</Grid>
      <Grid item xs={5}>{required} mAh</Grid>
      <Grid item xs={7}>Time Remaining</Grid>
      <Grid item xs={5}>{required} min:sec</Grid>
      <Grid item xs={7}>Current State (duration)</Grid>
      <Grid item xs={5}>{required} (since min:sec ago)</Grid>
      <Grid item xs={7}>Health</Grid>
      <Grid item xs={5}>{required} %</Grid>
      <Grid item xs={7}>Health Status</Grid>
      <Grid item xs={5}>{required}</Grid>
      <Grid item xs={7}>Used Cycle</Grid>
      <Grid item xs={5}>{required}</Grid>
    </>}
  </>

  const systemTelemetry = <>
    <Grid item xs={12} className={classes.title}>
      <Typography variant='overline'>Garuda CoPilot ROS</Typography>
    </Grid>
    <Grid item xs={7}>Current Config ID</Grid>
    <Grid item xs={5}>{t.currentConfigId}</Grid>
    <Grid item xs={7}>ROS Flight State</Grid>
    <Grid item xs={5}>{t.flightState}</Grid>
    <Grid item xs={12} className={classes.title}>
      <Typography variant='overline'>Precision Landing</Typography>
    </Grid>
    <Grid item xs={7}>Status</Grid>
    <Grid item xs={5}>{t.precLandStatus}</Grid>
    <Grid item xs={12} className={classes.title}>
      <Typography variant='overline'>Detect And Avoid</Typography>
    </Grid>
    <Grid item xs={7}>Status</Grid>
    <Grid item xs={5}>{t.daaStatus}</Grid>
    <Grid item xs={12} className={classes.title}>
      <Typography variant='overline'>Core Module (GCC)</Typography>
    </Grid>
    <OSMonitoring componentStats={t?.gccMonitoring} componentName={'GCC'}/>
  </>

  const networkTelemetry = <>
    <Grid item xs={12} className={classes.title}>
      <Typography variant='overline'>Comms Module (GCC-CM)</Typography>
    </Grid>
    <OSMonitoring componentStats={t?.gcccmMonitoring} componentName={'GCC-CM'}/>
    {/* TODO: Important "metadata" here */}
    {t.networks.map(n => <>
      <Grid item xs={12} className={classes.title}>
        <Typography variant='overline'>IMEI {n.imei || required}</Typography>
      </Grid>
      <Grid item xs={6}>IP Address</Grid>
      <Grid item xs={6}>{n.ip_address || optional}</Grid>
      <Grid item xs={6}>Name</Grid>
      <Grid item xs={6}>{n.name || required}</Grid>
      <Grid item xs={6}>Type</Grid>
      <Grid item xs={6}>{n.type || required}</Grid>
      <Grid item xs={6}>IMSI</Grid>
      <Grid item xs={6}>{n.imsi || required}</Grid>
      <Grid item xs={6}>Status</Grid>
      <Grid item xs={6}>{n.status || required}</Grid>
      <Grid item xs={6}>Cell ID</Grid>
      <Grid item xs={6}>{n.cell_id || required}</Grid>
      <Grid item xs={6}>Signal</Grid>
      <Grid item xs={6}>
        {n.signal_rssi ? 'RSSI ' + n.signal_rssi : null}<br />
        {n.signal_sinr ? 'SINR ' + n.signal_sinr : null}<br />
        {n.signal_rsrq ? 'RSRQ ' + n.signal_rsrq : null}<br />
        {n.signal_rscp ? 'RSCP ' + n.signal_rscp : null}<br />
        {!(n.signal_rssi || n.signal_sinr || n.signal_rsrq || n.signal_rscp) && optional}
      </Grid>
      <Grid item xs={6}>Performance</Grid>
      <Grid item xs={6}>@ <a className={classes.link} href='/network-health'>net health</a></Grid>
    </>)}
    {t.networks.length === 0 && <>
      <Grid item xs={12} className={classes.title}>
        <Typography variant='overline'>No Modems Found</Typography>
      </Grid>
      <Grid item xs={6}>IP Address</Grid>
      <Grid item xs={6}>{optional}</Grid>
      <Grid item xs={6}>Name</Grid>
      <Grid item xs={6}>{required}</Grid>
      <Grid item xs={6}>Type</Grid>
      <Grid item xs={6}>{required}</Grid>
      <Grid item xs={6}>IMSI</Grid>
      <Grid item xs={6}>{required}</Grid>
      <Grid item xs={6}>Status</Grid>
      <Grid item xs={6}>{required}</Grid>
      <Grid item xs={6}>Cell ID</Grid>
      <Grid item xs={6}>{required}</Grid>
      <Grid item xs={6}>Signal</Grid>
      <Grid item xs={6}>{optional}</Grid>
      <Grid item xs={6}>Performance</Grid>
      <Grid item xs={6}>@ <a className={classes.link} href='/network-health'>net health</a></Grid>
    </>}
  </>

  const payloadTelemetry = <>
    <Grid item xs={12} className={classes.title}>
      <Typography variant='overline'>Payload Camera</Typography>
    </Grid>
    <Grid item xs={7}>Brand, Model</Grid>
    <Grid item xs={5}>-, -</Grid>
    <Grid item xs={7}>Status</Grid>
    <Grid item xs={5}>-</Grid>
    <Grid item xs={7}>Pitch</Grid>
    <Grid item xs={5}>{t.cameraPitch}</Grid>
    <Grid item xs={7}>Resolution</Grid>
    <Grid item xs={5}>{t.cameraResolution}</Grid>
    <Grid item xs={12} className={classes.title}>
      <Typography variant='overline'>Winched Payload</Typography>
    </Grid>
    <Grid item xs={7}>Winch Status</Grid>
    <Grid item xs={5}>-</Grid>
    <Grid item xs={7}>Release Status</Grid>
    <Grid item xs={5}>-</Grid>
  </>

  const otherTelemetry = <>
    <Grid item xs={12} className={classes.title}>
      <Typography variant='overline'>Home Location</Typography>
    </Grid>
    <Grid item xs={7}>Latitude</Grid>
    <Grid item xs={5}>{t.homeLat}</Grid>
    <Grid item xs={7}>Longitude</Grid>
    <Grid item xs={5}>{t.homeLng}</Grid>
    <Grid item xs={7}>Altitude (AGL)</Grid>
    <Grid item xs={5}>{t.homeAlt}</Grid>
    <Grid item xs={12} className={classes.title}>
      <Typography variant='overline'>Versions</Typography>
    </Grid>
    <Grid item xs={7}>Plex Horizon</Grid>
    <Grid item xs={5}>{t.horizonVersion}</Grid>
    <Grid item xs={7}>Garuda Plex</Grid>
    <Grid item xs={5}>{t.plexVersion}</Grid>
    <Grid item xs={7}>Garuda CoPilot</Grid>
    <Grid item xs={5}>{t.copilotVersion}</Grid>
    <Grid item xs={7}>Ardupilot</Grid>
    <Grid item xs={5}>{t.apmVersion}</Grid>
    <Grid item xs={7}>Flight Controller</Grid>
    <Grid item xs={5}>{t.fcuVersion}</Grid>
    <Grid item xs={12} className={classes.title}>
      <Typography variant='overline'>Fleet Management</Typography>
    </Grid>
    <Grid item xs={7}>Telem Drone ID</Grid>
    <Grid item xs={5} className={classes.ellipsis}>{t.telemetryDroneId}</Grid>
    <Grid item xs={7}>Drone ID</Grid>
    <Grid item xs={5} className={classes.ellipsis}>{t.droneId}</Grid>
    <Grid item xs={7}>Company ID</Grid>
    <Grid item xs={5} className={classes.ellipsis}>{t.companyId}</Grid>
    <Grid item xs={7}>Pilot User ID</Grid>
    <Grid item xs={5} className={classes.ellipsis}>{t.pilotUserId}</Grid>
    <Grid item xs={7}>Pilot JWT</Grid>
    <Grid item xs={5} className={classes.ellipsis}>{t.pilotAccessToken}</Grid>
    <Grid item xs={7}>Commander ID</Grid>
    <Grid item xs={5} className={classes.ellipsis}>{t.commanderUserId}</Grid>
  </>

  return (
    <Grid container className={classes.root}>
      <Grid item xs={12}>
          <Typography variant='overline'>Telemetry Data {t.isOnline}</Typography>
      </Grid>
      <Grid item xs={2} className={classes.outerPanel}>
        <Grid container className={classes.innerPanel}>
          <Grid item xs={12}>
            <Typography variant='overline'>Flight Controller</Typography>
          </Grid>
          { flightTelemetry }
        </Grid>
      </Grid>
      <Grid item xs={2} className={classes.outerPanel}>
        <Grid container className={classes.innerPanel}>
          <Grid item xs={12}>
            <Typography variant='overline'>On-board System</Typography>
          </Grid>
          { systemTelemetry }
        </Grid>
      </Grid>
      <Grid item xs={2} className={classes.outerPanel}>
        <Grid container className={classes.innerPanel}>
          <Grid item xs={12}>
            <Typography variant='overline'>Power Subsystem</Typography>
          </Grid>
          { powerTelemetry }
        </Grid>
      </Grid>
      <Grid item xs={2} className={classes.outerPanel}>
        <Grid container className={classes.innerPanel}>
          <Grid item xs={12}>
            <Typography variant='overline'>Network Subsystem</Typography>
          </Grid>
          { networkTelemetry }
        </Grid>
      </Grid>
      <Grid item xs={2} className={classes.outerPanel}>
        <Grid container className={classes.innerPanel}>
          <Grid item xs={12}>
            <Typography variant='overline'>Payload Subsystem</Typography>
          </Grid>
          { payloadTelemetry }
        </Grid>
      </Grid>
      <Grid item xs={2} className={classes.outerPanel}>
        <Grid container className={classes.innerPanel}>
          <Grid item xs={12}>
            <Typography variant='overline'>Others</Typography>
          </Grid>
          { otherTelemetry }
        </Grid>
      </Grid>
    </Grid>
  )
}

function KBtoGB(value) {
  if (isNaN(value))
    return '-'
  return (value / (1024 * 1024)).toFixed(2)
}

function OSMonitoring({ componentStats, componentName }) {
  return <>
    <Grid item xs={7}>CPU Utilisation:</Grid>
    { componentStats?.cpus?.map((cpu, index) => (
      <Grid item xs={12} style={{ textAlign: 'center' }}>
        CPU {index}: {cpu.usage} %
      </Grid>
    )) || '- %'}
    <Grid item xs={7}>Temperature</Grid>
    <Grid item xs={5}>
      { componentStats?.temp ? componentStats?.temp['CPU'] : '-'} &deg;C
    </Grid>
    <Grid item xs={7}>RAM Utilisation</Grid>
    <Grid item xs={5}>
      { Math.round(componentStats?.ram_used / componentStats?.ram_total * 100) || '-' } %
      ({ KBtoGB(componentStats?.ram_used) } / { KBtoGB(componentStats?.ram_total) } GB)
    </Grid>
    <Grid item xs={7}>Disk Utilisation</Grid>
    <Grid item xs={5}>
      { Math.round(componentStats?.disk_used / componentStats?.disk_total * 100) || '-'} %
      ({ KBtoGB(componentStats?.disk_used) } / { KBtoGB(componentStats?.disk_total) } GB)
    </Grid>
    <Grid item xs={7}>Swap Utilisation</Grid>
    <Grid item xs={5}>
      { Math.round(componentStats?.swap_used / componentStats?.swap_total * 100) || '-'} %
      ({ KBtoGB(componentStats?.swap_used) } / { KBtoGB(componentStats?.swap_total)} GB)
    </Grid>
    {componentName === 'GCC-CM' && <>
      <Grid item xs={7}>GPU Utilisation</Grid>
      <Grid item xs={5}>{componentStats?.gpu_usage}%</Grid>
    </>}
  </>
}

const mapStateToProps = state => ({
  telemetry:  state.telemetry.telemetry,
  linkStatus: state.telemetry.linkStatus,
})

export default connect(mapStateToProps, {})(withStyles(styles)(TelemetryHealth))
