import axios from 'axios'
import store from '../store'
import { sendFlightPlan } from './commandActions'

import { LAST_KNOWN_TELEM_STORAGE } from './telemetryActions'
// Used to choose the mission plan to the redux store for use in live screen
export const CHOOSE_MISSION = 'CHOOSE_MISSION'
// Actions used to retrieve flight plans from Plex Core API
// LOADING -> Current loading and retrieving flight plan from API
// SUCCESS -> Successfully retrieved mission
// FAILURE -> FAiled to retrieve mission due to error in calling API
export const RETRIEVE_MISSIONS_LOADING = 'RETRIEVE_MISSIONS_LOADING'
export const RETRIEVE_MISSIONS_SUCCESS = 'RETRIEVE_MISSIONS_SUCCESS'
export const RETRIEVE_MISSIONS_FAILURE = 'RETRIEVE_MISSIONS_FAILURE'
// Actions used to signify submission of flight plan to airmap in order
// to get the airmap flight id
// IN_PROGRESS -> Signifiy submission in progress
// SUCCESS ->  Signify successfuly submission of flight plan
// ERROR -> Signify error in submitting flight plan
export const SUBMIT_TO_AIRMAP_IN_PROGRESS = 'SUBMIT_TO_AIRMAP_IN_PROGRESS'
export const SUBMIT_TO_AIRMAP_SUCCESS = 'SUBMIT_TO_AIRMAP_SUCCESS'
export const SUBMIT_TO_AIRMAP_ERROR = 'SUBMIT_TO_AIRMAP_ERROR'
export const CLOSE_AIRMAP_SUBMIT_DIALOG = 'CLOSE_AIRMAP_SUBMIT_DIALOG'

export const FLIGHT_PLAN_SENT = 'FLIGHT_PLAN_SENT'
export const FLIGHT_PLAN_SENT_STORAGE = 'FLIGHT_PLAN_SENT_STORAGE'
export const RESET_MISSION = 'RESET_MISSION'

// The key to store the value in local storage
const MISSION_STORAGE = 'MISSION'
const FLIGHT_PLANS_API = process.env.REACT_APP_FLIGHT_PLANS_API

// Reset Mission by removing all local storage information
export const resetMission = () => {
  return dispatch => {
    window.localStorage.removeItem(FLIGHT_PLAN_SENT_STORAGE)
    window.localStorage.removeItem(LAST_KNOWN_TELEM_STORAGE)
    window.localStorage.removeItem(MISSION_STORAGE)
    dispatch({
      type: RESET_MISSION,
    })
  }
}

// TODO: What is this?
export const submitAlertMission = (missionObj, airmapFlightId) => {
    return dispatch => {
        chooseMission(missionObj, dispatch, airmapFlightId)
    }
}

export const chooseMission = (missionObj, dispatch = false, airmapFlightId) => {
    // Verify if the mission is a valid one before choosing
    if (!missionObj.droneId || !missionObj.route || missionObj.length === 0) {
      console.log('Invalid mission, ignoring', missionObj)
      return
    }
    console.log('MissionObj: ', missionObj)

    missionObj = {
        ...missionObj,
        route: missionObj.route.filter(obj => obj.id !== '178'),
    }
    const mission = {
        ...missionObj,
        airmapFlightId,
        pilot: missionObj.coPilot,
        commander: missionObj.leadPilot,
    }
    console.log('Choosing mission: ', mission)

    // If we are here, this means that the API call is successful.
    // 1) Send a dispatch request to update the redux store with the current flight plan.
    // 2) Update the local storage with the flight plan. Important to ensure that it persists
    //    across browser pages.
    if (dispatch) {
        dispatch({
            type: CHOOSE_MISSION,
            mission,
        })
        // In case the first telemetry has already been established, we set the mission here as well
        sendFlightPlan()(dispatch)
    }

    // Remove the old storage information before starting the mission
    window.localStorage.removeItem(FLIGHT_PLAN_SENT_STORAGE)
    window.localStorage.removeItem(LAST_KNOWN_TELEM_STORAGE)
    window.localStorage.removeItem('MISSION_HAS_STARTED') // deprecated

    // Update the local storage with new mission
    window.localStorage.setItem(MISSION_STORAGE, JSON.stringify(mission))
}

// Deprecated action - to rename to requestFlightAuthorization
export const submitToAirmap = missionObj => requestFlightAuthorization(missionObj)

//
// New action name to be UTM agnostic (must support AirMap, OneSky, and CFMS)
// TODO: make this end point (flight_plans/:id/submit) generic by adding UTM choice
// TODO: Remove all hardcoding
//
export const requestFlightAuthorization = missionObj => {
    console.log('Mission obj ', missionObj)
    console.log('Store for airmap: ', store.getState())

    const {
        settings: { settings },
    } = store.getState()
    const { useAirmap } = settings
    const { flightPlanId } = missionObj
    const SUBMIT_TO_AIRMAP_FLAG = useAirmap
    console.log('Airmap Flag: ', SUBMIT_TO_AIRMAP_FLAG)
    return dispatch => {
        if (!SUBMIT_TO_AIRMAP_FLAG) {
            dispatch({
                type: SUBMIT_TO_AIRMAP_SUCCESS
            })
            chooseMission(missionObj, dispatch, '111')
        } else {
            dispatch({
                type: SUBMIT_TO_AIRMAP_IN_PROGRESS,
            })
            // Make API call to the submit flight plan API to airmap via Plex
            // Flight duration will be set to 120 minutes.
            axios
                .post(`${FLIGHT_PLANS_API}/${flightPlanId}/submit`, {
                    flight_duration: 120,
                })
                .then(resp => {
                    dispatch({
                        type: SUBMIT_TO_AIRMAP_SUCCESS,
                        resp,
                    })
                    const {
                        data: { errors, success },
                    } = resp
                    // Display error message if API call to Plex was
                    // unsuccessful.
                    // MOST COMMON CAUSE: Duplicate flight plan
                    if (!success) {
                        return dispatch({
                            type: SUBMIT_TO_AIRMAP_ERROR,
                            err: errors[0].data.message,
                        })
                    }
                    const {
                        data: { data: response },
                    } = resp
                    const { airmap_flight_id: airmapFlightId } = response
                    chooseMission(missionObj, dispatch, airmapFlightId)
                })
                .catch(err => {
                    // Submit error message to airmap
                    dispatch({
                        type: SUBMIT_TO_AIRMAP_ERROR,
                        err: err.message,
                    })
                    console.log('Error: ', err.message)
                })
        }
    }
}

/*
 *
 * Update the flight plan
 * @param: routePath -> Graph representation of the current updated flight plan.
 * 1. Send the latest flight plan to drone
 * to update their existing mission plan.
 *
 * 2. Send the updated flight plan to plex so that
 * the pilot can change this route the next time.
 *
 * 3. Mark the map as not dirty after both updates have been successful
 *
 *
 *
 */
// export const updateMissionPlan = routePath => {
    // const { companyId, droneId } = getAuthInfo()
    // Before sending the route information to
    // the various endpoints. We need to transform routePath
    // to the relevant flight plan format.
    // return dispatch => {
        // const newMissionPlan = graphToWaypoint(routePath)
        // console.log('new mission plan: ', newMissionPlan)
        // const existingMission = JSON.parse(window.localStorage.getItem(MISSION_STORAGE))
        // const newMissionJSON = {
        //     ...existingMission,
        //     route: newMissionPlan,
        // }
        // console.log('New mission json: ', newMissionJSON)
        // dispatch({
        //     type: UPDATE_MISSION_PLAN,
        //     mission: newMissionPlan,
        // })
        // window.localStorage.setItem(MISSION_STORAGE, JSON.stringify(newMissionJSON))
    // }
// }

// When choosing a new misson, we need to do two things
// 1. Update the redux store with the new mission JSON
// 2. Side-effect of updating the current mission string in local storage
export const chooseNewMission = mission => {
    return dispatch => {
        mission = { ...mission, pilot: 'Justin', commander: 'Deddy Marzuki' }
        console.log('Preparing to update ', MISSION_STORAGE, mission)
        dispatch({
            type: CHOOSE_MISSION,
            mission,
        })
        // Update the local storage with new mission
        window.localStorage.setItem(MISSION_STORAGE, JSON.stringify(mission))
    }
}

// Retrieve missions
export const retrieveMission = () => {
    return dispatch => {
        // Get list of flight plans from Garuda Plex.
        axios
            .get(`${FLIGHT_PLANS_API}/`)
            .then(resp => {
                // console.log('Successfully retrieve missions: ', resp)
                const { data: flightPlanResp } = resp
                const { success, data } = flightPlanResp
                // Dispatch the action to reducer together.
                if (success) {
                    dispatch({
                        type: RETRIEVE_MISSIONS_SUCCESS,
                        missionList: data.map(obj => {
                            return {
                                ...obj,
                                // Filter the flight plan by removing commands with the id '178'.
                                plan: {
                                    ...obj.plan,
                                },
                            }
                        }),
                    })
                } else {
                    dispatch({
                        type: RETRIEVE_MISSIONS_FAILURE,
                        error: 'No missions found for company',
                    })
                }
            })
            .catch(err => {
                console.error('Error in retrieving missions', err)
                dispatch({
                    type: RETRIEVE_MISSIONS_FAILURE,
                    error: err,
                })
            })
    }
}

// Action dispatcher used to close the airmap dialog.
export const closeAirmapSubmitDialog = () => {
    return dispatch => {
        dispatch({
            type: CLOSE_AIRMAP_SUBMIT_DIALOG,
        })
    }
}
