/*
 * Renders a map using Leaflet to view the current drone position, status, and selected mission.
 * The selected mission's operating area, flight path, emergency landing zones, and waypoints will be displayed.
 */
import { useState, useEffect } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { navigateToWaypoint } from '../modules/actions/commandActions'
import { getAllDeployments }  from '../modules/actions/deployment'

import CommonLayers from '../components/Map/CommonLayers'

import { parseMissionCommands } from '../utils/mission'
import FlightPlanMarkers from '../live/maps/FlightPlanMarkers'
import FlightPlanPath    from '../live/maps/FlightPlanPath'

import CfmsTrackMarkers     from './components/CfmsTrackMarkers'
import CfmsViolationMarkers from './components/CfmsViolationMarkers'
import AreaOfOperations     from './components/AreaOfOperations'
import DockingStations      from './components/DockingStations'
import DomeCameraMarker     from './components/DomeCameraMarker'
import DroneMarker          from './components/DroneMarker'
import FlyHereControls      from './components/FlyHereControls'
import FreeFlightArea       from './components/FreeFlightArea'
import MapControls          from './components/MapControls'
import CfmsControls         from './components/CfmsControls'
import NoFlyZones           from './components/NoFlyZones'

import {
  GeoJSON,
  LayerGroup,
  MapContainer,
  ZoomControl,
  ScaleControl,
} from 'react-leaflet'
// Needed to define 100% width and height for the leaflet container created by Leaflet
import '../static/css/leaflet.css'

import {
  Snackbar
} from '@material-ui/core'
import MuiAlert from '@material-ui/lab/Alert'
import { withStyles } from '@material-ui/core/styles'

import {
  DEFAULT_MAP_CENTER
} from '../utils/site-maps'

import { usePrevious } from '../utils/usePrevious'

function Alert(props) {
  return <MuiAlert elevation={6} variant='filled' {...props} />;
}

const DEFAULT_FLYHERE_AGL  = 10.00 // meters
const DEFAULT_FLYHERE_HEAD = 0     // north

const styles = theme => ({
  mapContainerWithTopBottomControls: {
    width: '100%',
    height: 'calc(100% - ' + theme.spacing(12) + 'px)',
  },
  mapContainerWithTopControls: {
    width: '100%',
    height: 'calc(100% - ' + theme.spacing(6) + 'px)',
  },
  mapContainerWithoutControls: {
    width: '100%',
  },
  geofence: {
    fill: theme.palette.primary.main,
    stroke: theme.palette.primary.main,
  },
  elzs: {
    fill: theme.palette.error.main,
    stroke: theme.palette.error.main,
    strokeWidth: 2
  },
  elzsSelected: {
    fill: theme.palette.error.main,
    stroke: theme.palette.error.main,
    strokeWidth: 4
  },
  elzPath: {
    stroke: theme.palette.error.main,
    strokeWidth: 2
  },
  snackbar: {
    zIndex: 1000,
  }
})

function LiveMap({
  docks,
  mission,
  deployments,
  telemetry,
  targetGeoJson,
  latestWarning,
  classes,
  elzs,
  cfmsTracks,
  cfmsViolations,
  onCfmsUasSelected,
  navigateToWaypoint,
  getAllDeployments,
  showControls,
  hideZoomControls,
  hideDomeCamera,
  showCfmsControls,
  emergencyLandingAreas,
  ...props
}) {
  // TODO: Change lon back to lng for the whole application to keep it consistent with
  //       other front end map tech (leaflet/gmaps etc.). Do conversions in adpaters.
  const { lat, lon, alt, agl, yaw } = telemetry

  const [ commands,  setCommands  ] = useState(undefined)
  const prevMission = usePrevious(mission)

  const [ showNfz,   setShowNfz   ] = useState(false)
  const [ showElz,   setShowElz   ] = useState(false)
  const [ selectingLocationToFly, setSelectingLocationToFly ] = useState(false)
  const [ flyHereLocation, setFlyHereLocation ] = useState(null)
  const [ flyHereAltitude, setFlyHereAltitude ] = useState(DEFAULT_FLYHERE_AGL)
  const [ flyHereHeading,  setFlyHereHeading  ] = useState(DEFAULT_FLYHERE_HEAD)
  const [ snackbarOpen,    setSnackbarOpen    ] = useState(false)
  const [ freeFlightAreasArray, setFreeFlightAreasArray ] = useState([])
  const [ fixateOnDrone, setFixateOnDrone ] = useState(false)

  const [ showCfmsNfz,    setShowCfmsNfz    ] = useState(false)
  const [ showCfmsTracks, setShowCfmsTracks ] = useState(false)
  const [ showCfmsViolations, setShowCfmsViolations ] = useState(false)

  useEffect(() => {
    if ((!prevMission?.route && mission?.route?.length > 0) || mission?.flightPlanId !== prevMission?.flightPlanId) {
      const parsedMission = parseMissionCommands(mission.route)
      setCommands(parsedMission)

      console.log('setCommands parsedMission', parsedMission)
    }
  // eslint-disable-next-line
  }, [mission])

  useEffect(() => {
    console.log('deployment changed', deployments)
    if (!(deployments?.length > 0))
      return
    const ffaArray = []
    deployments.forEach(d => {
      if (d.free_flight_areas)
        ffaArray.push(d.free_flight_areas)
    })
    setFreeFlightAreasArray(ffaArray)
  }, [deployments])

  function handleEnableFlyHere() {
    setSelectingLocationToFly(true)
    // Update Free Flying Area:
    // i)  if mission is selected, only for that mission // TODO later
    // ii) if no mission is selected, then from all missions // this is the one that's working
    getAllDeployments()
  }

  function handleToggleElz() {
    setShowElz(!showElz)

    // TODO: Actually display Emergency Landing Zones on the Map
  }

  function handleCancelFlyHere() {
    setSelectingLocationToFly(false)
    setFlyHereLocation(null)
  }

  function handleAltitudeChanged(agl) {
    setFlyHereAltitude(agl)
  }

  function handleHeadingChanged(head) {
    setFlyHereHeading(head)
  }

  function handleZoomToMission() {
    // Cheat: update the pointer to commands to trick bounds to refresh
    if (commands)
      setCommands([ ...commands ])
  }

  function handleSelectFlyHere(latlng) {
    setFlyHereLocation(latlng)
  }

  function handleConfirmFlyHere() {
    navigateToWaypoint({
      lat: flyHereLocation.lat,
      lng: flyHereLocation.lng,
      alt: flyHereAltitude,
      head: flyHereHeading,
    })
    setSelectingLocationToFly(false)
    setFlyHereLocation(null)
    setSnackbarOpen(true)
  }

  function handleCloseSnackbar() {
    setSnackbarOpen(false)
  }

  function handleFixateOnDrone() {
    setFixateOnDrone(!fixateOnDrone)
  }

  const targetGeoJsonLayer =
    <GeoJSON
      key={JSON.stringify(targetGeoJson)}
      data={targetGeoJson}
      className={classes.geofence}
    />

  const elzTargetPathLayer = telemetry?.elz_target_path &&
    <GeoJSON
      key={JSON.stringify(telemetry.elz_target_path)}
      data={telemetry.elz_target_path}
      className={classes.elzPath}
    />

  const elzLayers = showElz && emergencyLandingAreas?.features?.map(elz => (
    <GeoJSON
      key={JSON.stringify(elz)}
      data={elz}
      className={elz?.properties.highlighted ? classes.elzsSelected : (elz.geometry.type === 'LineString' ? classes.elzPath : classes.elzs)}
    />
  ))

  const dronePosition = lat && lon ? {
    lat: parseFloat(lat),
    lng: parseFloat(lon),
    alt: alt / 100, // change from cm to m
    agl: agl / 100, // change from cm to m
    yaw: parseFloat(yaw),
  } : undefined

  const droneMarkerLayer = dronePosition &&
    <DroneMarker
      lat={dronePosition.lat}
      lng={dronePosition.lng}
      yaw={dronePosition.yaw}
      fixateOnDrone={fixateOnDrone}
      showGuideLines={true}
      severity={latestWarning ? latestWarning.severity : 'NORMAL'}
      onClick={() => props.onDroneSelected(telemetry.drone_id)}
    />

  const flyHereSnackbar = snackbarOpen ?
    <Snackbar open={snackbarOpen}
      autoHideDuration={10000}
      onClose={handleCloseSnackbar}
      className={classes.snackbar}>
      <Alert onClose={handleCloseSnackbar} severity='success'>
        Navigate Command Issued
      </Alert>
    </Snackbar> : null

  const mapContainerClass = showControls ? (showCfmsControls ?
      classes.mapContainerWithTopBottomControls :
      classes.mapContainerWithTopControls) :
      classes.mapContainerWithoutControls

  return (<>
    { showControls && <MapControls
      onZoomToMission={handleZoomToMission}
      onEnableFlyHere={handleEnableFlyHere}
      onCancelFlyHere={handleCancelFlyHere}
      onConfirmFlyHere={handleConfirmFlyHere}
      selectingLocationToFly={selectingLocationToFly}
      onToggleNfz={() => { setShowNfz(!showNfz) }}
      showNfz={showNfz}
      onToggleElz={handleToggleElz}
      showElz={showElz}
      onFixateOnDrone={handleFixateOnDrone}
      fixateOnDrone={fixateOnDrone}
      commands={commands}
    /> }
    <div className={mapContainerClass}>
      <MapContainer
        // MapContainer props are immutable. Changing them after the 1st time it's set has no effect.
        center={dronePosition ? [dronePosition.lat, dronePosition.lng] : DEFAULT_MAP_CENTER}
        zoom={13}
        zoomControl={false}
        maxZoom={22}
        // Don't set bounds declaratively, as this will trigger refitting of bounds on every prop change.
        // Instead, call `fitBounds` programmatically.
        // bounds={this.bounds}
        {...props}>
        { !hideZoomControls &&
          <ZoomControl position='topleft' />
        }
        <ScaleControl position='bottomleft' imperial={false} />
        <CommonLayers />
        { (showNfz || showCfmsNfz) &&
          <NoFlyZones showNfz={showNfz} showCfmsNfz={showCfmsNfz} />
        }
        { docks &&
          <DockingStations docks={docks} telemetry={telemetry} />
        }
        { !hideDomeCamera &&
          <DomeCameraMarker />
        }
        <CfmsTrackMarkers
          cfmsTracks={cfmsTracks}
          showCfmsTracks={showCfmsTracks}
          onClick={onCfmsUasSelected}
        />
        <CfmsViolationMarkers
          cfmsViolations={cfmsViolations}
          showCfmsViolations={showCfmsViolations}
          // onClick={?}
        />
        { commands && <>
          <AreaOfOperations commands={commands} />
          <LayerGroup>
            <FlightPlanPath commands={commands} />
            <FlightPlanMarkers commands={commands} />
            { elzLayers }
            { droneMarkerLayer }
            { targetGeoJsonLayer }
            { elzTargetPathLayer }
          </LayerGroup>
        </>}
        { freeFlightAreasArray.length > 0 &&
          <FreeFlightArea areas={freeFlightAreasArray} /> }
        { selectingLocationToFly &&
          <FlyHereControls
            currentLocation={dronePosition}
            commands={commands}
            selectedLocation={flyHereLocation}
            onSelectLocation={handleSelectFlyHere}
            onChangeAltitude={handleAltitudeChanged}
            onChangeHeading={handleHeadingChanged}
            docks={docks} />
        }
      </MapContainer>
      { flyHereSnackbar }
    </div>
    { showCfmsControls && <CfmsControls
      showCfmsNfz={showCfmsNfz}
      onToggleCfmsNfzs={() => { setShowCfmsNfz(!showCfmsNfz) }}
      showCfmsTracks={showCfmsTracks}
      onToggleCfmsTracks={() => { setShowCfmsTracks(!showCfmsTracks) }}
      showCfmsViolations={showCfmsViolations}
      onToggleCfmsViolations={() => { setShowCfmsViolations(!showCfmsViolations) }}
    /> }
  </>)
}

const mapDispatchToProps = dispatch => {
  return bindActionCreators({
    navigateToWaypoint,
    getAllDeployments,
  }, dispatch)
}

export default connect(null, mapDispatchToProps)(withStyles(styles)(LiveMap))
