import { useState, useEffect, useMemo } from 'react'

import moment from 'moment' // TODO: DEPRECATE and migrate to date-fns

import rulesToPolygonProps from '../../utils/rulesToPolygonProps'
import NfzPolygon   from './NfzPolygon'
import NfzPoint     from './NfzPoint'
import VesselMarker from './VesselMarker'

if (!process.env.REACT_APP_AIRSPACE_WEB_API)
  throw Error('Environment Variable REACT_APP_AIRSPACE_WEB_API is missing')
const AIRSPACE_API = process.env.REACT_APP_AIRSPACE_WEB_API

const showVessels = false // for alignment with MDF after PSA Marine project goes somewhere

function NoFlyZones({ showNfz, showCfmsNfz }) {

  const [ validNfzs, setValidNfzs ] = useState([])

  const defaultNfzValidity = useMemo(() => {
    const start = moment().startOf('day').unix()
    const end   = moment().add(7, 'days').startOf('day').unix()
    return { start, end }
  }, [])

  useEffect(() => {
    async function fetchNfz() {
      try {
        const { start, end } = defaultNfzValidity
        fetch(`${AIRSPACE_API}/nfzs/?validity_start=${start}&validity_end=${end}`)
        .then(response => {
          if (response.ok)
            return response.json()
          throw new Error('Error in loading NFZs')
        })
        .then(result => {
          const valid_nfzs = result.data.nfzs?.filter(nfz => (nfz.status === 'active')) || []
          console.log('fetched NFZ', valid_nfzs)
          // mutate the objects for display
          const modified_nfzs = []
          valid_nfzs.forEach(nfz => {
            nfz.nfz_type = nfz.type
            nfz.type = 'Feature'
            nfz.properties = rulesToPolygonProps(nfz.restriction)
            modified_nfzs.push(nfz)
          })
          setValidNfzs(modified_nfzs)
        })
        .catch(error => {
          console.error('Error loading NFZs:', error)
          // TODO: Create snackbar for errors
        })
      } catch(error) {
        setValidNfzs([])
      }
    }
    fetchNfz()
  // eslint-disable-next-line
  }, [])

  if (!showNfz && !showCfmsNfz)
    return null

  let geoJsonArray = []

  if (showCfmsNfz) {
    validNfzs.forEach(nfz => {
      if (nfz.owner.company_id === 'caas' || nfz.nfz_type === 'temp')
        geoJsonArray.push(nfz)
    })
  }

  if (showNfz) {
    validNfzs.forEach(nfz => {
      if (nfz.owner.company_id !== 'caas' && nfz.nfz_type !== 'temp')
        geoJsonArray.push(nfz)
    })
  }
  // console.log('Total NFZ GeoJsons', geoJsonArray)

  return (<>
    { geoJsonArray.map(geojson => {
      if (geojson.type === 'vessel') {
        if (showVessels)
          return <VesselMarker
            key={geojson.nfz_id}
            vesselGeoJson={geojson}
          />
        else
          return null
      }
      if (geojson.geometry.type === 'Point') {
        return <NfzPoint
          key={geojson.nfz_id}
          nfzGeoJson={geojson}
          nfzValidity={defaultNfzValidity}
        />
      }
      return <NfzPolygon
        key={geojson.nfz_id}
        nfzGeoJson={geojson}
        nfzValidity={defaultNfzValidity}
        geoJsonArray={geoJsonArray}
      />
    }) }
  </>)
}

export default NoFlyZones