import React from 'react'

import Header       from '../components/Header'
import DroneHealth  from './DroneHealth'

import {
  CssBaseline,
  Grid,
  Paper,
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'

const styles = theme => ({
  root: {
    position: 'absolute',
    top: theme.spacing(6),
    bottom: 0,
  },
  paper: {
    paddingBottom: 20,
    margin: 20,
  },
  panel: {
    flex: 1,
    height: `calc(100% - ${theme.spacing(6)})`
  },
})

function SysHealth({ classes }) {
  return (
    <>
      <CssBaseline />
      <Header />
      <Grid className={classes.root} container direction={'column'}>
        <Grid container className={classes.panel}>
          <Grid item xs={12}>
            <Grid container justifyContent={'center'}>
              <Grid item xs={4}>
                <Paper className={classes.paper}>
                  <DroneHealth />
                </Paper>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  )
}

export default withStyles(styles)(SysHealth)
