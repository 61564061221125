import { createRoot }      from 'react-dom/client'
import { Router }          from 'react-router-dom'
import { Provider }        from 'react-redux'
import { ConnectedRouter } from 'connected-react-router'
import { OidcProvider }    from 'redux-oidc'
import store, { history }  from './modules/store'
import userManager         from './utils/userManager'
import App from './App'

import './static/css/index.css'

createRoot(document.getElementById('root')).render(
  <Provider store={store}>
    <OidcProvider store={store} userManager={userManager}>
      <ConnectedRouter history={history}>
        <Router history={history}>
          <App />
        </Router>
      </ConnectedRouter>
    </OidcProvider>
  </Provider> 
)
