import { useEffect, useState } from 'react'
import { connect } from 'react-redux'

import { getDecodedToken } from '../../api/accounts'

import {
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
} from '@material-ui/core'
import {
  SportsEsports,
  WifiOff,
} from '@material-ui/icons'
import { withStyles } from '@material-ui/core/styles'

import WSContainer from '../../components/WSContainer'

const FLIGHTSTATUS_WS = process.env.REACT_APP_FLIGHT_STATUS_WS

const styles = theme => ({
  container: {
    border: '1px solid ' + theme.palette.bluegrey.dark,
  },
  selectedOnline: {
    color: theme.palette.primary.main
  },
  selectedOffline: {
    color: theme.palette.secondary.main
  },
})

const DroneFleet = ({
  classes,
  drones,
  onlineDrones,
  setOnlineDrones,
  selectedDrone,
  setSelectedDrone,
  user,
}) => {

  const [ flightStatusWSURL,  setFlightStatusWSURL ] = useState('')

  useEffect(() => {
    const { company_id } = getDecodedToken()
    setFlightStatusWSURL(`${FLIGHTSTATUS_WS}?access_token=${user.access_token}&companyId=${company_id}`)
  }, [user])

  if (!drones)
    return null

  let timeoutId

  const onMessage = (message) => {
    const payload = JSON.parse(message.data)
    
    if (timeoutId)
      clearTimeout(timeoutId)

    timeoutId = setTimeout(function() {
      const currentDrones = [ ...onlineDrones ]
      const currentDroneIndex = currentDrones.findIndex((d) => d.drone_id === payload.entry.drone_id)
      if (currentDroneIndex !== -1) {
        currentDrones.splice(currentDroneIndex, 1)
        setOnlineDrones(currentDrones)
      }
    }, 5000)

    const currentDrones = [ ...onlineDrones ]

    if (payload.type === 'UPDATED') {
      const currentDroneIndex = currentDrones.findIndex((d) => d.drone_id === payload.entry.drone_id)
      currentDrones[currentDroneIndex] = payload.entry  
      setOnlineDrones(currentDrones)

    } else if (payload.type === 'CREATED') {
      setOnlineDrones([ ...onlineDrones, payload.entry ])
    }
  }

  const dronesIsOnline = {}

  drones.forEach((drone) => {
    const matchingDroneEntry = onlineDrones.find((d) => d.drone_id === drone.id)
    const droneIsOnline = matchingDroneEntry && matchingDroneEntry.status !== 'ended'
    dronesIsOnline[drone.id] = droneIsOnline
  })

  function handleChangeDrone(droneId) {
    setSelectedDrone(drones.find(d => d.id === droneId))
  }

  console.log('DroneFleet drones', drones)

  return (<div className={classes.container}>
    <WSContainer
      url={flightStatusWSURL}
      onMessage={onMessage}
      onError={() => {}}
    />
    <List>
      { drones.map(drone => (
        <ListItem
          key={drone.id}
          button
          selected={selectedDrone?.id === drone.id}
          onClick={(e) => handleChangeDrone(drone.id)}>
          <ListItemIcon
            className={selectedDrone?.id === drone.id ? (dronesIsOnline[drone.id] ? classes.selectedOnline : classes.selectedOffline) : undefined}>
            { dronesIsOnline[drone.id] ? <SportsEsports /> : <WifiOff /> }
          </ListItemIcon>
          <ListItemText
            primary={drone.name}
            secondary={dronesIsOnline[drone.id] ? 'Online' : 'Offline' }
          />
        </ListItem>
      ))}
    </List>
  </div>)
}

export default connect((state) => {
  return { user: state.oidc.user }
}, null)(withStyles(styles)(DroneFleet))
