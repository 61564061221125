import { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { getAppHistory } from '../api/skystream'

import { Scatter } from 'react-chartjs-2'
import { Chart as ChartJS, TimeScale, LinearScale, PointElement, LineElement } from 'chart.js'
import 'chartjs-adapter-date-fns'
// import ChartStreaming from 'chartjs-plugin-streaming'

import Header     from '../components/Header'
import GridHeader from './Components/GridHeader'
import GridPanel  from './Components/GridPanel'
import InfoButton from './Components/InfoButton'
import SpeedTest  from './Components/SpeedTest'
import NetworkLatency from './Components/NetworkLatency'
import {
  CssBaseline,
  Grid,
  Typography,
} from '@material-ui/core'
import { pink, yellow, green, grey } from '@material-ui/core/colors'
import { withStyles } from '@material-ui/core/styles'
import theme from '../utils/site-theme'

ChartJS.register(TimeScale, LinearScale, PointElement, LineElement)

const styles = theme => ({
  header: {
    height: theme.spacing(6),
  },
  center: {
    textAlign: 'center',
  },
  panel: {
    position: 'relative',
    height: `calc(100vh - ${theme.spacing(6)}px)`,
    padding: theme.spacing(1),
    border: '1px solid black',
  },
  graph: {
    margin: theme.spacing(1, 0),
    height: '42vh',
    padding: theme.spacing(1),
    backgroundColor: theme.palette.bluegrey.dark,
  },
  speedtest: {
    padding: theme.spacing(1),
    backgroundColor: theme.palette.bluegrey.dark,
  }
})

function NetworkHealth({ classes, useLightTheme }) {
  const [ droVideoBw, setDroVideoBw ] = useState(undefined)
  const [ e2eVideoBw, setE2eVideoBw ] = useState(undefined)

  useEffect(() => {
    // TODO: repeat this every x seconds
    getAppHistory()
    .then(result => {
      if (result.status !== 'success') {
        console.log('Cannot read video bandwidth', result)
        return
      }
      const entries = result.data.entries.actual
      const droneVideoBandwidth = entries.map(e => ({
        x: new Date(e.dateTime).getTime(),
        y: e.data[0] * 1000 // bw_inbound, KB/s -> MB/s
      }))
      const end2endVideoBandwidth = entries.map(e => ({
        x: new Date(e.dateTime).getTime(),
        y: (e.data[0] + e.data[1]) * 1000 // bw_inbound + bw_outbound, KB/s -> MB/s
      }))
      console.log('result', result)
      setDroVideoBw(droneVideoBandwidth)
      setE2eVideoBw(end2endVideoBandwidth)
    })
    .catch(exception => {
      console.log('Error reading video bandwidth', exception)
    })
  }, [])

  return (
    <>
      <CssBaseline />
      <Header className={classes.header} />
      <Grid container>
        <Grid item xs={4} className={classes.panel}>
          <InfoButton title='Drone 5G Network'>
            <Typography>[TODO add an image explanation] The segment between the Drone and the Server only.</Typography>
          </InfoButton>
          <Typography variant='h6' className={classes.center}>
            Drone 5G Network Stats
          </Typography>
          <div className={classes.graph}>
            <BandwidthLineGraph
              useLightTheme={useLightTheme}
              videoBw={droVideoBw} />
          </div>
          <div className={classes.graph}>
            <LatencyLineGraph useLightTheme={useLightTheme} />
          </div>
        </Grid>
        <Grid item xs={4} className={classes.panel}>
          <InfoButton title='Drone 5G Network'>
            <Typography>[TODO add an image explanation] The end to end segment from the Drone through the Server to the Pilot (web client).</Typography>
          </InfoButton>
          <Typography variant='h6' className={classes.center}>
            End to End Network Stats
          </Typography>
          <div className={classes.graph}>
            <BandwidthLineGraph
              useLightTheme={useLightTheme}
              videoBw={e2eVideoBw} />
          </div>
          <div className={classes.graph}>
            <LatencyLineGraph useLightTheme={useLightTheme} />
          </div>
        </Grid>
        <Grid item xs={4} className={classes.panel}>
          <Typography variant='h6' className={classes.center}>
            Current Network Stats
          </Typography>
          <Grid container>
            <GridHeader title='End to End Network Performance' />
            <GridPanel label='Bandwidth' value='380 Mbps' />
            <GridPanel label='Latency'>
              <NetworkLatency />
            </GridPanel>
            <GridPanel label='Total Clients' value='1' />

            <GridHeader title='Drone 5G Network' />
            <GridPanel label='Bandwidth' value='380 Mbps' />
            <GridPanel label='Latency' value='49 ms' />
            <GridPanel label='Total Drones' value='1' />

            <GridHeader title='Command and Control (C2) Performance' />
            <GridPanel label='Video Bandwidth' value='337 Mbps' />
            <GridPanel label='Control Latency' value='57 ms' />
            <GridPanel label='Telem Latency' value='49 ms' />

            <GridHeader title='Run Speed Test' />
            <Grid item xs={12} className={classes.speedtest}>
              <SpeedTest />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  )
}

const scatterOptions = (light) => ({
  color: light ? '#111' : '#eee',
  borderColor: '#999',
  backgroundColor: light ? '#ddd' : '#000',
  maintainAspectRatio: false,
  showLine: true,
  scales: {
    x: {
      ticks:{
        color: light ? '#111' : '#eee',
      },
      type: 'time',
      time: {
        unit: 'second',
        stepSize: 5,
        displayFormats: {
          'second': 'HH:mm:ss',
        }
      }
    },
    y: {
      ticks:{
        color: light ? '#111' : '#eee',
      },
      beginAtZero: true,
    },
  },
})

// Line graph, x axis time, y axis bandwidth
// 4 lines: Network(Drone), Video(Drone), Network(Overall), Video(Overall)
function BandwidthLineGraph({ videoBw, useLightTheme }) {
  const now = Date.now()

  const data = {
    datasets: [{
      id: 'bandwidth-overall',
      label: 'overall',
      backgroundColor: theme.palette.primary.main,
      borderColor: theme.palette.primary.light,
      data: [
        { x: now - 20000, y: 400 },
        { x: now - 15000, y: 410 },
        { x: now - 10000, y: 405 },
        { x: now - 5000,  y: 390 },
        { x: now, y: 380 }
      ],
    },
    {
      id: 'bandwidth-drone',
      label: 'drone',
      backgroundColor: theme.palette.secondary.main,
      borderColor: theme.palette.secondary.light,
      data: [
        { x: now - 20000, y: 300 },
        { x: now - 15000, y: 330 },
        { x: now - 10000, y: 320 },
        { x: now - 5000,  y: 333 },
        { x: now, y: 337 }
      ],
    },
    {
      id: 'bandwidth-video',
      label: 'video',
      backgroundColor: pink[700],
      borderColor: pink[500],
      data: videoBw,
    }]
  }

  return (
    <Scatter
      datasetIdKey='bandwidth'
      data={data}
      options={{
        ...scatterOptions(useLightTheme),
        plugins: {
          title: {
            color: grey[50],
            display: true,
            text: 'Bandwidth (in Mbps)',
          },
        },
      }}
    />
  )
}

// Line graph, x axis time, y axis latency
// 6 lines: Network(Drone), Network(Overall), Control(Overall), Telem (Overall)
function LatencyLineGraph({ useLightTheme }) {
  const now = Date.now()

  const data = {
    datasets: [{
      id: 'latency-overall',
      label: 'overall',
      backgroundColor: theme.palette.primary.main,
      borderColor: theme.palette.primary.light,
      data: [
        { x: now - 19000, y: 40 },
        { x: now - 15500, y: 35 },
        { x: now - 10500, y: 55 },
        { x: now - 4900,  y: 35 },
        { x: now, y: 49 }
      ],
    },
    {
      id: 'latency-drone',
      label: 'drone',
      backgroundColor: theme.palette.secondary.main,
      borderColor: theme.palette.secondary.light,
      data: [
        { x: now - 20000, y: 30 },
        { x: now - 16000, y: 25 },
        { x: now - 11000, y: 50 },
        { x: now - 5500,  y: 16 },
        { x: now, y: 48 }
      ],
    },
    {
      id: 'latency-control',
      label: 'control',
      backgroundColor: green[500],
      borderColor: green[200],
      data: [
        { x: now - 20000, y: 60 },
        { x: now - 16000, y: 55 },
        { x: now, y: 57 }
      ],
    },
    {
      id: 'latency-telemetry',
      label: 'telemetry',
      backgroundColor: yellow[500],
      borderColor: yellow[200],
      data: [
        { x: now - 20000, y: 35 },
        { x: now - 16000, y: 28 },
        { x: now - 11000, y: 52 },
        { x: now - 5500,  y: 25 },
        { x: now, y: 49 }
      ],
    }]
  }

  return (
    <Scatter
      datasetIdKey='latency'
      data={data}
      options={{
        ...scatterOptions(useLightTheme),
        plugins: {
          title: {
            color: grey[50],
            display: true,
            text: 'Latency (in ms)',
          },
        },
      }}
    />
  )
}

const mapStateToProps = state => ({ useLightTheme: state.settings.settings.useLightTheme })

export default connect(mapStateToProps, null)(withStyles(styles)(NetworkHealth))
