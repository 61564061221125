import {
  Button,
  Typography,
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'

const styles = theme => ({
  row: {
    padding: theme.spacing(1),
    display: 'flex',
    justifyContent: 'space-evenly',
  },
})

function SpeedTest({ classes }) {
  return (<>
    <Typography variant='caption'>
      Perform an intrusive, one off, upload and download speed test for drone or pilot,
      to Garuda Plex or to an arbitrary server on the Internet.
    </Typography>
    <div className={classes.row}>
      <Typography variant='body1'>Speed from/to Garuda Plex:</Typography>
      <Button variant='outlined' color='primary'
        onClick={() => alert('Implement me!')}>
        Pilot
      </Button>
      <Button variant='outlined' color='primary'
        onClick={() => alert('Implement me!')}>
        Drone
      </Button>
    </div>
    <div className={classes.row}>
      <Typography variant='body1'>Measure to SpeedTest.net:</Typography>
      <Button variant='outlined' color='primary'
        onClick={() => window.open('https://speedtest.net', '_blank')}>
        Pilot
      </Button>
      <Button variant='outlined' color='primary'
        onClick={() => alert('Implement me!')}>
        Drone
      </Button>
    </div>
  </>)
}

export default withStyles(styles)(SpeedTest)