import { useState } from 'react'
import {
  Menu,
  MenuItem,
  Typography,
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import intruderImage from '../../static/images/intruder.png'

const styles = theme => ({
  img: {
    border: `1px solid ${theme.palette.text.primary}`,
  },
  normal: {
    backgroundColor: theme.palette.bluegrey.dark,
  },
  highlight: {
    backgroundColor: theme.palette.secondary.main,
  },
  '@keyframes blinker': {
    from: { opacity: 0 },
    to: { opacity: 1 },
  },
  follow: {
    backgroundColor: theme.palette.warning.main,
    animationName: '$blinker',
    animationDuration: '1s',
    animationTimingFunction: 'linear',
    animationIterationCount: 'infinite',
  },
})

function InferenceTarget({ classes, name }) {
  const [ anchorEl,  setAnchorEl  ] = useState(null)
  const [ highlight, setHighlight ] = useState(false)
  const [ following, setFollowing ] = useState(false)

  function handleHighlight() {
    setHighlight(!highlight)
    setAnchorEl(null)
    // TODO: Send command / state to parents
  }
  function handleFollow() {
    setFollowing(!following)
    setAnchorEl(null)
    // TODO: Send command / state to parents
  } 

  return (<>
    <div onClick={(e) => setAnchorEl(e.currentTarget)}
      className={following ? classes.follow : highlight ? classes.highlight : classes.normal}>
      <img src={intruderImage} alt='sample' className={classes.img} />
      <Typography>{name}</Typography>
    </div>
    <Menu
      open={Boolean(anchorEl)}
      onClose={() => setAnchorEl(null)}
      anchorEl={anchorEl}
      getContentAnchorEl={null}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      transformOrigin={{ vertical: 'top', horizontal: 'center' }}>
      <MenuItem onClick={handleHighlight}>
        { highlight ? 'Un-highlight' : 'Highlight' }
      </MenuItem>
      <MenuItem onClick={handleFollow}>
        { following ? 'Stop' : 'Start' } Following
      </MenuItem>
    </Menu>
  </>)
}

export default withStyles(styles)(InferenceTarget)
