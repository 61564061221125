import axios from 'axios'
import store from '../store'
import { getDecodedToken } from '../../api/accounts'
import { FLIGHT_PLAN_SENT } from './mission'

// TODO: Save the console logs from this file, need to upload to flight logs API

// Roles
export const INIT_ROLES_ACTION     = 'INIT_ROLES_ACTION'  // Initialise all roles
export const REQUEST_CONTROL       = 'REQUEST_CONTROL'    // Request from any ua_secondary_pilot to control
export const REQUEST_CONTROL_OK    = 'REQUEST_CONTROL_OK' // Handover approval from ua_primary_pilot
export const TAKE_CONTROL          = 'TAKE_CONTROL'       // ua_primary_pilot / ua_secondary_pilot swap

// Drone Movement actions
export const ECHO_ACTION           = 'COMMAND_ECHO_ACTION'           // echo
export const TAKE_OFF_ACTION       = 'COMMAND_TAKE_OFF_ACTION'       // takeOff
export const MISSION_ACTION        = 'COMMAND_MISSION_ACTION'        // start
export const HOLD_ACTION           = 'COMMAND_HOLD_ACTION'           // hold
export const YAW_ACTION            = 'COMMAND_YAW_ACTION'            // yaw
export const NEXT_WAYPOINT_ACTION  = 'COMMAND_NEXT_WAYPOINT_ACTION'  // next-waypoint
export const PREV_WAYPOINT_ACTION  = 'COMMAND_PREV_WAYPOINT_ACTION'  // prev-waypoint
export const NAVIGATE_ACTION       = 'COMMAND_NAVIGATE_ACTION'       // navigate
export const RETURN_HOME_ACTION    = 'COMMAND_RETURN_HOME_ACTION'    // return_home (secret command)
export const LAND_ACTION           = 'COMMAND_LAND_ACTION'           // land
export const SBTL_ACTION           = 'COMMAND_SBTL_ACTION'           // replaces all referernces to emergency_land
export const END_FLIGHT_ACTION     = 'COMMAND_END_FLIGHT'            // end the flight after landing
export const DETECT_STICK_CONTROL  = 'DETECT_STICK_CONTROL'          // detect the existance of a joystick (via Gamepad API)
export const TOGGLE_STICK_CONTROL  = 'TOGGLE_STICK_CONTROL'          // enable / disable inputs from USB transmitter

// Outstanding (no server endpoints)
export const FOLLOW_ACTION = 'COMMAND_FOLLOW_ACTION'
export const NETWORK_SPEED_CHECK_STORAGE = 'NETWORK_SPEED_CHECK_STORAGE'
// End of Drone Movement actions

// Outstanding Network Command Actions
export const ECHO_PING_TIME = 'ECHO_PING_TIME'
export const SWITCH_NETWORK = 'SWITCH_NETWORK'
export const RESETTING_NETWORK_SWITCH = 'RESETTING_NETWORK_SWITCH'
export const SWITCH_NETWORK_FAIL = 'SWITCH_NETWORK_FAIL'

// Outstanding DAA Command Actions
export const TOGGLE_DETECTION = 'TOGGLE_DETECTION_ACTION'
export const TOGGLE_AVOIDANCE = 'TOGGLE_AVOIDANCE_ACTION'

const COMMAND_WEB_API = process.env.REACT_APP_COMMAND_WEB_API

function dispatchCommand({ cmd, desc, opts, type, errorDispatch, finalActions, ...rest }) {
  console.log('[CMD]', desc)
  const drone_id = getDecodedToken().drone_id || 'any' // any means the command can be for any drone who can do it

  return dispatch => {
    axios
    .post(`${COMMAND_WEB_API}/drone/${drone_id}/${cmd}`, opts || {})
    .then(resp => {
      console.log('[CMD]', cmd, 'successful:', resp)
      dispatch({
        ...rest,
        type: type,
      })
    })
    .catch(err => {
      console.log('[CMD]', cmd, 'error:', err)
      if (errorDispatch)
        dispatch(errorDispatch)
    })
    .finally(() => {
      if (finalActions)
        finalActions()
    })
  }
}

export function initRoles({ uas_ops_lead, ua_pilot_primary, ua_pilot_secondary }) {
  // TODO: Only allow this to be called during INIT. Once it crosses over to RTF,
  //       other commands need to be used instead. Only ops lead can init roles.
  return dispatchCommand({
    cmd: 'roles',
    desc: 'Designates individuals to play certain roles in this flight',
    opts: { role: { uas_ops_lead, ua_pilot_primary, ua_pilot_secondary }},
    type: INIT_ROLES_ACTION,
  })
}

export function requestControl() {

  // TODO: Only allow this to be called not during INIT / ENDED, and only by
  //       ua-secondary-pilots (or ops lead)
  return dispatchCommand({
    cmd: 'request-control',
    desc: 'UA Secondary Pilots requesting control from UA Primary Pilot',
    opts: { new_pilot_user_id: getDecodedToken().user_id },
    type: REQUEST_CONTROL,
  })
}

export function requestControlOk({ new_pilot_user_id, approval_token }) {

  // TODO: Only allow this to be called not during INIT / ENDED, and only by
  //       ua-primary-pilot. Must send the exact user_id to hand command to
  //       and an approval_token that will enable the pilot to take over
  return dispatchCommand({
    cmd: 'request-control-ok',
    desc: 'UA Primary Pilot approving control from a UA Secondary Pilot',
    opts: { new_pilot_user_id, approval_token },
    type: REQUEST_CONTROL_OK,
  })
}

export function takeControl({ approval_token }) {

  // TODO: Only allow this to be called not during INIT / ENDED, and only by
  //       ua-secondary-pilot with the approval_token
  return dispatchCommand({
    cmd: 'take-control',
    desc: 'UA Secondary Pilot takes control from UA Primary Pilot',
    opts: { new_pilot_user_id: getDecodedToken().user_id, approval_token },
    type: TAKE_CONTROL,
  })
}


export function echo() {
  const now = Date.now()
  window.localStorage.setItem(ECHO_PING_TIME, now)
  return dispatchCommand({
    cmd:  'echo',
    desc: 'Echo, sends an empty command and echoes is back.',
    opts: { ping_time: now }, // is this necessary?
    type: ECHO_ACTION,
  })
}

// Intialise the flight (normally drone does it automatically on
// boot, so this is only when we need to force it to restart)
export function initFlight() {
  
  // return dispatchCommand({
  //   cmd: 'init-flight',
  //   desc: '(Re)initialise the current flight',
  //   type: INIT_FLIGHT_ACTION,
  // })
}

export function sendFlightPlan() {
  const {
    mission: {
      flightPlanSent,
      mission: { route, airmapFlightId },
    },
    deployment,
  } = store.getState()

  if (flightPlanSent) {
    console.log('Flight Plan already sent')
    return (dispatch => {})
  }

  // TODO: use flight plan to create polygon and buffer distance as an derived geofence?
  const geoFenceCoords = deployment.deploymentData.geoJson ?
    deployment.deploymentData.geoJson.features[0].geometry.coordinates : []

  return dispatchCommand({
    cmd:  'sendFlightPlan',
    desc: 'Send flight plan that the pilot selected to the drone.',
    opts: {
      airmapFlightId,
      mission: {
        route: route,
        geofencePolygon: geoFenceCoords
      },
    },
    type: FLIGHT_PLAN_SENT,
    // TODO: Concrete action if failed to sendFlightPlan.
  })
}

export function takeOff() {
  const { mission: { mission: { route } } } = store.getState()
  const altitude = parseInt(route[0].param7)

  return dispatchCommand({
    cmd:  'takeoff',
    desc: 'Arm and takeoff to the home altitude.',
    opts: { altitude },
    type: TAKE_OFF_ACTION,
  })
}

export function playDrone() {
  return dispatchCommand({
    cmd:  'start',
    desc: 'Play. Drone start / resume autonomous mission.',
    type: MISSION_ACTION,
  })
}

export function holdDrone() {
  return dispatchCommand({
    cmd:  'hold',
    desc: 'Hold. Pause all actions and hover in place.',
    type: HOLD_ACTION,
  })
}

export function yawDrone({ degree, direction }) {
  // Set direction based on degree
  // If degree > 0, it means clockwise. Else anticlockwise
  return dispatchCommand({
    cmd:  'yaw',
    desc: 'Yaw by ' + degree + ' degrees, in ' + direction + ' direction',
    opts: { degree: degree.toString(), direction: direction, speed: '0.5', relative: true },
    type: YAW_ACTION,
  })
}

export function moveToNextWaypoint() {
  return dispatchCommand({
    cmd:  'next-waypoint',
    desc: 'Move to next waypoint.',
    type: NEXT_WAYPOINT_ACTION,
  })
}

export function moveToPrevWaypoint() {
  return dispatchCommand({
    cmd:  'prev-waypoint',
    desc: 'Move to previous waypoint.',
    type: PREV_WAYPOINT_ACTION,
  })
}

export function navigateToWaypoint({ lat, lng, alt, head, altref }) {
  const
    latitude  = lat.toFixed(7), // precision cm
    longitude = lng.toFixed(7), // precision cm
    altitude  = alt,   // float, ignore precision for now
    heading   = head,  // float, ignore precision for now
    alt_ref   = altref // AGL or AMSL, optional, defaults to AGL (to review)

  return dispatchCommand({
    cmd:  'navigate',
    desc: `Navigate to waypoint (${latitude || '-'} ${longitude || '-'} ${altitude || '-'}m ${alt_ref || ''} ${heading || '-'}°N)`,
    opts: { latitude, longitude, altitude, heading, 'altitude-reference': alt_ref },
    type: NAVIGATE_ACTION,
  })
}

// MISSING: Return Home

export function landDrone() {
  return dispatchCommand({
    cmd:  'land',
    desc: 'Land here (in place).',
    type: LAND_ACTION,
  })
}

// Land the drone on a specific emergency landing zone
export function selectBackTrackToLand(zone) {

  // Perform some axios request to confirm emergency landing zone is available
  // For now, assume that the request is successfully made, and the drone proceeds
  return dispatchCommand({
    cmd:  'sbtl',
    desc: 'Navigate to land at ELZ: ' + zone,
    opts: { target: zone },
    type: SBTL_ACTION,
    targetEmergencyLandingZone: zone,
  })
}

// End the flight (either at the end, or prematurely)
export function endFlight() {
  // return dispatchCommand({
  //   cmd: 'end-flight',
  //   desc: 'End the current flight',
  //   type: END_FLIGHT_ACTION,
  // })
}

export function detectStickControl(detected) {
  return dispatch => {
    dispatch({
      type: DETECT_STICK_CONTROL,
      stickDetected: detected,
    })
  }
}

export function toggleStickControl() {
  return dispatch => {
    dispatch({
      type: TOGGLE_STICK_CONTROL,
    })
  }
}

/*
 * DAA actions
 */
export function toggleObstacleDetection() {
  return dispatch => {
    dispatch({
      type: TOGGLE_DETECTION,
    })
  }
}

export function toggleObstacleAvoidance() {
  return dispatch => {
    dispatch({
      type: TOGGLE_AVOIDANCE,
    })
  }
}

/*
 * Network actions
 */
export function resetNetwork() {
  return dispatch => {
    dispatch({
      type: RESETTING_NETWORK_SWITCH,
    })
  }
}

export const switchNetwork = (networkid, setDidSwitch) => {
  return dispatchCommand({
    cmd:  'switchNetwork?networkId=' + networkid,
    desc: 'Switch Network to ' + networkid,
    type: SWITCH_NETWORK,
    targetNetwork: networkid,
    errorDispatch: { type: SWITCH_NETWORK_FAIL },
    finalActions: () => setDidSwitch(true)
  })
}
