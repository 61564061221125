import { connect }      from 'react-redux'
import { resetNetwork } from '../../modules/actions/commandActions'
import {
  Grid,
} from '@material-ui/core'

import SpeedInfoButton       from '../buttons/SpeedInfoButton'
import AdvancedOptionButton  from '../buttons/AdvancedOptionButton'
import PrevWaypointButton    from '../buttons/PrevWaypointButton'
import PlayButton            from '../buttons/PlayButton'
import PauseButton           from '../buttons/PauseButton'
import NextWaypointButton    from '../buttons/NextWaypointButton'
import YawLeftButton         from '../buttons/YawLeftButton'
import YawRightButton        from '../buttons/YawRightButton'
// import StickControlToggle    from '../buttons/StickControlToggle'
import SelectBTLButton       from '../buttons/SelectBTLButton'

function ForwardControls({ isMoving, telemetry, linkStatus, resetNetwork }) {
  return (
    <Grid container alignItems='center' justifyContent='flex-start' spacing={1}>
      <Grid item xs={1}>
        <SpeedInfoButton />
        <AdvancedOptionButton />
      </Grid>
      <Grid item xs={1}>
        <PrevWaypointButton />
      </Grid>
      <Grid item xs={1}>
        { isMoving ? <PauseButton /> : <PlayButton /> }
      </Grid>
      <Grid item xs={1}>
        <NextWaypointButton />
      </Grid>
      <Grid item xs={1}>
        <YawLeftButton />
      </Grid>
      <Grid item xs={1}>
        <YawRightButton />
      </Grid>
      <Grid item xs={1}>
        {/* coming soon */}
      </Grid>
      <Grid item xs={2}>
        {/* coming soon */}
      </Grid>
      <Grid item xs={3}>
        <SelectBTLButton />
        {/* TODO: highlightLandingZone, but in regional-map */}
      </Grid>
    </Grid>
  )
}

const mapDispatchToProps = dispatch => {
  return {
    resetNetwork: payload => dispatch(resetNetwork(payload))
  }
}

const mapStateToProps = ({ command: { isMoving } }) => {
  return { isMoving }
}

export default connect(mapStateToProps, mapDispatchToProps)(ForwardControls)
