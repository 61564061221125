import { useState } from 'react'
import Header       from '../components/Header'
import SingleVideo  from './Components/SingleVideo'
import SkyStreamHealthFooter from '../health/SkyStreamHealthFooter'

import {
  CssBaseline,
  Grid,
  IconButton,
} from '@material-ui/core'
import {
  Fullscreen,
  FullscreenExit,
} from '@material-ui/icons'
import { withStyles } from '@material-ui/core/styles'

const styles = theme => ({
  header: {
    height:   theme.spacing(6),
  },
  fsButton: {
    position: 'absolute',
    zIndex:   1000,
    right:    theme.spacing(0),
    bottom:   theme.spacing(0),
  },
  footer: {
    padding:  theme.spacing(0, 8),
  }
})

function FourByFourGrid({ classes }) {
  const [ fullscreen, setFullscreen ] = useState(false)

  const stylingClass = fullscreen ? 'fullscreen4x4' : '4x4'

  return (
    <>
      <CssBaseline />
      { !fullscreen &&
        <Header className={classes.header} />
      }
      <IconButton className={classes.fsButton}
        onClick={() => setFullscreen(!fullscreen)}>
        { fullscreen ? <FullscreenExit /> : <Fullscreen />}
      </IconButton>
      <Grid container>
        <Grid item xs={6}>
          <SingleVideo videoId='topLeft' streamName='forward' stylingClass={stylingClass} />
        </Grid>
        <Grid item xs={6}>
          <SingleVideo videoId='topRight' streamName='downward' stylingClass={stylingClass} />
        </Grid>
        <Grid item xs={6}>
          <SingleVideo videoId='bottomLeft' streamName='payload' stylingClass={stylingClass} />
        </Grid>
        <Grid item xs={6}>
          <SingleVideo videoId='bottomRight' streamName='video-analytics' stylingClass={stylingClass} />
        </Grid>
      </Grid>
      { !fullscreen &&
        <div className={classes.footer}>
          <SkyStreamHealthFooter />
        </div>
      }
    </>
  )
}

export default withStyles(styles)(FourByFourGrid)
