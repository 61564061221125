import { useEffect, useState } from 'react'
import { connect }             from 'react-redux'
import { bindActionCreators }  from 'redux'
import WSContainer             from '../../components/WSContainer'
import { addWarning }          from '../../modules/actions/warningsActions'
import { getDecodedToken }     from '../../api/accounts'

const WARNINGS_WSS_URL = process.env.REACT_APP_WARNINGS_WSS_URL

function WarningsStoreConnector({ children, addWarning }) {

  const [ wsURL, setWsURL ] = useState('')

  useEffect(() => {
    const { company_id, drone_id, access_token } = getDecodedToken()
    setWsURL(`${WARNINGS_WSS_URL}/?access_token=${access_token}&companyId=${company_id}&droneId=${drone_id}`)
  }, [])

  function onOpen() {
    console.log('WarningsStoreConnector: Connected')
  }

  function onMessage(e) {
    const warning = JSON.parse(e.data)
    // Debounce warning messages by their unique code (now name).
    console.log('Incoming (new) warning data', warning)

    addWarning(warning)
  }

  return (
    <WSContainer url={wsURL} onMessage={onMessage} onOpen={onOpen}>
      {children}
    </WSContainer>
  )
}

const mapDispatchToProps = dispatch => {
  return bindActionCreators({ addWarning }, dispatch)
}

export default connect(null, mapDispatchToProps)(WarningsStoreConnector)
