import { connect } from 'react-redux'
import Telemetry   from '../Components/Telemetry'

const TelemetryContainer = ({ telemetry, linkStatus }) => (
  <Telemetry linkStatus={linkStatus} telemetry={telemetry} />
)

const mapStateToProps = state => ({
  telemetry: state.telemetry.telemetry,
  linkStatus: state.telemetry.linkStatus,
})

export default connect(mapStateToProps, {})(TelemetryContainer)
