import { Component, createContext } from 'react'

const MissionContext = createContext({
  mission:       {},
  updateMission: () => {},
})

export class MissionProvider extends Component {

  updateMission = (mission, metaObj = {}) => {
    console.log('Updating mission:', mission, metaObj)
    if (!mission) {
      this.setState({ mission: {} })
    } else {
      this.setState({
        mission: {
          ...mission,
          ...metaObj,
        },
      })
    }
  }

  state = {
    mission:       {},
    updateMission: this.updateMission,
  }

  render() {
    return (
      <MissionContext.Provider value={this.state}>
        { this.props.children }
      </MissionContext.Provider>
    )
  }
}

export default MissionContext
