import { Component }          from 'react'
import { bindActionCreators } from 'redux'
import { connect }            from 'react-redux'
import { requestFlightAuthorization, resetMission } from '../modules/actions/mission'

import PreFlightCheck from './Components/PreFlightCheck'
import MissionProfile from './Components/MissionProfile'
import {
  Button,
  CircularProgress,
  Divider,
  FormControl,
  Input,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'

const styles = theme => ({
  loaderWrapper: {
    marginTop: theme.spacing(3),
    display: 'block',
    margin: '0 auto',
    textAlign: 'center',
  },
  progress: {
    margin: '0 auto',
    marginBottom: theme.spacing(1),
    display: 'block',
  },
  selectPanel: {
    height: '100vh',
    padding: theme.spacing(2),
    backgroundColor: theme.palette.bluegrey.dark,
  },
  missionSelect: {
    marginTop: theme.spacing(1)
  },
  selectButton: {
    display: 'flex',
    margin: '0 auto',
    marginTop: theme.spacing(3),
  },
  dropdown: {
    marginTop: theme.spacing(2),
    maxWidth: theme.spacing(50),
    display: 'flex',
    margin: '0 auto',
  },
})

class MissionForm extends Component {
  state = {
    selectedMission: '',
    selectedDrone:   '',
    preflightCheck:  false,
  }

  chooseNewMission = () => {
    const { selectedMission, selectedDrone } = this.state
    const { deploymentData, missionList } = this.props

    const selectedMissionObj = missionList.find(obj => obj.flight_plan_id === selectedMission)
    const { description, plan, flight_plan_id } = selectedMissionObj
    const selectedDroneObj = deploymentData.drones.find(obj => obj.id === selectedDrone)
    const transformRoutes = plan.commands

    console.log('Selected Drone: ', selectedDroneObj)
    const missionObj = {
      name:         description,
      route:        transformRoutes,
      flightPlanId: flight_plan_id,
      droneId:      selectedDroneObj.id,
      droneModel:   selectedDroneObj.model?.name || 'Unknown',
      droneName:    selectedDroneObj.name,
      leadPilot:    deploymentData.personnel[0].name,
      coPilot:      deploymentData.personnel[1] ? deploymentData.personnel[1].name : '',
    }

    console.log('New Mission', missionObj)
    this.props.requestFlightAuthorization(missionObj)

    // Note: Previously here, we enable people detection, by publishing drone id and wowza links
    //       through /trigger-prediction in garuda-flight/object-detection to plex.VideoPrediction
    // TODO: We want all AI inputs to be a "feed", not just a switch, in live flights, similar to
    //       DAA bounding box service. Switch GUI behaviour through listening to WS and drawing.
    //       Actual control panel to turn on/off people detection now in /select-object
  }

  handleDroneChange(evt) {
    console.log('Drone Changed: ', evt.target)
    this.setState({
      selectedDrone: evt.target.value,
    })
  }

  handleMissionChange(evt, payload) {
    const newMission = evt.target.value
    const { retrieveDeployment, missionList, updateMission } = this.props
    console.log('New Mission: ', newMission)

    if (newMission === 'remove') {
      this.props.resetMission()
      // The logic to repopulate the page is too complex, taking a shortcut for today
      window.location.reload()
    } else {
      this.setState({ selectedMission: newMission })
      const flightPlan = missionList.find(mission => mission.flight_plan_id === newMission)
      console.log('Flight plan: ', flightPlan)
      retrieveDeployment(flightPlan?.deployment_id)
      updateMission(flightPlan, payload)
    }
  }

  renderMissionSelectField = (selectedMission) => (
    <Select
      input={ <Input name='mission' id='select-mission' /> }
      value={selectedMission}
      className={this.props.classes.missionSelect}
      onChange={evt => this.handleMissionChange(evt)}>
      { this.props.missionList.map((obj, i) => (
        <MenuItem key={i} value={obj.flight_plan_id}>
          { new Date(obj.last_modified_date).toLocaleDateString() } {obj.description}
        </MenuItem>
      ))}
      <Divider />
      <MenuItem key='remove' value='remove'>
        Remove Mission
      </MenuItem>
    </Select>
  )

  renderDroneSelectField = (selectedDrone) => (
    <Select
      input={ <Input name='drone' id='select-drone' /> }
      value={selectedDrone}
      onChange={evt => this.handleDroneChange(evt)}>
      { this.props.deploymentData.drones.map((obj, i) => (
        <MenuItem key={i} value={obj.id}>{obj.name}</MenuItem>
      ))}
    </Select>
  )

  renderLoading = () => (
    <div className={this.props.classes.loaderWrapper}>
      <CircularProgress className={this.props.classes.progress} />
      <Typography variant={'h6'}>Submitting Flight Plan to UTM (AirMap)</Typography>
    </div>
  )

  render() {
    const {
      classes,
      mission,
      deploymentData,
      loading,
    } = this.props
    const {
      selectedMission,
      selectedDrone,
      preflightCheck,
    } = this.state

    if (loading)
      return this.renderLoading()

    return (
      <div className={classes.selectPanel}>
        <Typography align='center' variant='h5' gutterBottom>
          Select Mission
        </Typography>
        <FormControl className={classes.dropdown}>
          <InputLabel shrink htmlFor='select-mission'>
            Select Mission (Flight Plan)
          </InputLabel>
          { this.renderMissionSelectField(selectedMission) }
        </FormControl>
        { selectedMission !== '' && deploymentData !== null && (
          <FormControl className={classes.dropdown}>
            <InputLabel shrink htmlFor='select-drone'>
              Select Drone
            </InputLabel>
            { this.renderDroneSelectField(selectedDrone) }
          </FormControl>
        )}
        <Button
          disabled={!this.state.selectedDrone || !this.state.selectedMission}
          className={classes.selectButton}
          color='primary'
          variant='contained'
          onClick={() => this.setState({ preflightCheck: true })}>
          Select
        </Button>
        { selectedMission && deploymentData &&
          <MissionProfile
            mission={mission}
            deployment={deploymentData} />
        }
        { preflightCheck &&
          <PreFlightCheck
            open={preflightCheck}
            onClose={() => this.setState({ preflightCheck: false })}
            onProceed={this.chooseNewMission}
          />
        }
      </div>
    )
  }
}

const mapDispatchToProps = dispatch => {
  return bindActionCreators({
    requestFlightAuthorization,
    resetMission,
  }, dispatch)
}

const mapStateToProps = state => ({
  loading: state.mission.airmapSubmitLoading,
})

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(MissionForm))
