import { useState, useEffect, useCallback } from 'react'

import L from 'leaflet'
import {
  useMapEvents,
} from 'react-leaflet'
import 'leaflet-polylinedecorator'
import 'leaflet-geometryutil'
import * as turf from '@turf/turf'

import {
  TextField,
  InputAdornment,
} from '@material-ui/core'
import {
  ArrowForward,
  Directions,
  Height,
} from '@material-ui/icons'
import { withStyles } from '@material-ui/core/styles'
import theme from '../../utils/site-theme'

const DEFAULT_FLYHERE_AGL  = 10.00 // meters
const DEFAULT_FLYHERE_HEAD = 0     // north
const FOV = 80 // make assumption drone cameras has 80 degrees field of view, later read from Aircraft Service

function polylineOptions(distance, isClosest) {
  return {
    patterns: [{
      // 8px-wide dashes, repeated every 15px on the line
      offset: 0,
      endOffset: '15px',
      repeat: 15,
      symbol: L.Symbol.dash({
        pixelSize: 8,
        pathOptions: {
          color: theme.palette.error.main,
          weight: 3
        }
      })
    }, {
      // arrow head
      offset: '99%',
      repeat: 0,
      symbol: L.Symbol.arrowHead({
        pixelSize: 15,
        polygon: true,
        pathOptions: {
          color: theme.palette.error.main,
          fill: true
        }
      })
    }, {
      // label
      offset: '50%',
      repeat: 0,
      symbol: L.Symbol.marker({
        rotate: false,
        markerOptions: {
          icon: L.divIcon({
            html: `<div style="z-index: 2000; text-align: center; width: 110px; font-weight: bold; background-color: ${isClosest ? 'lightgreen' : 'white'}; border: 1px solid grey;">Distance ${distance < 1000 ? (distance * 1000).toFixed(0) + ' m' : distance.toFixed(2) + ' km'}</div>`,
            className: '' // override the empty white box
          })
        }
      })
    }]
  }
}

const circleOptions = {
  radius: 15,
  color: theme.palette.error.main,
  weight: 5,
  fill: true,
  fillColor: theme.palette.error.main,
}

function triangleOptions() {
  return {
    fillColor: 'url(#fov)',
    fillOpacity: 1,
    stroke: false,
  }
}

function triangleGradient(heading) {
  if (!isFinite(heading))
    return ''
  return `<linearGradient id="fov"
    x1="0%" y1="100%" x2="0%" y2="0%"
    gradientTransform="rotate(${heading} 0.5 0.5)"
    spreadMethod="pad">
    <stop offset=0 stop-color=rgba(255,255,0,1) />
    <stop offset=100% stop-color=rgba(255,255,0,0) />
    </linearGradient>`
}

function minIndex(arr) {
  if (!arr || arr.length === 0)
    return -1

  let minIndex = 0
  for (let i = 1; i < arr.length; i++) {
    if (arr[i] < arr[minIndex])
      minIndex = i
  }
  return minIndex
}

const styles = theme => ({
  controlBarRoot: {
    zIndex: 1000,
    position: 'fixed',
    bottom: theme.spacing(1) + 'px',
    left: theme.spacing(1) + 'px',
    width: '62%',
    height: theme.spacing(7),
    backgroundColor: theme.palette.bluegrey.dark,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-evenly',
  },
  textField: {
    width: theme.spacing(18)
  },
  wideField: {
    width: theme.spacing(24)
  },
})

function FlyHereControls({
  classes,
  currentLocation,
  commands,
  selectedLocation,
  onSelectLocation,
  onChangeAltitude,
  onChangeHeading,
  docks,
}) {
  const [ to,   setTo   ] = useState({})
  const [ agl,  setAgl  ] = useState(currentLocation?.agl?.toFixed(2) || DEFAULT_FLYHERE_AGL.toFixed(2))
  const [ head, setHead ] = useState(currentLocation?.yaw?.toFixed(0) || DEFAULT_FLYHERE_HEAD.toFixed(0))
  const [ polylines, setPolylines ] = useState([])
  const [ circle,    setCircle    ] = useState(null)
  const [ triangle,  setTriangle  ] = useState(null)

  const map = useMapEvents({
    click: (evt) => {
      setTo(evt.latlng)

      // Animate a line from current position to this point
      if (currentLocation?.lat && currentLocation?.lng) {
        drawPolyline(currentLocation, evt.latlng)
        drawTriangle(parseFloat(head), parseFloat(agl), evt.latlng)
        onSelectLocation(evt.latlng)
      }
      // If current position is not know, use the home point
      else if (commands?.length > 0) {
        drawPolyline(commands[0], evt.latlng)
        drawTriangle(parseFloat(head), parseFloat(agl), evt.latlng)
        onSelectLocation(evt.latlng)
      }
      // If there is no current location and no commands, display a circular target, and measure distance from each dock
      else {
        drawCircle(evt.latlng)
        drawPolylinesFromDock(evt.latlng)
        onSelectLocation(evt.latlng)
      }
    },
  })

  function drawPolyline(from, to) {
    if (polylines) {
      polylines.forEach(polyline => { polyline.remove(map) })
      setPolylines([])
    }
    const distance = turf.distance(
      turf.point([from.lng, from.lat]),
      turf.point([to.lng, to.lat]))
    const newPolyline = L.polylineDecorator(
      [[from.lat, from.lng], [to.lat, to.lng]],
      polylineOptions(distance, false)
    ).addTo(map)
    setPolylines([newPolyline])
  }

  const removeCircle = useCallback(() => {
    if (circle) {
      circle.remove(map)
      setCircle(null)
    }
  }, [circle, map])

  const removeTriangle = useCallback(() => {
    if (triangle) {
      const oldGradients = document.getElementsByTagName('linearGradient')[0]
      if (oldGradients)
        oldGradients.remove()
      triangle.remove(map)
      setTriangle(null)
    }
  }, [triangle, map])

  function drawCircle(center) {
    removeCircle()
    const newCircle = L.circleMarker(center, circleOptions).addTo(map)
    setCircle(newCircle)
  }

  // https://stackoverflow.com/questions/65426304/leaflet-multicolor-pattern-fill
  function drawTriangle(heading, altitude, target) {
    removeTriangle()
    if (!isFinite(heading) || !target.lng || !target.lat)
      return
    const start = turf.point([target.lng, target.lat])
    const radius = altitude / 1000 // assume the drone can see as far as it's high (i.e. camera pitch 45°)
    const sector = turf.sector(start, radius, heading - FOV / 2, heading + FOV / 2, { units: 'kilometers' })
    const sectorCoords = sector.geometry.coordinates[0].map(([lng, lat]) => [lat, lng])
    const newTriangle = L.polygon(sectorCoords, triangleOptions()).addTo(map)

    const svg = document.getElementsByTagName('svg')[0]
    const svgDefs = document.createElementNS("http://www.w3.org/2000/svg", 'defs')
    svgDefs.insertAdjacentHTML('afterbegin', triangleGradient(heading))
    svg.appendChild(svgDefs)
    setTriangle(newTriangle)
  }

  function drawPolylinesFromDock(target) {
    const dockCenters = docks.map(dock => turf.center(dock).geometry.coordinates) // TODO: do this once and store in dock object
    const distances = dockCenters.map(dockCenter => turf.distance(
      turf.point([dockCenter[0], dockCenter[1]]),
      turf.point([target.lng, target.lat]))
    )
    const indexOfClosest = minIndex(distances)
    const newPolylines = dockCenters.map((dockCenter, i) =>
      L.polylineDecorator(
        [[dockCenter[1], dockCenter[0]], target],
        polylineOptions(distances[i], i === indexOfClosest)
      ).addTo(map)
    )
    setPolylines(newPolylines)
  }

  useEffect(() => {
    return () => { // clean up
      removeTriangle()
      removeCircle()
      // TODO: change the polyline to green and leave it until the next time this control is activated
      //       issue: the polyline state is lost when the component unmounts. figure out where to put state
    }
  }, [removeTriangle, removeCircle])

  useEffect(() => {
    const flyHereControlDiv = L.DomUtil.get('fly-here-controls')
    L.DomEvent.on(flyHereControlDiv, 'click', L.DomEvent.stopPropagation)
    L.DomEvent.on(flyHereControlDiv, 'mousewheel', L.DomEvent.stopPropagation)
  }, [])

  function updateAgl(evt) {
    setAgl(evt.target.value)
    const newAgl = parseFloat(evt.target.value)
    drawTriangle(parseFloat(head), newAgl, to)
    onChangeAltitude(newAgl)
  }

  function updateHead(evt) {
    setHead(evt.target.value)
    const newHead = parseFloat(evt.target.value)
    drawTriangle(newHead, parseFloat(agl), to)
    onChangeHeading(newHead)
  }

  // TODO: Ability to switch between AGL and AMSL
  return (
    <div id='fly-here-controls' className={classes.controlBarRoot}>
      <TextField
        className={classes.textField}
        size='small'
        variant='outlined'
        label='Horizontal Distance'
        InputProps={{
          readOnly: true,
          startAdornment: <InputAdornment position='start'><ArrowForward /></InputAdornment>,
          endAdornment:   <InputAdornment position='end'>m</InputAdornment>
        }}
        value={commands?.length > 0 && selectedLocation ?
          selectedLocation.distanceTo([commands[0].lat, commands[0].lng]).toFixed(2) :
          '-'}
      />
      <TextField disabled
        className={classes.textField}
        size='small'
        variant='outlined'
        label='Horizontal Speed'
        InputProps={{
          readOnly: true,
          startAdornment: <InputAdornment position='start'><ArrowForward /></InputAdornment>,
          endAdornment:   <InputAdornment position='end'>m/s</InputAdornment>
        }}
        value='5.00'
      />
      <TextField
        className={classes.textField}
        size='small'
        variant='outlined'
        color='primary'
        label='Altitude (AGL)'
        InputProps={{
          startAdornment: <InputAdornment position='start'><Height /></InputAdornment>,
          endAdornment:   <InputAdornment position='end'>m</InputAdornment>
        }}
        value={agl}
        onChange={updateAgl}
      />
      <TextField disabled
        className={classes.textField}
        size='small'
        variant='outlined'
        label='Vertical Speed'
        InputProps={{
          readOnly: true,
          startAdornment: <InputAdornment position='start'><Height /></InputAdornment>,
          endAdornment:   <InputAdornment position='end'>m/s</InputAdornment>
        }}
        value='1.00'
      />
      <TextField
        className={classes.wideField}
        size='small'
        variant='outlined'
        color='primary'
        label='Heading'
        InputProps={{
          startAdornment: <InputAdornment position='start'><Directions /></InputAdornment>,
          endAdornment:   <InputAdornment position='end'>° (0° = North)</InputAdornment>
        }}
        value={head}
        onChange={updateHead}
      />

    </div>
  )
}

export default withStyles(styles)(FlyHereControls)
