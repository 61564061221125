import { UPDATE_DAA_TELEMETRY } from '../actions/telemetryActions'
import { TOGGLE_AVOIDANCE, TOGGLE_DETECTION } from '../actions/commandActions'

const initialState = {
    obstacleDetection: false,
    obstacleAvoidance: false,
    autonomyLevel: 1, // integer from 0 to 3
    obstacles: [
        {
            x1: 400,
            x2: 600,
            y1: 400,
            y2: 600,
            timestamp: 1580377136019,
            distance: 6500,
        },
    ],
}

function daaReducer(state = initialState, action) {
    switch (action.type) {
        case UPDATE_DAA_TELEMETRY:
            return {
                ...state,
                obstacles: action.obstacles,
            }
        case TOGGLE_DETECTION:
            const obstacleDetection = !state.obstacleDetection
            return {
                ...state,
                obstacleDetection,
                obstacleAvoidance: false,
                autonomyLevel: obstacleDetection ? 1 : 0,
            }
        case TOGGLE_AVOIDANCE:
            const obstacleAvoidance = !state.obstacleAvoidance
            return {
                ...state,
                obstacleAvoidance,
                autonomyLevel: obstacleAvoidance ? 2 : 1,
            }
        default:
            return state
    }
}

export default daaReducer

