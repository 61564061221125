import { useEffect, useState } from 'react'
import { useInterval } from 'usehooks-ts'
import * as turf from '@turf/turf'

import DockMarker  from './DockMarker'

function DockingStations({
  telemetry,
  docks,
}) {
  const [ highlightedDockIds, setHighlightedDockIds ] = useState({})

  useInterval(() => {
    if (telemetry && telemetry.metadata) {
      const newHighlightedDockIds = { ...highlightedDockIds }
      Object.keys(highlightedDockIds).forEach(dockId => {
        const isTakeoffOrLanding = telemetry.metadata.takeoff_docking_station_id + '' === dockId

        newHighlightedDockIds[dockId] = isTakeoffOrLanding ? !highlightedDockIds[dockId] : false
      })
      setHighlightedDockIds(newHighlightedDockIds)
    }
  }, 1 * 1000)

  useEffect(() => {
    if (docks) {
      const newHighlightedDockIds = { ...highlightedDockIds }
      docks.forEach(dock => {
        newHighlightedDockIds[dock.properties.id] = false
      })
      setHighlightedDockIds(newHighlightedDockIds)
    }
  // eslint-disable-next-line
  }, [docks])

  const dockMarkers = docks.map(dock => {
    const pos = turf.center(dock).geometry.coordinates
    return (<DockMarker
      key={dock.properties.id}
      lat={pos[1]}
      lng={pos[0]}
      highlighted={highlightedDockIds[dock.properties.id]}
    />)
  })
  
  return (<>
    {dockMarkers}
  </>)
}

export default DockingStations
