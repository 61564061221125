export default function prettyTimestamp(durationInSec) {
  if (!isFinite(durationInSec))
    return 'invalid time'
  if (durationInSec < 0) {
    return `-${prettyTimestamp(-durationInSec)}`
  }
  if (durationInSec < 60) {
    return `${Math.floor(durationInSec)}s`
  }
  if (durationInSec < 60 * 60) {
    const numMins = Math.floor(durationInSec / 60)
    const mins = numMins === 1 ? 'min' : 'mins'
    return `${numMins} ${mins}`
  }
  if (durationInSec < 60 * 60 * 24) {
    const numHours = Math.floor(durationInSec / 60 / 60)
    return `${numHours}h`
  }
  const numDays = Math.floor(durationInSec / 60 / 60 / 24)
  const days = numDays === 1 ? 'day' : 'days'
  return `${numDays} ${days}`
}