import { useState } from 'react'
import {
  getDecodedToken,
  useGetUsersByCompanyIdQuery,
} from '../../api/accounts'
import {
  useUpdateFlightStatusRolesMutation
} from '../../api/liveflights'
import TopXCloseButton from '../../components/TopXCloseButton'
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  FormControl,
  Grid,
  InputLabel,
  Input,
  MenuItem,
  Select,
  Step,
  StepLabel,
  Stepper,
  TextField,
  Typography,
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'

const stepLabels = [
  'Start',
  'Personnel',
  'Configuration',
  'SysHealth',
  'Done',
]

const stepTitles = [
  'Mission Setup Start',
  'Setup Personnel and Roles',
  'Choose Drone Configuration',
  'Perform System Health Check',
  'Mission Setup Complete',
]

const styles = theme => ({

})

function MissionSetupDialog({ classes, open, onClose }) {
  const {drone_id} = getDecodedToken()
  const [ step, setStep ] = useState(0)
  const [ role, setRole ] = useState({
    uas_ops_lead:       '',
    ua_pilot_primary:   '',
    ua_pilot_secondary: []
  })
  const [ updateRoles ] = useUpdateFlightStatusRolesMutation()

  function handleClose() {
    setStep(0)
    onClose()
  }
  function handlePrev() {
    setStep(prevStep => prevStep - 1)
  }
  function handleNext() {
    if (step === 1) {
      updateRoles({ drone_id: drone_id, role})
    }
    if (step === stepLabels.length - 1)
      return handleClose()
    setStep(prevStep => prevStep + 1)
  }

  return (
    <Dialog open={open} onClose={handleClose} maxWidth='lg' fullWidth>
      <TopXCloseButton onClick={handleClose} />
      <DialogContent>
        <Stepper activeStep={step}>
          { stepLabels.map(label => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          )) }
        </Stepper>
        <Typography variant='h5'>{ stepTitles[step] }</Typography>
        { step === 0 && <Step0Start /> }
        { step === 1 && <Step1Personnel role={role} onUpdate={(r) => setRole(r)} /> }
        { step === 2 && <Step2Configuration /> }
        { step === 3 && <Step3SysHealth /> }
        { step === 4 && <Step4Done /> }
      </DialogContent>
      <DialogActions>
        <Button disabled={step === 0} onClick={handlePrev}>Back</Button>
        <Button variant='contained' color='primary' onClick={handleNext}>
          { step === stepLabels.length - 1 ? 'Finish' : 'Next' }
        </Button>
      </DialogActions>
    </Dialog>
  )
}

function Step0Start() {
  return (<>
    <Typography>
      This wizard will help you set up your system for BVLOS flight.
    </Typography>
    <Typography>
      In the bext 3 screens you will be:
    </Typography>
    <ul>
      <li>Assigning roles to personnel in your operations team;</li>
      <li>Assigning configuration to your aircraft and docking system; and</li>
      <li>Performing a series of pre-flight checks.</li>
    </ul>
    <Typography>
      <b>Important:</b> You must have the role of a UAS Operations Lead to perform
      this setup process. Check with your Company Administrator if you do not have
      the role of a UAS Ops Lead.
    </Typography>
  </>)
}

function Step1Personnel({ role, onUpdate }) {
  const { company_id } = getDecodedToken()
  const { data, isLoading } = useGetUsersByCompanyIdQuery(company_id, {
    skip: !company_id
  })
  const users = data?.data?.users || []
  const opsLead = role.uas_ops_lead
  const priPilot = role.ua_pilot_primary
  const secPilots = role.ua_pilot_secondary

  function handleUpdate(key, value) {
    onUpdate({
      ...role,
      [key]: value
    })
  }

  // TODO: Do up a nicer UI to show avatar along side info of Ops Lead and Primary
  //       Pilot, and a proper list with avatar for secondary pilots.
  return (<>
    <Typography>
      Please assign personnel to the following roles.
    </Typography>
    { isLoading &&
      <><CircularProgress /> Loading Users ..</>
    }
    <Grid container>
      <Grid item xs={4}>
        <TextField select
          fullWidth
          label='UAS Operations Lead'
          value={opsLead}
          onChange={(e) => { handleUpdate('uas_ops_lead', e.target.value) }}>
          <MenuItem value=''>-</MenuItem>
          { users.map(u => (
            <MenuItem key={u.user_id} value={u}>{u.name}</MenuItem>
          )) }
        </TextField>
        { opsLead && <div>
            About {opsLead.name}<br />
            {opsLead.username}<br />
            {opsLead.email}<br />
            {opsLead.designation}<br />
            and show some flignt stats and qualifications
          </div>
        }
      </Grid>
      <Grid item xs={4}>
        <TextField select
          fullWidth
          label='UA Pilot Primary'
          value={priPilot}
          onChange={(e) => { handleUpdate('ua_pilot_primary', e.target.value) }}>
          <MenuItem value=''>-</MenuItem>
          { users.map(u => (
            <MenuItem key={u.user_id} value={u}>{u.name}</MenuItem>
          )) }
        </TextField>
        { priPilot && <div>
            About {priPilot.name}<br />
            {priPilot.username}<br />
            {priPilot.email}<br />
            {priPilot.designation}<br />
            and show some flignt stats and qualifications
          </div>
        }
      </Grid>
      <Grid item xs={4}>
        <FormControl fullWidth>
          <InputLabel>UA Pilot Secondary</InputLabel>
          <Select multiple
            value={secPilots}
            input={<Input />}
            onChange={(e) => { handleUpdate('ua_pilot_secondary', e.target.value) }}>
            { users.map(u => (
              <MenuItem key={u.user_id} value={u}>{u.name}</MenuItem>
            )) }
          </Select>
        </FormControl>
        { secPilots.map(p => (
          <div key={p.user_id}>
            About {p.name}<br />
            {p.username}<br />
            {p.email}<br />
            {p.designation}<br />
            and show some flignt stats and qualifications
          </div>
        )) }
      </Grid>
    </Grid>
  </>)
}

function Step2Configuration() {
  return (<>
    <Typography>
      Please assign configuration to the following subsystems.
    </Typography>
    <Typography>
      <i>This feature is temporarily disabled.</i>
    </Typography>
  </>)
}

function Step3SysHealth() {
  return (<>
    <Typography>
      Please wait while the drone performs its internal health checks based on your configurations.
    </Typography>
    <Typography>
      <i>This feature is temporarily disabled.</i>
    </Typography>
  </>)
}

function Step4Done() {
  return (<>
    <Typography>
      You have completed setting up for this mission.
    </Typography>
    <Typography>
      You can launch this wizard again to redo any part of the setup.
    </Typography>
  </>)
}


export default withStyles(styles)(MissionSetupDialog)
