import {
  NAV_TO_WAYPOINT,
  TAKE_OFF,
  RETURN_TO_LAUNCH_LOC,
  LAND,
  CHANGE_CAMERA_PITCH,
  SET_GIMBAL_PITCHYAW,
  filterMapCommands,
} from '../../utils/mission'
import JsxMarker      from '../../components/JsxMarker'
import { isNumber }   from '../../utils'
import { withStyles } from '@material-ui/core/styles'
import theme from '../../utils/site-theme'

const styles = theme => ({
  marker: {
    display:        'flex',
    flexDirection:  'column',
    justifyContent: 'center',
    alignItems:     'center',
  },
  homeLabel: {
    position:       'absolute',
    bottom:         theme.spacing(8),
    color:          theme.palette.primary.main,
    fontWeight:     800,
    textTransform:  'uppercase',
  },
  destLabel: {
    position:       'absolute',
    top:            theme.spacing(8),
    color:          theme.palette.success.main,
    fontWeight:     800,
    textTransform:  'uppercase',
  },
  waypointLabel: {
    position:       'absolute',
    color:          'white',
    fontWeight:     800,
  },
  longLabel: {
    position:       'absolute',
    top:            theme.spacing(6),
    color:          'white',
    fontWeight:     800,
  },
})

function CircleMarker({ size, colour, opacity }) {
  const circle = 'M12,2C6.48,2 2,6.48 2,12C2,17.52 6.48,22 12,22C17.52,22 22,17.52 22,12C22,6.48 17.52,2 12,2Z'
  return <div width={72} height={72}>
    <svg width={size} height={size} viewBox='0 0 24 24'>
      <path fill={colour} opacity={opacity} d={circle} />
    </svg>
  </div>
}

function FlightPlanMarkers({ classes, commands }) {

  if (!commands)
    return null

  const mapCommands = filterMapCommands(commands)

  const markerArray = mapCommands.map((command, i) => (
    <JsxMarker
      key={'cmd' + i}
      position={{ lat: command.lat, lng: command.lng }}
      iconClassName={classes.marker}
      iconSize={[72, 72]}>
      {
        command.type === TAKE_OFF ? <>
          <CircleMarker size={72} colour={theme.palette.primary.main} opacity={0.6} />
          <span className={classes.homeLabel}>Home</span>
        </> :
        command.type === RETURN_TO_LAUNCH_LOC || command.type === LAND ? <>
          <CircleMarker size={56} colour={theme.palette.success.main} opacity={0.6} />
          <span className={classes.destLabel}>Destination</span>
        </> :
        command.type === NAV_TO_WAYPOINT ? <>
          <CircleMarker size={40} colour={theme.palette.bluegrey.main} opacity={0.8} />
          <span className={classes.waypointLabel}>{command.map_index}</span>
        </> :
        command.type === CHANGE_CAMERA_PITCH ? <>
          <CircleMarker size={40} colour={theme.palette.secondary.dark} opacity={0.8} />
          <span className={classes.waypointLabel}>{command.map_index}</span>
          <span className={classes.longLabel}>
            Cam Pose<br />
            P{isNumber(command.pitch) ? command.pitch.toFixed(0) : '-'}&deg;
            Y{isNumber(command.yaw)   ? command.yaw.toFixed(0)   : '-'}&deg;
            {/*{isNumber(command.roll)? command.roll.toFixed(0)  : '-'}&deg;<br />
            Alt{isNumber(command.alt) ? command.alt.toFixed(1)   : '-'}m*/}
          </span>
        </> :
        command.type === SET_GIMBAL_PITCHYAW ? <>
          <CircleMarker size={40} colour={theme.palette.secondary.dark} opacity={0.8} />
          <span className={classes.waypointLabel}>{command.map_index}</span>
          <span className={classes.longLabel}>
            Gim {command.device_id || '-'}
            (flags: {isNumber(command.device_flags) ? command.device_flags           : '-'})<br />
                   P{isNumber(command.pitch_angle)  ? command.pitch_angle.toFixed(1) : '-'}&deg;
                   ({isNumber(command.pitch_rate)   ? command.pitch_rate.toFixed(1)  : '-'}&deg;/s)<br />
                   Y{isNumber(command.yaw_angle)    ? command.yaw_angle.toFixed(1)   : '-'}&deg;
                   ({isNumber(command.yaw_rate)     ? command.yaw_rate.toFixed(1)    : '-'}&deg;/s)
          </span>
        </> : null
      }
    </JsxMarker>
  ))

  return <>{ markerArray }</>
}

export default withStyles(styles)(FlightPlanMarkers)
