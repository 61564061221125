/**
 * A wrapper for divIcon markers that accept JSX components as children.
 */
import React, { Component } from 'react'
import { divIcon } from 'leaflet'
import PropTypes from 'prop-types'
import { Marker } from 'react-leaflet'

class JsxMarkerContent extends Component {
  onRef = ref => {
    if (ref) {
      const html = ref.innerHTML
      if (html !== this.previousHtml) {
        this.props.onRender(html)
        this.previousHtml = html
      }
    }
  }

  render() {
    return (
      <div className='jsx-marker' style={{ display: 'none' }} ref={this.onRef}>
        {this.props.children}
      </div>
    )
  }
}

const isLatitude  = num => isFinite(num) && Math.abs(num) <= 90
const isLongitude = num => isFinite(num) && Math.abs(num) <= 180

class JsxMarker extends Component {
  static propTypes = {
    position:      PropTypes.object,
    iconClassName: PropTypes.string,
    onClick:       PropTypes.func
  }

  constructor(props) {
    super(props)
    this.state = {
      html: null,
    }
  }

  onInsideRender(html) {
    this.setState({ html })
  }

  render() {
    const {
      iconClassName,
      iconSize,
      position,
      zIndexOffset,
      onClick,
      ...rest
    } = this.props

    const { html } = this.state
    let marker = false

    const
      latFloat = parseFloat(position?.lat),
      lngFloat = parseFloat(position?.lng)

    if (!isLatitude(latFloat) || !isLongitude(lngFloat)) {
      console.error('Invalid Position', position)
      return null
    }

    const pos = {
      ...position,
      lat: latFloat,
      lng: lngFloat,
    }

    if (html) {
      const icon = divIcon({
        className: iconClassName,
        html,
        iconSize:  iconSize, // optional, but by providing the size, the icon will be centered,
                             // otherwise icon will be at [0, 0]
                             // https://stackoverflow.com/questions/46101450/explanation-of-leaflet-custom-icon-latlng-vs-xy-coordinates/46109930#46109930
      })
      marker = <Marker
        position={pos}
        icon={icon}
        zIndexOffset={zIndexOffset || 0}
        eventHandlers={(onClick ? { click: onClick } : {})}
        {...rest}
      />
    }

    return (
      <JsxMarkerContent onRender={html => this.onInsideRender(html)}>
        {this.props.children}
        {marker}
      </JsxMarkerContent>
    )
  }
}

export default JsxMarker
