import { useState, useEffect } from 'react'
import {
  Avatar,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  Typography,
} from '@material-ui/core'
import {
  Warning,
} from '@material-ui/icons'
import { withStyles } from '@material-ui/core/styles'

const styles = theme => ({
})

function ConfirmTakeOffDialog({ classes, open, onClose, onConfirm, actionText, buttonText }) {
  const [ answer,  setAnswer  ] = useState(0)
  const [ attempt, setAttempt ] = useState('')

  useEffect(() => setAnswer(Math.floor(1000 + Math.random() * 9000)), [])

  function checkAttempt() {
    if (parseInt(attempt) === answer)
      onConfirm(answer)
    else
      onClose()
  }
 
  return (
    <Dialog open={open} onClose={onClose} maxWidth='xs' fullWidth>
      <DialogTitle>Confirm {actionText} by typing {answer} below</DialogTitle>
      <DialogContent>
        <TextField
          fullWidth
          value={attempt}
          variant='outlined'
          onChange={(evt) => setAttempt(evt.target.value)}
        />
        <br /><br />
        <Grid container direction='row'>
          <Grid item xs={2}>
            <Avatar><Warning color='secondary' /></Avatar>
          </Grid>
          <Grid item xs={10}>
            <Typography variant='body2'>
              <b>Reminder</b><br />It is an offence to fly a drone without
              the necessary BVLOS permits in your country.
            </Typography>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button color='primary' variant='outlined' onClick={onClose}>
          Cancel
        </Button>
        <Button color='primary' variant='contained' onClick={checkAttempt}>
          {buttonText}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default withStyles(styles)(ConfirmTakeOffDialog)
