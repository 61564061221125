import React from "react"
import { FormControlLabel, Checkbox, FormGroup, FormLabel } from "@material-ui/core"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { toggleObstacleAvoidance, toggleObstacleDetection } from "../modules/actions/commandActions"

const DaaControls = ({
    toggleObstacleAvoidance,
    toggleObstacleDetection,
    obstacleAvoidance,
    obstacleDetection,
    setDaaUpdated,
}) => {
    return (
        <FormGroup>
            <FormLabel>DAA</FormLabel>
            <FormControlLabel
                control={
                    <Checkbox checked={obstacleDetection} onChange={toggleObstacleDetection} />
                }
                label="Obstacle Detection"
            ></FormControlLabel>
            <FormControlLabel
                control={
                    <Checkbox
                        checked={obstacleAvoidance}
                        disabled={!obstacleDetection}
                        onChange={() => {
                            setDaaUpdated(true)
                            toggleObstacleAvoidance()
                        }}
                    />
                }
                label="Obstacle Avoidance"
            ></FormControlLabel>
        </FormGroup>
    )
}

const mapDispatchToProps = dispatch => {
    return bindActionCreators(
        {
            toggleObstacleDetection,
            toggleObstacleAvoidance,
        },
        dispatch
    )
}

const mapStateToProps = ({ daa: { obstacleDetection, obstacleAvoidance } }) => {
    return { obstacleDetection, obstacleAvoidance }
}

export default connect(mapStateToProps, mapDispatchToProps)(DaaControls)
