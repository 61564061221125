export const WARNING_TIMEOUT_DURATION = 5000

export const ADD_WARNING = 'ADD_WARNING'
export const UPDATE_WARNING_TIMEOUT = 'UPDATE_WARNING_TIMEOUT'

export const addWarning = warning => {
  return dispatch => {
    dispatch({
      type: ADD_WARNING,
      warning,
    })
    setTimeout(_ => {
      dispatch({
        type: UPDATE_WARNING_TIMEOUT,
        // code: warning.code,
        name: warning.name,
      })
    }, WARNING_TIMEOUT_DURATION)
  }
}