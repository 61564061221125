import { withStyles } from '@material-ui/core/styles'
import { dockCategorytoSVG } from '../../static/constants/droneSVG'

import JsxMarker from '../../components/JsxMarker'
import theme from '../../utils/site-theme'

// TODO: Create a backend for dock (similar concept as drone, battery, payload), make Airbotics dock a model, and store this as its Avatar
// https://bitbucket.org/garudarobotics/garuda-horizon/raw/8f7e07e92a86d30371769009787e8260ad11fd33/gcs/web/src/static/images/box.png
const styles = theme => ({
  iconContainer: {
    position:        'absolute',
    width:           theme.spacing(8),
    height:          theme.spacing(8),
    display:         'flex',
    justifyContent:  'center',
    alignItems:      'center',
  },
  dockHighlighted: {
    position:        'absolute',
    fill:            theme.palette.warning.main,
    stroke:          theme.palette.warning.main,
    strokeWidth:     4,
    backgroundColor: theme.palette.bluegrey.light + '33',
    borderRadius:    theme.spacing(5),
  },
  dock: {
    position:        'absolute',
    fill:            theme.palette.secondary.main,
    stroke:          theme.palette.secondary.dark,
    strokeWidth:     4,
    backgroundColor: theme.palette.bluegrey.light + '33',
    borderRadius:    theme.spacing(5),
  },
})

function DockMarker({ key, lat, lng, highlighted, classes }) {
  if (highlighted) {
    return (
      <JsxMarker key={key + 'h'} iconSize={[64, 64]} position={{ lat, lng }} zIndexOffset={500}>
        <div className={classes.iconContainer}>
          <svg className={classes.dockHighlighted} width='100%' height='100%' viewBox='0 0 450 450'>
            <path fill={theme.palette.warning.main} d={dockCategorytoSVG('Airobotics')}
              strokeWidth='10px'
            />
          </svg>
        </div>
      </JsxMarker>
    )
  } else {
    return (
      <JsxMarker key={key + 'd'} iconSize={[64, 64]} position={{ lat, lng }} zIndexOffset={500}>
        <div className={classes.iconContainer}>
          <svg className={classes.dock} width='100%' height='100%' viewBox='0 0 450 450'>
            <path fill={theme.palette.secondary.main} d={dockCategorytoSVG('Airobotics')}
              strokeWidth='10px'
            />
          </svg>
        </div>
      </JsxMarker>
    )
  }
}

export default withStyles(styles)(DockMarker)
