import axios from 'axios'
export const RETRIEVE_DEPLOYMENT_SUCCESS = 'RETRIEVE_DEPLOYMENT_SUCCESS'
export const RETRIEVE_DEPLOYMENT_LOADING = 'RETRIEVE_DEPLOYMENT_LOADING'
export const RETRIEVE_DEPLOYMENT_FAILURE = 'RETRIEVE_DEPLOYMENT_FAILURE'
export const GET_ALL_DEPLOYMENTS_SUCCESS = 'GET_ALL_DEPLOYMENTS_SUCCESS'
export const GET_ALL_DEPLOYMENTS_FAILURE = 'GET_ALL_DEPLOYMENTS_FAILURE'

const DEPLOYMENTS_API = process.env.REACT_APP_DEPLOYMENTS_API
if (!DEPLOYMENTS_API)
  throw Error('REACT_APP_DEPLOYMENTS_API needs to be specified')
const PAYLOAD_API = process.env.REACT_APP_PAYLOAD_API
if (!PAYLOAD_API)
  throw Error('REACT_APP_PAYLOAD_API needs to be specified')

const DEPLOYMENT_STORAGE = 'DEPLOYMENT'

export function clearDeployment() {
  return dispatch => {
    dispatch({
      type: RETRIEVE_DEPLOYMENT_SUCCESS,
      data: { data: null }
    })
  }
}

export function getAllDeployments() {
  return dispatch => {
    axios
    .get(`${DEPLOYMENTS_API}/`)
    .then(async res => {
      console.log('Successfully retrieved all deployments:', res)
      if (res.data?.success) {
        dispatch({
          type: GET_ALL_DEPLOYMENTS_SUCCESS,
          deployments: res.data.data
        })
      } else {
        dispatch({
          type: GET_ALL_DEPLOYMENTS_FAILURE,
          err: 'Failed to get all deployments'
        })
      }
    })
    .catch(err => {
      console.error('Error when getting all deployments:', err)
      dispatch({
        type: GET_ALL_DEPLOYMENTS_FAILURE,
        err: err
      })
    })
  }
}

export function retrieveDeployment(deploymentId) {
  return dispatch => {
    dispatch({
      type: RETRIEVE_DEPLOYMENT_LOADING
    })
    axios
    .get(`${DEPLOYMENTS_API}/${deploymentId}`)
    .then(async deploymentResponse => {
      console.log('Successfully retrieved deployment:', deploymentResponse)

      // Get the payload information (can be nothing)
      const payloadResponse = await axios
      .get(`${PAYLOAD_API}/${deploymentId}`)
      .then(res => {
        console.log('Successfully retrieved payloads:', res)
        return res.data
      })
      .catch(err => {
        console.info('Error retrieving payload, ignoring.')
      })

      const deploymentData = deploymentResponse.data
      const payloadData    = payloadResponse?.data

      // If the retrieval is successful
      if (deploymentData.success) {
        window.localStorage.setItem(DEPLOYMENT_STORAGE,
          JSON.stringify({
            ...deploymentData.data,
            payloads: payloadResponse?.status === 'success' ? payloadData?.payloads : []
        }))

        // Dispatch the deployment information
        dispatch({
          type: RETRIEVE_DEPLOYMENT_SUCCESS,
          data: {
            ...deploymentData,
            data: {
              ...deploymentData.data,
              payloads: payloadData?.payloads
            }
          }
        })
      } else {
        dispatch({
          type: RETRIEVE_DEPLOYMENT_FAILURE,
          err: 'Failed to retrieve deployment information'
        })
      }
    })
    .catch(err => {
      console.error('Error in retrieving deployment:', err)
      dispatch({
        type: RETRIEVE_DEPLOYMENT_FAILURE,
        err: err
      })
    })
  }
}


