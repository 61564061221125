
import moment from 'moment' // TODO: DEPRECATE and migrate to date-fns
import LiveTelemetryListener from '../../telemetry/LiveTelemetryListener'

import {
  Grid,
  Typography,
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'

const styles = theme => ({
  center: {
    textAlign: 'center',
  },
  flightStateBox: {
    padding: theme.spacing(1),
    border: '1px solid ' + theme.palette.bluegrey.dark
  },
  telemBox: {
    padding: theme.spacing(1),
    border: '1px solid ' + theme.palette.bluegrey.dark
  }
})

function MiniTelemetry({
  classes,
  selectedDrone,
  selectedDroneOnline,
  mission,
  telemetry,
  status,
}) {

  // If the telemetry arrives as props, how come the LiveTelemetryListener component only wraps the telem section?
  return (
    <LiveTelemetryListener>
      <Typography variant='h5' className={classes.center}>
        { selectedDrone.name || 'Unnamed Drone' }
      </Typography>
      <Typography variant='body1' color='secondary'  className={classes.center}>
        { selectedDrone.model?.name || 'Unknown Model' }
      </Typography>
      <Grid container>
        <Grid item xs={12} className={classes.flightStateBox}>
          <Typography variant='overline'>Flight Status</Typography>
          <Typography variant='body1'>{status || 'Unknown'}</Typography>
        </Grid>

        {selectedDroneOnline && <>
          { (status === 'Online' || true) &&
            <Grid item xs={12} container className={classes.telemBox}>
              <Grid item xs={6}>
                <Typography variant='overline'>Battery</Typography>
                <Typography variant='body1'>{selectedDrone && telemetry ? telemetry.batt_capacity : '-'} %</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant='overline'>AGL</Typography>
                <Typography variant='body1'>{selectedDrone && telemetry ? Math.round(telemetry.agl / 100) : '-'} m</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant='overline'>Speed</Typography>
                <Typography variant='body1'>{selectedDrone && telemetry ? (telemetry.air_speed / 100) : '-'} m/s</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant='overline'>Flight State</Typography>
                <Typography variant='body1'>{selectedDrone && telemetry ? telemetry.flight_state : '-'}</Typography>
              </Grid>
            </Grid>
          }
          <Grid item xs={12} container className={classes.telemBox}>
            <Grid item xs={6}>
              <Typography variant='overline'>Mission</Typography>
              <Typography variant='body1'>{selectedDrone && mission ? mission.name : 'No mission'}</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant='overline'>Mission Time</Typography>
              <Typography variant='body1'>{(selectedDrone && telemetry) ? `${moment.utc(moment.duration(telemetry.flight_time, 'seconds').asMilliseconds()).format('mm:ss')}` : '-'}</Typography>
            </Grid>
          </Grid>
        </>}
      </Grid>
    </LiveTelemetryListener>
  )
}

export default withStyles(styles)(MiniTelemetry)
