import {
  Grid,
  Typography,
} from '@material-ui/core'
import withStyles from '@material-ui/core/styles/withStyles'

const card = {
  width: 'auto',
  height: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}

const LEVELS = ['OFF', 'D', 'A']

const styles = theme => ({
  style: {
    width: '100%',
    borderBottom: '2px solid #fa8231',
  },
  neutral: {
    border: '2px solid #fa8231',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  card,
  selected: {
    ...card,
    background: theme.palette.success.main,
  },
})

const DaaAutonomyLevel = ({ classes, autonomyLevel }) => {
  const cards = [...Array(3).keys()].map((level, i) => (
    <Grid item xs={4} key={i}>
      <div className={
        level === autonomyLevel
          ? level === 0
            ? classes.neutral
            : classes.selected
          : classes.card
      }>
        <Typography variant='h6'>{LEVELS[level]}</Typography>
      </div>
    </Grid>
  ))
  return (
    <Grid className={classes.style} container direction='row'>
      {cards}
    </Grid>
  )
}

export default withStyles(styles)(DaaAutonomyLevel)
