import { useState, useEffect } from 'react'
import { useInterval } from 'usehooks-ts'
import {
  pingAircraftApi,
  pingPayloadApi,
} from '../api/aircraft'
import {
  pingOnboardConfigApi,
  pingGeoAiConfigApi,
} from '../api/config'
import {
  pingFlightPlansApi,
  pingFlightStatusWebApi,
  getFlightStatusWssUrl,
  getTelemetryWssUrl,
  getCommandWssUrl,
  pingTelemetryWebApi,
  pingTelemetryDroneApi,
  pingCommandWebApi,
  pingCommandDroneApi,
  pingPayloadTelemetryWebApi,
  pingPayloadTelemetryDroneApi,
  pingPayloadCommandWebApi,
  pingPayloadCommandDroneApi,
  pingWarningsWebApi,
  pingWarningsDroneApi,
  pingDaaWebApi,
  pingDaaDroneApi,
  pingMLModelTelemetryWebApi,
  pingMLModelCommandWebApi,
} from '../api/liveflights'
import {
  getServerName,
  getServerLocation,
  pingProjectsApi,
  pingDeploymentsApi,
  pingMediaApi,
  pingLegacyApi,
  pingAirspaceApi,
  pingWeatherApi,
  pingGeospatialApi,
} from '../api/misc'
import { pingSkyStream } from '../api/skystream'

import Header from '../components/Header'
import WSContainer from '../components/WSContainer'
import PlexHealthConfig from './Components/PlexHealthConfig'
import StatusGroup from './Components/StatusGroup'
import Status      from './Components/Status'
import {
  Button,
  CssBaseline,
  IconButton,
  Grid,
  Typography,
} from '@material-ui/core'
import {
  PlayArrow,
  Pause,
  Settings,
} from '@material-ui/icons'
import { withStyles } from '@material-ui/core/styles'

const styles = theme => ({
  root: {
    pading: theme.spacing(2),
    maxWidth: theme.breakpoints.values.lg,
    margin: '0 auto',
  },
  titleContainer: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    display: 'flex',
    justifyContent: 'space-between'
  },
  overline: {
    lineHeight: 1,
  },
  title: {
    lineHeight: 1,
    padding: theme.spacing(1, 3),
  },
  grid: {
    width: '100%',
  },
  settings: {
    width: theme.spacing(10),
  },
  pollControls: {
    position: 'fixed',
    bottom: theme.spacing(2),
    right: theme.spacing(2),
    textTransform: 'none',
  },
})

function PlexHealth({ classes }) {
  const [ showConfig, setShowConfig ] = useState(false)
  const [ svcOnline,  setSvcOnline  ] = useState([])
  const [ isPolling,  setIsPolling  ] = useState(true)
  const [ pollDelay,  setPollDelay  ] = useState(20000)
  const [ timeStamp,  setTimeStamp  ] = useState('none')
  const [ telemWsURL, setTelemWsURL ] = useState('')
  const [ telemWsOK,  setTelemWsOK  ] = useState(false)
  const [ commandWsURL, setCommandWsURL ] = useState('')
  const [ commandWsOK,  setCommandWsOK  ] = useState(false)
  const [ statusWsURL,  setStatusWsURL  ] = useState('')
  const [ statusWsOK,   setStatusWsOK   ] = useState(false)

  const services = [
    { title: 'Aircraft Service',    tooltip: 'Aircraft Service',  promise: pingAircraftApi },
    { title: 'Payload Service',     tooltip: 'NCS UA only',       promise: pingPayloadApi },
    { title: 'Project Service',     tooltip: 'Legacy Project',    promise: pingProjectsApi },
    { title: 'Deployment Service',  tooltip: 'Legacy Deployment', promise: pingDeploymentsApi },
    { title: 'Media Service',           tooltip: '', promise: pingMediaApi },

    { title: 'Flight Plans Service',    tooltip: '', promise: pingFlightPlansApi },
    { title: 'Flight Status Service',   tooltip: '', promise: pingFlightStatusWebApi },
    { title: 'Telemetry Web Service',   tooltip: '', promise: pingTelemetryWebApi },
    { title: 'Telemetry Drone Service', tooltip: '', promise: pingTelemetryDroneApi },
    { title: 'Command Web Service',     tooltip: '', promise: pingCommandWebApi },
    { title: 'Command Drone Service',   tooltip: '', promise: pingCommandDroneApi },
    { title: 'Payload Telemetry Web Service',   tooltip: '', promise: pingPayloadTelemetryWebApi },
    { title: 'Payload Telemetry Drone Service', tooltip: '', promise: pingPayloadTelemetryDroneApi },
    { title: 'Payload Command Web Service',     tooltip: '', promise: pingPayloadCommandWebApi },
    { title: 'Payload Command Drone Service',   tooltip: '', promise: pingPayloadCommandDroneApi },
    { title: 'Warnings Web Service',    tooltip: '', promise: pingWarningsWebApi },
    { title: 'Warnings Drone Service',  tooltip: '', promise: pingWarningsDroneApi },
    { title: 'DAA Web Service',         tooltip: 'Detect And Avoid (web api)',   promise: pingDaaWebApi },
    { title: 'DAA Drone Service',       tooltip: 'Detect And Avoid (drone api)', promise: pingDaaDroneApi },

    { title: 'ML Model Telemetry Service', tooltip: 'Machine Learning Model (telem)',   promise: pingMLModelTelemetryWebApi },
    { title: 'ML Model Command Service',   tooltip: 'Machine Learning Model (command)', promise: pingMLModelCommandWebApi },

    { title: 'Airspace Service',        tooltip: '', promise: pingAirspaceApi },
    { title: 'Meteorology Service',     tooltip: '', promise: pingWeatherApi },
    { title: 'Geospatial Service',      tooltip: '', promise: pingGeospatialApi },

    { title: 'Garuda Plex (Legacy) Service', tooltip: 'Old API Gateway', promise: pingLegacyApi },
    { title: 'Onboard Config Service',       tooltip: 'On-board Config Management Service', promise: pingOnboardConfigApi },
    { title: 'Geo AI Config Service',        tooltip: 'Geo AI Config Management Service', promise: pingGeoAiConfigApi },

    { title: 'SkyStream', tooltip: 'Video Server of Plex Horizon', promise: pingSkyStream },
  ]

  useInterval(() => {
    Promise.allSettled(services.map(s => (s.promise)()))
    .then(results => {
      const values = results.map(r => r.value)
      console.log('Ping results', values)
      setSvcOnline(values)
      setTimeStamp(new Date().toLocaleString())
    })
  }, isPolling ? pollDelay : null)

  useEffect(() => {
    window.addEventListener('offline', (e) => { console.log('Plex Horizon is offline') })
    window.addEventListener('online',  (e) => { console.log('Plex Horizon is online') })
    setStatusWsURL(getFlightStatusWssUrl())
    setTelemWsURL(getTelemetryWssUrl())
    setCommandWsURL(getCommandWssUrl())
  // eslint-disable-next-line
  }, [])

  return (
    <>
      <CssBaseline />
      <Header />
      { showConfig &&
        <PlexHealthConfig
          open={showConfig}
          onClose={() => setShowConfig(false)}
          pollDelay={pollDelay}
          setPollDelay={setPollDelay}
        />
      }
      <div className={classes.root}>
        <div className={classes.titleContainer}>
          <div className={classes.title}>
            <Typography variant='overline' className={classes.overline}>
              Plex Health
            </Typography>
            <Typography variant='h5'>
              {getServerName()} Node (at {getServerLocation()})
            </Typography>
          </div>
          <IconButton color='inherit' className={classes.settings} onClick={() => setShowConfig(true)}>
            <Settings />
          </IconButton>
        </div>
        <div className={classes.pollControls}>
          <Button variant='outlined' onClick={() => setShowConfig(true)}>
            Poll Delay: {pollDelay / 1000} s
          </Button>
          <Button variant='outlined' disabled>
            Last Updated: {timeStamp}
          </Button>
          <Button variant={ isPolling ? 'outlined' : 'contained' }
            color='primary' startIcon={ isPolling ? <Pause /> : <PlayArrow /> }
            onClick={() => setIsPolling(!isPolling)}>
            { isPolling ? 'Stop Polling' : 'Start Polling' }
          </Button>
        </div>
        <Grid container>
          <Grid item sm={3} className={classes.grid}>
            <StatusGroup title='Internet Connection' tooltip='Your connection to the Internet'>
              <Status bad enable={!navigator.onLine}>OFFLINE</Status>
              <Status good enable={navigator.onLine}>ONLINE</Status>
            </StatusGroup>
          </Grid>
          <Grid item sm={3} className={classes.grid}>
            <WSContainer url={statusWsURL}
              onOpen={() => setStatusWsOK(true)}
              onMessage={() => setStatusWsOK(true)}
              onReconnect={() => setStatusWsOK(true)}
              onError={() => setStatusWsOK(false)}
              onClose={() => setStatusWsOK(false)}>
              <StatusGroup title='Flight Status WS Connection' tooltip=''>
                <Status bad enable={!statusWsOK}>DISCONNECTED</Status>
                <Status good enable={statusWsOK}>CONNECTED</Status>
              </StatusGroup>
            </WSContainer>
          </Grid>
          <Grid item sm={3} className={classes.grid}>
            <WSContainer url={telemWsURL}
              onOpen={() => setTelemWsOK(true)}
              onMessage={() => setTelemWsOK(true)}
              onReconnect={() => setTelemWsOK(true)}
              onError={() => setTelemWsOK(false)}
              onClose={() => setTelemWsOK(false)}>
              <StatusGroup title='Telemetry Web WS Connection' tooltip=''>
                <Status bad enable={!telemWsOK}>DISCONNECTED</Status>
                <Status good enable={telemWsOK}>CONNECTED</Status>
              </StatusGroup>
            </WSContainer>
          </Grid>
          <Grid item sm={3} className={classes.grid}>
            <WSContainer url={commandWsURL}
              onOpen={() => setCommandWsOK(true)}
              onMessage={() => setCommandWsOK(true)}
              onReconnect={() => setCommandWsOK(true)}
              onError={() => setCommandWsOK(false)}
              onClose={() => setCommandWsOK(false)}>
              <StatusGroup title='Command Web WS Connection' tooltip=''>
                <Status bad enable={!commandWsOK}>DISCONNECTED</Status>
                <Status good enable={commandWsOK}>CONNECTED</Status>
              </StatusGroup>
            </WSContainer>
          </Grid>
          { services.map((s, i) =>
              <Grid item sm={3} key={s.title} className={classes.grid}>
                <StatusGroup title={s.title} tooltip={s.tooltip}>
                  <Status bad enable={!svcOnline[i]}>OFFLINE</Status>
                  <Status good enable={svcOnline[i]}>ONLINE</Status>
                </StatusGroup>
              </Grid>
            )
          }
        </Grid>
        <br />
        <br />
        <br />
        <br />
      </div>
    </>
  )
}

export default withStyles(styles)(PlexHealth)
