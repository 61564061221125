// Wrapper for everything else that has yet to fit a nice bucket
import axios from 'axios'

const SERVER_NAME      = process.env.REACT_APP_API_SERVER_NAME
const SERVER_LOCATION  = process.env.REACT_APP_API_SERVER_LOCATION
const PROJECTS_API     = process.env.REACT_APP_PROJECTS_API
const DEPLOYMENTS_API  = process.env.REACT_APP_DEPLOYMENTS_API
const MEDIA_API        = process.env.REACT_APP_MEDIA_API
const LEGACY_API_URL   = process.env.REACT_APP_API_URL
const WEATHER_API      = process.env.REACT_APP_METEOROLOGY_API
const AIRSPACE_API     = process.env.REACT_APP_AIRSPACE_WEB_API
const GEOSPATIAL_API   = process.env.REACT_APP_GEOSPATIAL_API

export function getServerName()     { return SERVER_NAME }
export function getServerLocation() { return SERVER_LOCATION }

function ping(url) {
  return axios.get(url, { timeout: 2000 })
  .then(res => res.status === 200 ? res.data : false)
  .catch(err => false)
}

export function pingProjectsApi()    { return ping(`${PROJECTS_API}/sanity`) }
export function pingDeploymentsApi() { return ping(`${DEPLOYMENTS_API}/sanity`) }
export function pingMediaApi()       { return ping(`${MEDIA_API}/sanity`) }

export function pingLegacyApi() { return ping(`${LEGACY_API_URL}/v1`) }

export function pingAirspaceApi()   { return ping(`${AIRSPACE_API}/sanity`) }
export function pingWeatherApi()    { return ping(`${WEATHER_API}/sanity`) }
export function pingGeospatialApi() { return ping(`${GEOSPATIAL_API}/sanity`) }