import React, { Component }   from 'react'
import { bindActionCreators } from 'redux'
import { connect }            from 'react-redux'
import { push }               from 'connected-react-router'

import { retrieveMission, chooseNewMission } from '../modules/actions/mission'
import { retrieveDeployment } from '../modules/actions/deployment'
import MissionForm            from './MissionForm'
import MissionContext         from './MissionContext'

function reverseChronologicalOrder(a, b) {
  if (a.last_modified_date > b.last_modified_date) return -1
  if (a.last_modified_date < b.last_modified_date) return 1
  return 0
}

const msgStyle = {
  width: '100%',
  margin: '24px auto'
}

class MissionFormContainer extends Component {
  componentDidMount() {
    const { retrieveMission } = this.props
    retrieveMission()
  }
  render() {
    const {
      loaded,
      loading,
      error,
      missionList,
      deploymentData,
      push,
      retrieveDeployment,
      chooseNewMission,
    } = this.props

    if (error) {
      return (<div style={msgStyle}>
        Error retrieving Flight Plans, try again later.
      </div>)
    }

    if (!loaded || loading) {
      return (<div style={msgStyle}>
        Loading Flight Plans from Garuda Plex...
      </div>)
    }

    return (
      <MissionContext.Consumer>
        {({ mission, updateMission }) => (
          <MissionForm
            mission={mission}
            updateMission={updateMission}
            push={push}
            deploymentData={deploymentData}
            retrieveDeployment={retrieveDeployment}
            chooseNewMission={chooseNewMission}
            missionList={[...missionList].sort(reverseChronologicalOrder)}
          />
        )}
      </MissionContext.Consumer>
    )
  }
}

const mapStateToProps = state => ({
  loading:        state.mission.missionListLoading,
  loaded:         state.mission.missionListLoaded,
  error:          state.mission.missionListError,
  missionList:    state.mission.missionList,
  deploymentData: state.deployment.deploymentData,
})

const mapDispatchToProps = dispatch => {
  return bindActionCreators({
    retrieveMission,
    retrieveDeployment,
    chooseNewMission,
    push,
  }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(MissionFormContainer)
