import { useState } from 'react'
import Header from '../components/Header'
import InferenceControls from './components/InferenceControls'
import InferenceTable    from './components/InferenceTable'
import {
  CssBaseline,
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'

const styles = theme => ({
  root: {
    height: `calc(100vh - ${theme.spacing(6)}px)`,
    padding: theme.spacing(2),
    backgroundColor: theme.palette.bluegrey.dark,
  },
})

function SelectObject({ classes }) {
  const [ modelId, setModelId ] = useState('')
  return (<>
    <CssBaseline />
    <Header />
    <div className={classes.root}>
      <InferenceControls
        modelId={modelId}
        setModelId={setModelId}
      />
      <InferenceTable
        modelId={modelId}
      />
    </div>
  </>)
}

export default withStyles(styles)(SelectObject)
