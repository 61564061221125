import React from 'react'
import { connect } from 'react-redux'
import { CallbackComponent } from 'redux-oidc'
import userManager from '../utils/userManager'
import { history } from '../modules/store'

class Callback extends React.Component {
  render() {
    return (
      <CallbackComponent
        userManager={userManager}
        successCallback={() => {
          history.push('/mission')
        }}
        errorCallback={error => {
          console.error('Error in callback', error)
        }}>
        <div>Loading Plex Horizon...</div>
      </CallbackComponent>
    )
  }
}

export default connect(
  null,
  null
)(Callback)
