// Wrapper for the rest of Live Flights API interaction (main ones in redux (modules/actions/reducers))
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import axios from 'axios'
import { getDecodedToken } from './accounts'

const FLIGHT_PLANS_API      = process.env.REACT_APP_FLIGHT_PLANS_API
const FLIGHT_STATUS_WEB_API = process.env.REACT_APP_FLIGHT_STATUS_WEB_API
const FLIGHT_STATUS_WSS_URL = process.env.REACT_APP_FLIGHT_STATUS_WSS_URL

const TELEMETRY_WSS_URL     = process.env.REACT_APP_TELEMETRY_WSS_URL
const TELEM_WEB_API         = process.env.REACT_APP_TELEMETRY_WEB_API
const TELEM_DRONE_API       = process.env.REACT_APP_TELEMETRY_DRONE_API

const COMMAND_WEB_API       = process.env.REACT_APP_COMMAND_WEB_API
const COMMAND_WSS_URL       = process.env.REACT_APP_COMMAND_WSS_URL
const COMMAND_DRONE_API     = process.env.REACT_APP_COMMAND_DRONE_API

const PAYLOAD_TELEMETRY_WSS_URL   = process.env.REACT_APP_TELEMETRY_PAYLOAD_WSS_URL
const PAYLOAD_TELEMETRY_WEB_API   = process.env.REACT_APP_TELEMETRY_PAYLOAD_WEB_API
const PAYLOAD_TELEMETRY_DRONE_API = process.env.REACT_APP_TELEMETRY_PAYLOAD_DRONE_API

const PAYLOAD_COMMAND_WEB_API     = process.env.REACT_APP_COMMAND_PAYLOAD_WEB_API
const PAYLOAD_COMMAND_DRONE_API   = process.env.REACT_APP_COMMAND_PAYLOAD_DRONE_API

const WARNINGS_WEB_API   = process.env.REACT_APP_WARNINGS_WEB_API
const WARNINGS_DRONE_API = process.env.REACT_APP_WARNINGS_DRONE_API

const DAA_WEB_API   = process.env.REACT_APP_DAA_WEB_API
const DAA_DRONE_API = process.env.REACT_APP_DAA_DRONE_API

const ML_MODEL_TELEMETRY_WSS_URL = process.env.REACT_APP_TELEMETRY_ML_MODEL_WSS_URL
const ML_MODEL_TELEMETRY_WEB_API = process.env.REACT_APP_TELEMETRY_ML_MODEL_WEB_API
const ML_MODEL_COMMAND_WEB_API   = process.env.REACT_APP_COMMAND_ML_MODEL_WEB_API

export function getFlightStatusWssUrl() {
  const { company_id, drone_id, access_token } = getDecodedToken()
  return `${FLIGHT_STATUS_WSS_URL}/?access_token=${access_token}&companyId=${company_id}&droneId=${drone_id}`
}
export function getTelemetryWssUrl() {
  const { company_id, drone_id, access_token } = getDecodedToken()
  return `${TELEMETRY_WSS_URL}/?access_token=${access_token}&companyId=${company_id}&droneId=${drone_id}`
}
export function getCommandWssUrl() {
  const { company_id, drone_id, access_token } = getDecodedToken()
  return `${COMMAND_WSS_URL}/?access_token=${access_token}&companyId=${company_id}&droneId=${drone_id}`
}
export function getPayloadTelemetryWssUrl() {
  const { company_id, access_token } = getDecodedToken()
  return `${PAYLOAD_TELEMETRY_WSS_URL}/?access_token=${access_token}&companyId=${company_id}`
}
export function getInferenceWssUrl(videoId) {
  const { company_id, access_token } = getDecodedToken()
  return `${ML_MODEL_TELEMETRY_WSS_URL}/?access_token=${access_token}&companyId=${company_id}&videoId=${videoId}`
}

function ping(url) {
  return axios.get(`${url}/sanity`, { timeout: 8000 })
    .then(res => res.status === 200 ? res.data : false)
    .catch(err => false)
}

export function pingFlightPlansApi()           { return ping(FLIGHT_PLANS_API) }
export function pingFlightStatusWebApi()       { return ping(FLIGHT_STATUS_WEB_API) }
export function pingTelemetryWebApi()          { return ping(TELEM_WEB_API) }
export function pingTelemetryDroneApi()        { return ping(TELEM_DRONE_API) }
export function pingCommandWebApi()            { return ping(COMMAND_WEB_API) }
export function pingCommandDroneApi()          { return ping(COMMAND_DRONE_API) }
export function pingPayloadTelemetryWebApi()   { return ping(PAYLOAD_TELEMETRY_WEB_API) }
export function pingPayloadTelemetryDroneApi() { return ping(PAYLOAD_TELEMETRY_DRONE_API) }
export function pingPayloadCommandWebApi()     { return ping(PAYLOAD_COMMAND_WEB_API) }
export function pingPayloadCommandDroneApi()   { return ping(PAYLOAD_COMMAND_DRONE_API) }
export function pingWarningsWebApi()           { return ping(WARNINGS_WEB_API) }
export function pingWarningsDroneApi()         { return ping(WARNINGS_DRONE_API) }
export function pingDaaWebApi()                { return ping(DAA_WEB_API) }
export function pingDaaDroneApi()              { return ping(DAA_DRONE_API) }
export function pingMLModelTelemetryWebApi()   { return ping(ML_MODEL_TELEMETRY_WEB_API) }
export function pingMLModelCommandWebApi()     { return ping(ML_MODEL_COMMAND_WEB_API) }

let ws

async function openWS(arg) {
  return new Promise(function (resolve, reject) {
    const onOpen = () => {
      resolve(ws)
    }
    if (ws)
      return resolve()

    if (arg.drone_id)
      ws = new WebSocket(`${FLIGHT_STATUS_WSS_URL}?drone_id=${arg.drone_id}&companyId=${arg.company_id}&access_token=${arg.access_token}`)
    else
      ws = new WebSocket(`${FLIGHT_STATUS_WSS_URL}?companyId=${arg.company_id}&access_token=${arg.access_token}`)

    ws.addEventListener('open', onOpen)
  })
}

function reduceArray(array, key) {
  if (!array || !Array.isArray(array))
    return {}
  return array.reduce((acc, curr) => {
    acc[curr[key]] = curr
    return acc
  }, {})
}
export const flightStatusApi = createApi({
  reducerPath: 'flightStatusApi',
  baseQuery: fetchBaseQuery({
    baseUrl: FLIGHT_STATUS_WEB_API,
    prepareHeaders: (headers, { getState }) => {
      const user = getState().oidc?.user
      if (user)
        headers.set('Authorization', `Bearer ${user.access_token}`)
      headers.set('Content-Type', 'application/json')
      headers.set('Accept', 'application/json')
      return headers
    },
  }),
  tagTypes: ['Flight Status', 'Flight Status Roles', 'Flight Status Handover'],
  endpoints: (builder) => ({

    // === FLIGHT STATUS ===
    getAllFlightStatus: builder.query({
      // TODO: is there a way to provide arugments different for websocket?
      query: ({ drone_id, only_ongoing_flights }) => {
        if(drone_id){
          return `/?drone_id=${drone_id}&only_ongoing_flights=${only_ongoing_flights ? 'true' : 'false'}`
        }else{
          return `/?only_ongoing_flights=${only_ongoing_flights ? 'true' : 'false'}`
        }
      },
      providesTags: (flightStatuses, error, arg) => {
        if (flightStatuses) {
          return Object.keys(flightStatuses).map((r) => ({ type: 'Flight Status', id: r }))
        } else {
          return ['Flight Status']
        }
      },
      transformResponse: (response) => reduceArray(response.data.flights, 'drone_id'),
      onCacheEntryAdded: async(arg, { updateCachedData, cacheEntryRemoved, cacheDataLoaded }) => {
        await cacheDataLoaded

        await openWS(arg)
        ws.addEventListener('message', (event)=>{
          updateCachedData((draft)=>{
            const request = JSON.parse(event.data)
            if (request?.data?.flight_status) {
              draft[request.data.flight_status.drone_id] = request.data.flight_status
            }
            return draft
          })
        })

        await cacheEntryRemoved
        ws.close()
      }
    }),
    getFlightStatusById: builder.query({
      query: (flight_status_id) => `/${flight_status_id}`,
      providesTags: ['Flight Status'],
    }),
    updateFlightStatus: builder.mutation({
      query: ({ flight_status_id, last_received_state }) => ({
        url: `/${flight_status_id}`,
        method: 'PATCH',
        body: { last_received_state: last_received_state }, // only valid value is ENDED
      }),
      invalidatesTags: ['Flight Status'],
    }),

    // === FLIGHT STATUS ROLES ===
    updateFlightStatusRoles: builder.mutation({
      query: ({ drone_id, role }) => {
        const formatUser = (user) => {
          const userInfo = {}
          const takeFields = ['user_id', 'username', 'email', 'name']
          for (const key of takeFields) {
            userInfo[key] = user[key]
          }
          return userInfo
        }

        return ({
          method: 'POST',
          url: `/${drone_id}/roles`,
          body: {
            uas_ops_lead: formatUser(role.uas_ops_lead),
            ua_pilot_primary: formatUser(role.ua_pilot_primary),
            ua_pilot_secondary: role.ua_pilot_secondary.map(formatUser)
          }
        })
      },
      providesTags: ['Flight Status Roles']
    }),

    // === FLIGHT STATUS HANDOVER ===
    getActiveHandoverRequests: builder.query({
      query: ({ drone_id, company_id, access_token }) => `/${drone_id}/requests`,
      providesTags: (result, error, arg) => {
        return [{ type: 'Flight Status Handover', id: arg.handover_id }]
      },
      transformResponse: (response) => reduceArray(response.data.handover_requests, 'handover_id'),
      async onCacheEntryAdded(arg, { updateCachedData, cacheEntryRemoved, cacheDataLoaded }) {
        await cacheDataLoaded

        await openWS(arg)
        // TODO: see if there is a way to make it reuse same connections
        ws.addEventListener('message', (event) =>{
          updateCachedData((draft) => {
            const request = JSON.parse(event.data)
            if (request?.data?.handover_request && 'handover_id' in request?.data?.handover_request) {
              console.info('Handover WS: update handover_id')

              draft[request.data.handover_request.handover_id] = request.data.handover_request
            }
          })
        })
        console.info('Handover WS: waiting for removal')
        await cacheEntryRemoved
        console.info('Handover WS: closing..')
        ws.close()
      },
    }),
    requestControl: builder.mutation({
      query: ({ drone_id }) => ({
        method: 'POST',
        url: `/${drone_id}/request-control`
      }),
      invalidatesTags: ['Flight Status Handover']
    }),
    requestControlOk: builder.mutation({
      query: ({ drone_id, handover_id, approval_token }) => ({
        method: 'POST',
        url: `/${drone_id}/request-control-ok`,
        body: {
          drone_id, handover_id, approval_token
        }
      }),
      invalidatesTags: (result, error, arg) => ({ type: 'Flight Status Handover', id: arg.handover_id })
    }),
    takeControl: builder.mutation({
      query: ({ drone_id, handover_id, approval_token }) => ({
        method: 'POST',
        url: `/${drone_id}/take-control`,
        body: {
          drone_id, handover_id, approval_token
        }
      }),
      invalidatesTags: (result, error, arg) => ({ type: 'Flight Status Handover', id: arg.handover_id })
    }),


  })
})

export const {
  // === FLIGHT STATUS ===
  useGetAllFlightStatusQuery,
  useGetFlightStatusByIdQuery,
  useUpdateFlightStatusMutation,

  // === FLIGHT STATUS ROLES ===
  useUpdateFlightStatusRolesMutation,

  // === FLIGHT STATUS HANDOVER ===
  useGetActiveHandoverRequestsQuery,
  useRequestControlMutation,
  useRequestControlOkMutation,
  useTakeControlMutation,

} = flightStatusApi
