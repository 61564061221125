import { useRef, useEffect } from 'react'
import Sockette from 'sockette'
import { getDecodedToken } from '../api/accounts'

const STATUS_WSS_URL = process.env.REACT_APP_FLIGHT_STATUS_WSS_URL

if (!STATUS_WSS_URL)
  throw Error('Environment Variable REACT_APP_FLIGHT_STATUS_WSS_URL is missing')

function statusWS({
  onOpen, onMessage, onReconnect, onMaximum, onClose, onError
}) {
  const { company_id, access_token } = getDecodedToken()
  if (!company_id || !access_token)
    return null

  const url  = `${STATUS_WSS_URL}?companyId=${company_id}&access_token=${access_token}`
  const ws = new Sockette(url, {
    timeout:     5000, // 5 seconds
    maxAttempts: 3,    // 3 attempts
    onopen:      e => { onOpen      ? onOpen(e)      : console.info('fs ws connected.', e) },
    onmessage:   e => { onMessage   ? onMessage(e)   : console.info('fs ws received:', e) },
    onreconnect: e => { onReconnect ? onReconnect(e) : console.info('fs ws reconnecting..', e) },
    onmaximum:   e => { onMaximum   ? onMaximum(e)   : console.info('fs ws stop attempting.', e) },
    onclose:     e => { onClose     ? onClose(e)     : console.info('fs ws closed.', e) },
    onerror:     e => { onError     ? onError(e)     : console.error('fs ws error:', e) },
  })
  return ws
}

function LiveStatusListener({
  onFlightStatusMessage,
}) {
  // https://stackoverflow.com/questions/60152922/proper-way-of-using-react-hooks-websockets
  const wsRef = useRef(null)

  useEffect(() => {
    if (wsRef.current)
      return // only need to create it once

    wsRef.current = statusWS({ onMessage: onFlightStatusMessage })

      // Cannot close here: it will close after every message...
      // const wsCurrent = wsRef.current
      // return (() => {
      //   wsCurrent.close()
      // })
    // }
  }, [onFlightStatusMessage])

  return null
}

export default LiveStatusListener