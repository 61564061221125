// Default WebRTC Implementation for Video Streaming
// NOTE: this video component may need to be reloaded if it is open before the drone starts streaming, or when streaming pauses and resumes
import { Component } from 'react'
import videojs from 'video.js'
import 'video.js/dist/video-js.css'
import {
  CircularProgress,
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'

const skystreamWebRtcUrl = process.env.REACT_APP_SKYSTREAM_WEBRTC_URL

window.RTCPeerConnection =
  window.RTCPeerConnection || window.mozRTCPeerConnection || window.webkitRTCPeerConnection

window.RTCIceCandidate =
  window.RTCIceCandidate || window.mozRTCIceCandidate || window.webkitRTCIceCandidate

window.RTCSessionDescription =
  window.RTCSessionDescription || window.mozRTCSessionDescription || window.webkitRTCSessionDescription

const DEFAULT_SOCKET_CONNECT_DELAY = 10000
const DEFAULT_STREAM_CONNECT_DELAY = 5000
let SOCKET_CONNECT_DELAY = DEFAULT_SOCKET_CONNECT_DELAY
let STREAM_CONNECT_DELAY = DEFAULT_STREAM_CONNECT_DELAY

let sleepID
const sleep = ms => {
  // console.log((new Date()).toLocaleTimeString(), 'Sleeping', ms, 'ms')
  return new Promise(resolve => { sleepID = setTimeout(resolve, ms) })
}

const styles = theme => ({
  screenContainer: ({ width, height }) => ({
    position:  'relative',
    width:     width || '100%',
    height:    height || '100%',
    backgroundColor: theme.palette.common.black,
  }),
  videoJsContainer: {
    position:  'relative',
    zIndex:    900,
    width:     '100%',
    height:    '100%',
  },
  loadingContainer: {
    position:  'absolute',
    zIndex:    990,
    left:      '50%',
    top:       '50%',
    transform: 'translate(-50%, -50%)',
  },
  errorContainer: {
    zIndex:    980,
    position:  'absolute',
    left:      '50%',
    top:       '50%',
    transform: 'translate(-50%, -50%)',
    borderRadius: theme.spacing(1),
    backgroundColor: theme.palette.error.main,
  },
  error: {
    position:  'relative',
    width:     theme.spacing(25),
    color:     theme.palette.common.white,
    textAlign: 'center',
  },
  statusContainer: {
    zIndex:    970,
    position:  'absolute',
    left:      '50%',
    top:       theme.spacing(2),
    transform: 'translate(-50%, 0)',
    borderRadius: theme.spacing(1),
    border:    '1px solid ' + theme.palette.info.main, 
    color:     theme.palette.info.main,
  },
  status: {
    position:  'relative',
    width:     theme.spacing(25),
    color:     theme.palette.common.white,
    textAlign: 'center',
  },
})


// Provide a unique videoId as props if there are multiple
// instances of WebRTCStream on the same window
class WebRTCStream extends Component {

  streamInfo = {
    applicationName: this.props.applicationName,
    streamName: this.props.streamName,
    sessionId: '[empty]',
  }
  userData = {
    companyId: this.props.companyId,
  }
  videoId = this.props.videoId || 'screen'
  state = {
    streamError:  null,
    streamStatus: null,
    videoLoading: false,
  }

  // Internal variables to manage WebRTC control logic
  // Changing them does not cause React to rerender
  player = null
  currTrack = null
  tryingToPlay = false
  isOnline = true
  wsConnection = null
  peerConnection = null

  showError = (errText, event) => {
    this.setState({
      streamError:  errText || 'Error',
      streamStatus: null,
    })
    if (event)
      console.info(errText, event)
  }

  showOK = (okText, ...rest) => {
    this.setState({
      streamError:  null,
      streamStatus: okText || 'OK'
    })
    if (okText)
      console.info(okText, ...rest)
  }

  componentDidMount() {
    // Check the user connectivity when the component first mounts
    const { onLine } = window.navigator

    // If not online, we indicate that the user is not online.
    if (!onLine)
      this.showError('You are not connected to internet.')

    // If user went offline, stop the entire websocket connectivity
    window.addEventListener('offline', event => {
      this.showError('Your internet connection has been lost.')
      this.isOnline = false
      this.stop()
    })

    // When back online, restart websocket connection and establish ice candidate
    window.addEventListener('online', event => {
      this.isOnline = true

      // If the connection is null, this means that it is the first attempted WebRTC offer creation
      if (this.peerConnection === null) {
        this.showOK('You are now online. Connecting...')
        // Starting first websocket connection
        this.startWebsocketConnection(skystreamWebRtcUrl)
      } else {
        this.showOK('You are now back online. Retrying...')
        // Retry Ice Connectivity when the client is back offline (e.g. when IP address change)
        this.retryIceConnectivity()
      }
    })

    // VideoJS
    //   Constructor https://docs.videojs.com/player
    //   Options https://docs.videojs.com/tutorial-options.html
    // Underlying <video> elem
    //   https://developer.mozilla.org/en-US/docs/Web/HTML/Element/video
    this.player = videojs(
      this.videoNode,
      {
        // <video> options
        autoplay: true,
        controls: false,
        preload: 'none',
        // videojs options
        children: {
          // no bigPlayButton
          controlBar: {
            fullscreenToggle: true,      // to see more
            progressControl: false,      // live stream, no progress
            currentTimeDisplay: true,    // live stream duration
            remainingTimeDisplay: false, // live stream has no remaining time
            liveDisplay: true,           // show "live" symbol
            playToggle: true,            // toggle play and pause
            telemetryToggle: true,       // TOOD: find out what this is?
          },
        },
        inactivityTimeout: 0,
      },
      function onPlayerReady() {
        this.showOK('Video Player is ready to accept live stream.')
      }
    )
    this.start()
    if (this.props.onUpdate)
      this.props.onUpdate(this.videoNode)
  }

  async componentDidUpdate(prevProps) {
    // When there is a change in streamName, restart connection
    if (this.props.streamName !== prevProps.streamName) {
      this.showOK('Switching to ' + this.props.streamName,
        'switch from', prevProps.streamName,
        'to', this.props.streamName)
      this.stop()
      STREAM_CONNECT_DELAY = DEFAULT_STREAM_CONNECT_DELAY
      await sleep(STREAM_CONNECT_DELAY)
      this.start()
    }
  }

  componentWillUnmount() {
    if (this.player)
      this.player.dispose()
    if (sleepID)
      clearTimeout(sleepID)
  }

  start = () => {
    this.streamInfo.applicationName = this.props.applicationName
    this.streamInfo.streamName = this.props.streamName
    this.showOK('Connected to Garuda SkyStream',
      'Skystream URL', skystreamWebRtcUrl)
    this.startWebsocketConnection(skystreamWebRtcUrl)
  }

  // Play video and catch if there is any error
  playVideo = async () => {
    if (this.tryingToPlay) {
      if (!this.state.videoLoading) {
        this.setState({ videoLoading: true })
      }
      return
    }

    this.tryingToPlay = true
    // Create a waiting while loop as long as ready state is less than 2
    // 2: HAVE_CURRENT_DATA
    // 3: HAVE_FUTURE_DATA
    // 4: HAVE_ENOUGH_DATA
    console.log('Ensure video node is available: ', this.videoNode)

    let videoReadyStatus = 0
    try {
      do {
        await sleep(STREAM_CONNECT_DELAY)
        videoReadyStatus = this.videoNode.readyState
        console.log('videoReadyStatus', videoReadyStatus)
      } while (videoReadyStatus < 2)
      await this.videoNode.play()

      if (this.props.onUpdate)
        this.props.onUpdate(this.videoNode)
      this.setState({ videoLoading: false, streamStatus: null })
      this.tryingToPlay = false

    } catch (e) {
      this.showError('Unhandled Exception. Please refresh to try again.', e)
      // TODO: How to clean up when there's an exception? Can we continue to retry?
    }
  }

  retryIceConnectivity = (startPlay = true) => {
    if (this.peerConnection) {
      this.peerConnection.setConfiguration({ iceServers: [] })
      this.peerConnection.createOffer({ iceRestart: true })
    }
    if (this.wsConnection && startPlay) {
      // console.log('retryIceConnectivity(): startPlay')
      this.wsConnection.send(JSON.stringify({
        direction:  'play',
        command:    'getOffer',
        streamInfo: this.streamInfo,
        userData:   this.userData
      }))
    }
  }

  stop() {
    console.log('Getting peer connectivity before closing!', this.peerConnection)
    if (this.peerConnection !== null) {
      this.showOK('Closing video connection')
      this.peerConnection.close()
    }
    this.peerConnection = null

    if (this.wsConnection !== null) {
      this.wsConnection.onmessage = null
      this.wsConnection.close()
    }
    this.wsConnection = null
  }

  sendPlayGetAvailableStreams = () => {
    this.wsConnection.send(JSON.stringify({
      direction:  'play',
      command:    'getAvailableStreams',
      streamInfo: this.streamInfo,
      userData:   this.userData
    }))
  }

  sendPlayOffer = () => {
    if (!this.wsConnection)
      return
    const { readyState } = this.wsConnection
    if (readyState !== 1)
      return
    this.wsConnection.send(JSON.stringify({
      direction:  'play',
      command:    'getOffer',
      streamInfo: this.streamInfo,
      userData:   this.userData
    }))
  }

  async startWebsocketConnection(url) {
    console.log('webrtc ws, opening:', url)
    try {
      this.wsConnection = new WebSocket(url)
      this.wsConnection.binaryType = 'arraybuffer'
      SOCKET_CONNECT_DELAY = DEFAULT_SOCKET_CONNECT_DELAY
    } catch (err) {
      console.log('Error in startWebsocketConnection(): ', err)
      await sleep(SOCKET_CONNECT_DELAY)
      SOCKET_CONNECT_DELAY = SOCKET_CONNECT_DELAY * 1.1
      this.startWebsocketConnection(url)
      return
    }

    this.wsConnection.onopen = () => {
      console.log('webrtc ws onopen')
      try {
        if (this.peerConnection !== null) {
          // Try to establish a new ice connection
          this.retryIceConnectivity(false)
        } else {
          console.log('Peer connection: ', this.peerConnection)
          this.peerConnection = new RTCPeerConnection({ iceServers: [] })
        }
        this.peerConnection.onicecandidate = gotIceCandidate
        this.peerConnection.oniceconnectionstatechange = onIceConnectionStateChange
        // If still using old API
        if (navigator.mediaDevices.getUserMedia) {
          this.peerConnection.ontrack = gotRemoteTrack
        } else {
          this.peerConnection.onaddstream = gotRemoteStream
        }
        console.log('webrtc ws conn:', this.wsConnection)
        // Command to start playing stream
        this.sendPlayOffer()
      } catch (e) {
        console.error('Error opening peer connection to video stream', e)
      }
    }

    function gotIceCandidate(event) {
      console.log('Received ice candidate:', event.candidate)
    }

    const onIceConnectionStateChange = async event => {
      const {
        currentTarget: { iceConnectionState },
      } = event
      console.log(`Ice connection state change to: ${iceConnectionState}`, event)
      switch (iceConnectionState) {
        case 'disconnected':          
          this.stop()
          await sleep(SOCKET_CONNECT_DELAY)
          this.startWebsocketConnection(skystreamWebRtcUrl)
          break
        case 'connected':
          this.playVideo()
          break
        case 'failed':
        case 'closed':
          this.stop()
          // Wait a while and start again
          await sleep(SOCKET_CONNECT_DELAY)
          this.startWebsocketConnection(skystreamWebRtcUrl)
          break
        default:
      }
    }

    const gotRemoteTrack = event => {
      this.showOK('Remote track received')
      console.log('gotRemoteTrack: kind:', event.track.kind, 'streams[0]:', event.streams[0], 'Event', event)
      let remoteVideo = document.getElementById(this.videoId + '_html5_api')
      console.info(remoteVideo)

      event.track.onended = () => console.log('Track Ended')
      try {
        remoteVideo.srcObject = event.streams[0]
      } catch (error) {
        remoteVideo.src = window.URL.createObjectURL(event.streams[0])
      }
      this.currTrack = event
    }

    const gotRemoteStream = event => {
      this.showOK(`Playing stream ${event.stream}...`, event)
      let remoteVideo = document.getElementById(this.videoId + '_html5_api')
      console.info(remoteVideo)
      try {
        remoteVideo.srcObject = event.stream
      } catch (error) {
        // Avoid using this in new browsers, as it is going away.
        remoteVideo.src = window.URL.createObjectURL(event.stream)
      }
      this.playVideo()
    }

    this.wsConnection.onmessage = async evt => {
      try {
        const msgJSON = JSON.parse(evt.data)
        const msgStatus = Number(msgJSON.status)

        if (msgStatus === 504) {
          console.log('webrtc ws msg: Repeater stream not ready (504)')
          await sleep(STREAM_CONNECT_DELAY)
          this.sendPlayOffer() // Command to start playing stream
        }
        else if (msgStatus !== 200) {
          if (msgStatus === 502)
            this.showError('Lost video connection with drone')
          else
            console.log('webrtc ws msg:', msgJSON)

          STREAM_CONNECT_DELAY = STREAM_CONNECT_DELAY * 1.1
          await sleep(STREAM_CONNECT_DELAY)
          this.retryIceConnectivity()
        }
        else {
          STREAM_CONNECT_DELAY = DEFAULT_STREAM_CONNECT_DELAY

          if (msgJSON.streamInfo)
            this.streamInfo.sessionId = msgJSON.streamInfo.sessionId

          if (msgJSON.sdp) {
            this.peerConnection
            .setRemoteDescription(new RTCSessionDescription(msgJSON.sdp))
            .then(() => {
              this.peerConnection.createAnswer(
                e => this.gotDescription(e),
                error => {
                  console.log('webrtc ws msg: Error createAnswer', error)
                }
              )
            })
            .catch(({ message }) => {
              console.log('webrtc ws msg: Exception setRemoteDescription', message)
            })
          }

          msgJSON.iceCandidates?.forEach((candidate, i) => {
            this.peerConnection.addIceCandidate(
              new RTCIceCandidate(candidate)
            )
          })
        }

        if ('sendResponse'.localeCompare(msgJSON.command) === 0) {
          console.log('webrtc ws msg: Stream sendResponse')
          this.playVideo()
        }

        // now check for getAvailableResponse command to close the connection
        if ('getAvailableStreams'.localeCompare(msgJSON.command) === 0) {
          console.log('webrtc ws msg: Stream disconnected. getAvailableStreams[0].onmessage:', evt.data)
          this.stop()
        }
      } catch (e) {
        console.error('webrtc ws msg: Unhandled exception onmessage', e)
      }
    }

    this.wsConnection.onclose = async () => {
      console.log('webrtc ws onclose:', this.peerConnection)

      if (this.peerConnection !== null) {
        console.log('webrtc ws onclose: Closing peer connection')
        this.peerConnection.close()
      }
      this.peerConnection = null
    }

    this.wsConnection.onerror = async evt => {
      console.log('webrtc ws onerror: Peer Connection', this.peerConnection)

      if (this.peerConnection !== null) {
        console.log('webrtc ws onerror: Closing peer connection')
        this.peerConnection.close()
      }
      this.peerConnection = null

      this.showError('webrtc ws onerror: Unexpected Error', evt)
      await sleep(STREAM_CONNECT_DELAY)

      this.startWebsocketConnection(url)
    }
  }

  gotDescription(description) {
    this.peerConnection
    .setLocalDescription(description)
    .then(() => {
      this.wsConnection.send(JSON.stringify({
        direction: 'play',
        command:   'sendResponse',
        streamInfo: this.streamInfo,
        sdp:        description,
        userData:   this.userData
      }))
      this.showOK('Initializing Session...')
    })
    .catch(() => {
      console.log('Set description error')
    })
  }

  render() {
    const { videoLoading, streamError, streamStatus } = this.state
    const { classes } = this.props

    return (
      <div className={`video-container ${classes.screenContainer}`} ref='screenContainer'>
        {!streamError && videoLoading && (
          <div className={classes.loadingContainer}>
            <CircularProgress />
          </div>
        )}
        {streamError && (
          <div className={classes.errorContainer}>
            <p className={classes.error}>{ streamError }</p>
          </div>
        )}
        {streamStatus && (
          <div className={classes.statusContainer}>
            <p className={classes.status}>{ streamStatus }</p>
          </div>
        )}

        <div data-vjs-player className={classes.videoJsContainer}>
          <video
            onContextMenu={e => { e.preventDefault() }}
            ref={node => (this.videoNode = node)}
            className='video-js vjs-default-skin vjs-big-play-centered'
            autoPlay={true}
            muted={true}
            style={{ width: '100%', height: '100%' }}
            id={this.videoId}>
            <p className='vjs-no-js'>
              Please enable JavaScript on a web browser that supports HTML5 video
            </p>
          </video>
        </div>
      </div>
    )
  }
}

export default withStyles(styles)(WebRTCStream)
