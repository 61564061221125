import { useState } from 'react'
import { getDecodedToken }   from '../api/accounts'
import LiveTelemetryListener from '../telemetry/LiveTelemetryListener'
import LiveStatusListener from '../telemetry/LiveStatusListener'
import Header             from '../components/Header'
import SingleVideo        from '../video/Components/SingleVideo'
import DroneRadar         from '../daa/DroneRadar'
import TelemetryContainer from './telemetry/TelemetryContainer'
import HeadUpDisplay      from './Components/HeadUpDisplay'
import DroneStatus        from './Components/DroneStatus'
import ForwardControls    from './Components/ForwardControls'
import JoyStickControl    from './Components/JoyStickControl'

import {
  CssBaseline,
  Grid,
} from '@material-ui/core'
import {
  ToggleButton,
  ToggleButtonGroup,
} from '@material-ui/lab'
import {
  FormatListBulleted,
  Landscape,
} from '@material-ui/icons'
import { withStyles } from '@material-ui/core/styles'

const styles = theme => ({
  root: {
    height: '95%',
    width: '100%',
    position: 'fixed',
  },
  header: {
    height: theme.spacing(6),
  },
  status: {
    height: theme.spacing(7),
  },
  controls: {
    height: theme.spacing(8),
  },
  videoRadarTelem : {
    // view height - header, status, controls, and footer
    height: 'calc(100vh - ' + theme.spacing(6 + 7 + 8 + 1) + 'px)',
  },
  radarAndTelem: {
    display: 'flex',
    padding: '0 ' + theme.spacing(2) + 'px',
    flexDirection: 'column',
    justifyContent: 'space-around',
    alignItems: 'center',
    width: 'calc(100vw - 75vh * 16 / 9)'
  }
})

function PilotForwardScreen({ classes, telemetry }) {
  const [ tab, setTab ] = useState('telem') // 'telem', 'hud'
  const { company_id, drone_id } = getDecodedToken()
  return (
    <LiveTelemetryListener>
      { false && <LiveStatusListener onFlightStatusMessage={(e) => { console.info('flight status:', e)}} />}
      <CssBaseline />
      <Grid container spacing={0} direction='row' className={classes.root}>
        <Grid item xs={12}>
          <Header className={classes.header} />
        </Grid>
        <Grid item xs={12} container alignItems='center' className={classes.status}>
          <DroneStatus />
        </Grid>
        <Grid container item xs={12} className={classes.videoRadarTelem}>
          <Grid item>
            <SingleVideo
              videoId='forward'
              streamName='forward'
              stylingClass='videoContainer'
            />
          </Grid>
          <Grid item className={classes.radarAndTelem}>
            <DroneRadar />
            <ToggleButtonGroup value={tab} exclusive
              onChange={(e, val) => setTab(val)}>
              <ToggleButton value='telem'><FormatListBulleted /></ToggleButton>
              <ToggleButton value='hud'><Landscape /></ToggleButton>
            </ToggleButtonGroup>
            { tab === 'telem' && <TelemetryContainer /> }
            { tab === 'hud'   && <HeadUpDisplay /> }
            <JoyStickControl/>
          </Grid>
        </Grid>
        <Grid item xs={12} className={classes.controls}>
          <ForwardControls
            droneId={drone_id}
            companyId={company_id}
          />
        </Grid>
      </Grid>
    </LiveTelemetryListener>
  )
}


export default withStyles(styles)(PilotForwardScreen)

// TODO: Permanently disable or rework
// {<DaaTelemetryListener />}
// import DaaTelemetryListener from '../telemetry/DaaTelemetryListener'

// TODO: customise warning messages
//       >> These are removed from redux telemetry state, and should just based on telemetry
// warnBattery0 = 'Low Battery'
// warnBattery1 = 'Critial Low Battery, Landing Immediately'
// warnGPS = 'Satellite Count Too Low, Inaccurate GPS'
// warnHeight0 = 'Approaching Legal Height Limit'
// warnHeight1 = 'Flying Above Legal Limit'
// warnAir = 'High air speed'

// exceeding the following conditions will trigger warning
// TODO: customise for different drone models in the future
// batteryThreshold = 25
// heightThreshold = 100
// satCountThreshold = 4
// airThreshold = 15

