/*
 *
 * This component is used to render the health of a single drone
 *
 * Including:
 *
 * - The connectivity: Whether it is currently connected. The feature to dynamically check
 * which network it is currently connected is pending the ability of co-pilot to retrieve such information
 *
 * - Whether DAA is turned on: By right, daa is only turned on when it is within 15 metres from any obstacle.
 */
import { Component } from 'react'
import { connect }   from 'react-redux'

import HealthText       from './Components/HealthText'
import SignalBars       from './Components/SignalBar'
import DaaAutonomyLevel from './Components/DaaAutonomyLevel'

import {
  Typography,
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'

const styles = theme => ({
  healthText: {
    paddingTop: '10px',
    paddingBottom: '10px',
    borderBottom: '2px solid #fa8231',
  },
  healthTextLast: {
    paddingTop: '10px',
    paddingBottom: '10px',
  },
  container: {
    display: 'flex',
    flexDirection: 'row',
  },
  telcoHeader: {
    display: 'flex',
    flexDirection: 'row',
    borderBottom: '2px #303030',
    borderBottomStyle: 'solid',
  },
  bvlos: {
    border: '2px solid #fa8231',
    borderBottom: '',
    margin: '0 12px'
  }
})

class DroneHealth extends Component {
  convertSpeed = speed => {
    if (typeof speed !== 'string') {
      let convertedSpeed = speed / 1000000 //in Mb/s
      return convertedSpeed.toFixed(2) + ' Mb/s'
    } else {
      return speed + ' Mb/s'
    }
  }

  render() {
    const {
      classes,
      telemetry,
      mission,
      linkStatus,
      networkSpeed,
      autonomyLevel,
      network,
    } = this.props

    // if (mission === null) return <HealthText label='NO DRONES ONLINE' />
    const { droneName } = mission
    const { downloadSpeed, uploadSpeed } = networkSpeed
    const isDroneOnline = !(Object.keys(telemetry).length === 0 || linkStatus !== null)

    const isSpeedTestDone = uploadSpeed !== '-' && downloadSpeed !== '-'

    // Change daa activated flag to true only if the drone is online and the radar distance is less than 15 metres.
    let daaActivated = true
    // const { radDistance } = telemetry
    // if (isDroneOnline && radDistance < 15) {
    //     daaActivated = true
    // }
    const networkStatus = telco => (<>
      <div className={classes.telcoHeader}>
        <HealthText
          fill='bg'
          status={
            telco.status === 'selected'
              ? 'good'
              : telco.status === 'active'
              ? 'available'
              : 'disabled'
          }
          label={telco.name || 'No Network'}
          last
          tooltip={telco.ip || 'No IP Addr'}
        />
        <SignalBars
          signalStrength={telco.quality}
          tooltipText={telco.rssi || 'No Signal'}
        />
      </div>
      <div className={classes.container}>
        {telco.status === 'selected' ? (
            <>
              <HealthText
                fill='bg'
                label={
                  isSpeedTestDone
                    ? '↑ ' + uploadSpeed + ' Mb/s'
                    : '↑ - Mb/s'
                }
              />
              <HealthText
                fill='bg'
                label={
                  isSpeedTestDone
                    ? '↓ ' + downloadSpeed + ' Mb/s'
                    : '↓ - Mb/s'
                }
              />
            </>
        ) : (
            <>
              <HealthText fill='bg' label={'↑ - Mb/s'} />
              <HealthText fill='bg' label={'↓ - Mb/s'} />
            </>
        )}
      </div>
    </>)
    return (
      <>
        <Typography align='center' variant='h6' gutterBottom>
          Drones
        </Typography>
        <Typography align='center' variant='body1' gutterBottom>
          Beyond Visual Line Of Sight
        </Typography>
        <div className={classes.bvlos}>
          <HealthText label={droneName} />
          <HealthText
            fill='bg'
            status={isDroneOnline ? 'good' : 'bad'}
            label={isDroneOnline ? 'ONLINE' : 'OFFLINE'}
          />
          { Boolean(network) && isDroneOnline ?
            network.map(networkStatus) :
            networkStatus({})
          }
          <div className={classes.container}>
            <HealthText label='Detect & Avoid' />
            <DaaAutonomyLevel
              autonomyLevel={autonomyLevel}
              fill='bg'
              status={daaActivated ? 'good' : 'bad'}
              label={daaActivated ? 'ON' : 'OFF'}
            />
          </div>
        </div>
      </>
    )
  }
}

const mapStateToProps = state => {
    return {
        mission: state.mission.mission,
        telemetry: state.telemetry.telemetry,
        linkStatus: state.telemetry.linkStatus,
        network: state.telemetry.telemetry.networks, // TODO: This will no longer work as we migrate to Flight Status
        networkSpeed: state.telemetry.telemetry.networkSpeed || state.telemetry.networkSpeed,
        autonomyLevel: state.daa.autonomyLevel,
    }
}

export default connect(mapStateToProps, null)(withStyles(styles)(DroneHealth))
