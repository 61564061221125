import { useState, useEffect } from 'react'
import { bindActionCreators }  from 'redux'
import { connect }             from 'react-redux'
import {
  useGetMLModelsQuery,
  useGetMLModelVersionsQuery,
} from '../../api/config'
import {
  startInference,
  stopInference,
} from '../../modules/actions/inferenceActions'
import {
  Button,
  Grid,
  Link,
  ListItemText,
  MenuItem,
  TextField,
  Typography,
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'

const styles = theme => ({
  top: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-evenly',
    padding: theme.spacing(1),
  },
})

function InferenceControls({
  classes,
  modelId,
  setModelId,
  // state
  mission,
  video_table,
  // actions
  startInference,
  stopInference,
}) {
  const [ mlModels,   setMlModels   ] = useState([])
  const [ mlVersions, setMlVersions ] = useState([])
  const [ versionId,  setVersionId  ] = useState('')

  const { data: modelData } = useGetMLModelsQuery()
  const { data: versionData } = useGetMLModelVersionsQuery(modelId, { skip: !modelId })

  useEffect(() => {
    const models = modelData?.data?.ml_model
    if (models)
      setMlModels(models)
  }, [modelData])
  useEffect(() => {
    const versions = versionData?.data?.ml_model_version
    if (versions)
      setMlVersions(versions)
  }, [versionData])

  function handleStartInference() {
    startInference({
      camera: 'payload',
      ml_model_version_id: versionId,
    })
  }

  function handleStopInference() {
    const video_id = Object.keys(video_table)
      .find(vid => video_table[vid].ml_model_version_id === versionId)
    if (video_id)
      stopInference({ video_id, ml_model_version_id: versionId  })
    else
      console.log('Cannot stop video because video_id is unknown')
  }

  console.log('video_table', video_table)

  return (
    <Grid container>
      <Grid item xs={4}>
        <Typography variant='h5'>Select Object for Tracking</Typography>
        <ol>
          <li>Select a mission with a <u>Payload</u> Camera</li>
          <li>Choose a pre-train ML Model / Version, click Start</li>
          <li>Visit <Link href='/track-object' underline='always'>Track Object</Link> to view the annotated video</li>
          <li>Keep this window open!</li>
        </ol>
      </Grid>
      <Grid item xs={3} className={classes.top}>
        <TextField select fullWidth
          label='ML Model'
          value={modelId}
          onChange={(e) => setModelId(e.target.value)}>
          { mlModels.map(m => <MenuItem key={m._id} value={m._id}>
              <ListItemText primary={m.name} secondary={m.description} />
            </MenuItem>)
          }
        </TextField>
      </Grid>
      <Grid item xs={3} className={classes.top}>
        <TextField select fullWidth
          label='Version'
          value={versionId}
          onChange={(e) => setVersionId(e.target.value)}>
          { mlVersions.map(v => <MenuItem key={v._id} value={v._id}>
              <ListItemText primary={v.semver} secondary={v.release_notes} />
            </MenuItem>)
          }
        </TextField>
      </Grid>
      <Grid item xs={2} className={classes.top}>
        <Button disabled={!versionId || !mission?.droneId} variant='contained' color='primary'
          onClick={handleStartInference}>
          Start
        </Button>
      </Grid>
      <Grid item xs={10}>
        { Object.keys(video_table).map(id => {
          const v = video_table[id]
          return <div key={id}>
            <Typography variant='body1'>Video {id} | Status: {v.status} | ML Model Version ID {v.ml_model_version_id}</Typography>
            <Typography variant='body2'>Raw {v.raw_video}</Typography>
            <Typography variant='body2'>Annotated {v.annotated_video}</Typography>
          </div>
        }) }
      </Grid>
      <Grid item xs={2} className={classes.top}>
        <Button disabled={!versionId || !mission?.droneId} variant='contained' color='primary'
          onClick={handleStopInference}>
          Stop
        </Button>
      </Grid>
    </Grid>
  )
}

const mapStateToProps = state => ({
  mission:     state.mission.mission,
  video_table: state.inference.video_table,
})

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ startInference, stopInference }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(InferenceControls))
