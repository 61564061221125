const initialState = {
  droneId: null,
  companyId: null,
  applicationName: process.env.REACT_APP_APPLICATION_NAME,
  streamName: process.env.REACT_APP_STREAM_NAME,
}

function authReducer(state = initialState, action) {
  switch (action.type) {
    default:
      return state
  }
}

export default authReducer