import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { push } from 'connected-react-router'
import { closeAirmapSubmitDialog } from '../modules/actions/mission'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core'

function AirmapSubmitDialog({ closeAirmapSubmitDialog, open, push, error }) {

  function proceedToLiveScreen() {
    push({ pathname: '/live' })
    closeAirmapSubmitDialog()
  }
  if (error)
    console.log(error)

  return (
    <Dialog open={open}>
      <DialogTitle>UTM Clearance</DialogTitle>
      <DialogContent>
        <DialogContentText>No UTM Clearance Enabled</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button color='primary' onClick={proceedToLiveScreen}>
          Proceed to live screen
        </Button>
      </DialogActions>
    </Dialog>
  )
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ push, closeAirmapSubmitDialog }, dispatch)
}

const mapStateToProps = (state) => ({
  open:  state.mission.airmapSubmittedDialog,
  error: state.mission.airmapSubmitError,
})

export default connect(mapStateToProps, mapDispatchToProps)(AirmapSubmitDialog)
