import { red, yellow, blue, green, blueGrey } from '@material-ui/core/colors'
import { createTheme } from '@material-ui/core/styles'

const severityColors = {
  purple: '#EB3CF7',
  red:    '#EC3323',
  orange: '#F29D38',
  yellow: '#FFFE54',
  green:  '#71FA4C',
}

// Plex Horizon's Site Theme to follow Style Guide for Plex Horizon
// DEFAULT DARK EDITION
const plexHorizonTheme = createTheme({
  palette: {
    type: 'dark',
    primary: {
      main:  '#246FF1',
      light: '#709DFF',
      dark:  '#0045BD'
    },
    secondary: {
      main:  '#F1A624',
      light: '#FFD759',
      dark:  '#B97700'
    },
    error: {
      main: red.A700,
    },
    warning: {
      main: yellow.A700,
    },
    info: {
      main: blue.A700
    },
    success: {
      main: green.A700
    },
    severity: [
      'white',               // default
      severityColors.purple, // severity 1
      severityColors.red,    // severity 2
      severityColors.orange, // severity 3
      severityColors.yellow, // severity 4
      severityColors.green,  // severity 5
    ],
    risk: {
      CATASTROPHIC: severityColors.purple,
      HAZARDOUS:    severityColors.red,
      MAJOR:        severityColors.orange,
      MINOR:        severityColors.yellow,
      NO_EFFECT:    severityColors.green,
    },
    background: {
      main:  blueGrey['800'],
      light: blueGrey['700'],
      dark:  blueGrey['900'],
    },
    bluegrey: {
      main:  blueGrey['500'],
      light: blueGrey['200'],
      dark:  blueGrey['900'],
    },
    map: { // to harmonise (also in mydronefleets-web)
      offWhite:   '#F4F4F4',
      // Public NFZs
      liverRed:   '#C0392B',
      freshRed:   '#ED2939',
      magenta:    '#CD006F',
      yellow:     '#FFCD35',
      lightGreen: '#7FFF00',
      // Public Geospatial
      orange:     '#E67B3A',
      // Private NFZ (keep out)
      darkBlue:   '#0045BD',
      darkGrey:   '#666666',
      // Flight / Reservations (keep in)
      lightBlue:  '#4DDBFF',
      blue:       '#246FF1',
      grey:       '#DDDDDD',
      black:      '#000000',
    },
  }
})

export default plexHorizonTheme
