import { history }  from '../../modules/store'
import userManager  from '../../utils/userManager'
import { useGetUserByIdQuery } from '../../api/accounts'
import { createMediaUrl } from '../../api/media'
import {
  Avatar,
  Drawer,
  Divider,
  List,
  ListItem,
  ListItemAvatar,
  ListItemIcon,
  ListItemText,
  ListSubheader,
} from '@material-ui/core'
import {
  ArrowDownward,
  ArrowForward,
  Ballot,
  CloudUploadOutlined,
  ControlCamera,
  CropFree,
  EditLocation,
  Explore,
  FlightTakeoff,
  LiveTv,
  // LocalHospital,
  NetworkCheck,
  PowerSettingsNew,
  Smartphone,
  Timer,
  Toys,
  ViewCompact,
  Warning,
} from '@material-ui/icons'
import { withStyles } from '@material-ui/styles'

const ACCOUNTS_URL = process.env.REACT_APP_ACCOUNTS_URL
if (!ACCOUNTS_URL) throw new Error('Environment Variable REACT_APP_ACCOUNTS_URL is required.')

const styles = theme => ({

})

function handleClick(event) {
  history.push(event)
}

function MenuDrawer({ classes, user, open, onClose }) {
  const user_id = user?.profile.sub
  const { data } = useGetUserByIdQuery(user_id, {
    skip: !user_id
  })
  const userInfo = data?.data.user

  function handleLogout() {
    userManager
    .signoutRedirect()
    .then(() => { history.push({ pathname: '/login' }) })
    .catch(err => console.error('Error while signing out user: ', err))
  }

  return (
    <Drawer anchor='right' open={open} onClose={onClose}>
      <div tabIndex={0}
        role='button'
        onClick={onClose}
        onKeyDown={onClose}>
        <List>
          { userInfo && (
            <ListItem button onClick={() => window.open(ACCOUNTS_URL, '_blank')}>
              <ListItemAvatar>
                <Avatar
                  alt={userInfo.username}
                  src={userInfo.avatar ? createMediaUrl({
                    mediaId: userInfo.avatar,
                    size: 'thumb'
                  }) : userInfo.name?.substring(0, 1) || ''}
                />
              </ListItemAvatar>
              <ListItemText
                primary={userInfo.name}
                secondary={userInfo.email}
              />
            </ListItem>
          )}
        </List>
        <Divider/>
        <List>
          <ListSubheader>Pilot Console</ListSubheader>
          <ListItem button onClick={() => handleClick('/live')}>
            <ListItemIcon><ArrowForward /></ListItemIcon>
            <ListItemText primary='Forward Camera' />
          </ListItem>
          <ListItem button onClick={() => handleClick('/downward')}>
            <ListItemIcon><ArrowDownward /></ListItemIcon>
            <ListItemText primary='Downward Camera' />
          </ListItem>
          <ListItem button onClick={() => handleClick('/payload')}>
            <ListItemIcon><ControlCamera /></ListItemIcon>
            <ListItemText primary='Payload Camera' />
          </ListItem>
          <ListItem button onClick={() => handleClick('/regional-map')}>
            <ListItemIcon><Explore /></ListItemIcon>
            <ListItemText primary='Regional Map' />
          </ListItem>
          <ListItem button onClick={() => handleClick('/cerana-health')}>
            <ListItemIcon><Toys /></ListItemIcon>
            <ListItemText primary='Cerana Health' />
          </ListItem>
          <ListItem button onClick={() => handleClick('/cerana-warnings')}>
            <ListItemIcon><Warning /></ListItemIcon>
            <ListItemText primary='Cerana Warnings' />
          </ListItem>
        </List>
        <Divider/>
        <List>
          <ListSubheader>Pilot Console Extensions</ListSubheader>
          <ListItem button onClick={() => handleClick('/picture-in-picture')}>
            <ListItemIcon><LiveTv /></ListItemIcon>
            <ListItemText primary='Picture In Picture' />
          </ListItem>
          <ListItem button onClick={() => handleClick('/mobile-app')}>
            <ListItemIcon><Smartphone /></ListItemIcon>
            <ListItemText primary='Mobile App' />
          </ListItem>
        </List>
        <Divider/>
        <List>
          <ListSubheader>Joint Console</ListSubheader>
          <ListItem button onClick={() => handleClick('/counter')}>
            <ListItemIcon><Timer /></ListItemIcon>
            <ListItemText primary='Mission Counter' />
          </ListItem>
          <ListItem button onClick={() => handleClick('/select-object')}>
            <ListItemIcon><CropFree /></ListItemIcon>
            <ListItemText primary='Select Object' />
          </ListItem>
          <ListItem button onClick={() => handleClick('/track-object')}>
            <ListItemIcon><CropFree /></ListItemIcon>
            <ListItemText primary='Track Object' />
          </ListItem>
          <ListItem button onClick={() => handleClick('/video-analytics')}>
            <ListItemIcon><CropFree /></ListItemIcon>
            <ListItemText primary='Video Analytics' />
          </ListItem>
          <ListItem button onClick={() => handleClick('/four-by-four-grid')}>
            <ListItemIcon><LiveTv /></ListItemIcon>
            <ListItemText primary='Four By Four Grid' />
          </ListItem>
          <ListItem button onClick={() => handleClick('/plex-health')}>
            <ListItemIcon><ViewCompact /></ListItemIcon>
            <ListItemText primary='Plex Health' />
          </ListItem>
          <ListItem button onClick={() => handleClick('/network-health')}>
            <ListItemIcon><NetworkCheck /></ListItemIcon>
            <ListItemText primary='Network Health' />
          </ListItem>
          <ListItem button onClick={() => handleClick('/video-health')}>
            <ListItemIcon><LiveTv /></ListItemIcon>
            <ListItemText primary='Video Health' />
          </ListItem>
        </List>
        <Divider/>
        <List>
          <ListSubheader>Flight Planning</ListSubheader>
          <ListItem button onClick={() => handleClick('/planner')}>
            <ListItemIcon><EditLocation /></ListItemIcon>
            <ListItemText primary='Plan Mission' />
          </ListItem>
          <ListItem button onClick={() => handleClick('/mission')}>
            <ListItemIcon><FlightTakeoff /></ListItemIcon>
            <ListItemText primary='Select Mission' />
          </ListItem>
          <ListItem button onClick={() => handleClick('/preflight')}>
            <ListItemIcon><Ballot /></ListItemIcon>
            <ListItemText primary='Pre-flight Check' />
          </ListItem>
          <ListItem button onClick={() => handleClick('/postflight')}>
            <ListItemIcon><Ballot /></ListItemIcon>
            <ListItemText primary='Post-flight Review' />
          </ListItem>
          <ListItem button onClick={() => handleClick('/upload-flight')}>
            <ListItemIcon><CloudUploadOutlined /></ListItemIcon>
            <ListItemText primary='Upload Flight Plan' />
          </ListItem>
        </List>
        <Divider/>
        <List>
          <ListItem button onClick={() => handleClick('/settings')}>
            <ListItemText primary='Settings' />
          </ListItem>
          <ListItem button onClick={() => handleClick('/developer')}>
            <ListItemText primary='Developer' />
          </ListItem>
        </List>
        <Divider/>
        <List>
          <ListItem button onClick={handleLogout}>
            <ListItemIcon><PowerSettingsNew /></ListItemIcon>
            <ListItemText primary='Logout' />
          </ListItem>
        </List>
      </div>
    </Drawer>
  )
}

export default withStyles(styles)(MenuDrawer)
