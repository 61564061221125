

import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@material-ui/core'
import {
  OndemandVideo
} from '@material-ui/icons'
import { withStyles } from '@material-ui/core/styles'

const styles = theme => ({
  container: {
    border: '1px solid ' + theme.palette.bluegrey.dark,
  },
  noVideo: {
    border: '1px solid ' + theme.palette.bluegrey.dark,
    padding: theme.spacing(2)
  }
})

function VideoControls({
  classes,
  videoStreams,
  selectedVideo,
  setSelectedVideo,
}) {

  if (!videoStreams)
    return null

  if (videoStreams.length === 0)
    return (
      <div className={classes.noVideo}>
        <Typography variant='body1'>No video streams available from this drone</Typography>
      </div>
    )

  function handleChangeVideo(streamName) {
    setSelectedVideo(videoStreams.find(s => s.name === streamName))
  }

  // any reason to default to forward?
  const videoList = videoStreams.map(stream => {
    return (
      <ListItem
        key={stream.name}
        button
        selected={selectedVideo?.name === stream.name}
        onClick={(e) => handleChangeVideo(stream.name)}>
        <ListItemIcon><OndemandVideo /></ListItemIcon>
        <ListItemText
          primary={stream.name}
          secondary='Unknown resolution and fps' />
      </ListItem>
    )
  })

  return (
    <div className={classes.container}>
      <Typography variant='body1'>{videoStreams.length} video streams found:</Typography>
      <List>
        {videoList}
      </List>
    </div>
  )
}

export default withStyles(styles)(VideoControls)
