import theme from './site-theme'

export default function rulesToPolygonProps(rule) {
  const properties = {}

  // TODO: To recatalogue rulesets in the data model for airspace
  //       rules should be an enum not a random list of strings
  switch (
    rule
      .toLowerCase()
      .split(/[\s_]+/gm)
      .join('-')
  ) {
    case 'operation-restriction':
      properties.color = theme.palette.map.blue
      break
    
    case 'protected-area':
    case 'industrial-properties':
    case 'military-properties':
    case 'government-properties':
    case 'power-plant':
    case 'prison':
    case 'school':
    case 'hospital':
    case 'event-tfr':
    case 'emergency-area':
      properties.color = theme.palette.map.liverRed
      break

    case 'temporary-area':
      properties.customCssClassName = 'nfz-polygon-pulse'
      properties.color = theme.palette.map.darkBlue
      break

    case 'aerodrome':
    case 'military-airport':
    case 'public-airport':
    case 'airport':
    case 'special-use-airspace':
    case 'caas-boundary-5km':
      properties.color = theme.palette.map.magenta
      properties.icon = 'airport'
      properties.text = 'white'
      break

    case 'danger-area':
    case 'prohibited-area':
    case 'restricted-area':
    case 'caas-restricted-areas':
    case 'caas-prohibited-areas':
    case 'caas-danger-areas':
      properties.color = theme.palette.map.magenta
      properties.icon = 'awas-light'
      properties.text = 'white'
      break

    case 'controlled-airspace':
    case 'mha-air-navigation-act':
      properties.color = theme.palette.map.freshRed
      properties.icon = 'fence'
      properties.text = 'white'
      break

    case 'national-park':
    case 'npark-national-park':
      properties.color = theme.palette.map.yellow
      properties.icon = 'national-park'
      properties.text = 'black'
      break

    case 'caas-air-navigation-order':
      properties.color = theme.palette.map.lightBlue
      properties.icon = 'awas-light'
      properties.text = 'black'
      break

    case 'open-use-areas':
    case 'community-led-areas':
    case 'test-estate':
    case 'garuda-dte':
      properties.color = theme.palette.map.lightGreen
      properties.icon = 'awas-light'
      properties.text = 'black'
      break

    case 'mha-public-act-order':
      properties.color = theme.palette.map.orange
      properties.icon = 'awas-light'
      properties.text = 'black'
      break

    case 'garuda-custom':
      properties.icon = 'firestation'
      break

    case 'garuda-custom-hospital':
      properties.icon = 'hospital'
      break

    case 'garuda-custom-doc':
      properties.icon = 'doc'
      break

    default:
      console.info('Unknown rule', rule)
      properties.color = 'black'
      properties.icon = 'danger-amber'
      properties.text = 'white'
      break
  }

  return properties
}