import { useState, useEffect } from 'react'

function LocalVideo({ videoId, localVideo, onLoadedData }) {
  const [ src, setSrc ] = useState('')
  useEffect(() => {
    setSrc(URL.createObjectURL(localVideo))
  }, [localVideo]) // this prevents the video from restarting on every render

  return (
    <video id={videoId || 'local-video'}
      src={src}
      onContextMenu={e => { e.preventDefault() }}
      autoPlay
      loop
      controls
      width='100%'
      height='100%'
      onLoadedData={onLoadedData}>
      Please enable JavaScript on a web browser that supports HTML5 video
    </video>
  )
}

export default LocalVideo
