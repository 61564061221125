import prettyTimestamp from '../../utils/prettyTimestamp'
import WarningCount from './WarningCount'
import {
  Card,
  Grid,
  Typography,
} from '@material-ui/core'
import { withStyles } from '@material-ui/styles'
import { useInterval } from 'usehooks-ts'
import { useState } from 'react'

const styles = theme => ({
  card: {
    display: 'flex',
    alignItems: 'stretch',
    backgroundColor: theme.palette.bluegrey.dark,
  },
  colorBar: {
    width: theme.spacing(2),
    backgroundColor: ({ severity }) =>
      // TODO: align color with risk and severity correctly with EMERGENCY,ALERT,CRITICAL,ERROR,WARNING,NOTICE,INFO and DEBUG(?)
      theme.palette.risk[severity] || theme.palette.severity[severity] || theme.palette.risk.NO_EFFECT,
  },
  content: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    height: theme.spacing(7),
    padding: theme.spacing(0, 2),
  },
  details: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    justifyContent: 'center'
  },
})

// Note: startingTime is the time the warning started coming (in seconds)
//       latestTime is the time the same warning still happened (not the present time) (in seconds)
//       see warningsReducer to see how it's collated
function WarningListItem({ classes, severity, warningMessage, startingTime, latestTime, count, children }) {
  // TODO: switch to date-fns
  const [ duration, setDuration ] = useState(Date.now() / 1000 - latestTime)

  let timeString = ''
  if (duration === 0) {
    timeString = 'now'
  } else if (duration < 60) {
    timeString = `${prettyTimestamp(duration)} ago`
  } else if( startingTime ){
    const date = new Date(startingTime * 1000)
    timeString = `${prettyTimestamp(duration)} ago (${date.getHours()}:${(date.getMinutes()+'').padStart(2, "0")})`
  }

  useInterval(() => {
    setDuration(Date.now() / 1000 - latestTime)
  }, 5000)

  return (
    <Card className={classes.card}>
      <div className={classes.colorBar} />
      <div className={classes.content}>
        <Grid container justifyContent='space-between'>
          <Grid item xs container alignItems={'center'}>
            <Typography variant='h6' style={{ lineHeight: 1 }}>{warningMessage}</Typography>
          </Grid>
          <Grid item className={classes.details}>
            <WarningCount count={count} severity={severity} />
            <Typography fontStyle='italic'>{timeString}</Typography>
          </Grid>
          <Grid item>
            { children }
          </Grid>
        </Grid>
      </div>
    </Card>
  )
}

export default withStyles(styles)(WarningListItem)
