import Header from '../components/Header'
import SingleVideo from '../video/Components/SingleVideo'
import DownwardControls from './Components/DownwardControls'

import {
  CssBaseline,
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'

const styles = theme => ({
  header: {
    height: theme.spacing(6),
  },
  body: {
    display:'flex',
  },
})

function PilotDownwardScreen({ classes }) {
  return (
    <>
      <CssBaseline />
      <Header className={classes.header} />
      <div className={classes.body}>
        <SingleVideo
          videoId='downward'
          streamName='downward'
          stylingClass='downwardVideoContainer'
        />
        <DownwardControls />
      </div>
    </>
  )
}

export default withStyles(styles)(PilotDownwardScreen)
