import { useState } from 'react'

import TopXCloseButton from '../../components/TopXCloseButton'
import {
  Button,
  Dialog,
  DialogContent,
  Divider,
  Menu,
  MenuItem,
  Typography,
} from '@material-ui/core'
import {
  AspectRatio,
  VisibilityOutlined,
} from '@material-ui/icons'
import { withStyles } from '@material-ui/core/styles'

const styles = theme => ({
  videoControls: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
    alignItems: 'center',
    position: 'absolute',
    color: theme.palette.common.white,
    zIndex: 2,
  },
  dialog: {
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
  },
  input: {
    display: 'none',
  },
})

// TODO: Find the API for SkyStream that shows how many downstreams given a 
//       specific streamName
const watchCount = 1

function VideoControls({
  classes,
  streamName,
  setStreamName,
  setLocalVideo,
  anchorEl,
  setAnchorEl,
  videoWidth,
  videoHeight,
}) {
  const [ chooseLocal, setChooseLocal ] = useState(false)

  function updateStream(v) {
    setStreamName(v)
    setAnchorEl(null)
  }
  function handleOpenLocalVideo(e) {
    setLocalVideo(e.target.files[0])
    setStreamName('Local Sample')
    setChooseLocal(false)
    setAnchorEl(null)
  }

  const resolution =
    videoWidth <= 0 && videoHeight <= 0 ? 'No video' :
    videoWidth === 3840 && videoHeight === 2160 ? '4K / Ultra HD' :
    videoWidth === 1920 && videoHeight === 1080 ? 'Full HD' :
    videoWidth === 1280 && videoHeight === 720  ? 'HD' :
    videoWidth === 720  && videoHeight === 480  ? 'WVGA / Wide SD' :
    videoWidth === 640  && videoHeight === 480  ? 'VGA / SD' :
    `Non Standard (${videoWidth} x ${videoHeight})`

  return (<>
    <div className={classes.videoControls}>
      <Button variant='outlined' onClick={(event) => { setAnchorEl(event.currentTarget) }}>
        { streamName }
      </Button>
      <Button variant='outlined' startIcon={<AspectRatio />}>
        { resolution }
      </Button>
      <Button variant='outlined' startIcon={<VisibilityOutlined />}>
        { watchCount }
      </Button>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={() => { setAnchorEl(null) }}>
        <MenuItem onClick={() => { updateStream('forward') }}>Forward</MenuItem>
        <MenuItem onClick={() => { updateStream('downward') }}>Downward</MenuItem>
        <MenuItem onClick={() => { updateStream('payload') }}>Payload</MenuItem>
        <MenuItem onClick={() => { updateStream('video-analytics') }}>Video Analytics</MenuItem>
        <Divider />
        <MenuItem onClick={() => { updateStream('sample-1080p-1') }}>Sample 1080p 1</MenuItem>
        <MenuItem onClick={() => { updateStream('sample-1080p-2') }}>Sample 1080p 2</MenuItem>
        <MenuItem onClick={() => { updateStream('sample-1080p-3') }}>Sample 1080p 3</MenuItem>
        <MenuItem onClick={() => { updateStream('sample-1080p-4') }}>Sample 1080p 4</MenuItem>
        <Divider />
        <MenuItem onClick={() => { updateStream('sample-4k-1') }}>Sample 4K 1</MenuItem>
        <MenuItem onClick={() => { updateStream('sample-4k-2') }}>Sample 4K 2</MenuItem>
        <MenuItem onClick={() => { updateStream('sample-4k-3') }}>Sample 4K 3</MenuItem>
        <MenuItem onClick={() => { updateStream('sample-4k-4') }}>Sample 4K 4</MenuItem>
        <Divider />
        <MenuItem onClick={() => { updateStream('sample-720p-1') }}>Sample 720p 1</MenuItem>
        <MenuItem onClick={() => { updateStream('sample-720p-2') }}>Sample 720p 2</MenuItem>
        <MenuItem onClick={() => { updateStream('sample-720p-3') }}>Sample 720p 3</MenuItem>
        <MenuItem onClick={() => { updateStream('sample-720p-4') }}>Sample 720p 4</MenuItem>
        <Divider />
        <MenuItem onClick={() => { updateStream('sample-thermal') }}>Sample Thermal</MenuItem>
        <MenuItem onClick={() => { updateStream('sample-oem') }}>Sample (OEM)</MenuItem>
        <MenuItem onClick={() => { setChooseLocal(true) }}>Local Sample</MenuItem>
        <Divider />
      </Menu>
    </div>
    { // https://stackoverflow.com/questions/8885701/play-local-hard-drive-video-file-with-html5-video-tag
      chooseLocal &&
      <Dialog fullWidth maxWidth='xs'
        open={chooseLocal} onClose={() => { setChooseLocal(false) }}>
        <TopXCloseButton onClick={() => { setChooseLocal(false) }} />
        <DialogContent className={classes.dialog}>
          <Typography>Play a local sample video</Typography>
          <input
            id='local-video-file'
            type='file'
            accept='video/mp4,video/x-m4v,video/*'
            className={classes.input}
            onChange={handleOpenLocalVideo}
          />
          <label htmlFor='local-video-file'>
            <Button
              variant='contained'
              color='primary'
              component='span'>
              Open
            </Button>
          </label>
        </DialogContent>
      </Dialog>
    }
  </>)
}

export default withStyles(styles)(VideoControls)
