import { Component } from 'react'
import PropTypes     from 'prop-types'
import Sockette      from 'sockette'
import { getDecodedToken } from '../api/accounts'

class CommandSender extends Component {

  componentDidMount() {
    const baseUrl = this.props.endpoint
    const { company_id, drone_id, access_token } = getDecodedToken()
    const url = `${baseUrl}/?access_token=${access_token}&companyId=${company_id}&droneId=${drone_id}`

    this.ws = new Sockette(url, {
      timeout:     5000,
      maxAttempts: 5,
      onopen:      this.props.onOpen,
      onmessage:   e => this.props.onMessage(e),
      onreconnect: e => console.log('Command Sender: Reconnecting...', e.reason),
      onmaximum:   e => console.log('Command Sender: Stop Attempting.', e.reason),
      onclose:     e => console.log('Command Sender: Closed.', e.reason),
      onerror:     e => console.log('Command Sender: Error:', e)
    })
  }

  componentWillUnmount() {
    console.log('Command Sender: Stopping')
    this.ws.close()
  }

  render() {
    return this.props.children
  }
}

CommandSender.propTypes = {
  onMessage: PropTypes.func.isRequired,
  onOpen: PropTypes.func.isRequired,
  endpoint: PropTypes.string.isRequired
}

export default CommandSender