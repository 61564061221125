import { useState, useEffect } from 'react'
import { useGamepads }   from 'react-gamepads'
import { useInControl }  from '../../utils/useFlightStates'
import { usePrevious }   from '../../utils/usePrevious'
import LiveCommandSender from '../../telemetry/LiveCommandSender'
import {
  Grid,
} from '@material-ui/core'
import {
  Autorenew,
  Cached,
  ChangeHistory,
  Height,
  Power,
  PowerOff,
  RotateRight,
  SwapHorizontalCircle,
  SwapVerticalCircle,
} from '@material-ui/icons'
import { withStyles } from '@material-ui/core/styles'

const styles = theme => ({
  controlPanel: {
    position: 'absolute',
    width: '62%',
    height: theme.spacing(5),
    bottom: theme.spacing(6),
    left: theme.spacing(6),
    padding: theme.spacing(1, 3),
    borderRadius: theme.spacing(2),
  },
  axes: {
    display: 'flex',
    justifyContent: 'space-evenly',
  },
  activeInControl: {
    backgroundColor: theme.palette.primary.main,
  },
  activeNoControl: {
    backgroundColor: theme.palette.error.main,
  },
  inactive: {
    backgroundColor: theme.palette.bluegrey.main,
  },
  telem: {
    padding: theme.spacing(0, 2),
    display: 'flex',
    alignItems: 'center',
  },
})

function twodp(x) {
  return isFinite(x) ? Math.round(x * 100) / 100 : '-'
}

function JoyStickControl({ classes }) {

  const [ gamepads, setGamepads ] = useState({})
  const [ cmdWs,    setCmdWs    ] = useState({})
  const [ lastTime, setLastTime ] = useState(0.0)
  const prevLastValue = usePrevious(lastTime)
  const inControl = useInControl()

  const gamepad = gamepads && Object.keys(gamepads).length > 0 ? gamepads[0] : null
  const axis = gamepad?.axes

  // if there are only 4 axis on the gamepad, it is a console controller
  // if there are more than 4 axis on the gamepad, it is a flight controller
  const flightJoystick = axis?.length > 4
  const buttons = gamepad?.buttons
  const powerOn = (axis && axis[4] > 0) || (!flightJoystick && buttons?.length > 5 && buttons[5].pressed)

  useGamepads(gamepads => setGamepads(gamepads))

  useEffect(() => {
    // this line seems to be needed for gamepad info to refresh more often
    setInterval(function () {
      navigator.getGamepads()
    }, 1)
    // TODO: increase this from 1ms to 2, 3, 4, ... to find a balance of human
    //       performance to avoid creating too much garbage to collect in memory
  }, [setLastTime, gamepads])

  useEffect(() => {
    // gamepads['0'].axes contains at least 6 values ranging from -1.0 to 1.0. 
    // First 4 axes are joystick controls, fifth axes has to be held high for getting input from joystick, sixth axes maps to flight modes: ALT_HOLD, STABILIZE and LOITER
    if (cmdWs?.target && axis) {
      const values = axis.slice(0, 6)

      if (flightJoystick && buttons?.length >= 5) {
        values.push(buttons[5].value)
      }

      if (inControl && lastTime - prevLastValue >= 20) {
        cmdWs.target.send(JSON.stringify({
          action: 'update-rc-channels',
          values: values.map((v, index) => {
            if (Math.abs(v) >= 0.05 && values[4] >= 1.0) {
              if (index === 4) {
                return ((Math.round(v * 1000) / 1000) * 1.0)
              }
              return ((Math.round(v * 1000) / 1000) * 1.0 / (flightJoystick ? 4 : 5))
            } else {
              return 0.0
            }
          })
        }))
      }
      setLastTime(gamepads['0'].timestamp)
    }
  }, [cmdWs, gamepads, lastTime, prevLastValue, axis, buttons, inControl, flightJoystick])

  /* 
    flight transmitters can be configured to have their axis configured as mode 2(yaw, throttle, roll, pitch), but for other kinds of 4-axis joystick(such as COTS gaming controllers), it is not configurable.
    mode 2 is throttle(flipped y axis) + yaw(x axis) on left side controls, pitch (y axis) + roll (x axis) on right side controls
    Xbox controller:
      Throttle: flip 2nd axis
      Yaw: 1st axis
      Roll: 3rd axis
      Pitch: 4th axis
  */
  // TODO: design choose your joystick config in settings screen?
  // TODO: Separate code for transmitter and code for joystick. Have one component for each
  //       possible device and tune it as such. Might  need to go all the way to the model.
  const yaw      = axis?.length > 0 ? (!flightJoystick ? axis[0]          : axis[3]) : '-'
  const throttle = axis?.length > 0 ? (!flightJoystick ? axis[1] * -1     : axis[2]) : '-'
  const roll     = axis?.length > 0 ? (!flightJoystick ? axis[2]          : axis[0]) : '-'
  const pitch    = axis?.length > 0 ? (!flightJoystick ? axis[3]          : axis[1]) : '-'
  const onOrOff  = axis?.length > 0 ? (!flightJoystick ? buttons[5].value : axis[4]) : '-'

  return (
    <LiveCommandSender onWSOpen={(ws) => { setCmdWs(ws) }}>
      {gamepad && <>
        <div className={`${classes.controlPanel} ${
          powerOn > 0 ?
            (inControl ?
              classes.activeInControl :
              classes.activeNoControl) :
            classes.inactive
          }`}>
          <Grid container className={classes.axes}>
            <Grid item xs={2} className={classes.telem}>
              <SwapHorizontalCircle /> {twodp(roll)}
            </Grid>
            <Grid item xs={2} className={classes.telem}>
              <SwapVerticalCircle /> {twodp(pitch)}
            </Grid>
            <Grid item xs={2} className={classes.telem}>
              <Height /> {twodp(throttle)}
            </Grid>
            <Grid item xs={2} className={classes.telem}>
              <RotateRight /> {twodp(yaw)}
            </Grid>
            <Grid item xs={1} className={classes.telem}>
              {onOrOff > 0 ? <Power /> : <PowerOff />} {onOrOff}
            </Grid>
            <Grid item xs={1} className={classes.telem}>
              <ChangeHistory /> {twodp(axis && axis[5])}
            </Grid>
            <Grid item xs={1} className={classes.telem}>
              <Autorenew /> {twodp(axis && axis[6])}
            </Grid>
            <Grid item xs={1} className={classes.telem}>
              <Cached /> {twodp(axis && axis[7])}
            </Grid>
          </Grid>
        </div>
      </>}
    </LiveCommandSender>
  )
}

export default withStyles(styles)(JoyStickControl)
