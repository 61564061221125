import axios from 'axios'
import { getDecodedToken } from '../../api/accounts'

// Camera Payload Actions
// export const SET_CAMERA_PITCH_ACTION = 'SET_CAMERA_PITCH_ACTION'
export const PAYLOAD_STATE_UPDATE_ACTION = 'PAYLOAD_STATE_UPDATE_ACTION'

export const PAYLOAD_ZOOM_ACTION            = 'PAYLOAD_ZOOM_ACTION'
export const PAYLOAD_PITCH_ACTION           = 'PAYLOAD_PITCH_ACTION'
export const PAYLOAD_YAW_ACTION             = 'PAYLOAD_YAW_ACTION'
export const PAYLOAD_RESET_PITCH_YAW_ACTION = 'PAYLOAD_RESET_PITCH_YAW_ACTION'
export const PAYLOAD_RECORD_ACTION          = 'PAYLOAD_RECORD_ACTION'

// Winch Payload Actions
export const SET_WINCH_ACTION = 'SET_WINCH_ACTION'
export const SET_SERVO_ACTION = 'SET_SERVO_ACTION'

export const UPDATE_PAYLOAD_TELEMETRY  = 'UPDATE_PAYLOAD_TELEMETRY'
export const PAYLOAD_TELEMETRY_STORAGE = 'PAYLOAD_TELEMETRY_STORAGE'

const CommandWebApi = process.env.REACT_APP_COMMAND_PAYLOAD_WEB_API
if (!CommandWebApi)
  throw Error('REACT_APP_COMMAND_PAYLOAD_WEB_API must be defined')

export const updatePayloadTelemetry = (telemetry) => {
  return dispatch => {

    // Why do I need to store the telemetry in localStorage?
    window.localStorage.setItem(PAYLOAD_TELEMETRY_STORAGE, JSON.stringify(telemetry))

    dispatch({
      type: UPDATE_PAYLOAD_TELEMETRY,
      payloadTelemetry: telemetry
    })
  }
}

function genericPayloadPost(payloadId, command, body, reducer) {
  return dispatch => {
    console.log('Payload command dispatch', body)
    axios.post(`${CommandWebApi}/payload/${payloadId}/${command}`, body)
    .then(res => {
      console.info('Payload command OK', res)
      if (res.data.status === 'fail')
        throw Error(res.data.data.drone_id) // TODO: Don't throw Error, display an error message instead.
      dispatch({ type: reducer })
    })
    .catch(err => {
      console.error('Error payload command', err)
    })
  }
}

export const payloadZoomAction = ({ payloadId, zoomType, zoomLevel, zoomFocus }) => {

  const zoomCmd = [
    'zoomTo',    // zoom to a specific mag level
    'zoomIn',    // start zooming in until stop zoom
    'zoomOut',   // start zooming out until stop zoom
    'stopZoom',  // stop zooming (after zoomIn and zoomOut)
    'resetZoom', // reset to the default zoom level (minimum zoom)
  ].includes(zoomType) ? zoomType : 'resetZoom'

  const value = {}

  if (zoomCmd === 'zoomTo') {
    value.sZoomMagTimes = zoomLevel || 1.0
    if (zoomFocus)
      value.autofocusPoint = zoomFocus
  }
  else if (zoomCmd === 'zoomIn' || zoomCmd === 'zoomOut') {
    value.zoomSpeed = value || 1
  }
  return genericPayloadPost(payloadId, zoomCmd, { value }, PAYLOAD_ZOOM_ACTION)
}

export const payloadPitchAction = (payloadId, pitchType, value) => {
  const pitchCmd = [
    'pitchUp',   // pitch up by an undefined degrees
    'pitchDown', // pitch down by an undefined degrees
    'pitchTo',   // pitch to a specific degree
    'resetPitch' // reset to the default pitch degree (look level)
  ].includes(pitchType) ? pitchType : 'resetPitch'

  const body = {
    value: {}
  }
  if (pitchCmd === 'pitchTo')
    body.value.dPitch = value || 0.0
  return genericPayloadPost(payloadId, pitchCmd, body, PAYLOAD_PITCH_ACTION)
}

export const payloadYawAction = (payloadId, yawType, value) => {

  const yawCmd = [
    'yawLeft',  // yaw left by an undefined degrees
    'yawRight', // yaw right by an undefined degrees
    'yawTo',    // yaw to a specific degree
    'resetYaw', // reset to the default yaw degree (look forward)
  ].includes(yawType) ? yawType : 'resetYaw'

  const body = {
    value: {}
  }
  if (yawCmd === 'yawTo')
    body.value.dYaw = value || 0.0

  return genericPayloadPost(payloadId, yawCmd, body, PAYLOAD_YAW_ACTION)
}

export const payloadResetPitchYawAction = (payloadId) => {
  const body = {
    value: {}
  }
  return genericPayloadPost(payloadId, 'resetToHome', body, PAYLOAD_RESET_PITCH_YAW_ACTION)
}

export const payloadRecordAction = (payloadId, recordType) => {

  const recordCmd = [
    'record',
    'stopRecord'
  ].includes(recordType) ? recordType : 'stopRecord'

  const body = {
    value: {}
  }

  return genericPayloadPost(payloadId, recordCmd, body, PAYLOAD_RECORD_ACTION)
}

export const ChangeWinchState = winch => {
  const { drone_id } = getDecodedToken()
  return dispatch => {
    axios
    .post(CommandWebApi + `/drone/${drone_id}/changeWinchState`, {
      winchState: winch,
    })
    .then(resp => {
      console.log('Change winch state command OK', resp)
      dispatch({
        type: SET_WINCH_ACTION,
      })
    })
    .catch(err => {
      console.error('Error change winch state command', err)
    })
  }
}

export const ChangeReleaseState = servo => {
  const { drone_id } = getDecodedToken()
  return dispatch => {
    axios
    .post(CommandWebApi + `/drone/${drone_id}/changeReleaseState`, {
      releaseState: servo,
    })
    .then(resp => {
      console.log('Successful in issuing change servo command to drone: ', resp)
      dispatch({
        type: SET_SERVO_ACTION,
      })
    })
    .catch(err => {
      console.error('Error change release state command', err)
    })
  }
}