/**
 * Precipitation map data is scraped from www.weather.gov.sg/weather-rain-area-50km.
 * 
 * As of 16 Jun 2020, the image overlays are provided with the following format:
 * `https://www.weather.gov.sg/files/rainarea/50km/v2/dpsri_70km_{YYYY}{MM}{DD}{HH}{mm}{ssss}dBr.dpsri.png` 
 * 
 * where:
 * - `YYYY`: The current year in full
 * -   `MM`: The current month in two digits
 * -   `DD`: The current date in two digits
 * -   `HH`: The current hour in 24 hour format
 * -   `mm`: The current minute rounded down to 5 minute intervals
 * - `ssss`: The current milliseconds, usually 0000.
 * 
 * Note that the date time of the API is in Singapore Standard Time (UTC+08).
 * 
 * Data is only updated at every 5 minute interval, with a 3 minute delay before
 * it becomes available. Therefore, delay all requests by 3 minutes, and bin into
 * 5 minute intervals.
 * 
 * e.g. For 16 Jul 2020 10:42pm UTC+08, the date query should be `2020071622400000`
 * 
 * @param {number} nowInMs The current timestamp in ms.
 * @returns string The url of the precipitation map image overlay
 */
export function getPrecipitationImageUrl(nowInMs) {
    return `https://www.weather.gov.sg/files/rainarea/50km/v2/dpsri_70km_${getTimecode(nowInMs)}dBR.dpsri.png`
}

function getTimecode(nowInMs) {
    const now = new Date(nowInMs)
    const year = now.getFullYear()
    const month = `${now.getMonth() + 1}`.padStart(2, '0')
    const date = `${now.getDate()}`.padStart(2, '0')
    const hour = `${now.getHours()}`.padStart(2, '0')
    const rawMinute = now.getMinutes()
    const minute = `${parseInt(Math.floor(rawMinute / 5)) * 5}`.padStart(2, '0')
    const ms = '0000'
    return `${year}${month}${date}${hour}${minute}${ms}`
}