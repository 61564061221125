// Wrapper for all SkyStream API interaction
// Working example: https://skystream-api.garuda.io/v2/servers/_defaultServer_/vhosts/_defaultVHost_/applications/SkyStream/instances/520a86d7e4137e76bcf4a9f213275d00/incomingstreams/downward/monitoring/current

import axios from 'axios'
import { format } from 'date-fns'

const skystream_axios = axios.create({
  baseURL: process.env.REACT_APP_SKYSTREAM_API_URL
})
skystream_axios.defaults.headers.common['Accept'] = 'application/json; charset=utf-8'
skystream_axios.defaults.headers.common['Content-Type'] = 'application/json; charset=utf-8'
skystream_axios.defaults.timeout = 5000 // 5 seconds

// For now, everything defaults to the default server / vhost. Later when we have
// multiple instances of wowza, we can draw feeds from different servers / vhosts
// on a per request basis
const servers = ['_defaultServer_']
const vhosts  = ['_defaultVHost_']
const host_url = `/v2/servers/${servers[0]}/vhosts/${vhosts[0]}`

const applications = ['SkyStream', 'vod']
const instances = ['_definst_']

// Simulate JSend for consistency
function success(data) {
  return { status: 'success', data }
}
function fail(message, data) {
  return { status: 'fail', message, data }
}
// function error(message) {
//   return { status: 'error', message }
// }
function get(name, url) {
  return skystream_axios.get(url)
    .then(res => success(res.data))
    .catch(err => {
      console.log(`Error loading ${name}`, url, err)
      return fail(`Error loading ${name}`, err)
    })
}
const fmt = "yyyy-MM-dd'T'HH:mm:ss" // wowza date format for URL query

export function pingSkyStream() {
  return skystream_axios.get('/')
    .then(res => res.status === 200 ? res.data : false)
    .catch(err => false)
}

export function getAppConfig(application) {
  const app = application || applications[0]
  return get('AppConfig', `${host_url}/applications/${app}`)
}

export function getAppStats(application) {
  const app = application || applications[0]
  return get('AppStats', `${host_url}/applications/${app}/monitoring/current`)
}

export function getAppHistory(application) {
  const app = application || applications[0]
  const now = Date.now()
  const eightHoursAgo = now - 60 * 60 * 1000
  return get('AppStats', `${host_url}/applications/${app}/monitoring/historic?start=${format(new Date(eightHoursAgo), fmt)}&end=${format(new Date(now), fmt)}`)

  // 10 values of result.data.entries.[actual/average/min/max].[].data is
  // [ bandwidth_inbound in kilobytes/second, bandwidth_outbound in kilobytes/second,
  //   rtmp, rtsp, hds, hls, smooth, webrtc, webm, dash ]
}

export function getInstanceConfig(instance, application) {
  const app = application || applications[0]
  const ins = instance || instances[0]
  return get('InstanceConfig', `${host_url}/applications/${app}/instances/${ins}`)
}

export function getIncomingStreamStats(stream, instance, application) {
  const app = application || applications[0]
  const ins = instance || instances[0]
  return get('StreamStats', `${host_url}/applications/${app}/instances/${ins}/incomingstreams/${stream}/monitoring/current`)
}



