import { useState, useEffect } from 'react'
import TopXCloseButton from '../../components/TopXCloseButton'
import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  TextField,
} from '@material-ui/core'

const MIN_POLL_DELAY_SECONDS = 5

function PlexHealthConfig({ open, onClose, pollDelay, setPollDelay }) {
  const [ delayText,  setDelayText  ] = useState('')
  const [ delayError, setDelayError ] = useState('')

  useEffect(() => {
    if (pollDelay) {
      setDelayText((pollDelay / 1000).toString())
    } else {
      setDelayText('')
    }
  }, [pollDelay])

  function handleSave() {
    const delaySeconds = parseInt(delayText)
    if (delaySeconds < MIN_POLL_DELAY_SECONDS) {
      setDelayError(`The minimum polling delay is ${MIN_POLL_DELAY_SECONDS} seconds`)
    } else {
      setDelayError('')
      setPollDelay(delaySeconds * 1000)
      onClose()
    }
  }

  return (
    <Dialog open={open} onClose={onClose} maxWidth='xs' fullWidth>
      <DialogTitle>
        Plex Health Settings
      </DialogTitle>
      <DialogContent>
        <TopXCloseButton onClick={onClose} />
        <TextField
          label='Poll Delay (seconds)'
          value={delayText}
          error={!!delayError}
          onChange={e => setDelayText(e.target.value)}
          helperText={delayError || 'Set the refresh rate for Plex Health polling sanity end points'}
        />
      </DialogContent>
      <DialogActions>
        <Button variant='contained' color='primary' onClick={handleSave}>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default PlexHealthConfig