import { useState, useEffect }    from 'react'
import { bindActionCreators }     from 'redux'
import { connect }                from 'react-redux'
import WSContainer                from '../components/WSContainer'
import { updatePayloadTelemetry } from '../modules/actions/payloadActions'
import { getPayloadTelemetryWssUrl } from '../api/liveflights'

function LivePayloadTelemetryListener({ user, children, updatePayloadTelemetry }) {

  const [ wsURL, setWsURL ] = useState('')
  
  useEffect(() => {
    setWsURL(getPayloadTelemetryWssUrl())
  }, [user])

  const onMessage = (e) => {
    try {
      const data = JSON.parse(e.data)

      console.log('LPTL received data:', data)
      updatePayloadTelemetry(data)

    } catch(exception) {
      console.error('Unhandled exception caught at Live Payload Telemetry Listener', exception)
    }
  }

  return (
    <WSContainer url={wsURL} onMessage={onMessage}>
      {children}
    </WSContainer>
  )
}

const mapStateToProps = state => ({ user: state.oidc.user })

const mapDispatchToProps = dispatch => {
  return bindActionCreators({ updatePayloadTelemetry }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(LivePayloadTelemetryListener)
