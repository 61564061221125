import { useState, useEffect } from 'react'
import Dropzone from 'react-dropzone'
import axios from 'axios'

import Header from '../components/Header'
import {
  Button,
  CssBaseline,
  FormControl,
  Input,
  InputLabel,
  MenuItem,
  Select,
  SnackbarContent,
  Typography,
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'

// do you still need this?
// .dropzone {
//   background-color: rgba(0,0,0,0.1);
//   width: 100%;
//   height: 100%;
//   display: flex;
//   flex-direction: column;
//   justify-content: center;
//   align-items: center;
//   border-radius: 5px;
//   border-style: dashed;
//   border-color: rgba(0,0,0,0.5);
// }

const styles = theme => ({
  root: {
    height: '100%',
    width: '100%',
  },
  paper: {
    paddingBottom: theme.spacing(2),
    margin: theme.spacing(2),
    width: 500,
  },
  title: {
    paddingTop: theme.spacing(2),
  },
  container: {
    padding: theme.spacing(3),
  },
  panel: {
    width: 600,
    paddingTop: 10,
    paddingBottom: 10,
    margin: '0 auto',
    backgroundColor: theme.palette.bluegrey.dark,
  },
  dropdown: {
    marginTop: theme.spacing(2),
    width: 500,
    maxWidth: 500,
    display: 'flex',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  dropzone: {
    margin: '0 auto',
    marginTop: theme.spacing(4),
    width: 500,
    border: '2px solid',
    height: theme.spacing(8),
  },
  createButton: {
    margin: 'auto',
    display: 'block',
    textAlign: 'center',
    marginTop: theme.spacing(2),
  },
  success: {
    backgroundColor: theme.palette.success.main
  },
  waypoint: {
    margin: '0 auto',
    textAlign: 'center'
  },
})
const DEPLOYMENTS_API  = process.env.REACT_APP_DEPLOYMENTS_API
const FLIGHT_PLANS_API = process.env.REACT_APP_FLIGHT_PLANS_API

const UploadFlightPlans = ({ classes }) => {
  const [ deployments,    setDeployments    ] = useState(null)
  const [ deploymentId,   setDeploymentId   ] = useState(null)
  const [ flightPlanName, setFlightPlanName ] = useState(null)
  const [ waypointJSON,   setWaypointJSON   ] = useState(null)
  const [ uploadStatus,   setUploadStatus   ] = useState(null)

  useEffect(() => {
    const fetchDeployments = async () => {
      const result = await axios.get(DEPLOYMENTS_API)
      console.log('Deployments: ', result)
      setDeployments(result.data.data)
    }
    fetchDeployments()
  }, [])

  const createFlightPlan = async () => {
    const deploymentObj = deployments.find(elem => elem.deployment_id === deploymentId)
    console.log('Deployment Object: ', deploymentObj)
    const { drones } = deploymentObj
    const flightPlanJSON = {
      description: flightPlanName,
      plan_type: 'ardupilot',
      drones: drones.map(obj => obj.id),
      deployment_id: deploymentId,
      plan: {
        requirements: {
          RTL_ALT: 3000,
          FS_BATT_ENABLE: 2,
          FS_GCS_ENABLE: 2,
          FS_THR_ENABLE: 1,
        },
        commands: waypointJSON,
      },
    }
    console.log('Flight plan json: ', flightPlanJSON)
    try {
      await axios.post(FLIGHT_PLANS_API, flightPlanJSON)
      setUploadStatus({
        done:    true,
        success: true,
        err:     null,
      })
    } catch (err) {
      setUploadStatus({
        done:    true,
        success: false,
        err:     'Unexpected error while uploading flight plan.',
      })
    }
  }

  const formatWaypoints = lines => {
    const route = []
    let bufferLat = 0.00
    let bufferLon = 0.00
    const rows = lines.split('\n')

    for (let i = 0; i < rows.length; i++) {
      const line = rows[i]

      const mavlink    = line.split('\t')
      const mavcommand = mavlink[3]
      const latitude   = mavlink[8]
      const longitude  = mavlink[9]

      if (latitude > 0 && longitude > 0){
        bufferLat = latitude
        bufferLon = longitude
      }

      if (latitude < 1 && longitude < 1){
        mavlink[8] = bufferLat
        mavlink[9] = bufferLon
      }

      console.log('LatLong Values: ' + mavlink[8] + '-' + mavlink[9])

      console.log('Mavcommand: ', mavcommand)
      if (mavcommand !== '0' && typeof mavcommand !== 'undefined') {
        route.push({
          id: mavlink[3],
          param1: mavlink[4],
          param2: mavlink[5],
          param3: mavlink[6],
          param4: mavlink[7],
          param5: mavlink[8],
          param6: mavlink[9],
          param7: mavlink[10],
          description: '',
        })
      }
    }
    return route
  }

  const onDrop = acceptedFiles => {
    acceptedFiles.forEach(file => {
      const reader = new FileReader()
      reader.readAsText(file)
      reader.onload = e => {
        const route = formatWaypoints(reader.result)
        console.log('Route: ', route)
        setWaypointJSON(route)
      }
    })
  }

  return (<>
    <CssBaseline />
    <Header />
    <div className={classes.container}>
      <div className={classes.panel}>
        { uploadStatus !== null && uploadStatus.success && uploadStatus.done &&
          <SnackbarContent className={classes.success}
            message='Successfully saved settings'
          />
        }
        <Typography align='center' className={classes.title} variant='h4'>
          Upload Flight Plan
        </Typography>
        <FormControl className={classes.dropdown}>
          <InputLabel shrink htmlFor='select-deployment'>
            <p style={{ fontSize: 20, textAlign: 'center' }}>Select Deployments</p>
          </InputLabel>
          { deployments && (
            <Select
              input={<Input name='mission' id='select-deployment' />}
              onChange={evt => setDeploymentId(evt.target.value)}
              style={{ marginTop: 30 }}
              value={deploymentId}>
              { deployments.map(obj => (
                  <MenuItem value={obj.deployment_id}>{obj.name}</MenuItem>
              )) }
            </Select>
          )}
        </FormControl>
        <br />
        <FormControl className={classes.dropdown}>
          <InputLabel shrink htmlFor='flight-plan-name'>
            <p style={{ fontSize: 20, textAlign: 'center' }}>Flight Plan Name</p>
          </InputLabel>
          <Input
            style={{ marginTop: 30 }}
            name='flight-plan-name'
            id='flight-plan-name-input'
            onChange={e => setFlightPlanName(e.target.value)}
          />
        </FormControl>
        <Dropzone className={classes.dropzone}
          // className='dropzone'
          onDrop={files => onDrop(files)}>
          <br />
          { waypointJSON &&
            <p className={classes.waypoint}>
              Uploaded successfully
            </p>
          }
          { !waypointJSON &&
            <p className={classes.waypoint}>
              Drag and Drop to Upload directly or click to select files
            </p>
          }
        </Dropzone>
        <Button
          className={classes.createButton}
          variant='contained'
          color='primary'
          onClick={() => createFlightPlan()}>
          Create Flight Plan
        </Button>
      </div>
    </div>
  </>)
}

export default withStyles(styles)(UploadFlightPlans)
