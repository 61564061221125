import HeliosMissionControl from './HeliosMissionControl'
import MissionContext from '../mission/MissionContext'

const HeliosMissionControlContainer = () => {
  return <MissionContext.Consumer>
    {({ updateMission,
      // triggerAlert
    }) => {
      return <HeliosMissionControl
        updateMission={updateMission}
      />
    }}
  </MissionContext.Consumer>
}
export default HeliosMissionControlContainer
