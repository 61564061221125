import { useEffect, useState } from 'react'
import '../../static/css/ome_video.css'
import {
  Grid,
  CircularProgress,
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'

const styles = theme => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    width: '100%',
    backgroundColor: 'black'
  },
  player: (width) => ({
    width: width,
    height: '100%',
  }),
  loading: {
    position: 'absolute',
    display: 'flex'
  },
})

function OvenWebRTCStream({ classes, droneId, streamName }) {
  const [ loading, setLoading ] = useState(true)
  const [ error,   setError   ] = useState()

  useEffect(() => {
    let player
    setLoading(true)
    const script = document.createElement('script')

    script.src = 'http://localhost:8080/dist/production/ovenplayer/ovenplayer.js'
    script.async = true
    script.id = 'ovenplayer_script'

    document.body.appendChild(script)
    document.getElementById('ovenplayer_script').addEventListener('load', () => {
      const OvenPlayer = window.OvenPlayer
      OvenPlayer.debug(false)
      player = OvenPlayer.create('player', {
        sources: [{
          type: 'webrtc',
          file: `ws://localhost:3333/app/${droneId}_${streamName}?transport=tcp`,
          label: 'WebRTC 1080p'
        }],
        showBigPlayButton: false,
        showSeekControl: false,
        autoStart: true,
        controls: false
      })
      player.on('ready', function (data) {
        player.play()
        setLoading(false)
      })
      player.on('error', function (error) {
        console.log('should show error')
        setError(error.message)
      })
      player.on('time' ,function(){
        player.play()
      })
    })
    return () => {
      if(player)
        player.remove()
      document.body.removeChild(script)
    }
  // eslint-disable-next-line
  }, [])

  return (
    <div className={classes.root}>
      <Grid container>
        <Grid item xs={12}>
          <div id='player' className={classes.player} />
        </Grid>
      </Grid>
      {loading &&
        <div className={classes.loading}>
          <CircularProgress />
        </div>}
      {error &&
        <div style={{ position: 'absolute'}}>
          {error}
        </div>}
    </div>
  )
}

export default withStyles(styles)(OvenWebRTCStream)
