import { UPDATE_MARKER_DIRTY, DISCARD_MISSION_PLAN_CHANGE } from '../actions/map'
const initialState = {
  isMapDirty: false,
}

function mapReducer(state = initialState, action) {
  switch (action.type) {
    case UPDATE_MARKER_DIRTY:
      return {
        ...state,
        isMapDirty: action.isDirty,
      }
    case DISCARD_MISSION_PLAN_CHANGE:
      return {
        ...state,
        isMapDirty: false,
      }
    default:
      return state
  }
}

export default mapReducer
