import { useEffect, useState } from 'react'
import FlightPlanMarkers    from '../live/maps/FlightPlanMarkers'
import FlightPlanPath       from '../live/maps/FlightPlanPath'
import { parseMissionCommands } from '../utils/mission'
import CommonLayers from '../components/Map/CommonLayers'
import AreaOfOperations  from '../regional-map/components/AreaOfOperations'
import {
  GeoJSON,
  LayerGroup,
  MapContainer,
  ZoomControl,
  ScaleControl,
} from 'react-leaflet'
import { withStyles } from '@material-ui/core/styles'
// Needed to define 100% width and height for the leaflet container created by Leaflet
import '../static/css/leaflet.css'

import {
  DEFAULT_MAP_CENTER
} from '../utils/site-maps'

const styles = theme => ({
  root: {
    position: 'absolute',
    top: 48,
    left: 300,
    right: 0,
    bottom: 0,
    display: 'relative',
  },
  geofence: {
    fill: theme.palette.primary.main,
    stroke: theme.palette.primary.main,
  },
})

function MissionPreviewMap({ mission, targetGeoJson, classes }) {

  const [ commands, setCommands ] = useState(undefined)

  useEffect(() => {
    // Check if a mission plan exists. Since a non-existent mission is
    // passed as an empty object, we cannot use a simple null comparison.
    if (Object.keys(mission).length !== 0) {
      setCommands(parseMissionCommands(mission.plan.commands))
    }
  }, [mission])

  return (
    <div className={classes.root}>
      <MapContainer
        center={DEFAULT_MAP_CENTER}
        zoom={14}
        zoomControl={false}
        maxZoom={22}>
        <AreaOfOperations commands={commands} />
        <CommonLayers />
        <ZoomControl position='topright' />
        <ScaleControl position='bottomright' imperial={false} />
        { commands &&
          <LayerGroup>
            <FlightPlanPath commands={commands} />
            <FlightPlanMarkers commands={commands} />
            <GeoJSON key={targetGeoJson} data={targetGeoJson} className={classes.geofence} />
          </LayerGroup>
        }
      </MapContainer>
    </div>
  )
}

export default withStyles(styles)(MissionPreviewMap)