import { useState } from 'react'
import RTTDialog from '../Components/RTTDialog'
import {
  Button,
} from '@material-ui/core'
import {
  NetworkCheck
} from '@material-ui/icons'
import { withStyles } from '@material-ui/core/styles'

const styles = theme => ({
  button: {
    width:  '100%',
    height: theme.spacing(3),
    marginBottom: theme.spacing(1),
  },
})

function SpeedInfoButton({ classes }) {
  const [ showRTT, setShowRTT ] = useState(false)

  return (<>
    <Button
      size='small'
      variant='contained'
      color='primary'
      className={classes.button}
      startIcon={<NetworkCheck />}
      onClick={() => setShowRTT(true)}>
      SpeedInfo
    </Button>
    { showRTT &&
      <RTTDialog
        open={showRTT}
        onClose={() => setShowRTT(false)}
      />
    }
  </>)
}

export default withStyles(styles)(SpeedInfoButton)
