import { useGetDroneByDroneIdQuery } from '../../api/aircraft'
import TopXCloseButton from '../../components/TopXCloseButton'
import {
  Button,
  Dialog,
  DialogContent,
  Grid,
  Typography,
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'

import defaultAvatar from '../../static/images/drone.svg'
const styles = theme => ({
  img: {
    padding: theme.spacing(2),
    border: '1px solid white',
    width: '100%'
  }
})

function AircraftDialog({ classes, open, onClose, mission }) {

  const { data } = useGetDroneByDroneIdQuery(mission.droneId, {
    skip: !mission
  })
  const drone = data?.data?.drone || {}

  return (
    <Dialog open={open} onClose={onClose} maxWidth='md' fullWidth>
      <DialogContent>
        <TopXCloseButton onClick={onClose} />
        <Grid container spacing={2}>
          <Grid item xs={3}>
            <img src={drone.avatar || drone.model?.avatar || defaultAvatar}
              alt='avatar' className={classes.img} />
          </Grid>
          <Grid item xs={9}>
            <Typography variant='h4'>{drone.name || '-'}</Typography>
            <Typography variant='caption'>Serial Number: {drone.serial_number || 'no serial number'}</Typography>
            <br />
            <br />
            { drone.serviceable ?
              <Button size='small' variant='contained' color='primary'>Serviceable</Button>
            :
              <Button size='small' variant='contained' color='secondary'>Not Serviceable</Button>
            } { drone.status === 'active' ?
              <Button size='small' variant='contained' color='primary'>Active</Button>
            : drone.status === 'deleted' ?
              <Button size='small' variant='contained' color='secondary'>Deleted</Button>
            :
              <Button size='small' variant='outlined'>Invalid Status {drone.status}</Button>
            } {
              drone.shared ?
              <Button size='small' variant='contained' color='primary'>Shared</Button>
            :
              <Button size='small' variant='contained' color='secondary'>Not Shared</Button>
            }
            <br />
            <br />
            <Typography variant='body2'><u>Mission Info</u></Typography>
            <Typography variant='body2'>Drone ID: {mission.droneId}</Typography>
            <Typography variant='body2'>Drone Name: {mission.droneName}</Typography>
            <Typography variant='body2'>Drone Model: {mission.droneModel}</Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography variant='h6'>About This Drone</Typography>
            <Typography variant='body1'>
              {drone.properties?.manufacture_date ?
                'Manufactured on ' + new Date(drone.properties.manufacture_date).toLocaleDateString() :
                'Unknown manufacturing date'}
            </Typography>
            <Typography variant='body1'>
              {drone.properties?.acquire_date ?
                'Acquired on ' + new Date(drone.properties.acquire_date).toLocaleDateString() :
                'Unknown acquisition date'}
            </Typography>
            <Typography variant='h6'>Provider / Supplier</Typography>
            <Typography variant='body1'>{drone.provider?.name || '-'}</Typography>
            <Typography variant='body2'>Country: {drone.provider?.country || '-'}</Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography variant='h6'>Brand</Typography>
            <Typography variant='body1'>{drone.provider?.brand || '-'}</Typography>

            <Typography variant='h6'>Details</Typography>
            <Typography variant='body1'>Category:</Typography>
            <Typography variant='body1'>Weight:</Typography>
            <Typography variant='body1'>Max Flight Time:</Typography>
            <Typography variant='body1'>RC Frequency:</Typography>
            <Typography variant='body1'>Dimensions:</Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography variant='h6'>Model</Typography>
            <Typography variant='body1'>{drone.model?.name || '-'}</Typography>

            <Typography variant='h6'>&nbsp;</Typography>
            <Typography variant='body1'>{drone.model?.category || '-'}</Typography>
            <Typography variant='body1'>{drone.model?.weight_kg || '-'} kg</Typography>
            <Typography variant='body1'>{drone.model?.properties?.max_flight_time || '-'} minutes</Typography>
            <Typography variant='body1'>{drone.model?.properties?.rc_frequency || '-'}</Typography>
            <Typography variant='body1'>
              (w){drone.model?.properties?.dimensions?.width || '-'} x (l)
              {drone.model?.properties?.dimensions?.length || '-'} x (h)
              {drone.model?.properties?.dimensions?.height || '-'} metres
              {drone.model?.properties?.dimensions?.diagonal &&
                <>(diagonal: {drone.model.properties.dimensions.diagonal} metres)</>
              }
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography variant='h6'>Notes</Typography>
            {drone.properties?.notes && <>
              <Typography variant='body1'>{drone.properties.notes}</Typography>
            </>}
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  )
}


export default withStyles(styles)(AircraftDialog)
