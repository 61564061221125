import { useState } from 'react'
import { connect } from 'react-redux'
import Header from '../components/Header'
import {
  Avatar,
  Checkbox,
  CssBaseline,
  Table,
  TableCell,
  TableBody,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core'
import {
  Block,
  Check,
} from '@material-ui/icons'
import { withStyles } from '@material-ui/core/styles'

const styles = theme => ({
  root: {
    padding: theme.spacing(2),
  },
  panel: {
    border: '1px solid ' + theme.palette.bluegrey.main,
    borderRadius: theme.spacing(2),
    marginRight: theme.spacing(1),
    padding: theme.spacing(2),
    maxWidth: '50%',
    backgroundColor: theme.palette.bluegrey.dark,
  },
  bold: {
    fontWeight: 'bold',
    color: theme.palette.primary.main,
  },
})

function PreFlightChecks({ classes, mission, deployment }) {

  return (<>
    <CssBaseline />
    <Header />
    <div className={classes.root}>
      <Typography variant='h5' gutterBottom>Pre-flight Checks</Typography>
      <div style={{ display: 'flex' }}>
        { deployment &&
          <div className={classes.panel}>
            <DeploymentChecklist deployment={deployment} />
          </div>
        }
        { mission &&
          <div className={classes.panel}>
            <MissionChecklist mission={mission} />
          </div>
        }
      </div>
    </div>
  </>)
}

const DeploymentChecklist = withStyles(styles)(({ classes, deployment }) => {
  const [ checks, setChecks ] = useState({})

  function handleChange(event) {
    setChecks({ ...checks, [event.target.name]: event.target.checked })
  }

  const { lead, personnel, drones, batteries, cameras, payloads } = checks
  return (<>
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>
            <Avatar style={{ color: 'white' }}><Check /></Avatar>
          </TableCell>
          <TableCell>
            <Typography variant='h6'>Deployment Checklist</Typography>
            <Typography variant='body2'>{deployment.name}</Typography>
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        <CheckTableRow checked={lead}
          msg={<>Deployment lead <B>{deployment.lead?.name}</B> is filling in this
            Deployment Checklist.</>}
          onChange={handleChange} />

        <CheckTableRow checked={personnel} onChange={handleChange}>
          <Typography variant='body1'>
            The following <B>{deployment.personnel?.length} personnel</B> are present:
          </Typography>
          <ul>
            {deployment.personnel?.map(p => <LI key={p.email}>{p.name}</LI>)}
          </ul>
        </CheckTableRow>

        <CheckTableRow checked={drones} onChange={handleChange}>
          <Typography variant='body1'>
            The following <B>{deployment.drones?.length} drones</B> are present:
          </Typography>
          <ul>
            {deployment.drones?.map(d => <LI key={d.name}>{d.name}</LI>)}
          </ul>
        </CheckTableRow>

        { deployment.batteries?.length > 0 ?
          <CheckTableRow checked={batteries} onChange={handleChange}>
            <Typography variant='body1'>
              The following <B>{deployment.batteries?.length} batteries</B> are present:
            </Typography>
            <ul>
              {deployment.batteries?.map(b => <LI key={b.name}>{b.name}</LI>)}
            </ul>
          </CheckTableRow>
        :
          <NoCheckTableRow msg='No batteries are required in this deployment.' />
        }
        { deployment.cameras?.length > 0 ?
          <CheckTableRow checked={cameras} onChange={handleChange}>
            <Typography variant='body1'>
              The following <B>{deployment.cameras?.length} cameras</B> are present:
            </Typography>
            <ul>
              {deployment.cameras?.map(c => <LI key={c.name}>{c.name}</LI>)}
            </ul>
          </CheckTableRow>
        :
          <NoCheckTableRow msg='No cameras are required in this deployment.' />
        }
        { deployment.payloads?.length > 0 ?
          <CheckTableRow checked={payloads} onChange={handleChange}>
            <Typography variant='body1'>
              The following <B>{deployment.payloads?.length} payloads</B> are present:
            </Typography>
            <ul>
              {deployment.payloads?.map(p => <LI key={p.name}>{p.name}</LI>)}
            </ul>
          </CheckTableRow>
        :
          <NoCheckTableRow msg='No payloads are required in this deployment.' />
        }
      </TableBody>
    </Table>
    <div style={{ padding: '16px' }}>
      <Typography variant='body2'>
        A deployment check only needs to be carried out once per deployment (e.g. per day
        out in the field). You can ignore this check if you have already done it.
      </Typography>
    </div>
  </>)
})

const MissionChecklist = withStyles(styles)(({ classes, mission }) => {
  const [ checks, setChecks ] = useState({})

  function handleChange(event) {
    setChecks({ ...checks, [event.target.name]: event.target.checked })
  }

  const { commander, geofence, maxalt, failsafe, } = checks
  return (<>
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>
            <Avatar style={{ color: 'white' }}><Check /></Avatar>
          </TableCell>
          <TableCell>
            <Typography variant='h6'>Mission Checklist</Typography>
            <Typography variant='body2'>{mission.name}</Typography>
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        <CheckTableRow checked={commander}
          msg={<>Commander <B>{mission.commander}</B> is filling in this
            Mission Preflight Checklist.</>}
          onChange={handleChange} />

        <CheckTableRow checked={geofence}
          msg={<>A <B>geofence</B> has been configured to match that of your
            Area of Operation (AO).</>}
          onChange={handleChange} />

        <CheckTableRow checked={maxalt}
          msg={<>A <B>maximum altitude</B> has been configured to match the
            altitude ceiling by the regulator or airspace manager.</>}
          onChange={handleChange} />

        <CheckTableRow checked={failsafe} onChange={handleChange}>
          <Typography variant='body1'>
            The following <B>failsafes</B> has been configured.
          </Typography>
          <ul>
            <LI>Lost VLOS GCS</LI>
            <LI>Lost BVLOS GCS (Network disconnect)</LI>
            <LI>Lost GNSS</LI>
            <LI>Lost Transmitter</LI>
            <LI>Battery below 50%</LI>
            <LI>Battery below 30%</LI>
            <LI>Battery below 10%</LI>
          </ul>
        </CheckTableRow>
      </TableBody>
    </Table>
    <div style={{ padding: '16px' }}>
      <Typography variant='body2'>
        A mission (or preflight) check needs to be carried out once before every flight.
        You can do a short version after you select your mission, or this longer one.
        No two preflight checks are the same, you should try to build your own preflight check lists.
      </Typography>
    </div>
  </>)
})

const B = withStyles(styles)(({ classes, children }) => {
  return <span className={classes.bold}>{children}</span>
})

const LI = ({ children, ...rest }) => {
  return <li {...rest}><Typography variant='body1'>{children}</Typography></li>
}

function CheckTableRow({ checked, onChange, msg, children }) {
  return (
    <TableRow>
      <TableCell>
        <Checkbox checked={checked} onChange={onChange} />
      </TableCell>
      <TableCell>
        { msg && <Typography variant='body1'>{msg}</Typography> }
        { children }
      </TableCell>
    </TableRow>
  )
}
function NoCheckTableRow({ msg }) {
  return (
    <TableRow>
      <TableCell>
        <Avatar style={{ color: 'white' }}><Block /></Avatar>
      </TableCell>
      <TableCell>
        <Typography variant='body1'>{msg}</Typography>
      </TableCell>
    </TableRow>
  )
}

const mapStateToProps = state => ({
  deployment: state.deployment.deploymentData,
  mission:    state.mission.mission,
})

export default connect(mapStateToProps, null)(withStyles(styles)(PreFlightChecks))
