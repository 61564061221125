import { useState } from 'react'
import { useInControl } from '../../utils/useFlightStates'
import CommandTable from '../../live/maps/CommandTable'
import TopXCloseButton from '../../components/TopXCloseButton'
import {
  Avatar,
  Button,
  Dialog,
  IconButton,
  Grid,
  Typography,
} from '@material-ui/core'
import {
  Cancel,
  FilterCenterFocus,
  FormatListNumbered,
  FlightLand,
  TouchApp,
  TrackChanges,
  Warning,
} from '@material-ui/icons'
import { withStyles } from '@material-ui/core/styles'

const styles = theme => ({
  container: {
    height: theme.spacing(6),
    backgroundColor: theme.palette.bluegrey.dark
  },
  instructions: {
    height:     theme.spacing(6),
    display:    'flex',
    alignItems: 'center',
    flexWrap:   'wrap',
    justifyContent: 'space-between',
    padding:    '0 ' + theme.spacing(1) + 'px',
  },
  avatarTypography: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  touchAvatar: {
    margin: '0 ' + theme.spacing(1) + 'px',
    color: theme.palette.common.white
  },
  cancelButton: {
    justifyContent: 'flex-end'
  },
})

function MapControls({
  classes,
  onZoomToMission,
  onEnableFlyHere, selectingLocationToFly, onCancelFlyHere, onConfirmFlyHere,
  onToggleNfz,     showNfz,
  onToggleElz,     showElz,
  onFixateOnDrone, fixateOnDrone,
  commands,
}) {
  const [ cmdOpen, setCmdOpen ] = useState(false)
  const inControl = useInControl()

  if (selectingLocationToFly) {
    return (
      <div className={classes.instructions}>
        <Button
          className={classes.button}
          variant='contained'
          color='primary'
          onClick={onConfirmFlyHere}>
          Confirm
        </Button>
        <div className={classes.avatarTypography}>
          <Avatar className={classes.touchAvatar}><TouchApp /></Avatar>
          <Typography>
            Click on a location on the map to send the drone to
          </Typography>
        </div>
        <IconButton
          color='primary'
          className={classes.cancelButton}
          onClick={onCancelFlyHere}>
          <Cancel />
        </IconButton>
      </div>
    )
  }

  const toggleNfzButton =
    <Button
      color={ showNfz ? 'secondary' : 'primary' }
      variant='contained'
      startIcon={<Warning />}
      onClick={onToggleNfz}>
      { showNfz ? 'Hide' : 'Show' } NFZ
    </Button>

  const toggleElzButton =
    <Button
      color={ showElz ? 'secondary' : 'primary' }
      variant='contained'
      startIcon={<FlightLand />}
      onClick={onToggleElz}>
      { showElz ? 'Hide' : 'Show' } ELZ
    </Button>

  const flyHereButton =
    <Button
      color='primary'
      variant='contained'
      startIcon={<TouchApp />}
      disabled={!inControl}
      onClick={onEnableFlyHere}>
      Fly Here
    </Button>

  const zoomToMissionButton =
    <Button
      color='primary'
      variant='contained'
      startIcon={<FilterCenterFocus />}
      onClick={onZoomToMission}>
      Zoom Mission
    </Button>

  const listCommandsButton =
    <Button
      color='primary'
      variant='contained'
      startIcon={<FormatListNumbered />}
      onClick={() => setCmdOpen(true)}>
      List Commands
    </Button>

  const fixateOnDroneButton = 
    <Button
      color={fixateOnDrone ? 'primary' : 'default'}
      variant={fixateOnDrone ? 'contained' : 'outlined'}
      startIcon={<TrackChanges />}
      onClick={onFixateOnDrone}>
      Center Drone
    </Button>

  return (<>
    <Grid container justifyContent='space-evenly' alignItems='center' className={classes.container}>
      <Grid item>{ toggleNfzButton     }</Grid>
      <Grid item>{ toggleElzButton     }</Grid>
      <Grid item>{ flyHereButton       }</Grid>
      <Grid item>{ zoomToMissionButton }</Grid>
      <Grid item>{ listCommandsButton  }</Grid>
      <Grid item>{ fixateOnDroneButton }</Grid>
      { cmdOpen &&
        <Dialog open={cmdOpen} onClose={() => setCmdOpen(false)}
          fullWidth maxWidth='lg'>
          <TopXCloseButton onClick={() => setCmdOpen(false)} />
          <CommandTable commands={commands} />
        </Dialog>
      }
    </Grid>
  </>)
}

export default withStyles(styles)(MapControls)
