import React from "react"
import Typography from "@material-ui/core/Typography"
import withStyles from "@material-ui/core/styles/withStyles"
import { Tooltip } from "@material-ui/core"
import theme from '../../utils/site-theme'


const styles = theme => ({
    healthText: {
        paddingTop: "10px",
        paddingBottom: "10px",
        borderBottom: "2px solid " + theme.palette.secondary.main,
        width: "100%",
    },
    healthTextLast: {
        paddingTop: "10px",
        paddingBottom: "10px",
        width: "100%",
    },
})

const statusColors = {
    good: theme.palette.success.main,
    bad: theme.palette.error.main,
    pending: theme.palette.secondary.main,
    // TODO how to put these into theme?
    available: "rgba(32, 191, 107, 0.12)",
    disabled: "rgba(255, 255, 255, 0.114)",
}

const HealthText = ({ fill, status, last, label, tooltip, classes: { healthText, healthTextLast } }) => (
  <Tooltip title={tooltip || ' '}>
    <Typography
        align="center"
        className={last ? healthTextLast : healthText}
        style={{
            backgroundColor: fill === "bg" ? statusColors[status] : "none",
            color:
                fill === "text"
                    ? statusColors[status]
                    : status === "disabled"
                    ? theme.palette.bluegrey.main
                    : theme.palette.common.white,
        }}
        variant="h6"
        color="textSecondary"
        component="p"
    >
        {label}
    </Typography>
  </Tooltip>
)

export default withStyles(styles)(HealthText)
