import { getDecodedToken } from '../api/accounts'
import { useGetAllFlightStatusQuery } from '../api/liveflights'

export function useInControl() {
  const { drone_id, user_id, company_id, access_token } = getDecodedToken()
  
  const { data: flightStatus } = useGetAllFlightStatusQuery({
    company_id, access_token, drone_id, only_ongoing_flights: true
  })
  return flightStatus ?
    flightStatus[drone_id]?.personnel_roles?.ua_pilot_primary.user_id === user_id : false
}
