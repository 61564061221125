// Listen to nearby violations.
//
// What constitutes "nearby" is defined in the backend by company_id for now.
// In the future this will need to go towards Identification Service Areas

import { useRef, useEffect } from 'react'
import Sockette from 'sockette'

const CONFORMANCE_MONITORING_WEBSOCKET_API = process.env.REACT_APP_CONFORMANCE_MONITORING_WEBSOCKET_API

if (!CONFORMANCE_MONITORING_WEBSOCKET_API)
  throw Error('Environment Variable REACT_APP_CONFORMANCE_MONITORING_WEBSOCKET_API is missing')

function conformanceWS({
  onOpen, onMessage, onReconnect, onMaximum, onClose, onError
}) {
  const url  = `${CONFORMANCE_MONITORING_WEBSOCKET_API}`
  const ws = new Sockette(url, {
    timeout:     5000, // 5 seconds
    maxAttempts: 3,    // 3 attempts
    onopen:      e => { onOpen      ? onOpen(e)      : console.info('conformance ws connected.', e) },
    onmessage:   e => { onMessage   ? onMessage(e)   : console.info('conformance ws received:', e) },
    onreconnect: e => { onReconnect ? onReconnect(e) : console.info('conformance ws reconnecting..', e) },
    onmaximum:   e => { onMaximum   ? onMaximum(e)   : console.info('conformance ws stop attempting.', e) },
    onclose:     e => { onClose     ? onClose(e)     : console.info('conformance ws closed.', e) },
    onerror:     e => { onError     ? onError(e)     : console.error('conformance ws error:', e) },
  })
  return ws
}

function NearbyViolationListener({
  // showCfmsViolations,
  onNearbyViolationMessage,
}) {
  // https://stackoverflow.com/questions/60152922/proper-way-of-using-react-hooks-websockets
  const wsRef = useRef(null)

  useEffect(() => {
    if (wsRef.current)
      return // only need to create it once

    // To figure out whether we can split regular tracks or combine
    // if (showCfmsViolations) {
      wsRef.current = conformanceWS({ onMessage: onNearbyViolationMessage })

      // Cannot close here: it will close after every message...
      // const wsCurrent = wsRef.current
      // return (() => {
      //   wsCurrent.close()
      // })
    // }
  }, [onNearbyViolationMessage])

  return null
}

export default NearbyViolationListener
