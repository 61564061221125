import { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect }            from 'react-redux'

import { handleTelemetry } from '../modules/actions/telemetryActions'
import CommandSender from './CommandSender'

const COMMAND_WSS_URL = process.env.REACT_APP_COMMAND_WSS_URL

class LiveCommandSender extends Component {
  state = {
    wsCon: null
  }
  onReceivedMessage(e) {
    // console.info('received message')
  }

  onCommandOpen(ws) {
    this.setState({ wsCon: ws.currentTarget })
    console.info(this.props)
    this.props.onWSOpen(ws)
  }
  
  render(){
    return <CommandSender
      endpoint={COMMAND_WSS_URL}
      onMessage={this.onReceivedMessage}
      onOpen={this.onCommandOpen.bind(this)}>
        {this.props.children}
    </CommandSender>
  }
}
const mapStateToProps = (state) => ({
  companyId: state.oidc.user.profile.company_id,
  droneId:   state.mission.mission?.droneId
})

const mapDispatchToProps = dispatch => {
  return bindActionCreators({ handleTelemetry }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(LiveCommandSender)
