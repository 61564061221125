//
// TODO: Combine these settings with Config Management?
//
import {
  UPDATE_SETTINGS,
  USE_LIGHT_THEME,
  USE_AIRMAP_UTM,
  USE_CFMS_UTM,
  USE_ONESKY_UTM,
} from '../actions/settings'

const initialState = {
  settings: {
    // Default is Dark Theme
    useLightTheme: window.localStorage.getItem(USE_LIGHT_THEME) === 'true',
    // Default all UTMs to false
    useAirmap: window.localStorage.getItem(USE_AIRMAP_UTM) === 'true',
    useCfms:   window.localStorage.getItem(USE_CFMS_UTM)   === 'true',
    useOnesky: window.localStorage.getItem(USE_ONESKY_UTM) === 'true',
  },
}

function settingsReducer(state = initialState, action) {
  const { settings } = action
  switch (action.type) {
    case UPDATE_SETTINGS:
      return {
        ...state,
        settings,
      }
    default:
      return state
  }
}

export default settingsReducer
