import { useState } from 'react'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
} from '@material-ui/core'
import {
  Info,
} from '@material-ui/icons'
import { withStyles } from '@material-ui/core/styles'

const styles = theme => ({
  button: {
    position: 'absolute',
    top: 0,
    right: theme.spacing(1),
  },
})

function InfoButton({ classes, title, children }) {
  const [ open, setOpen ] = useState(false)
  return (<div className={classes.button}>
    <IconButton
      onClick={() => setOpen(!open)}>
      <Info />
    </IconButton>
    <Dialog
      open={open}
      onClose={() => setOpen(false)}>
      <DialogTitle>About {title}</DialogTitle>
      <DialogContent>
        {children}
      </DialogContent>
      <DialogActions>
        <Button variant='contained' color='primary'
          onClick={() => setOpen(false)}>
          OK
        </Button>
      </DialogActions>
    </Dialog>
  </div>)
}

export default withStyles(styles)(InfoButton)
