import { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import useCanvas from '../../utils/useCanvas'
import theme from '../../utils/site-theme'

import { withStyles } from '@material-ui/core/styles'
import { tree } from '../../static/constants/hudSVG.js'

const styles = theme => ({
  canvas: {
    width: '100%',
  }
})

function drawDefault(context) {
  const width  = context.canvas.width
  const height = context.canvas.height

  // TODO: canvas not filling color when given SVG, switch to PNG?
  const green = theme.palette.success.main
  const treeSVGs = [tree(green, 3), tree(green, 2), tree(green, 1)]
  const treeImages = treeSVGs.map(t => {
    const img = new Image()
    img.src = `data:image/svg+xml;base64,${window.btoa(t)}`
    return img
  })
  context.fillStyle = theme.palette.primary.dark
  context.fillRect(0, 0, width, height / 2)
  context.fillStyle = theme.palette.secondary.dark
  context.fillRect(0, height / 2, width, height)
  context.strokeStyle = theme.palette.success.main
  treeImages.forEach((treeImage, i) => {
    context.drawImage(treeImage, i * 30, 50 - i * 25)
    context.drawImage(treeImage, 250 - i * 30, 50 - i * 25)
  })
}

function drawTelemetry(telemetry, context) {
  // TODO: based on roll, pitch, yaw, draw false horizon
}

function HeadUpDisplay({ classes, telemetry }) {

  const [ draw, setDraw ] = useState(null)
  const canvasRef = useCanvas({ draw: draw || drawDefault })

  useEffect(() => {
    if (!telemetry.time) {
      console.log('no telemetry, do nothing')
    }
    else {
      console.log('draw new telemetry', telemetry)
      setDraw((context) => drawTelemetry(telemetry, context))
    }
  }, [telemetry])

  return <canvas ref={canvasRef}
    className={classes.canvas}
  />
}

const mapStateToProps = state => ({
  telemetry: state.telemetry.telemetry,
})

export default connect(mapStateToProps, null)(withStyles(styles)(HeadUpDisplay))
