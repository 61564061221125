import {
  Button,
  Grid,
  Typography,
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { useGetActiveHandoverRequestsQuery, useGetAllFlightStatusQuery, useRequestControlMutation, useTakeControlMutation } from '../../api/liveflights'
import { getDecodedToken } from '../../api/accounts'

const styles = theme => ({
  root: {
    height: `calc(100vh - ${theme.spacing(6)}px)`,
    padding: theme.spacing(2),
    backgroundColor: theme.palette.bluegrey.dark,
  }
})
function SecondaryPilot() {
  const { drone_id, company_id, access_token, user_id } = getDecodedToken()
  const [requestControl, requestControlResult] = useRequestControlMutation()
  const [takeoverControl, takeControlResult] = useTakeControlMutation()

  const { data } = useGetActiveHandoverRequestsQuery({ drone_id, company_id, access_token }, {
    selectFromResult: (a) => {
      if(a.isSuccess){
        console.info(a)
        a.data = Object.values(a.data).filter((d) => (d.status === 'pending' && d.requested_on > (Date.now() - (30 * 1000)) ) || d.status === 'accepted')
      }
      return a
    }
  })
  console.info(data)
  const handleClickRequestControl = () => {
    requestControl({ drone_id })
  }
  const handleClickTakeover = (request) => {
    return () => takeoverControl(request)
  }

  const requests = data || []
  const IssuedRequests = requests.map((request) => {
    if (request?.requested_by.user_id !== user_id) {
      return <></>
    }
    
    return <Grid container key={request.handover_id} direction="column">
      <Grid item>
        {
          request.status === 'accepted' && <Button variant='outlined' onClick={handleClickTakeover(request)}>Takeover Control</Button> }
      </Grid>
      <Grid item>
        {
          request.status === 'pending' && <Typography>Waiting for primary pilot to approve handover</Typography>
        }
      </Grid>
    </Grid>
  })
  
  return <>
    <Typography variant="h4">Secondary Pilot View</Typography>
    <Button variant="outlined" onClick={handleClickRequestControl} disabled={requests.length > 0}> Request Control</Button>
    {IssuedRequests}
    { requestControlResult?.data?.status === 'success' && <Typography> Intiated Take Control Request</Typography>}
    { takeControlResult?.data?.status === 'fail' && <Typography> Failed to take control. Details: {takeControlResult?.data?.data?.handover_id}</Typography>}

  </>
}
function OtherRoles(){
  const { drone_id, company_id, access_token } = getDecodedToken()
  
  const { data } = useGetActiveHandoverRequestsQuery({ drone_id, company_id, access_token })
  const { data: flightData } = useGetAllFlightStatusQuery({ company_id, access_token,  only_ongoing_flights: true})

  const requests = data || {} 
  const flights = flightData || {}
  
  const Flights = Object.values(flights).map((r)=>{
    return <Grid item key={r.drone_id}>
      <Typography variant="body2">{r.flight_status_id}</Typography>
      <Typography> UAS Operation Lead: {r.personnel_roles?.uas_ops_lead?.name}</Typography>
      <Typography> Primary Pilot: {r.personnel_roles?.ua_pilot_primary?.name}</Typography>
      <Typography> Secondary Pilots: {r.personnel_roles?.ua_pilot_secondary.map((p)=> p.name).join(' , ')}</Typography>
      <br></br>
    </Grid>

  })
  const OngoingHandoverRequests = Object.keys(requests).map((k)=>{
    return <Grid item key={k}>
      <Typography variant="h5"> Handover ID {requests[k].handover_id} </Typography>
      <Typography> Requested by: {requests[k].requested_by.username} </Typography>
      <Typography> Status: {requests[k].status} </Typography>
      <Typography> Flight: {requests[k].flight_status_id} </Typography>
    </Grid>
  })
  return <>
    <Typography variant="h4">What other users see (across all drones)</Typography>
    
    <Grid container direction='column'>
      {Flights}
      {OngoingHandoverRequests}
    </Grid>
  </>
}

function RequestControl({ classes }) {
  // TODO: check if user is primary or secondary for this flight to decide what component to show
  return <>
    <hr></hr>
    <OtherRoles />
    <hr></hr>
    <SecondaryPilot />
  </>
}

export default withStyles(styles)(RequestControl)