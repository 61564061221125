import { useState, useEffect } from 'react'
import { bindActionCreators }  from 'redux'
import { connect } from 'react-redux'
import { echo } from '../../modules/actions/commandActions'
import { Typography } from '@material-ui/core'

function NetworkLatency({ echo, echoDuration }) {
  const [ latency, setLatency ] = useState(0)

  useEffect(() => {
    echo()
  }, [echo])

  useEffect(() => {
    if (echoDuration)
      setLatency(parseInt(echoDuration))
  }, [echoDuration])

  if (latency)
    return <Typography variant='h5'>{latency} ms</Typography>
  else
    return <Typography variant='h5'>- ms</Typography>
}

const mapStateToProps = state => ({
  echoDuration: state.command.echoDuration
})

const mapDispatchToProps = dispatch => {
  return bindActionCreators({ echo }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(NetworkLatency)
