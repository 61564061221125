import { createUserManager }    from 'redux-oidc'
import { WebStorageStateStore } from 'oidc-client'

const userManagerConfig = {
  // client_id: '2e6dba60-dad8-43b3-a51d-955945039ecb',

  authority:     process.env.REACT_APP_OIDC_AUTHORITY,
  client_id:     process.env.REACT_APP_OIDC_CLIENT_ID,
  redirect_uri:  process.env.REACT_APP_OIDC_REDIRECT_URI,
  response_type: 'code',
  scope:         'openid email plex offline_access',
  prompt:        'consent',

  loadUserInfo:         true,
  userStore:            new WebStorageStateStore({ store: window.localStorage }),

  automaticSilentRenew: true,
  silent_redirect_uri:  process.env.REACT_APP_OIDC_REDIRECT_URI,

  post_logout_redirect_uri: process.env.REACT_APP_POST_LOGOUT_REDIRECT_URI,

  //filterProtocolClaims: true,
}

const userManager = createUserManager(userManagerConfig)
export default userManager
