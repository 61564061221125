import { useRef, useEffect } from 'react'
import Sockette from 'sockette'
import PropTypes from 'prop-types'

// This is a generic WS listender that exposes events
function WSContainer({
  url,
  onOpen,
  onMessage,
  onReconnect,
  onMaxAttempts,
  onClose,
  onError,
  children
}) {
  // https://stackoverflow.com/questions/60152922/proper-way-of-using-react-hooks-websockets
  const wsRef = useRef(null)
  useEffect(() => {
    if (wsRef.current) {
      console.log(`ws ${url} alerady created`)
      return // only need to create it once
    }
    if (url) {
      console.log(`ws opening ${url}`)
      try {
        wsRef.current = new Sockette(url, {
          timeout: 10000,
          maxAttempts: 10,
          onopen:      e => onOpen        ? onOpen(e)        : console.debug('ws opened'),
          onmessage:   e => onMessage     ? onMessage(e)     : console.debug('ws received msg', e),
          onreconnect: e => onReconnect   ? onReconnect(e)   : console.debug('ws reconnecting..', e),
          onmaximum:   e => onMaxAttempts ? onMaxAttempts(e) : console.debug('ws stop attempting', e),
          onclose:     e => onClose       ? onClose(e)       : console.debug('ws closed', e),
          onerror:     e => onError       ? onError(e)       : console.debug('ws error', e),
        })
        const wsCurrent = wsRef.current
        return (() => {
          console.debug(`ws closing ${url}`)
          wsCurrent.close()
        })
      } catch (e) {
        console.info(e)
        if (onError) onError(e)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [url])

  return <>{children}</>
}

WSContainer.propTypes = {
  onMessage: PropTypes.func,
  onReconnect: PropTypes.func,
  onMaxAttempts: PropTypes.func,
  onClose: PropTypes.func,
  onError: PropTypes.func,
  onOpen: PropTypes.func
}

export default WSContainer
