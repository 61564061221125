import { bindActionCreators } from 'redux'
import { connect }            from 'react-redux'
import { requestControl }     from '../../modules/actions/commandActions'
import { addWarning }         from '../../modules/actions/warningsActions'

import { Button }     from '@material-ui/core'
import { RepeatOne }  from '@material-ui/icons'
import { withStyles } from '@material-ui/core/styles'

const styles = theme => ({
  button: {
    width:  theme.spacing(24),
    height: theme.spacing(7),
  },
})

function TakeOverButton({ classes, connected, inControl, isLocked, isReady, takeOff, addWarning }) {
  const canTakeover = connected && !inControl && !isLocked && isReady

  function handleRequestControl() {
    addWarning({
      source: 'GCS',
      severity: 'NO_EFFECT',
      details: 'Request for Control sent to Primary Pilot',
      name: 'OTHER',
      time: Date.now()
    })
    requestControl()
  }

  return (
    <Button
      color='primary'
      variant='contained'
      disabled={!canTakeover}
      className={classes.button}
      startIcon={<RepeatOne />}
      onClick={() => handleRequestControl()}>
      Take Over
    </Button>
  )
}

const mapStateToProps = state => ({
  connected: state.telemetry?.linkStatus === null,
  isLocked:  state.command.isLocked,
  isReady:   state.command.isReady,
  inControl: state.command.inControl,
})

const mapDispatchToProps = dispatch => {
  return bindActionCreators({ requestControl, addWarning }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(TakeOverButton))
