import { useState, useEffect } from 'react'
import { getAppConfig, getAppStats, getInstanceConfig } from '../api/skystream'
import Header from '../components/Header'
import GridHeader from './Components/GridHeader'
import GridPanel from './Components/GridPanel'

import {
  CssBaseline,
  IconButton,
  Grid,
  Typography,
} from '@material-ui/core'
import {
  Info,
} from '@material-ui/icons'
import { withStyles } from '@material-ui/core/styles'

const styles = theme => ({
  root: {
    pading: theme.spacing(2),
    maxWidth: theme.breakpoints.values.md,
    margin: '0 auto',
  },
  titleContainer: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    display: 'flex',
    justifyContent: 'space-between'
  },
  title: {
    lineHeight: 1,
    padding: theme.spacing(1, 3),
  },
})

function VideoHealth({ classes }) {

  const [ appConfig, setAppConfig ] = useState({})
  const [ appStats,  setAppStats  ] = useState({})
  const [ instanceConfig, setInstanceConfig ] = useState({})

  useEffect(() => {
    Promise.allSettled([
      getAppConfig(),
      getAppStats(),
      getInstanceConfig(),
    ]).then(result => {
      setAppConfig(result[0].status === 'success' ? result[0].data : {})
      setAppStats(result[1].status === 'success' ? result[1].data : {})
      setInstanceConfig(result[2].status === 'success' ? result[2].data : {})
    })
  }, [])

  console.log('appConfig', appConfig)
  console.log('appStats', appStats)
  console.log('instanceConfig', instanceConfig)
  const webrtc = appConfig.webRTCConfig || {}
  const stream = appConfig.streamConfig || {}
  const sec    = appConfig.securityConfig || {}
  function clean(str) {
    const m = str && str.match(/\$\{.+\}/g)
    return m ? str.split(m[0]).join('{home}') : str
  }
  const incoming = instanceConfig.incomingStreams || []
  const outgoing = instanceConfig.outgoingStreams || []
  const recorders = instanceConfig.recorders || []

  return <>
    <CssBaseline />
    <Header />
    <div className={classes.root}>
      <div className={classes.titleContainer}>
        <div className={classes.title}>
          <Typography variant='overline'>
            Video Server Health
          </Typography>
          <Typography variant='h5'>
            Garuda SkyStream
          </Typography>
        </div>
        <IconButton className={classes.info}>
          <Info />
        </IconButton>
      </div>
      <Grid container>
        <GridHeader title='SkyStream Config' />
        <GridPanel label='Server Name' value={appConfig.serverName} />
        <GridPanel label='Application Name' value={appConfig.name} />
        <GridPanel label='Application Type' value={appConfig.appType} />
        <GridPanel label='Incoming Streams' value='None' list={incoming} />
        <GridPanel label='Outgoing Streams' value='None' list={outgoing} />
        <GridPanel label='Recorders' value='None' list={recorders} />

        <GridHeader title='SkyStream WebRTC Config' />
        <GridPanel label='Enable Play' value={webrtc.enablePlay} />
        <GridPanel label='Enable Publish' value={webrtc.enablePublish} />
        <GridPanel label='Enable Query' value={webrtc.enableQuery} />
        <GridPanel label='Audio Codec' value={webrtc.preferredCodecsAudio} />
        <GridPanel label='Video Codec' value={webrtc.preferredCodecsVideo} />
        <GridPanel label='ICE Candidates' value={webrtc.iceCandidateIpAddresses} />

        <GridHeader title='SkyStream Stream Config' />
        <GridPanel label='Storage Directory' value={clean(stream.storageDir)} />
        <GridPanel label='Storage Directory Exists' value={stream.createStorageDir} />
        <GridPanel label='Create Storage Directory' value={stream.storageDirExists} />
        <GridPanel label='Keys Directory' value={clean(stream.keyDir)} />
        <GridPanel label='Stream Type' value={stream.streamType} />
        <GridPanel label='Randomize Media Name' value={stream.httpRandomizeMediaName} />

        <GridHeader title='SkyStream Network and Security Config' />
        <GridPanel label='Ping Timeout' value={appConfig.pingTimeout} />
        <GridPanel label='Application Timeout' value={appConfig.applicationTimeout} />
        <GridPanel label='HTTP CORS Enabled' value={appConfig.httpCORSHeadersEnabled} />
        <GridPanel label='Play Authentication' value={sec.playAuthenticationMethod} />
        <GridPanel label='Play Max Connection' value={sec.playMaximumConnections} />
        <GridPanel label='Play Require Secure' value={sec.playRequireSecureConnection} />
      </Grid>
    </div>
  </>

}

export default withStyles(styles)(VideoHealth)