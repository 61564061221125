import { useState } from 'react'
import { connect } from 'react-redux'

import MissionInfo from './Components/MissionInfo'
import MenuDrawer  from './Components/MenuDrawer'
import {
  Button,
  SvgIcon,
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import logo from '../static/images/horizon.logo.png'
import { getDecodedToken } from '../api/accounts'
import { useGetAllFlightStatusQuery } from '../api/liveflights'

const styles = theme => ({
  container: {
    display: 'flex',
    width: '100vw',
    height: theme.spacing(6),
    backgroundColor: theme.palette.common.black,
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  logoContainer: {
    display: 'flex',
    alignItems: 'center', 
  },
  logo: {
    padding: theme.spacing(1)
  },
  menuIcon: {
    color: theme.palette.common.white,
    fontSize: 36,
    right: 0,
  },
})

function Header({
  classes,
  user,
  mission,
  hideMissionInfo,
  overwriteDrone,
  overwritePilot,
  overwriteCommander,
}) {
  const [ open, setOpen ] = useState(false)
  const { drone_id, company_id, access_token} = getDecodedToken()
  const { data: flights } = useGetAllFlightStatusQuery({ company_id, access_token, only_ongoing_flights: true})
  
  const flightStatusDetail = flights ? flights[drone_id] : {}

  return (
    <div className={classes.container}>
      <div className={classes.logoContainer}>
        <img className={classes.logo} src={logo} alt='Plex Horizon Logo' />
      </div>
      { mission && !hideMissionInfo && 
        <MissionInfo
          name={mission.name}
          drone={overwriteDrone || (mission?.droneId && (mission.droneName || mission.droneModel)) || '-'}
          pilot={overwritePilot || flightStatusDetail?.personnel_roles?.ua_pilot_primary?.name || '-'}
          commander={overwriteCommander || flightStatusDetail?.personnel_roles?.uas_ops_lead?.name || '-'}
        />
      }
      <Button onClick={() => setOpen(true)}>
        <SvgIcon className={classes.menuIcon}>
          <path d='M3,6H21V8H3V6M3,11H21V13H3V11M3,16H21V18H3V16Z' />
        </SvgIcon>
      </Button>
      <MenuDrawer
        open={open}
        onClose={() => setOpen(false)}
        user={user}
      />
    </div>
  )
}

const mapStateToProps = (state) => ({
  mission: state.mission.mission,
  user:    state.oidc.user,
})
export default connect(mapStateToProps, null)(withStyles(styles)(Header))
