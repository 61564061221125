import { CHOOSE_MISSION, RESET_MISSION } from '../actions/mission'
import {
  INIT_ROLES_ACTION,
  REQUEST_CONTROL,
  REQUEST_CONTROL_OK,
  TAKE_CONTROL,
  ECHO_ACTION,
  TAKE_OFF_ACTION,
  MISSION_ACTION,
  HOLD_ACTION,
  YAW_ACTION,
  NEXT_WAYPOINT_ACTION,
  PREV_WAYPOINT_ACTION,
  NAVIGATE_ACTION,
  LAND_ACTION,
  SBTL_ACTION,
  END_FLIGHT_ACTION,
  DETECT_STICK_CONTROL,
  TOGGLE_STICK_CONTROL,
  ECHO_PING_TIME,
  SWITCH_NETWORK,
  RESETTING_NETWORK_SWITCH,
  SWITCH_NETWORK_FAIL,
} from '../actions/commandActions'

import {
  TELEMETRY_UPDATE_DRONE_STATE
} from '../actions/telemetryActions'

// Allowable commands (all assumes drone is connected and UI is not locked for read-only clients)
// [ AND CONDITION ]
//                     isReady isTakingOff isFlying isMoving isLanding isLanded
// Take Off               T
// Yaw Left/Right                              T
// Manual Stick Control                        T
// Play Mission / Fly Here / NextPrev Waypoint T        F
// Pause (Mission, Moving, Backtrack, Landing)          T
// Land (here) / SBTL                          T                 F
//
// [ Outstanding ]
// Return Home (not recommended for BVLOS)     T                 F
// Upload Flight Logs                                                      T
//
const initialState = {
  // Check whether drone command is locked (for read-only clients)
  isLocked: false, // TO DEPRECATE

  role: { // Person(s) carrying out this role in { user_id, username, name } format
    uas_ops_lead:               null,
    ua_pilot_primary:           null,
    ua_pilot_secondary:         [],
    ground_equipment_operator:  [],
    ground_safety_officer:      [],
    uas_maintenance_officer:    null,
    uas_maintenance_eng:        [],
    uas_ground_technician:      [],
    ua_recovery_officer:        null,
    ua_recovery_responder:      [],
    engineering_on_call:        [],
    responsible_personnel:      null,
    map_maintenance_specialist: [],
  },

  // See flight state diagram mapping in telemetryActions
  isReady:     false, // The drone is ready to fly
  isTakingOff: false, // The drone is taking off
  isFlying:    false, // The drone is flying
  isMoving:    false, // The drone is moving
  isLanding:   false, // The drone is landing
  isLanded:    false, // The drone has landed

  inControl:   true,  // The drone is currently controlled by the user
                      // TODO: match logged in user with current controller of
                      //       drone from backend. For now, everyone's in control

  stickDetected: false, // The device providing the stick input has been detected
  stickEnabled:  false, // The pilot has been enabled manual control from device

  // The available emergency landing zones
  emergencyLandingZones: [],
  // The target emergency landing zone
  targetEmergencyLandingZone: null,
  // Check if network switch is successful
  isNetworkSwitchSuccess: false,
}

function commandReducer(state = initialState, action) {
  switch (action.type) {
    case RESET_MISSION:
      return {
        ...state,
        ...initialState,
      }
    case CHOOSE_MISSION:
      return {
        ...state,
      }
    case TELEMETRY_UPDATE_DRONE_STATE:
      return {
        ...state,
        ...(action?.droneState ?? initialState),
      }
    case INIT_ROLES_ACTION:
      return {
        ...state,
        // TODO - update role based on what server sends back
      }
    case REQUEST_CONTROL:
      return {
        ...state,
        // TODO - This might be stateless
      }
    case REQUEST_CONTROL_OK:
      return {
        ...state,
        // TODO - This might be stateless too (approval_token is fire and forget)
      }
    case TAKE_CONTROL:
      return {
        ...state,
        // TODO - Open ended discussion here: To ensure that all front ends get
        //        the updated role object everywhere, we need to insert it into
        //        something like a telemetry. Merge it with existing telemetry?
        //        Standalone? This reducer cannot change own state unilaterally.
      }
    case ECHO_ACTION:
      const now = Date.now()
      const then = window.localStorage.getItem(ECHO_PING_TIME)
      window.localStorage.removeItem(ECHO_PING_TIME)
      return {
        ...state,
        echoDuration: now - then
      }
    case TAKE_OFF_ACTION:
      return {
        ...state,
        // These state will no longer change until telem comes back as LAUNCH
        // isTakingOff: true,
        // isMoving: true,
        // isFlying: true,
        // TODO: Need to collect back a true ACK (command received) from the drone
      }
    case MISSION_ACTION:
      return {
        ...state,
        // These state will no longer change until telem comes back as MISSION
        // isMoving: true,
        // TODO: Need to collect back a true ACK (command received) from the drone
      }
    case HOLD_ACTION:
      return {
        ...state,
        // These state will no longer change until telem comes back as HOVER
        // isMoving: false,
        // isLanding: false,
        // TODO: Need to collect back a true ACK (command received) from the drone
      }
    case YAW_ACTION:
      return {
        ...state
        // TODO: Need to collect back a true ACK (command received) from the drone
      }
    case NEXT_WAYPOINT_ACTION:
      return {
        ...state,
        // These state will no longer change until telem comes back as MOVING
        // isMoving: true,
        // TODO: Need to collect back a true ACK (command received) from the drone
      }
    case PREV_WAYPOINT_ACTION:
      return {
        ...state,
        // These state will no longer change until telem comes back as MOVING
        // isMoving: true,
        // TODO: Need to collect back a true ACK (command received) from the drone
      }
    case NAVIGATE_ACTION:
      return {
        ...state
      }
    case LAND_ACTION:
      return {
        ...state,
        // These state will no longer change until telem comes back as LAND
        // isLanding: true,
        // TODO: Need to collect back a true ACK (command received) from the drone
        targetEmergencyLandingZone: null,
      }
    case SBTL_ACTION:
      return {
        ...state,
        // These state will no longer change until telem comes back as EMERGENCY
        // isLanding: true,
        // TODO: Need to collect back a true ACK (command received) from the drone
        targetEmergencyLandingZone: action.targetEmergencyLandingZone,
      }
    case END_FLIGHT_ACTION:
      return {
        ...state,
      }
    case DETECT_STICK_CONTROL:
      return {
        ...state,
        stickDetected: action.stickDetected,
      }
    case TOGGLE_STICK_CONTROL:
      console.log(`stick ${state.stickEnabled ? 'disabled' : 'enabled'}`)
      return {
        ...state,
        // For discussion -> once stick is enabled, technically isMoving needs to be true
        // i.e. there shouldn't be an uncontrolled manual mixing like VLOS controller
        stickEnabled: !state.stickEnabled,
      }
    case SWITCH_NETWORK:
      return {
        ...state,
        isNetworkSwitchSuccess: true,
      }
    case RESETTING_NETWORK_SWITCH:
      return {
        ...state,
        isNetworkSwitchSuccess: false,
      }
    case SWITCH_NETWORK_FAIL:
      return {
        ...state,
        isNetworkSwitchSuccess: false,
      }
    default:
      return state
  }
}

export default commandReducer
