import {
  Grid,
  Typography,
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'

const styles = theme => ({
  header: {
    marginTop: theme.spacing(1),
    padding: theme.spacing(1),
    backgroundColor: theme.palette.bluegrey.dark,
  },
})

function GridHeader({ classes, title }) {
  return (
    <Grid item xs={12} className={classes.header}>
      <Typography variant='h6'>{title}</Typography>
    </Grid>
  )
}

export default withStyles(styles)(GridHeader)
