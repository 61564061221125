import {
  WARNING_TIMEOUT_DURATION,
  ADD_WARNING,
  UPDATE_WARNING_TIMEOUT,
} from '../actions/warningsActions'
// import prettyTimestamp from '../../utils/prettyTimestamp'

/**
 * A key value store of all active warnings.
 * The active warnings store has the shape:
 * OLD:
 * [code: string]: {
 *   startingTimestamp: number,
 *   latestTimestamp: number,
 *   count: number,
 *   severity: number,
 *   name: string,
 * }
 * NEW:
 * [name: string]: {
 *   startingTime: number,
 *   latestTime: number,
 *   count: number,
 *   severity: number,
 *   source: string,
 *   details: string,
 * }
 */
const initialState = {
  activeWarnings: {},
  previousWarnings: [],
}

function warningsReducer(state = initialState, action) {
  const { activeWarnings, previousWarnings } = state
  switch (action.type) {
    case ADD_WARNING: {
      console.log('warningsReducer', action)
      // const { code, severity, timestamp, info } = action.warning
      const { source, severity, details, name, time } = action.warning
      // const modifiedName = getName(action.warning)
      const startingTime = activeWarnings[name] ? activeWarnings[name].startingTime : time
      const count = activeWarnings[name] ? activeWarnings[name].count + 1 : 1
      return {
        activeWarnings: {
          ...activeWarnings,
          [name]: {
            severity,
            startingTime,
            latestTime: time,
            count,
            source,
            details,
            // info, // TODO: This does not exists
          }
        },
        previousWarnings,
      }
    }
    case UPDATE_WARNING_TIMEOUT: {
      const currentActiveWarning = activeWarnings[action.name]
      if (!currentActiveWarning) {
        return state
      }
      const now = Date.now()
      // Warning expired.
      if (currentActiveWarning.latestTime * 1000 + WARNING_TIMEOUT_DURATION <= now) {
        // Remove the warning from active warnings.
        const filteredWarningsKeys = Object.keys(activeWarnings).filter(name => name !== action.name)
        const filteredActiveWarnings = filteredWarningsKeys.reduce((acc, key) => ({
          ...acc,
          [key]: activeWarnings[key],
        }), {})
        return {
          activeWarnings: filteredActiveWarnings,
          previousWarnings: [
            ...previousWarnings,
            activeWarnings[action.name],
          ]
        }
      }
      return state
    }
    default:
      return state
  }
}

// TODO: info does not exist in the new definition
// const getName = (warning) => {
  // const { code, info, name, timestamp } = warning
  // const { source, severity, details, name, time } = warning
  // switch (code) {
  // switch (name) {
    // case 'NETWORK_RECOVERED_INTERMITTENT': {
    //   const lastUpdatedTime = info.timestamp_since_last_update 
    //   const durationInSec = time - lastUpdatedTime
    //   return `Network recovered after ${prettyTimestamp(durationInSec)} intermittent network loss`
    // }
    // case 'NETWORK_RECOVERED_COMPLETE': {
    //   const lastUpdatedTime = info.timestamp_since_last_update
    //   const durationInSec = time - lastUpdatedTime
    //   return `Network recovered after ${prettyTimestamp(durationInSec)} complete network loss`
    // }
    // default:
      // return name
  // }
// }

export default warningsReducer
