import { GeoJSON } from 'react-leaflet'

function FreeFlightArea({ areas }) {
  console.log('drawing ffa:', areas)
  return <>
    { areas.map((area, i) => <GeoJSON key={i} data={area} />) }
  </>
}

export default FreeFlightArea
