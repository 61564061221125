import {
  Tooltip,
  Typography,
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'

const styles = theme => ({
  indicator: {
    display: 'flex',
    justifyContent: 'space-evenly',
  },
  container: {
    // border: '1px solid grey',
    padding: theme.spacing(2),
    textAlign: 'center',
    width: '100%',
  }
})

function StatusGroup({ classes, tooltip, title, children }) {
  return (
    <div className={classes.container}>
      <Tooltip title={tooltip || ' '}>
        <Typography variant='body1'>{title}</Typography>
      </Tooltip>
      <div className={classes.indicator}>{children}</div>
    </div>
  )
}

export default withStyles(styles)(StatusGroup)
