import { Tooltip } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import theme from '../../utils/site-theme'

const style = theme => ({
  icon: {
    width: '100%',
    maxHeight: '54px',
  },
  fill: {
    fill: theme.palette.success.main,
  },
})

function SignalBars({ classes, signalStrength, tooltipText }) {
  const findStrengthLevel = signalStrength => {
    switch (signalStrength) {
      case 'good':
        return 3
      case 'caution':
        return 2
      case 'error':
        return 1
      default:
        return 0
    }
  }
  const strengthLevel = findStrengthLevel(signalStrength)
  const barStyle = {
    fill:
    strengthLevel === 3
      ? theme.palette.success.main
      : strengthLevel === 2 
        ? theme.palette.warning.main
        : theme.palette.error.main,
  }
  const opacity = [...Array(3).keys()].map(i => (i < strengthLevel ? barStyle : null))

  return (
    <Tooltip title={tooltipText}>
      <svg
        fill='rgba(255, 255, 255, 0.38)'
        className={classes.icon}
        version='1.1'
        id='Capa_1'
        xmlns='http://www.w3.org/2000/svg'
        // xmlns:xlink='http://www.w3.org/1999/xlink'
        x='0px'
        y='0px'
        width='511.626px'
        height='511.627px'
        viewBox='0 0 511.626 511.627'
        // style='enable-background:new 0 0 511.626 511.627;'
        // xml:space='preserve'
        >
        <path
          style={opacity[0]}
          d='M173.589,365.451h-54.818c-2.667,0-4.854,0.855-6.567,2.566c-1.711,1.711-2.568,3.901-2.568,6.563v91.358
        c0,2.669,0.854,4.859,2.568,6.57c1.713,1.711,3.899,2.566,6.567,2.566h54.818c2.663,0,4.853-0.855,6.567-2.566
        c1.709-1.711,2.568-3.901,2.568-6.57v-91.358c0-2.662-0.859-4.853-2.568-6.563C178.442,366.307,176.251,365.451,173.589,365.451z'
        />
        <path
          style={opacity[1]}
          d='M283.225,292.36h-54.818c-2.667,0-4.854,0.855-6.567,2.566c-1.711,1.711-2.57,3.901-2.57,6.563v164.456
        c0,2.665,0.856,4.855,2.57,6.563c1.713,1.718,3.899,2.57,6.567,2.57h54.818c2.665,0,4.855-0.853,6.563-2.57
        c1.711-1.708,2.573-3.898,2.573-6.563V301.49c0-2.662-0.862-4.853-2.573-6.563C288.081,293.216,285.89,292.36,283.225,292.36z'
        />
        <path
          style={opacity[2]}
          d='M392.857,182.725h-54.819c-2.666,0-4.856,0.855-6.57,2.568c-1.708,1.714-2.563,3.901-2.563,6.567v274.086
        c0,2.665,0.855,4.855,2.563,6.563c1.714,1.718,3.904,2.57,6.57,2.57h54.819c2.666,0,4.856-0.853,6.563-2.57
        c1.718-1.708,2.57-3.898,2.57-6.563V191.86c0-2.666-0.853-4.853-2.57-6.567C397.713,183.58,395.523,182.725,392.857,182.725z'
        />
      </svg>
    </Tooltip>
  )
}

export default withStyles(style)(SignalBars)
