import { useState, useEffect } from 'react'
import { withRouter }          from 'react-router'
import { connect }             from 'react-redux'

import axios           from 'axios'
import queryString     from 'query-string'
import { useInterval } from 'usehooks-ts'

import { getDecodedToken } from '../api/accounts'
import { submitToAirmap } from '../modules/actions/mission'
import { clearDeployment, retrieveDeployment } from '../modules/actions/deployment'

import Header           from '../components/Header'
import OvenWebRTCStream from '../video/Components/OvenWebRTCStream'
import WebRTCStream     from '../video/Components/WebRTCStream'
import LiveMap          from '../regional-map/LiveMap'

import DroneFleet        from './components/DroneFleet'
import VideoControls     from './components/VideoControls'
import CommanderControls from './components/CommanderControls'
import MiniTelemetry     from './components/MiniTelemetry'

import 'leaflet/dist/leaflet.css' // what is this for?
import {
  CssBaseline,
  Grid,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@material-ui/core'
import {
  ChevronLeft
} from '@material-ui/icons'
import withStyles from '@material-ui/core/styles/withStyles'

// TODO: Move these to RTK Query
const IDENTITY_API      = process.env.REACT_APP_OIDC_AUTHORITY
const PROJECTS_API      = process.env.REACT_APP_PROJECTS_API
const DEPLOYMENTS_API   = process.env.REACT_APP_DEPLOYMENTS_API
const FLIGHT_PLANS_API  = process.env.REACT_APP_FLIGHT_PLANS_API
const FLIGHT_STATUS_API = process.env.REACT_APP_FLIGHT_STATUS_WEB_API
// TODO: Consider adding Flight Status WSS API here instead of polling

const styles = theme => ({
  instruction: {
    paddingLeft: theme.spacing(20),
    paddingTop: theme.spacing(10),
  },
  leftColumn: {
    padding: theme.spacing(1)
  },
  centerColumn: {

  },
  rightColumn: {
    padding: theme.spacing(1)
  },
  liveMap: {
    height: theme.spacing(30),
    width: '100%',
  },
})

function HeliosMissionControl({
  classes,
  retrieveDeployment,
  updateMission,
  clearDeployment,
  deployment,
  telemetry,
  location,
  mission,
}) {
  const [selectedDrone, setSelectedDrone] = useState()
  const [drones, setDrones] = useState('')
  const [commanderInfo, setCommanderInfo] = useState()
  const [elzs, setELZs] = useState()
  const [project, setProject] = useState()

  const [videoStreams, setVideoStreams] = useState()
  const [selectedVideo, setSelectedVideo] = useState()
  const [onlineDrones, setOnlineDrones] = useState([])
  const [qs, setQs] = useState({})

  useEffect(() => {
    if (location && drones.length > 0) {
      const parsed = queryString.parse(location.search)
      if (parsed.droneId) {
        console.log('Taking control of drone id', parsed.droneId)
        
        const selectedDroneByQuery = drones.find((d) => d.id === parsed.droneId)
        setSelectedDrone(selectedDroneByQuery)
      }
      setQs(parsed)
    }
  }, [drones, location])

  useEffect(() => {
    axios.get(`${PROJECTS_API}/`).then((response) => {
      const htxProject = response.data.data.find((project) => project.project_id === 'htx-helios-project')
      setProject(htxProject)
    })

    // TODO: This is broken, to decide whether this screen is needed before moving from 
    //       FLEET_API (which has been retired) to the formal Aircraft API (using RTK Query)
    const FLEET_API = ''
    axios.get(`${FLEET_API}/drone/company_drones/`).then((response) => {
      const companyDrones = response.data
      setDrones(companyDrones.data)
    })

    // TODO: Replace with Accounts API (remove IDENTITY_API dependency)
    axios.get(`${IDENTITY_API}api/users/${getDecodedToken().user_id}`).then((r) => {
      setCommanderInfo(r.data.user)
    })

    axios.get(`${FLIGHT_STATUS_API}/`).then((r) => {
      if (r.data && r.data.data && r.data.data.flights) {
        setOnlineDrones(r.data.data.flights)
      } else {
        setOnlineDrones([])
      }
    })
  }, [])

  // TODO: Switch over to WSS instead of polling
  useInterval(() => {
    if (selectedDrone) {
      axios.get(`${FLIGHT_STATUS_API}/video/${selectedDrone.id}?video_source=ome`).then((r) => {
        setVideoStreams(r.data.data.streams)
      })
    }
  }, 1000)

  useEffect(() => {
    if (selectedDrone) {
      const onlineDroneInfo = onlineDrones.find((f) => f.drone_id === selectedDrone.id)
      if (onlineDroneInfo) {
        axios.get(`${FLIGHT_STATUS_API}/${selectedDrone.id}`).then(async (r) => {
          const flight = r.data.data.flight
          if (flight && flight.flightplan_id && flight.deployment_id) {
            const deploymentAndFpResponses = await Promise.all([
              axios.get(`${FLIGHT_PLANS_API}/${flight.flightplan_id}`),
              axios.get(`${DEPLOYMENTS_API}/${flight.deployment_id}`),
              axios.get(`${FLIGHT_STATUS_API}/video/${selectedDrone.id}?video_source=ome`)
            ]).then((responses) => responses.map((r) => r.data.data))

            retrieveDeployment(flight.deployment_id)

            const [flightplan, deployment, video] = deploymentAndFpResponses
            const copy = { ...flight }
            delete copy.flightplan_id
            delete copy.deployment_id

            if (flightplan) {
              updateMission({
                name: flightplan.description,
                route: flightplan.plan.commands,
                flightPlanId: flight.flightplan_id,
                droneId: selectedDrone.id,
                droneModel: '',
                droneName: selectedDrone.name,
                leadPilot: deployment.personnel[0].name,
                coPilot: deployment.personnel[1] ? deployment.personnel[1].name : null
              })

              setVideoStreams(video.streams)
            } else {
              updateMission({
                name: 'No mission',
                route: [],
                flightPlanId: '',
                droneId: selectedDrone.id,
                droneModel: '',
                droneName: selectedDrone.name,
                leadPilot: '',
                coPilot: ''
              })
              setVideoStreams([])
            }
          } else {
            clearDeployment()
            updateMission({
              name: 'No mission',
              route: [],
              flightPlanId: '',
              droneId: selectedDrone.id,
              droneModel: '',
              droneName: selectedDrone.name,
              leadPilot: '',
              coPilot: ''
            })
            setVideoStreams([])
          }
        })
      } else { // drone is not online
        clearDeployment()
        updateMission({
          name: 'No mission',
          route: [],
          flightPlanId: '',
          droneId: selectedDrone,
          droneModel: '',
          droneName: '',
          leadPilot: '',
          coPilot: ''
        })
      }
    } else {
      clearDeployment()
      updateMission({
        name: 'No mission',
        route: [],
        flightPlanId: '',
        droneId: selectedDrone,
        droneModel: '',
        droneName: '',
        leadPilot: '',
        coPilot: ''
      })
    }
  // eslint-disable-next-line
  }, [selectedDrone, onlineDrones])

  useEffect(() => {
    if (drones && onlineDrones.length === 1 && !selectedDrone) {
      const foundDrone = drones.find((d)=> d.id === onlineDrones[0].drone_id)
      setSelectedDrone(foundDrone)
    }
  // eslint-disable-next-line
  }, [onlineDrones, drones])

  // TODO: Switch over to WSS instead of polling
  useInterval(() => {
    axios.get(`${FLIGHT_STATUS_API}/`).then((r) => {
      if (r.data && r.data.data && r.data.data.flights) {
        setOnlineDrones(r.data.data.flights)
      } else {
        setOnlineDrones([])
      }
    })
  }, 2000)

  useEffect(() => {
    console.log(selectedVideo)
  }, [selectedVideo])

  useEffect(() => {
    if (deployment) { setELZs(deployment.emergency_landing_areas) }
  }, [deployment])

  if (telemetry.flight_state === 'RTF') {
    telemetry.flight_state = 'Ready to Fly'
  }
  const selectedDroneOnline = selectedDrone && Object.keys(telemetry).length > 0 && telemetry.drone_id === selectedDrone.id
  
  let status = 'Offline'
  if (selectedDroneOnline) {
    if (telemetry.flight_state === 'OG') {
      status = 'Mission Ended'
    } else if (telemetry.lat === '' || telemetry.lon === '') {
      status = 'Starting Mission'
    } else {
      status = 'Online'
    }
  }

  return (
    <>
      <CssBaseline />
      <Header
        overwriteDrone={selectedDrone?.name}
        overwritePilot='-' // how to determine the pilot?
        overwriteCommander={commanderInfo?.name}
      /> 
      <Grid container direction='row'>
        <Grid item xs={2} className={classes.leftColumn}>
          <Typography variant='h5'>
            Helios Mission Control
          </Typography>
          <Typography variant='caption'>
            Commander v1.1.0 (for HTX helios)
          </Typography>
          <DroneFleet
            drones={drones}
            onlineDrones={onlineDrones}
            setOnlineDrones={setOnlineDrones}
            selectedDrone={selectedDrone}
            setSelectedDrone={setSelectedDrone}
          />
          <VideoControls
            videoStreams={videoStreams}
            selectedVideo={selectedVideo}
            setSelectedVideo={setSelectedVideo}
          />
        </Grid>
        <Grid item xs={8} className={classes.centerColumn}>
          {
            !qs.new_video && selectedDrone &&
            <WebRTCStream
              width='100%'
              streamName={selectedVideo ? selectedVideo.name : 'forward'}
              applicationName={`live/${selectedDrone.id}`}
            />
          }
          {
            qs.new_video && selectedDrone &&
            <OvenWebRTCStream
              width='100%'
              streamName={selectedVideo ? selectedVideo.name : 'forward'}
              droneId={selectedDrone.id.substring(0, 25)}
            />
          }
          {
            !selectedDrone &&
            <ListItem className={classes.instruction}>
              <ListItemIcon><ChevronLeft /></ListItemIcon>
              <ListItemText
                primary='Mission Commander'
                secondary='Select a drone on the left to get started' />
            </ListItem>
          }
          <CommanderControls
            elzs={elzs}
            setELZs={setELZs}
            selectedDrone={selectedDrone}
          />
        </Grid>
        <Grid item xs={2} className={classes.rightColumn}>
          <LiveMap
            telemetry={(selectedDrone && telemetry && selectedDroneOnline) ? telemetry : {}}
            mission={(selectedDrone && mission) ? mission : null}
            elzs={(selectedDrone && deployment) ? { ...elzs } : null}
            latestWarning={null}
            targetGeoJson={(selectedDrone && deployment) ? deployment.geoJson : null}
            project={project}
            hideZoomControls
            hideDomeCamera
            className={classes.liveMap}
          />
          {
            selectedDrone && mission.droneId &&
            <MiniTelemetry
              status={status}
              mission={mission}
              telemetry={telemetry}
              selectedDrone={selectedDrone}
              selectedDroneOnline={selectedDroneOnline}
            />
          }
        </Grid>
      </Grid>
    </>
  )
}

function mapStateToProps(state) {
  return {
    mission: state.mission.mission,
    user: state.oidc.user,
    deployment: state.deployment?.deploymentData || null,
    telemetry: state.telemetry.telemetry
  }
}

export default connect(mapStateToProps, {
  updateMission: submitToAirmap,
  retrieveDeployment: retrieveDeployment,
  clearDeployment: clearDeployment
})(withStyles(styles)(withRouter(HeliosMissionControl)))
