import { Typography } from '@material-ui/core'
import { withStyles } from '@material-ui/styles'

const styles = theme => ({
  pill: {
    width: 'min-content',
    minWidth: '3em',
    borderRadius: '500px',
    backgroundColor: ({ severity }) =>
      theme.palette.risk[severity] || theme.palette.severity[severity] || theme.palette.risk.NO_EFFECT,
  },
  root: {
    textAlign: 'center',
    color: 'black',
    fontWeight: 800,
  }
})

function WarningCount({ classes, count }) {
  return (
    <div className={classes.pill}>
      <Typography className={classes.root}>{count}</Typography>
    </div>
  )
}

export default withStyles(styles)(WarningCount)
