import { bindActionCreators } from 'redux'
import { connect }            from 'react-redux'
import { useInControl }       from '../../utils/useFlightStates'
import { Button }             from '@material-ui/core'
import { withStyles }         from '@material-ui/core/styles'
import { moveToNextWaypoint } from '../../modules/actions/commandActions'
import { ReactComponent as NextWaypoint } from '../../static/images/icon_gotoWaypoint.svg'

const styles = theme => ({
  button: {
    width:  '100%',
    height: theme.spacing(7),
  },
  svg: {
    transform: 'scale(0.8, 0.8)',
  },
})

function NextWaypointButton({ classes, connected, isLocked, isFlying, isMoving, moveToNextWaypoint }) {
  const inControl = useInControl()

  const canMove = connected && inControl && isFlying && !isMoving
  
  return (
    <Button
      size='small'
      disabled={!canMove}
      variant='contained'
      color='primary'
      className={classes.button}
      onClick={moveToNextWaypoint}>
      <NextWaypoint className={classes.svg} />
    </Button>
  )
}

const mapStateToProps = state => ({
  connected: state.telemetry?.linkStatus === null,
  isLocked:  state.command.isLocked,
  isFlying:  state.command.isFlying,
  isMoving:  state.command.isMoving
})

const mapDispatchToProps = dispatch => {
  return bindActionCreators({ moveToNextWaypoint }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(NextWaypointButton))
